const LoadingTimeout = (num) => {
  let result = false;
  setTimeout(() => {
    result = true;
  }, num);

  return result;
};

const continuationCalculator = (birthday) => {
  let day1 = new Date(birthday);

  let day2 = new Date();

  let diff = Math.abs(day2 - day1);

  let daysCalc = diff / (1000 * 3600 * 24);

  let days1 = Math.floor(daysCalc);

  return days1;
};

const dateFormatter = (newDate) => {
  const dateString = newDate;
  const date = new Date(dateString);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfWeekTest = date.getDay();

  const dayOfMonth = date.getDate();

  const month = monthsOfYear[date.getMonth()];
  const year = date.getFullYear();

  let suffix;
  if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
    suffix = "st";
  } else if (dayOfMonth === 2 || dayOfMonth === 22) {
    suffix = "nd";
  } else if (dayOfMonth === 3 || dayOfMonth === 23) {
    suffix = "rd";
  } else {
    suffix = "th";
  }

  return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;
};

const isUrl = (str) => {
  // Regular expression to match URL pattern
  const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  return urlPattern.test(str);
};

const ZeroRemove = (array) => {
  let lastItem = array.length - 1;

  if (array[lastItem] === undefined || isNaN(array[lastItem])) {
    return array.pop();
  }

  if (array[lastItem].es || array[lastItem].endScore) {
    if (
      array[lastItem].es === undefined ||
      isNaN(array[lastItem].es) ||
      array[lastItem].endScore === undefined ||
      isNaN(array[lastItem].endScore)
    ) {
      return array.pop();
    }
  } else if (array[lastItem].total) {
    if (array[lastItem].total === undefined || isNaN(array[lastItem].total)) {
      return array.pop();
    }
  } else if (array[lastItem].tcr) {
    if (array[lastItem].tcr === undefined || isNaN(array[lastItem].tcr)) {
      return array.pop();
    }
  } else if (array[lastItem].wave) {
    if (array[lastItem].wave === undefined || isNaN(array[lastItem].wave)) {
      return array.pop();
    }
  } else {
    return array;
  }
};

const memorySelector = (days) => {
  let memArray =
    days &&
    days
      .filter((d) => d.rememberToday !== undefined)
      .map((d) => {
        return { date: d.logDate, memory: d.rememberToday };
      });

  let randomIndex = memArray && Math.floor(Math.random() * memArray.length);

  let memory = memArray && randomIndex && memArray[randomIndex];
  return memory.length > 4
    ? memory
    : "When you add your daily reflections, they will appear here at random.";
};

export {
  LoadingTimeout,
  continuationCalculator,
  ZeroRemove,
  dateFormatter,
  isUrl,
  memorySelector,
};
