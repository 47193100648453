// React
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// D3
import * as d3 from "d3";

// Internal Components
import { ZeroRemove } from "../../../utils/UtilityFunctions";

// Styling
import "./tc.css";

const TasksCompleted = (props) => {
  const navigate = useNavigate();
  const d3Chart1 = useRef();
  const parseDate = d3.timeParse("%Y-%m-%d");
  let device = props.device;

  // console.log(props.data);

  const getDataTransform = () => {
    const taskData = Array.isArray(props.data) && props.data;
    // console.log(taskData);
    taskData &&
      taskData.forEach((day) => {
        let sum =
          (day.momentOneScore !== undefined && parseFloat(day.momentOneScore)) +
          (day.momentTwoScore !== undefined && parseFloat(day.momentTwoScore)) +
          (day.momentThreeScore !== undefined &&
            parseFloat(day.momentThreeScore)) +
          (day.momentFourScore !== undefined &&
            parseFloat(day.momentFourScore)) +
          (day.momentFiveScore !== undefined &&
            parseFloat(day.momentFiveScore));
        // console.log(sum);

        day.total = sum;

        let count =
          (day.momentOneScore / day.momentOneScore === 1 ||
          day.momentOneScore == 0
            ? 1
            : 0) +
          (day.momentTwoScore / day.momentTwoScore == 1 ||
          day.momentTwoScore == 0
            ? 1
            : 0) +
          (day.momentThreeScore / day.momentThreeScore == 1 ||
          day.momentThreeScore == 0
            ? 1
            : 0) +
          (day.momentFourScore / day.momentFourScore === 1 ||
          day.momentFourScore == 0
            ? 1
            : 0) +
          (day.momentFiveScore / day.momentFiveScore === 1 ||
          day.momentFiveScore == 0
            ? 1
            : 0);
        day.tasksAttempted = count;

        let tcr = day.total / 10 / day.tasksAttempted;
        day.taskCompletionRate = tcr;
      });

    const tasksCompletedData =
      taskData.length > 7 &&
      taskData.map((each, index) => {
        let node = false;
        let memOne = each.momentOne;
        let memTwo = each.momentTwo;
        let memThree = each.momentThree;
        let memFour = each.momentFour;
        let memFive = each.momentFive;

        if (props.nodeSelected === each.logDate.slice(0, 10)) {
          node = true;
        }

        return {
          id: index,
          date: parseDate(each.logDate.slice(0, 10)),
          es: each.endScore,
          total: each.total,
          tcr: each.taskCompletionRate,
          node: node,
          m1: memOne,
          m1Score: each.momentOneScore,
          m2: memTwo,
          m2Score: each.momentTwoScore,
          m3: memThree,
          m3Score: each.momentThreeScore,
          m4: memFour,
          m4Score: each.momentFourScore,
          m5: memFive,
          m5Score: each.momentFiveScore,
        };
      });

    const tcDataFinal = tasksCompletedData.sort((a, b) => a.date - b.date);

    if (tcDataFinal.length > 5) {
      let tcDataLength = tcDataFinal.length - 1;
      if (tcDataFinal[tcDataLength].es === undefined) {
        tcDataFinal.pop();
      }
    }

    return tcDataFinal;
  };

  useEffect(() => {
    let tcData = getDataTransform();
    // console.log(tcData);
    const avgArray = [];

    tcData && tcData.map((each) => avgArray.push(each.total));

    ZeroRemove(avgArray);
    // console.log(avgArray);
    const avg = avgArray && Math.round(d3.mean(avgArray) * 10) / 10;

    const margin = { top: 24, right: 24, bottom: 48, left: 72 };
    const width =
      parseInt(d3.select(`#d3demo5${device}`).style("width")) -
      margin.left -
      margin.right;
    const height =
      parseInt(d3.select(`#d3demo5${device}`).style("height")) -
      margin.top -
      margin.bottom;

    // Set up chart
    const svg = d3
      .select(d3Chart1.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // x axis scale
    const x = d3
      .scaleTime()
      .domain(
        d3.extent(tcData, function (d) {
          return d.date;
        })
      )
      .range([0, width]);

    const tickFormat =
      tcData.length < 50 ? d3.timeFormat("%b %y") : d3.timeFormat("%b %y");

    const xAxis2 = d3.axisBottom(x).tickFormat(d3.timeFormat(tickFormat));

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis2.ticks(d3.timeMonth))
      .style("font-family", "Futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 200);

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 6) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Date");

    const max1 = d3.max(tcData, function (d) {
      return d.total;
    });
    const min1 = d3.min(tcData, function (d) {
      return d.total;
    });

    const upperLimit = max1 - max1 / 20;
    const lowerLimit = min1 + max1 / 20;

    var handleNavigate = (id) => {
      // navigate(`/today/${id}`);

      window.open(`/today/${id}`, "_blank");
    };

    const yScale = d3.scaleLinear().domain([0, 50]).range([height, 0]);

    const customTickLabels = ["Very Little", "Quite a lot"];
    const customTicks = [0, 50];

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(customTicks)
      .tickFormat((d, i) => customTickLabels[i]);

    svg
      .append("g")
      .call(yAxis)
      .style("font-family", "futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 200);

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Productivity");

    const Tooltip4 = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("border", "2px solid #004a22")
      .style("max-width", "250px")
      .style("border-radius", "2.5px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover1 = function (d) {
      Tooltip4.style("opacity", 1);
      Tooltip4.style("display", "flex");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)")
        .style("stroke-width", "2")
        .style("cursor", "pointer")
        .attr("width", 10);
    };

    const mousemove1 = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo5${device}`));

      Tooltip4.html(
        `<div style={{ display: 'flex', flex-direction: 'column'}}>
             <p>${d.m1}</p> 
             <p>${d.m2}</p> 
             <p>${d.m3}</p> 
             <p>${d.m4}</p> 
             <p>${d.m5}</p> 
								</div>`
      )
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "#004a22");
    };

    var mouseleave1 = function (d) {
      Tooltip4.style("opacity", 0);
      Tooltip4.style("display", "none");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "1")
        .style("cursor", "auto")
        .attr("width", 10);
    };

    svg
      .append("path")
      .datum(tcData)
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "1")
      .style("z-index", "-1")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", 4)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(avg);
          })
      );

    // Draw line
    svg
      .append("path")
      .datum(tcData)
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "0.1")
      .style("z-index", "-1")
      .attr("stroke-width", 6)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(d.total);
          })
          .curve(d3.curveBasis)
      );

    svg
      .append("g")
      .selectAll("rect") // Change to 'rect'
      .data(tcData)
      .enter()
      .append("rect") // Change to 'rect'
      .attr("x", function (d) {
        return x(d.date) - 2.5;
      }) // Adjust x position as needed
      .attr("y", function (d) {
        return yScale(d.total);
      })
      .attr("width", 10) // Set the width of the bars
      .attr("height", function (d) {
        return height - yScale(d.total);
      }) // Calculate the height of the bars
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners (ry is for
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (d.total > upperLimit || d.total < lowerLimit) {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(103, 36, 131,1)";
          }
        } else {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(0,74,34,1)";
          }
        }
      })
      .style("stroke", function (d) {
        // Check if the value falls within the specified range
        if (d.node === true) {
          return "#f5f5f5";
        } else {
          return "#e2dfcf";
        }
      })
      .style("stroke-width", "1")
      .style("opacity", 1)
      .style("z-index", 10)
      .on("mouseover", mouseover1)
      .on("mousemove", mousemove1)
      .on("mouseleave", mouseleave1)
      .on("click", function (d) {
        Tooltip4.style("opacity", 0);
        handleNavigate(d.srcElement.__data__.id);
      });
  }, [props.nodeSelected]);

  return (
    <div
      id={`d3demo5${device}`}
      style={props.graphWidth && { width: `${props.graphWidth}px` }}
    >
      <svg ref={d3Chart1}></svg>
    </div>
  );
};

export default TasksCompleted;
