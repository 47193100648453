// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// User Actions
import { getYearByIdAction } from "../../actions/yearActions";
import { listDays } from "../../actions/dayActions";
import {
  createWeekAction,
  getWeekbyIdAction,
  updateWeekAction,
} from "../../actions/weekActions";

// Internal Components
import Header from "../../layouts/Header/Header";
import TooltipWrapper from "../../components/MouseTooltip/TooltipWrapper";
import PageContainer from "../../layouts/PageContainer";
import WeekDock from "../../features/dock/WeekDock";
import Board from "../../components/Objective/Board/Board";
import {
  reorder,
  reorderQuoteMap,
  moveBetween,
} from "../../components/Objective/Reorder";
import TestFooter from "../../layouts/Footer/TestFooter";

// Custom Hooks
import useResponsiveState from "../../components/Hooks/useResponsiveState";

// Utils
import { FullDockMobileTwo } from "../../features/dock/FullDockMobile";

// Styling
import "./weeksscreen.css";
import TileLoading from "../../components/Loading/TileLoading";

function WeekScreen({ history }) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { viewType, data } = useResponsiveState();
  const { id } = useParams();

  // State
  // Redux
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const weekCreate = useSelector((state) => state.weekCreate);
  const { loading, error, weeks } = weekCreate;

  const yearList = useSelector((state) => state.yearList);
  const yearItem = yearList.years?.at(-1);

  const yearSingle = useSelector((state) => state.yearSingle);

  const weekSingle = useSelector((state) => state.weekSingle?.week);

  const dayList = useSelector((state) => state.dayList); //
  const { days } = dayList;

  // Initialised
  useEffect(() => {
    dispatch(getWeekbyIdAction(id));
  }, [dispatch, id, userInfo, history]);

  useEffect(() => {
    if (yearList && yearList.years && yearItem)
      dispatch(getYearByIdAction(yearItem._id));
  }, [yearList, yearItem, userInfo]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  const [pageState, setPageState] = useState(
    id ? "Update | Week" : "Create | Week"
  );
  const [cursorState, setCursorState] = useState("false");
  const [fontState, setFontState] = useState(72);
  const [cursorDisplayState, setCursorDisplayState] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [calloutActive, setCalloutActive] = useState(false);
  const [mouseState, setMouseState] = useState("");
  const [viewWidth, setViewWidth] = useState(
    Math.max(document.documentElement.clientWidth)
  );
  const [objectives, setObjectives] = useState({
    "obj-one": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-two": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-three": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-four": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-five": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-six": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-seven": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-eight": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-nine": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
    "obj-ten": {
      title: "",
      score: 0,
      active: 0,
      subTasks: [],
    },
  });
  const [ordered, setOrdered] = useState(
    objectives &&
      (viewType === "mobile" ? ["obj-one"] : Object.keys(objectives))
  );
  const [meyear, setMeyear] = useState({});
  const [load, setLoad] = useState("");

  console.log(ordered);

  const handleSave = () => {
    setLoad("success");
    setTimeout(() => {
      setLoad("");
    }, 2000);
  };

  useEffect(() => {
    if (weekSingle) {
      setObjectives(weekSingle);
    }
  }, [weekSingle]);

  useEffect(() => {
    if (yearSingle && yearSingle.year) {
      setMeyear((meyear) => ({
        obj: yearSingle.year,
        values_arr: Object.keys(yearSingle.year.values),
      }));
    }
  }, [yearSingle]);

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;

    setTimeout(() => {
      setPageLoading(false);
    }, randNum);
  };

  useEffect(() => {
    if (weekSingle) {
      loadingTimeout();
    }
  }, [id, weekSingle]);

  const handleColumnChange = (e) => {
    let data = e.currentTarget.dataset;
    let newColumn = data.name;
    setOrdered((ordered) => [newColumn]);
  };

  const handleOverlayChange = (newState) => {
    setOverlayVisible(newState);
  };

  const handleSort = (e, colIndex) => {
    // Capture data attributes from the clicked element
    const { name: sortStyle, sort: sortType } = e.target.dataset;

    setObjectives((prevObjectives) => {
      // Create a deep copy of the objectives to avoid state mutation
      const newObjectives = { ...prevObjectives };
      const column = newObjectives[colIndex];

      if (!column.subTasks) return prevObjectives;

      const subTasks = [...column.subTasks];

      // Sorting logic
      subTasks.sort((a, b) => {
        if (sortStyle === "urge") {
          return sortType === "asc"
            ? a.urgency - b.urgency // Ascending order
            : b.urgency - a.urgency; // Descending order
        } else {
          return sortType === "asc"
            ? new Date(a.deadline) - new Date(b.deadline) // Ascending deadline
            : new Date(b.deadline) - new Date(a.deadline); // Descending deadline
        }
      });

      // Update the specific column's subTasks
      column.subTasks = subTasks;

      return newObjectives;
    });
  };

  const changeSubTaskUrgency = (e, subTaskIndex, colIndex, ref) => {
    const data = e.currentTarget.dataset;
    const key = data.name ? data.name : e.target.name;
    const targetValue = data.value ? data.value : e.target.value;
    const displayName = data.displayName;

    if (displayName === "remove") {
      setObjectives((prev) => {
        let newState = { ...prev };
        let subTasksArray = newState[colIndex].subTasks;

        let taskObj = subTasksArray[subTaskIndex];

        delete taskObj[ref];

        return newState;
      });
    } else {
      if (displayName === "urgency") {
        setObjectives((prev) => {
          let newState = { ...prev };
          let subTasksArray = newState[key].subTasks;

          let taskObj = subTasksArray[subTaskIndex];

          taskObj.urgency = parseInt(targetValue);

          return newState;
        });
      } else {
        setObjectives((prev) => {
          let newState = { ...prev };
          let subTasksArray = newState[key].subTasks;

          let taskObj = subTasksArray[subTaskIndex];

          taskObj.deadline = targetValue;

          return newState;
        });
      }
    }
  };

  const changeObjectiveTitle = (e, order) => {
    let newTitle = e.target.value;
    let name = e.target.name;

    setObjectives((prev) => {
      let newState = { ...prev };

      let reorderedColumns = order.reduce((acc, key) => {
        if (newState[key]) {
          acc[key] = newState[key];
        }
        return acc;
      }, {});

      reorderedColumns[name].title = newTitle;

      return reorderedColumns;
    });
  };

  const updateDescription = (event, columnId, index) => {
    let newDescription = event.target.value;

    setObjectives((prevState) => {
      const updatedObjectives = { ...prevState };
      const task = updatedObjectives[columnId];
      if (task && task.subTasks[index]) {
        task.subTasks[index].description = newDescription;
      }
      return updatedObjectives;
    });
  };

  const deleteObjective = (id) => {
    setObjectives((prevState) => ({
      ...prevState,
      [id]: {
        title: "",
        active: 0,
        subTasks: [],
      },
    }));
  };

  const onDragEnd = (result) => {
    if (result.combine) {
      // After drag and drop, check if result is type COLUMN, then setOrdered
      if (result.type === "COLUMN") {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);

        return;
      }

      const column = objectives[result.source.droppableId];
      const withQuoteRemoved = [...column];

      withQuoteRemoved.splice(result.source.index, 1);

      const orderedColumns = {
        ...objectives,
        [result.source.droppableId]: withQuoteRemoved,
      };

      setObjectives(orderedColumns);
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const reorderedorder = reorder(ordered, source.index, destination.index);

      setOrdered(reorderedorder);
      return;
    }

    const subTaskMap = (objectives) => {
      return Object.keys(objectives).reduce((acc, key) => {
        acc[key] = objectives[key].subTasks;
        return acc;
      }, {});
    };

    const data = reorderQuoteMap({
      quoteMap: subTaskMap(objectives),
      source,
      destination,
    });

    setObjectives((prevObjectives) => {
      const updatedObjectives = { ...prevObjectives };

      Object.keys(data.quoteMap).forEach((key) => {
        updatedObjectives[key] = {
          ...updatedObjectives[key], // Keep title and active
          subTasks: data.quoteMap[key], // Update subTasks
        };
      });

      return updatedObjectives;
    });

    // handleSubmit();
  };

  const onDragEndStep = (result, columnId, subTaskIndex) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setObjectives((prevObjectives) => {
      // Copy the previous state
      const newObjectives = { ...prevObjectives };

      // Get the subTasks array that needs to be updated
      const subTasksToUpdate = [
        ...newObjectives[columnId].subTasks[subTaskIndex].steps,
      ];

      // Remove the dragged item from its previous position
      const [removed] = subTasksToUpdate.splice(source.index, 1);

      // Insert the dragged item at its new position
      subTasksToUpdate.splice(destination.index, 0, removed);

      // Update the subTasks array in the newObjectives
      newObjectives[columnId].subTasks[subTaskIndex].steps = subTasksToUpdate;

      return newObjectives;
    });
  };

  const addSubTask = (event, id) => {
    const descriptionValue = event.target.value;
    const name = event.target.name;
    const random = Math.floor(Math.random() * 100);

    setObjectives((prevState) => {
      // Make a copy of the previous state
      const updatedObjectives = { ...prevState };

      // Check if the objective exists in the state
      if (updatedObjectives[name]) {
        // Update the subTasks array of the specific objective
        updatedObjectives[name] = {
          ...updatedObjectives[name],
          subTasks: [
            ...updatedObjectives[name].subTasks,
            {
              id: `${name}-${id}-${random}`,
              description: descriptionValue,
              steps: [],
              notes: "",
              completed: 0,
            },
          ],
        };
      }

      return updatedObjectives;
    });

    // Clear the input value
    event.target.value = "";
  };

  const addStepTwo = (event, columnId, subTaskId, length) => {
    const descriptionValue = event.target.value;
    const random = Math.floor(Math.random() * 100);
    const id = length;

    setObjectives((prevState) => {
      // Make a copy of the previous state
      const updatedObjectives = { ...prevState };

      // Check if the objective exists
      if (updatedObjectives[columnId]) {
        // Find the specific subTask by subTaskId
        const updatedSubTasks = updatedObjectives[columnId].subTasks.map(
          (subTask) => {
            if (subTask.id === subTaskId) {
              // If it matches, add the new step
              return {
                ...subTask,
                steps: [
                  ...subTask.steps,
                  {
                    id: `${subTask.id}-${id}`,
                    action: descriptionValue,
                    completed: 0,
                  },
                ],
              };
            }
            return subTask; // Return unchanged
          }
        );

        // Update the subTasks of the specific objective
        updatedObjectives[columnId] = {
          ...updatedObjectives[columnId],
          subTasks: updatedSubTasks,
        };
      }

      return updatedObjectives;
    });
    event.target.value = "";
  };

  const toggleCompletedSubTasks = (index, columnId) => {
    // Assuming objectives and setObjectives are defined in your component
    // Make sure objectives is a state variable and setObjectives is a setState function

    // Make a copy of objectives[columnId]
    const columnObjectives = { ...objectives[columnId] };

    // Ensure columnObjectives has a subTasks array
    if (columnObjectives && Array.isArray(columnObjectives.subTasks)) {
      // Toggle the completed value of the specified subTask
      columnObjectives.subTasks[index].completed =
        columnObjectives.subTasks[index].completed === 0 ? 1 : 0;

      // Update the state with the new copy
      setObjectives((prevObjectives) => ({
        ...prevObjectives,
        [columnId]: columnObjectives,
      }));
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const reorderObjectives = (newOrder) => {
    const reordered = newOrder.reduce((acc, key) => {
      if (objectives[key]) {
        acc[key] = objectives[key];
      }
      return acc;
    }, {});
    setObjectives(reordered);
  };

  const toggleStep = (subTaskIndex, stepIndex, columnId) => {
    // Make a copy of the entire objectives object
    const updatedObjectives = { ...objectives };

    // Ensure columnId exists and has a subTasks array
    if (
      updatedObjectives[columnId] &&
      Array.isArray(updatedObjectives[columnId].subTasks)
    ) {
      const updatedSubTasks = [...updatedObjectives[columnId].subTasks];
      const updatedSteps = [...updatedSubTasks[subTaskIndex].steps];

      // Toggle the completed value of the specified subTask
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        completed: updatedSteps[stepIndex].completed === 0 ? 1 : 0,
      };

      // Update the subTasks array with the new copy
      updatedSubTasks[subTaskIndex] = {
        ...updatedSubTasks[subTaskIndex],
        steps: updatedSteps,
      };

      // Update the objectives object with the new subTasks array
      updatedObjectives[columnId] = {
        ...updatedObjectives[columnId],
        subTasks: updatedSubTasks,
      };

      // Update the state with the new objectives copy
      setObjectives(updatedObjectives);
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const handleCalloutDisplay = () => {
    setCalloutActive((calloutActive) => !calloutActive);
  };

  const updateStep = (columnId, subTaskIndex, stepIndex, event) => {
    const { value } = event.target;
    setObjectives((prevObjectives) => {
      const newObjectives = { ...prevObjectives };
      newObjectives[columnId].subTasks[subTaskIndex].steps[stepIndex].action =
        value;
      return newObjectives;
    });
  };

  const deleteStep = (columnId, subTaskIndex, stepIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives[columnId].subTasks[subTaskIndex].steps.splice(
      stepIndex,
      1
    );
    setObjectives(updatedObjectives);
  };

  const updateNotes = (columnId, index, event) => {
    let newNotes = event.target.value;

    const updatedObjectives = { ...objectives };
    updatedObjectives[columnId].subTasks[index] = {
      ...updatedObjectives[columnId].subTasks[index],
      notes: newNotes,
    };
    setObjectives(updatedObjectives);
  };

  const deleteSubtask = (columnId, subtaskIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives[columnId].subTasks.splice(subtaskIndex, 1);
    setObjectives(updatedObjectives);
  };

  let objectiveScoreSetter = (arr) => {
    /**
     * Score Setter Function for Each Objective
     * Returns cast that can be translated into opacity value for dock icons
     * Parameters:
     * Objective obj.subtask Array
     * Objective obj.steps Array
     */

    // Calculate denominator i.e. subTasks array length

    let denominator = arr.length;

    // Calculate numerator

    let generateNumerator = (arr) => {
      let globalNumerator = 0;

      // Count length of uncompleted subtasks (z)
      let incompleteTasks = 0;
      // Count how many subtasks have a completed of 1
      arr
        .filter((d, i) => d.completed === 1)
        .forEach((d, i) => globalNumerator++);

      arr
        .filter((d, i) => d.completed === 0)
        .forEach((d, i) => incompleteTasks++);

      // Transform remaining subtasks into shape favourable to extracting completed steps
      let testArr = [];
      arr
        .filter((d, i) => d.completed === 0)
        .filter((d, i) => d.steps.length > 0)
        .forEach((d, i) => testArr.push(d.steps));

      let numArr = [];
      let denArr = [];

      for (let i = 0; i < testArr.length; i++) {
        for (let j = 0; j < testArr[i].length; j++) {
          denArr.push(1);
          if (testArr[i][j].completed === 1) {
            numArr.push(testArr[i][j].completed);
          }
        }
      }

      if (numArr.length !== 0 && denArr.length !== 0) {
        let initialValue = 0;

        const x = numArr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );

        const y = denArr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );

        // Multiply x by the result of dividing x and y
        let final = (x / y) * testArr.length;

        // Add to previous global numerator to create final numerator
        let finalFinal = globalNumerator + final;

        return finalFinal;
      } else {
        return globalNumerator;
      }
    };

    let result =
      !isNaN((generateNumerator(arr) / denominator).toFixed(2)) &&
      (generateNumerator(arr) / denominator).toFixed(2);

    return result;
  };

  const handleScoreChange = (title) => {
    let result = objectiveScoreSetter(objectives[title].subTasks);

    setObjectives((prevObjectives) => ({
      ...prevObjectives,
      [title]: {
        ...prevObjectives[title], // Spread the existing objective's properties
        score: result, // Update the score
      },
    }));
  };

  const handleSubmit = async () => {
    if (id === undefined) {
      dispatch(createWeekAction(objectives))
        .then(async (response) => {
          if (response) {
            let nextPage = await response._id;
            navigate(`/week/${nextPage}`);
          } else {
            console.log("something still not right");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(updateWeekAction(id, objectives))
        .then(async (response) => {
          let r = await response;
          handleSave();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleMouseState = (str) => {
    if (str === mouseState) {
      setCursorDisplayState(false);
    } else {
      setMouseState(str);
      setCursorDisplayState(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageState}</title>
      </Helmet>
      <Header />
      <TooltipWrapper
        viewType={viewType}
        mouseState={mouseState}
        handleMouseState={handleMouseState}
        meyear={meyear}
        cursorDisplayState={cursorDisplayState}
      />
      <form>
        <PageContainer
          className={
            isOverlayVisible ? "wekContainer--overlay" : "wekContainer"
          }
        >
          {pageLoading ? (
            <TileLoading />
          ) : (
            objectives && (
              <>
                {viewType && viewType === "mobile" && objectives && (
                  <FullDockMobileTwo
                    handleColumnChange={handleColumnChange}
                    objectives={objectives}
                    handleSubmit={handleSubmit}
                  />
                )}
                <Board
                  initial={objectives}
                  ordered={ordered}
                  overlay={isOverlayVisible}
                  onDragEnd={onDragEnd}
                  onDragEndSteps={onDragEndStep}
                  handleScoreChange={handleScoreChange}
                  handleUrgencyChange={changeSubTaskUrgency}
                  handleSort={handleSort}
                  yearList={yearSingle}
                  addSubTask={addSubTask}
                  addStep={addStepTwo}
                  toggleSubTask={toggleCompletedSubTasks}
                  toggleStep={toggleStep}
                  toggleDisplay={handleCalloutDisplay}
                  toggleOverlay={handleOverlayChange}
                  updateTitle={changeObjectiveTitle}
                  updateOrder={reorderObjectives}
                  updateDescription={updateDescription}
                  updateStep={updateStep}
                  updateNotes={updateNotes}
                  deleteObjective={deleteObjective}
                  deleteStep={deleteStep}
                  deleteSubtask={deleteSubtask}
                  withScrollableColumns
                />

                <WeekDock
                  viewType={viewType}
                  dockLoading={pageLoading}
                  meyear={yearSingle.year}
                  yearid={yearItem._id}
                  load={load}
                  handleSubmit={handleSubmit}
                  handleMouseState={handleMouseState}
                />
              </>
            )
          )}
        </PageContainer>
      </form>
      <TestFooter />
    </>
  );
}

export default WeekScreen;
