// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Actions
import {
  getYearByIdAction,
  createYearAction,
  updateYearAction,
} from "../../actions/yearActions";
import { listDays } from "../../actions/dayActions";

// Internal Components
import TileLoading from "../../components/Loading/TileLoading";
import Header from "../../layouts/Header/Header";
import YearDock from "../../features/dock/YearDock";
import TestFooter from "../../layouts/Footer/TestFooter";
import PageContainer from "../../layouts/PageContainer";
import YearMap from "../../features/yearmap/YearMap";

// Utils
import {
  continuationCalculator,
  memorySelector,
} from "../../utils/UtilityFunctions";

// External Components

// CSS
import "./yearscreen.css";

export default function YearScreen({ history }) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearSingle = useSelector((state) => state.yearSingle?.year);

  const dayList = useSelector((state) => state.dayList);
  const { days } = dayList;

  const yearCreate = useSelector((state) => state.yearCreate); //
  // const { loading, error, success } = yearCreate;

  const yearUpdate = useSelector((state) => state.yearUpdate);
  const { loading, error, success } = yearUpdate;

  useEffect(() => {
    if (id) dispatch(getYearByIdAction(id));
  }, [id, userInfo]);

  console.log(days);
  // State
  // Initialise
  const [pageLoading, setPageLoading] = useState(true);
  const [viewWidth, setViewWidth] = useState(1400);
  const [meyear, setMeyear] = useState(
    yearSingle || {
      birthday: "",
    }
  );
  const [pageName, setPageName] = useState(
    id === undefined ? "Create | Year" : "Update | Year"
  );
  const [load, setLoad] = useState("");

  useEffect(() => {
    if (yearSingle) {
      setMeyear(yearSingle);
      if (yearSingle.birthday || meyear.birthday) {
        let birthday = yearSingle.birthday || meyear.birthday;
        setMeyear((prevYear) => {
          let shallow = { ...prevYear };

          shallow["continuation"] = continuationCalculator(birthday);
          if (days) {
            shallow["memory"] = memorySelector(days);
          }

          return shallow;
        });
      }
    }
  }, [yearSingle, meyear.birthday, days]);

  const handleSubmit = async () => {
    if (id === undefined) {
      dispatch(createYearAction(meyear))
        .then(async (response) => {
          if (response) {
            console.log("create");
            let nextPage = await response._id;
            navigate(`/year/${nextPage}`);
          } else {
            console.log("something still not right");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(updateYearAction(id, meyear))
        .then(async (response) => {
          let r = await response;
          console.log(r);
          handleSave();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChange = (e, tile_name) => {
    let { name, value, title } = e.target;
    let targetTile = tile_name;
    let targetName = name;
    let targetTitle = title;
    let inputValue = value;

    setMeyear((prevYear) => {
      let shallowYear = { ...prevYear };

      if (targetTile && targetTile.startsWith("v")) {
        shallowYear[targetTile] = {
          ...(shallowYear[targetTile] || {}),

          [targetName]: {
            ...(shallowYear[targetTile]?.[targetName] || {}),
            [targetTitle]: inputValue,
          },
        };

        return shallowYear;
      } else {
        shallowYear[targetTile] = inputValue;
        return shallowYear;
      }
    });
  };

  const handleSave = () => {
    setLoad("success");
    setTimeout(() => {
      setLoad("");
    }, 2000);
  };

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;

    setTimeout(() => {
      setPageLoading(false);
    }, randNum);
  };

  useEffect(() => {
    if (days) {
      loadingTimeout();
    }
  }, [id, days]);

  return (
    <>
      <>
        <Helmet>
          <title>{pageName}</title>
        </Helmet>
      </>
      <Header loading={load} />
      <form>
        <PageContainer className="yearContainer">
          {pageLoading ? (
            <TileLoading />
          ) : (
            <>
              <YearMap year={meyear} handleChange={handleChange} />
              <YearDock
                load={load}
                handleSubmit={handleSubmit}
                viewWidth={viewWidth}
              />
            </>
          )}
        </PageContainer>
      </form>
      <TestFooter />
    </>
  );
}
