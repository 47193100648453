// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//External Packages

// Internal Components
import UserDemographic from "../../components/Register/UserDemographics";
import TestFooter from "../../layouts/Footer/TestFooter";
import Header from "../../layouts/Header/Header";

// User Actions

// Images

// Styling
import "./registerscreen.css";

export default function RegisterDemographicScreen() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <section id="registerScreen">
        <UserDemographic />
      </section>
      <TestFooter />
    </>
  );
}
