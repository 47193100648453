//React
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

//Internal Components
import Login from "../../components/Login/Login";
import TestFooter from "../../layouts/Footer/TestFooter";
import Header from "../../layouts/Header/Header";

//CSS
import "./loginscreen.css";

export default function ViewLoginScreen() {
  const [paycPicker, setPaycPicker] = useState("");

  return (
    <>
      <Header variant="light" />
      <main id="registerScreen">
        <Login />
      </main>
      <TestFooter />
    </>
  );
}
