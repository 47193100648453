// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPointRight,
  faX,
  faFloppyDisk,
  faPenToSquare,
  faEraser,
  faCircleCheck,
  faQuestion,
  faSquareCaretRight,
  faSquareCaretLeft,
  faSquareXmark,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

// Internal Components
import TestFooter from "../../layouts/Footer/TestFooter";
import VideoComponent from "../../components/Video/Video";

export default function ViewInsightScreen({ history, dateSelected, props }) {
  // State
  const [cursorState, setCursorState] = useState("");
  const [cursorDisplayState, setCursorDisplayState] = useState(true);

  const [datePicker, setDatePicker] = useState();

  const [flipCardState, setFlipCardState] = useState("tile centre card");

  const [pageLoading, setPageLoading] = useState(true);
  const [buttonState, setButtonState] = useState(false);

  const [show, setShow] = useState(true);
  const [num, setNum] = useState(99);
  const [numTwo, setNumTwo] = useState(103);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showHideState = {
    show: {
      display: "flex",
      flexDirection: "column",
    },
    hide: {
      display: "none",
    },
  };

  const introState = {
    hidden: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const activeState = {
    hidden: {
      width: "20%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      width: "70%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      width: "20%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const activeStateImage = {
    hidden: {
      opacity: 0,
      width: "10%",
      position: "absolute",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      width: "20%",
      position: "absolute",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      width: "10%",
      position: "absolute",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  };

  const [instructionState, setInstructionState] = useState("");
  const [supportingState, setSupportingState] = useState("");
  const [clickCounter, setClickCounter] = useState(0);
  const [successState, setSuccessState] = useState(" .active");

  const clickPlus = () => {
    setClickCounter((clickCounter) => clickCounter + 1);
  };

  const clickMinus = () => {
    setClickCounter((clickCounter) => clickCounter - 1);
  };

  const dateSelector = (e) => {
    setDatePicker((datePicker) => e.target.value);
  };

  const displayText = () => {
    if (clickCounter === 0) {
      setInstructionState(
        (instructionState) =>
          "Your diary sits at the heart of your NoWastedDays."
      );
      setSupportingState((supportingState) => "");
    } else if (clickCounter === 1) {
      setInstructionState(
        (instructionState) =>
          "In this position, it can be supercharged by and also give life to other brilliant parts of the platform. Let's take a look at this, moving from outside to in."
      );
      setSupportingState((supportingState) => "");
    } else if (clickCounter === 2) {
      setInstructionState(
        (instructionState) =>
          "Your year map helps you define what matters most to you about the year ahead."
      );
      setSupportingState((supportingState) => "");
    } else if (clickCounter === 3) {
      setInstructionState(
        (instructionState) =>
          "Those great ideas are then available in your week planner to help you turn your goals and values into weeks that express them."
      );
      setSupportingState((supportingState) => "");
    } else if (clickCounter === 4) {
      setInstructionState(
        (instructionState) =>
          "These great plans can then easily become smart and streamlined days."
      );
      setSupportingState(
        (supportingState) =>
          "Click on each node to open the corresponding week objective. The nodes will fill up as you complete more of that objective to indicate what remains to be done."
      );
    } else if (clickCounter === 5) {
      setInstructionState(
        (instructionState) =>
          "Now, back at the diary, our attention turns to what fantastic opportunities it creates."
      );
      setSupportingState(
        (supportingState) => "I find this bit really, really exciting!"
      );
      setNum(100);
    } else if (clickCounter === 6) {
      setInstructionState(
        (instructionState) =>
          "In day-to-day life, we are constantly creating data about ourselves; the vast majority of which is never captured."
      );
      setSupportingState((supportingState) => "");
      setNum(101);
    } else if (clickCounter === 7) {
      setInstructionState(
        (instructionState) =>
          "By completing a day in your diary, you have captured a highly indicative and super insightful set of data from that day."
      );
      setSupportingState((supportingState) => "");
      setNum(102);
    } else if (clickCounter === 8) {
      setInstructionState(
        (instructionState) =>
          "These detailed days add up to build the opportunity for greater self-understanding in your insights hub."
      );
      setSupportingState((supportingState) => "");
      setButtonState((button) => true);
      setNumTwo(103);
    } else if (clickCounter === 9) {
      setInstructionState(
        (instructionState) =>
          "Look out for the purple days and data points. They offer you the greatest return on time spent trying to understand yourself more."
      );
      setSupportingState((supportingState) => "");
      setButtonState((button) => true);
      setNumTwo(104);
    } else if (clickCounter === 10) {
      setInstructionState(
        (instructionState) =>
          "What really brought you joy on that great day? What really got in the way of your productivity on that tricky day? What made a day slightly sour despite being successful on the surface?"
      );
      setSupportingState((supportingState) => "");
      setButtonState((button) => true);
      setNumTwo(105);
    } else if (clickCounter === 11) {
      setInstructionState(
        (instructionState) =>
          "If you find it hard to look at the charts, your insight story is there to help."
      );
      setSupportingState((supportingState) => "");
      setButtonState((button) => true);
      setNumTwo(106);
    } else if (clickCounter === 12) {
      setInstructionState(
        (instructionState) =>
          "To help you become an even more awesome analyser of your own data, we have created a unique online adventure called 'the Art of Looking'."
      );
      setSupportingState((supportingState) => "");
      setButtonState((button) => true);
    } else if (clickCounter === 13) {
      setInstructionState(
        (instructionState) =>
          "With this guidance, you are more than ready to get started. Click on the cross to return to your diary."
      );
      setSupportingState((supportingState) => "");
      setButtonState((button) => true);
    }
  };

  useEffect(() => {
    displayText();
  });

  const handleDataPointClick = (xValue) => {
    if (xValue) {
      // Access the x-axis value of the clicked data point here
      setDatePicker((datePicker) => xValue.toISOString().slice(0, 10));
    }
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  return (
    <>
      <Helmet>
        <title>Learn | Insights</title>
      </Helmet>

      <main className="tutorialpage--container" style={{ width: `${vw - 48}` }}>
        <motion.div className="instruction insightscreen">
          <div>
            <h2>{instructionState}</h2>
            <span>{supportingState}</span>
          </div>

          <div>
            <FontAwesomeIcon
              id="nextIcon"
              icon={faSquareCaretRight}
              onClick={clickPlus}
            />

            <a href={"/today"}>
              <FontAwesomeIcon id="crossIcon" icon={faSquareXmark} />
            </a>
            <FontAwesomeIcon
              id="nextIcon"
              icon={faSquareCaretLeft}
              onClick={clickMinus}
            />
          </div>
        </motion.div>

        <motion.section
          className="tutorial__main--insight"
          variants={introState}
          initial="hidden"
          animate={show ? "open" : "closed"}
        >
          <motion.div
            className="main__column--tutorial"
            variants={activeState}
            initial="hidden"
            animate={clickCounter === 2 ? "open" : "closed"}
          >
            <motion.div
              variants={showHideState}
              animate={clickCounter < 2 ? "show" : "hide"}
            >
              <motion.img
                src="https://cdn.sanity.io/images/ou6d2gec/production/e73f17ae5ce749d33815c4d66b5213338507c2b1-4500x4500.png"
                width="100%"
                alt="Mindful Man"
                variants={introState}
                initial="hidden"
                animate={clickCounter < 2 ? "open" : "closed"}
              ></motion.img>
              <motion.a
                variants={introState}
                initial="hidden"
                animate={clickCounter === 1 ? "open" : "closed"}
              >
                <FontAwesomeIcon id="nextIcon" icon={faArrowRightLong} />
              </motion.a>
            </motion.div>
            <motion.div
              variants={showHideState}
              animate={clickCounter >= 2 ? "show" : "hide"}
            >
              <VideoComponent
                path="https://cdn.sanity.io/files/ou6d2gec/production/2619a63361ca91b192c0578a00988e4f942eee53.mp4"
                state={clickCounter}
                num={2}
              />
              <span
                style={
                  clickCounter < 3
                    ? {
                        opacity: 1,
                        fontSize: "1.2em",
                        fontWeight: 600,
                        color: "#004a22",
                      }
                    : { opacity: 0.2, fontSize: "1em", fontWeight: 400 }
                }
              >
                What would you love to achieve? What are your values? What is
                your reason for being? There are experiences and space on your
                year map to think clearly and easily about these influential
                ideas.
              </span>
            </motion.div>
          </motion.div>
          <motion.div
            className="main__column--tutorial"
            variants={activeState}
            initial="hidden"
            animate={clickCounter === 3 ? "open" : "closed"}
          >
            <motion.div
              variants={showHideState}
              animate={clickCounter < 3 ? "show" : "hide"}
            >
              <motion.img
                src="https://cdn.sanity.io/images/ou6d2gec/production/088a4919740e18a8e075aea3319a06cbe5dfba5d-4500x4500.png"
                width="100%"
                alt="Planner Man"
                variants={introState}
                initial="hidden"
                animate={clickCounter < 3 ? "open" : "closed"}
              ></motion.img>
              <motion.a
                variants={introState}
                initial="hidden"
                animate={clickCounter === 1 ? "open" : "closed"}
              >
                <FontAwesomeIcon id="nextIcon" icon={faArrowRightLong} />
              </motion.a>
            </motion.div>
            <motion.div
              variants={showHideState}
              animate={clickCounter >= 3 ? "show" : "hide"}
            >
              <VideoComponent
                path="https://cdn.sanity.io/files/ou6d2gec/production/73dcbe0f67906a15ea2d62c2054b05f4f206d24f.mp4"
                state={clickCounter}
                num={3}
              />
              <span
                style={
                  clickCounter < 4
                    ? {
                        opacity: 1,
                        fontSize: "1.2em",
                        fontWeight: 600,
                        color: "#004a22",
                      }
                    : { opacity: 0.2, fontSize: "1em", fontWeight: 400 }
                }
              >
                Click on the nodes in the dock to make your year map ideas super
                present whilst planning your week.
              </span>
            </motion.div>
          </motion.div>
          <motion.div
            className="main__column--tutorial"
            variants={activeState}
            initial="hidden"
            animate={
              clickCounter === 4 ||
              clickCounter === 5 ||
              clickCounter === 6 ||
              clickCounter === 7
                ? "open"
                : "closed"
            }
          >
            <motion.div
              variants={showHideState}
              animate={clickCounter < 4 ? "show" : "hide"}
            >
              <motion.img
                src="https://cdn.sanity.io/images/ou6d2gec/production/d21767160175802a7ecd195e49732406237ba2eb-4500x4500.png"
                width="100%"
                className="tutorial__image--active"
                alt="Diary Page"
                style={{ marginBottom: "24px" }}
                variants={introState}
                initial="hidden"
                animate={clickCounter < 4 ? "open" : "closed"}
              ></motion.img>
              <motion.a
                variants={introState}
                initial="hidden"
                animate={clickCounter === 1 ? "open" : "closed"}
              >
                <FontAwesomeIcon id="nextIcon" icon={faArrowRightLong} />
              </motion.a>
            </motion.div>
            <motion.div
              variants={showHideState}
              animate={clickCounter >= 4 ? "show" : "hide"}
            >
              {(num === 99 || num === 100) && (
                <VideoComponent
                  path="https://cdn.sanity.io/files/ou6d2gec/production/5d7b286bf25b714bbf7e9eabeea4006e850c6276.mp4"
                  state={clickCounter}
                  num={4}
                />
              )}

              {num === 101 && (
                <VideoComponent
                  path="https://cdn.sanity.io/files/ou6d2gec/production/a5a98dae4c0a3b79c00dc43c5e8b903178c181da.mp4"
                  state={clickCounter}
                  num={6}
                />
              )}
              {num === 102 && (
                <>
                  <VideoComponent
                    path="https://cdn.sanity.io/files/ou6d2gec/production/749899cbd61d871d3ee4e03e0505e20b16ac63f0.mp4"
                    state={clickCounter}
                    num={7}
                  />

                  <span
                    style={
                      clickCounter < 8
                        ? {
                            opacity: 1,
                            fontSize: "1.2em",
                            fontWeight: 600,
                            color: "#004a22",
                          }
                        : { opacity: 0.2, fontSize: "1em", fontWeight: 400 }
                    }
                  >
                    Over time, and when combined other such days, you have
                    created something that you can use to learn deeply and
                    sustainably about yourself.
                  </span>
                </>
              )}
            </motion.div>
          </motion.div>
          <motion.div
            className="main__column--tutorial"
            variants={activeState}
            initial="hidden"
            animate={
              clickCounter === 8 ||
              clickCounter === 9 ||
              clickCounter === 10 ||
              clickCounter === 11
                ? "open"
                : "closed"
            }
          >
            <motion.div
              variants={showHideState}
              animate={clickCounter < 8 ? "show" : "hide"}
            >
              <motion.img
                src="https://cdn.sanity.io/images/ou6d2gec/production/3411573e3489a97682d5d55ac08eb9cd48c6e517-4500x4500.png"
                width="100%"
                alt="Diary Page"
                variants={introState}
                initial="hidden"
                animate={clickCounter < 8 ? "open" : "closed"}
              ></motion.img>
              <motion.a
                variants={introState}
                initial="hidden"
                animate={clickCounter === 1 ? "open" : "closed"}
              >
                <FontAwesomeIcon id="nextIcon" icon={faArrowRightLong} />
              </motion.a>
            </motion.div>
            <motion.div
              variants={showHideState}
              animate={clickCounter >= 8 ? "show" : "hide"}
            >
              {numTwo === 103 && (
                <>
                  <VideoComponent
                    path="https://cdn.sanity.io/files/ou6d2gec/production/c39d0ff1a74c9ece3bcbbc6e7f7aff163ef11f48.mp4"
                    state={clickCounter}
                    num={8}
                  />
                  <span
                    style={
                      clickCounter < 9
                        ? {
                            opacity: 1,
                            fontSize: "1.2em",
                            fontWeight: 600,
                            color: "#004a22",
                          }
                        : { opacity: 0.2, fontSize: "1em", fontWeight: 400 }
                    }
                  >
                    Explore a host of simple graphs and charts designed to
                    transform your diary data into signals leading towards
                    greater joy and growth.
                  </span>
                </>
              )}
              {numTwo === 104 && (
                <VideoComponent
                  path="https://cdn.sanity.io/files/ou6d2gec/production/cb9491eb7d2ca1bb268e80f4117414e120002119.mp4"
                  state={clickCounter}
                  num={9}
                />
              )}
              {numTwo === 105 && (
                <VideoComponent
                  path="https://cdn.sanity.io/files/ou6d2gec/production/cb9491eb7d2ca1bb268e80f4117414e120002119.mp4"
                  state={clickCounter}
                  num={10}
                />
              )}
              {numTwo === 106 && (
                <>
                  <VideoComponent
                    path="https://cdn.sanity.io/files/ou6d2gec/production/cb9491eb7d2ca1bb268e80f4117414e120002119.mp4"
                    state={clickCounter}
                    num={11}
                  />
                  <span
                    style={
                      clickCounter < 12
                        ? {
                            opacity: 1,
                            fontSize: "1.2em",
                            fontWeight: 600,
                            color: "#004a22",
                          }
                        : { opacity: 0.2, fontSize: "1em", fontWeight: 400 }
                    }
                  >
                    It is designed to steer you towards days with the strongest
                    insight signals and prime you with the right questions to
                    ask in order to make unlocking great personal insight much,
                    much simpler.
                  </span>
                </>
              )}
            </motion.div>
          </motion.div>
          <motion.div
            className="main__column--tutorial"
            variants={activeState}
            initial="hidden"
            animate={clickCounter === 12 ? "open" : "closed"}
          >
            <motion.div
              variants={showHideState}
              animate={clickCounter < 12 ? "show" : "hide"}
            >
              <motion.img
                src="https://cdn.sanity.io/images/ou6d2gec/production/032bf99cf1fbcf01606be4d3e58f6787dc0107fc-4500x4500.png"
                width="100%"
                alt="Learning Platform"
                variants={introState}
                initial="hidden"
                animate={clickCounter < 12 ? "open" : "closed"}
              ></motion.img>
              <motion.a
                variants={introState}
                initial="hidden"
                animate={clickCounter === 1 ? "open" : "closed"}
              >
                <FontAwesomeIcon id="nextIcon" icon={faArrowRightLong} />
              </motion.a>
            </motion.div>
            <motion.div
              variants={showHideState}
              animate={clickCounter >= 12 ? "show" : "hide"}
            >
              <VideoComponent
                path="https://cdn.sanity.io/files/ou6d2gec/production/fe84c44da89385d635dbcb56ab23d83099d73a30.mp4"
                state={clickCounter}
                num={12}
              />
              <span
                style={
                  clickCounter < 13
                    ? {
                        opacity: 1,
                        fontSize: "1.2em",
                        fontWeight: 600,
                        color: "#004a22",
                      }
                    : { opacity: 0.2, fontSize: "1em", fontWeight: 400 }
                }
              >
                Follow along with Joey as it discovers different dimensions in
                the world of data that helps it see itself in a clearer light.
              </span>
            </motion.div>
          </motion.div>
        </motion.section>
      </main>
      <TestFooter />
    </>
  );
}
