//React & Redux
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { useInView } from "react-intersection-observer";

//Internal Compoments
import Header from "../../layouts/Header/Header";

import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import TestFooter from "../../layouts/Footer/TestFooter";
import VideoComponent from "../../components/Video/Video";

//Actions
import { listDays } from "../../actions/dayActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";

// Icons
import { QuestionIcon } from "../../components/Icons/Icon";

//Framer Motion
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

//CSS
import "./tutorialmobile.css";

export default function TutorialMobile(history) {
  //Tooltip State
  const [dayIconState, setDayIconState] = useState(false);
  const [lpCursorState, setLpCursorState] = useState();
  const [cursorState, setCursorState] = useState("");
  const [cursorDisplayState, setCursorDisplayState] = useState(false);
  const [tutorialState, setTutorialState] = useState("tutorial");

  const cursorDisplayToggle = () => {
    setCursorDisplayState((cursorDisplayState) => !cursorDisplayState);
  };

  const tutorialDisplayToggle = () => {
    setHelpStateIntro((helpStateIntro) => !helpStateIntro);
  };

  //Homepage Tutorial State
  const [startScore, setStartScore] = useState(4.1);
  const [logDate, setLogDate] = useState("2023-01-03");
  const [thankYou, setThankYou] = useState(
    "my living room for providing me a warm, happy place to live and work."
  );
  const [selfLess, setSelfLess] = useState(
    "focusing on the present moment only."
  );
  const [mindBody, setMindBody] = useState("it is here with me");
  const [momentOne, setMomentOne] = useState(
    "Work Before Work: Read 40 pages of The Bluest Eye"
  );
  const [momentOneScore, setMomentOneScore] = useState(8.8);
  const [momentTwo, setMomentTwo] = useState(
    "Work: Be present and treat each task with curiousity and a sense of opportunity"
  );
  const [momentTwoScore, setMomentTwoScore] = useState(7.5);
  const [momentThree, setMomentThree] = useState(
    "Meditate - 20 mins > Stretch > 15 minutes"
  );
  const [momentThreeScore, setMomentThreeScore] = useState(5.5);
  const [momentFour, setMomentFour] = useState("Finish online learning module");
  const [momentFourScore, setMomentFourScore] = useState(10);
  const [momentFive, setMomentFive] = useState(
    "Listen to Brian Eno LP to wind down"
  );
  const [momentFiveScore, setMomentFiveScore] = useState(10);
  const [rememberToday, setRememberToday] = useState(
    "Working hard; taking care of what I could control and protecting my recovery / relax time."
  );
  const [leaveBehind, setLeaveBehind] = useState(
    "Not doing recovery earlier in the day"
  );
  const [endScore, setEndScore] = useState(4.5);
  const [onMyMind, setOnMyMind] = useState();

  const handleChange_onMyMind = (e) => {
    setOnMyMind(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  //Animation State
  const [buttonState, setButtonState] = useState("");
  const [isIntro, setIsIntro] = useState(true);
  const [show, setShow] = useState(false);
  const [isPrimes, setIsPrimes] = useState(false);
  const [isMoments, setIsMoments] = useState(false);
  const [isEvenings, setIsEvenings] = useState(false);
  const [beforeScore, setBeforeScore] = useState(false);
  const [finalScore, setFinalScore] = useState(false);
  const [thankYouState, setThankYouState] = useState(false);
  const [selfLessState, setSelfLessState] = useState(false);
  const [mindBodyState, setMindBodyState] = useState(false);
  const [rememberTodayState, setRememberTodayState] = useState(false);
  const [leaveBehindState, setLeaveBehindState] = useState(false);
  const [momentActive, setMomentActive] = useState(false);

  //Mobile Tutorial State
  const [helpStateIntro, setHelpStateIntro] = useState(false);
  const [helpStateName, setHelpStateName] = useState(false);
  const [helpStateDOB, setHelpStateDOB] = useState(false);
  const [helpStateVision, setHelpStateVision] = useState(false);
  const [helpStateValues, setHelpStateValues] = useState(false);
  const [helpStateIki, setHelpStateIki] = useState(false);
  const [helpStateQuote, setHelpStateQuote] = useState(false);
  const [helpStateCont, setHelpStateCont] = useState(false);
  const [helpStateMemory, setHelpStateMemory] = useState(false);

  const introState = {
    hidden: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    closed: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const displayState = {
    hidden: {
      display: "none",
    },
    open: {
      display: "grid",
    },
    closed: {
      display: "none",
    },
  };

  const blockState = {
    hidden: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    closed: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const showHideState = {
    show: {
      height: "auto",
      opacity: 1,
      backgroundColor: "rgba(0, 74, 35,1)",
      color: "rgba(245,245,245,1)",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    hide: {
      height: "0px",
      opacity: 0,
      backgroundColor: "rgba(0, 74, 35,0)",
      color: "rgba(245,245,245,0)",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const activeState = {
    hidden: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      padding: "4px 6px 4px 6px",
      borderRadius: "0 0 5px 5px",
      opacity: 0,
      color: "rgba(0, 74, 35,1)",
      fontWeight: 400,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(245,245,245,1)",
      fontWeight: "600",
      padding: "6px 8px 6px 8px",
      borderRadius: "0 0 5px 5px",
      boxSizing: "border-box",
      backgroundColor: "rgba(103, 36, 131, 1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      padding: "4px 6px 4px 6px",
      borderRadius: "0 0 5px 5px",
      color: "rgba(0, 74, 35,1)",
      fontWeight: "400",
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const slideActiveState = {
    hidden: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      padding: "0px",
      borderRadius: "5px",
      opacity: 0,
      color: "rgba(0, 74, 35,1)",
      fontWeight: 400,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(0, 74, 35,1)",
      fontWeight: "600",
      padding: "4px",
      borderRadius: "5px",
      boxSizing: "border-box",
      backgroundColor: "rgba(226, 223, 207,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      padding: "0px",
      borderRadius: "5px",
      color: "rgba(0, 74, 35,1)",
      marginTop: "0px",
      fontWeight: "400",
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  // const [content, setContent] = useState();

  // async function getPosts() {
  //   const posts = await client.fetch(`*[_type == 'post']{
  //     _id,
  //     title,
  //     mainImage {
  //       asset -> {
  //         _id,
  //         url
  //       },
  //     },
  //   }`);
  //   return posts;
  // }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let picsObj = [];

  //     try {
  //       const response = await getPosts();
  //       if (response) {
  //         for (let i = 0; i < response.length; i++) {
  //           if (response[i].title.startsWith("LP_")) {
  //             picsObj.push({
  //               id: response[i].mainImage.asset._id,
  //               title: response[i].title,
  //               url: response[i].mainImage.asset.url,
  //             });
  //           }
  //         }
  //       }
  //       setContent(picsObj);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const [videos, setVideos] = useState([]);

  // useEffect(() => {
  //   client
  //     .fetch(
  //       '*[_type == "video"]{title, description, "videoUrl": videoFile.asset->url}'
  //     )
  //     .then((data) => setVideos(data))
  //     .catch(console.error);
  // }, []);

  // useEffect(() => {
  //   console.log(content);
  //   console.log(videos);
  // });

  // const { ref: myRef, inView: isPrimes, entry } = useInView();

  //Loading State
  const [pageLoading, setPageLoading] = useState(true);

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 4000;
    setTimeout(() => {
      if (days) {
        setPageLoading(false);
      }
    }, randNum);
  };

  //React Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  const dayIconMethod = {
    iconOff: {
      backgroundColor: "#004a22",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        repeat: 20,
      },
    },
    iconOn: {
      color: "whitesmoke",
    },
  };

  function tooltipSelector() {
    if (!userInfo) {
      setLpCursorState(
        (lpCursorState) =>
          "A mindfulness journal that works with you, not through"
      );
      setCursorState((cursorState) => "");
    } else if (days && days.length < 1 && userInfo) {
      setLpCursorState((lpCursorState) => "");
      setCursorState(
        (cursorState) =>
          "Welcome to NWD! Time to start your first day! Click the calendar button below."
      );
    } else if (
      userInfo &&
      days &&
      days.length > 0 &&
      weeks &&
      weeks.length < 1
    ) {
      setCursorState(
        (cursorState) =>
          "Great stuff! Remember to check back in later to review and reflect! When you have a moment, try planning out your week."
      );
    } else if (
      userInfo &&
      days &&
      days.length > 0 &&
      weeks &&
      weeks.length > 0 &&
      years &&
      years.length < 1
    ) {
      setCursorState(
        (cursorState) =>
          "Brilliant stuff. Week planning is very helpful, and even more so if you have a vision for the year you are working towards. Jump onto the year page and start mapping!"
      );
    } else if (
      userInfo &&
      days &&
      days.length > 0 &&
      weeks &&
      weeks.length > 0 &&
      years &&
      years.length > 0
    ) {
      setCursorState(
        (cursorState) =>
          "Smashed it! Time to have a look at the quiz as you build your first week of logged days"
      );
    } else if (
      userInfo &&
      days &&
      days.length >= 7 &&
      weeks &&
      weeks.length > 0 &&
      years &&
      years.length > 0 &&
      localStorage.quiz_start === "0"
    ) {
      setCursorState(
        (cursorState) =>
          "You are well and truly heating up. Would you consider sharing NWD with a friend "
      );
    } else if (
      userInfo &&
      days &&
      days.length >= 7 &&
      weeks &&
      weeks.length > 0 &&
      years &&
      years.length > 0 &&
      localStorage.quiz_start === "1" &&
      localStorage.quiz_end === "1"
    ) {
      setCursorState(
        (cursorState) =>
          "We are in the presence of a master. Maybe consider sharing your wisdom with the community?"
      );
    }
  }

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  const buttonFlex = () => {
    if (vw > 500) {
      navigate("/tutorial/today");
    } else {
      setIsIntro(true);
    }
  };

  useEffect(() => {
    tooltipSelector();
    loadingTimeout();
  });

  const [testState, setTestState] = useState("testState");

  const [value, setValue] = useState(50); // Initial value for the range slider
  const [tooltipPositionStart, setTooltipPositionStart] = useState(0);
  const [tooltipPositionEnd, setTooltipPositionEnd] = useState(0);
  const [tooltipPositionOne, setTooltipPositionOne] = useState(0);
  const [tooltipPositionTwo, setTooltipPositionTwo] = useState(0);
  const [tooltipPositionThree, setTooltipPositionThree] = useState(0);
  const [tooltipPositionFour, setTooltipPositionFour] = useState(0);
  const [tooltipPositionFive, setTooltipPositionFive] = useState(0);

  const handleSliderChange = (event, num, pos) => {
    const sliderValue = event.target.value;
    setValue(sliderValue);

    // Calculate the tooltip position based on the slider value
    const sliderWidth = event.target.clientWidth - 15;

    const newPercent = sliderValue / num;
    const newPosition = sliderWidth * newPercent;

    switch (pos) {
      case 1:
        setTooltipPositionStart(newPosition + 20);
        break;
      case 2:
        setTooltipPositionEnd(newPosition + 20);
        break;
      case 3:
        setTooltipPositionOne(newPosition + 20);
        break;
      case 4:
        setTooltipPositionTwo(newPosition + 20);
        break;
      case 5:
        setTooltipPositionThree(newPosition + 20);
        break;
      case 6:
        setTooltipPositionFour(newPosition + 20);
        break;
      case 7:
        setTooltipPositionFive(newPosition + 20);
        break;
    }
  };

  const getBackgroundStyle = (data) => {
    const percent = (data / 10) * 100; // Calculate percentage value
    return `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`;
  };

  const getBackgroundStyleTwo = (data) => {
    const percent = (data / 5) * 100; // Calculate percentage value
    return `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`;
  };

  return (
    <>
      <MouseTooltip
        visible={cursorDisplayState}
        offsetX={15}
        offsetY={10}
        zIndex={1000}
      >
        <h1 className="sickTooltip">{cursorState}</h1>
      </MouseTooltip>
      <>
        <Helmet>
          <title>NoWastedDays Tutorial</title>
        </Helmet>
      </>

      <>
        {userInfo ? <Header variant="light" /> : <Header variant="light" />}

        <motion.main
          className="mobile_main"
          variants={introState}
          initial="hidden"
          animate={isIntro ? "open" : "closed"}
        >
          <motion.div
            className="tutorialIntro"
            variants={introState}
            initial="hidden"
            animate={isIntro ? "open" : "closed"}
          >
            <h2>NoWastedDays is a digital diary.</h2>
            <p>
              Each day in the diary has three pages.
              <br></br>Scroll down to discover them.
            </p>
            <img
              src="https://cdn.sanity.io/images/ou6d2gec/production/088a4919740e18a8e075aea3319a06cbe5dfba5d-4500x4500.png"
              width="25%"
              alt="daily planner"
              loading="lazy"
            />
          </motion.div>
        </motion.main>

        <motion.section
          className="rtb3"
          variants={displayState}
          initial="hidden"
          animate={isIntro ? "open" : "closed"}
        >
          <motion.div
            className="one"
            variants={introState}
            initial="hidden"
            whileInView="open"
            viewport={{ onViewportLeave: "closed", onViewportEntry: "open" }}
          >
            <div className="pageDescriptor">
              <motion.h3
                style={{
                  color: "#e2dfcf",
                  fontWeight: "600",
                  paddingLeft: "6px",
                }}
              >
                1. The Priming Page.
              </motion.h3>
              <motion.p
                style={{
                  color: "#e2dfcf",
                  paddingLeft: "6px",
                }}
              >
                To help focus and free your mind for the day ahead.
              </motion.p>
            </div>
            <motion.div className="upperDiv">
              <div className="tutorialtile left">
                <div style={{ width: "100%" }}>
                  <div className="dateRow">
                    <label htmlFor="loggedDate">Diary Entry Date:</label>

                    <input
                      style={{ fontFamily: "monospace" }}
                      id="loggedDate"
                      type="date"
                      value={logDate}
                      onChange={(e) => setLogDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="scoring__row">
                  <div className="score__row">
                    <label
                      htmlFor="beforeScore"
                      style={startScore ? { opacity: "1" } : { opacity: "0.5" }}
                    >
                      Before filling in my NWD today, I feel{" "}
                    </label>{" "}
                    {startScore > 0 && !startScore === undefined && (
                      <span className="score__display">{startScore}</span>
                    )}
                  </div>
                  <input
                    id="beforeScore"
                    className={startScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                    value={startScore}
                    style={{
                      background: getBackgroundStyleTwo(startScore),
                    }}
                    onChange={(e) => {
                      setStartScore(e.target.value);
                      handleSliderChange(e, 5, 1);
                    }}
                  />
                </div>
                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {thankYou ? (
                        <label htmlFor="thankYou" className="score label">
                          Today, I would like to say thank you to...
                        </label>
                      ) : (
                        <label htmlFor="thankYou" className="score label">
                          Today, I would like to say thank you to...
                        </label>
                      )}
                    </div>
                    {/* <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <motion.textarea
                    id="thankYou"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={thankYou}
                    placeholder="Today, I would like to say thank you to..."
                    onChange={(e) => setThankYou(e.target.value)}
                    variants={activeState}
                    whileInView="open"
                    viewport={{ once: true }}
                  />
                </div>

                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {selfLess ? (
                        <label htmlFor="selfLess" className="score label">
                          I will detach from self today by...
                        </label>
                      ) : (
                        <label htmlFor="selfLess" className="score label">
                          I will detach from self today by...
                        </label>
                      )}
                    </div>
                    {/* <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <motion.textarea
                    id="selfLess"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={selfLess}
                    placeholder="I will detach from self today by"
                    onChange={(e) => setSelfLess(e.target.value)}
                    variants={activeState}
                    whileInView="open"
                    viewport={{ once: true }}
                  />
                </div>

                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {mindBody ? (
                        <label htmlFor="mindBody" className="score label">
                          I am pleased to live in my mind & body because...
                        </label>
                      ) : (
                        <label htmlFor="mindBody" className="score label">
                          I am pleased to live in my mind & body because...
                        </label>
                      )}
                    </div>
                    {/* <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <motion.textarea
                    id="mindBody"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={mindBody}
                    placeholder="I am pleased to live in my mind and body today because..."
                    onChange={(e) => setMindBody(e.target.value)}
                    variants={activeState}
                    whileInView="open"
                    viewport={{ once: true }}
                  />
                </div>
              </div>
            </motion.div>
          </motion.div>

          <motion.div
            className="two"
            variants={introState}
            initial="hidden"
            whileInView="open"
            viewport={{ once: true }}
          >
            <div className="pageDescriptor">
              <h3
                style={{
                  color: "#e2dfcf",
                  fontWeight: "600",
                  paddingLeft: "6px",
                }}
              >
                2. The Planning Page.
              </h3>
              <p
                style={{
                  paddingLeft: "6px",
                  color: "#e2dfcf",
                }}
              >
                To give each day clear priorities and purpose.
              </p>
            </div>
            <motion.div style={{ width: "100%" }}>
              <div className="tutorialtile">
                <div className="moment__submission">
                  <div className="score__row--moment">
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentOne}
                      autoComplete="off"
                      placeholder="Today's first moment is..."
                      onChange={(e) => setMomentOne(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={momentOneScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentOneScore}
                    style={{ background: getBackgroundStyle(momentOneScore) }}
                    onChange={(e) => {
                      setMomentOneScore(e.target.value);
                      handleSliderChange(e, 10, 3);
                    }}
                    variants={slideActiveState}
                    whileInView="open"
                    viewport={{
                      onViewportLeave: "closed",
                      onViewportEntry: "open",
                    }}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row--moment">
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentTwo}
                      autoComplete="off"
                      placeholder="Today's second moment is..."
                      onChange={(e) => setMomentTwo(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={momentTwoScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentTwoScore}
                    style={{ background: getBackgroundStyle(momentTwoScore) }}
                    onChange={(e) => {
                      setMomentTwoScore(e.target.value);
                      handleSliderChange(e, 10, 4);
                    }}
                    variants={slideActiveState}
                    whileInView="open"
                    viewport={{
                      onViewportLeave: "closed",
                      onViewportEntry: "open",
                    }}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row--moment">
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentThree}
                      autoComplete="off"
                      placeholder="Today's third moment is..."
                      onChange={(e) => setMomentThree(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={
                      momentThreeScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentThreeScore}
                    onChange={(e) => {
                      setMomentThreeScore(e.target.value);
                      handleSliderChange(e, 10, 5);
                    }}
                    style={{
                      background: getBackgroundStyle(momentThreeScore),
                    }}
                    variants={slideActiveState}
                    whileInView="open"
                    viewport={{
                      onViewportLeave: "closed",
                      onViewportEntry: "open",
                    }}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row--moment">
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentFour}
                      autoComplete="off"
                      placeholder="Today's fourth moment is..."
                      onChange={(e) => setMomentFour(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={
                      momentFourScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFourScore}
                    style={{ background: getBackgroundStyle(momentFourScore) }}
                    onChange={(e) => {
                      setMomentFourScore(e.target.value);
                      handleSliderChange(e, 10, 6);
                    }}
                    variants={slideActiveState}
                    whileInView="open"
                    viewport={{
                      onViewportLeave: "closed",
                      onViewportEntry: "open",
                    }}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row--moment">
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentFive}
                      autoComplete="off"
                      placeholder="Today's fifth moment is..."
                      onChange={(e) => setMomentFive(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={
                      momentFiveScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFiveScore}
                    style={{ background: getBackgroundStyle(momentFiveScore) }}
                    onChange={(e) => {
                      setMomentFiveScore(e.target.value);
                      handleSliderChange(e, 10, 7);
                    }}
                    variants={slideActiveState}
                    whileInView="open"
                    viewport={{
                      onViewportLeave: "closed",
                      onViewportEntry: "open",
                    }}
                  />
                </div>
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            className="three"
            variants={introState}
            initial="hidden"
            whileInView="open"
            viewport={{ once: true }}
          >
            <div className="pageDescriptor">
              <h3
                style={{
                  color: "#e2dfcf",
                  fontWeight: "600",
                  paddingLeft: "6px",
                }}
              >
                3. The Perspective Page.
              </h3>
              <p
                style={{
                  color: "#e2dfcf",
                  paddingLeft: "6px",
                }}
              >
                To help distill your day and filter out its worst thoughts and
                feelings.
              </p>
            </div>
            <motion.div>
              <div className="tutorialtile right">
                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {rememberToday ? (
                        <label htmlFor="rememberToday" className="score label">
                          The memories from today that will capture it forever
                          are...
                        </label>
                      ) : (
                        <label htmlFor="rememberToday" className="score label">
                          The memories from today that will capture it forever
                          are...
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <motion.textarea
                    id="rememberToday"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={rememberToday}
                    placeholder="The memories from today that will capture it forever are..."
                    onChange={(e) => setRememberToday(e.target.value)}
                    rows={4}
                    variants={activeState}
                    whileInView="open"
                    viewport={{
                      onViewportLeave: "closed",
                      onViewportEntry: "open",
                    }}
                  />
                </div>
                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {leaveBehind ? (
                        <label htmlFor="leaveBehind" className="score label">
                          The thoughts & feelings from today I would like to
                          leave behind are...
                        </label>
                      ) : (
                        <label htmlFor="leaveBehind" className="score label">
                          The thoughts & feelings from today I would like to
                          leave behind are...
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <motion.textarea
                    id="leaveBehind"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={leaveBehind}
                    placeholder="The thoughts and feelings from today I would like to leave behind are..."
                    onChange={(e) => setLeaveBehind(e.target.value)}
                    rows={4}
                    variants={activeState}
                    whileInView="open"
                    viewport={{
                      onViewportLeave: "closed",
                      onViewportEntry: "open",
                    }}
                  />
                </div>

                <div className="yeartile--day">
                  <div className="tutorial access">
                    <div className="iconDiv--day">
                      {/* <div className="newIcon">
                        <MemoryIcon />
                      </div> */}
                      {onMyMind ? (
                        <label
                          htmlFor="leaveBehind"
                          className="score label"
                          style={
                            vw < 425 && leaveBehind
                              ? { opacity: "0.5", fontSize: "0.8em" }
                              : { opacity: "0.5" }
                          }
                        >
                          and finally, anything else that is on my mind
                        </label>
                      ) : (
                        <label
                          htmlFor="leaveBehind"
                          className="score label"
                          style={
                            vw < 425 && leaveBehind
                              ? { opacity: "1", fontSize: "0.8em" }
                              : { opacity: "1" }
                          }
                        >
                          and finally, anything else that is on my mind
                        </label>
                      )}
                    </div>
                    <div className="icon_div">
                      <div className="question_mark icon">
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <motion.textarea
                    id="leaveBehind"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={onMyMind}
                    placeholder="Not an easy day today."
                    onChange={handleChange_onMyMind}
                    variants={activeState}
                    animate={leaveBehindState ? "open" : "closed"}
                  />
                </div>

                <div className="scoring__row" style={{ borderBottom: "none" }}>
                  <div
                    className="score__row"
                    style={
                      endScore === "5"
                        ? { borderRadius: "5px 5px 0px 0px" }
                        : { borderRadius: "5px 5px 5px 0px" }
                    }
                  >
                    <motion.label
                      htmlFor="endScore"
                      style={endScore ? { opacity: "1" } : { opacity: "0.5" }}
                    >
                      After the day described above, I feel
                    </motion.label>{" "}
                    <span>{endScore}</span>
                  </div>
                  <motion.input
                    id="endScore"
                    className={endScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                    value={endScore}
                    style={{ background: getBackgroundStyleTwo(endScore) }}
                    onChange={(e) => {
                      setEndScore(e.target.value);
                      handleSliderChange(e, 5, 2);
                    }}
                  />
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.section>

        <motion.main
          className="mobile_main"
          variants={introState}
          initial="hidden"
          animate={isIntro ? "open" : "closed"}
        >
          <motion.div
            className="tutorialIntro"
            variants={introState}
            initial="hidden"
            animate={isIntro ? "open" : "closed"}
          >
            <div className="pageDescriptor">
              <h2 style={{ lineHeight: "1.5" }}>
                The diary provides a great framework for designing a day.{" "}
                <br></br>
                <br></br>However, even greater opportunity comes courtesy of the
                diary being at the center of your No Wasted Days. <br></br>
                <br></br>
                In this position, it can be supercharged by and also give life
                to other brilliant parts of the platform.
                <br></br>
                <br></br>Let's take a look at this, moving from outside to in.
              </h2>
            </div>
          </motion.div>
        </motion.main>

        <motion.section
          className="rtb43"
          variants={displayState}
          initial="hidden"
          animate={isIntro ? "open" : "closed"}
        >
          <motion.div className="one">
            <h3
              style={{
                color: "#004a22",
                fontWeight: "700",
                padding: "0 12px",
              }}
            >
              Your year map helps you define what matters most to you about the
              year ahead.
            </h3>
            <VideoComponent
              path="https://cdn.sanity.io/files/ou6d2gec/production/2619a63361ca91b192c0578a00988e4f942eee53.mp4"
              state={2}
              num={1}
            />
            <p
              style={{
                color: "#004a22",
                padding: "12px 6px 0 6px",
                fontSize: "0.8em",
              }}
            >
              What would you love to achieve? What are your values? What is your
              reason for being? There are experiences and space on your year map
              to think clearly and easily about these influential ideas.
            </p>
          </motion.div>
          <motion.div className="two">
            <h3
              style={{
                color: "#004a22",
                fontWeight: "700",
                padding: "0 12px",
              }}
            >
              Those ideas are then available in your week planner to help you
              turn your great goals and values into weeks that express them.
            </h3>
            <VideoComponent
              path="https://cdn.sanity.io/files/ou6d2gec/production/73dcbe0f67906a15ea2d62c2054b05f4f206d24f.mp4"
              state={2}
              num={1}
            />

            <p
              style={{
                color: "#004a22",
                padding: "12px 6px 0 6px",
                fontSize: "0.8em",
              }}
            >
              Click on the nodes in the dock to make your year map ideas super
              present whilst planning your week.
            </p>
          </motion.div>

          <motion.div className="three">
            <h3
              style={{ color: "#004a22", fontWeight: "700", padding: "0 12px" }}
            >
              These great plans can then easily become smart and streamlined
              days.
            </h3>
            <VideoComponent
              path="https://cdn.sanity.io/files/ou6d2gec/production/5d7b286bf25b714bbf7e9eabeea4006e850c6276.mp4"
              state={2}
              num={1}
            />
            <p
              style={{
                color: "#004a22",
                padding: "12px 6px 0 6px",
                fontSize: "0.8em",
              }}
            >
              Click on each node to open the corresponding week objective. The
              nodes will fill up as you complete more of that objective to
              indicate what remains to be done.
            </p>
          </motion.div>
        </motion.section>

        <motion.main
          className="mobile_main"
          variants={introState}
          initial="hidden"
          animate={isIntro ? "open" : "closed"}
        >
          <motion.div
            className="tutorialIntro"
            variants={introState}
            initial="hidden"
            animate={isIntro ? "open" : "closed"}
          >
            <div className="pageDescriptor">
              <h2 style={{ lineHeight: "1.5" }}>
                Now, back at the diary, our attention turns to what
                opportunities it creates.
                <br></br>
                <br></br>I find this bit really, really exciting! <br></br>
                <br></br>
                In day-to-day life, we are constantly creating data about
                ourselves; the vast majority of which is never captured.
                <br></br>
                <br></br>The diary format gives us an unbelievable opportunity
                to solve this problem.
              </h2>
            </div>
          </motion.div>
        </motion.main>

        <motion.section
          className="rtb44"
          variants={displayState}
          initial="hidden"
          animate={isIntro ? "open" : "closed"}
        >
          <motion.div className="one">
            <h3
              style={{
                color: "#004a22",
                fontWeight: "700",
                padding: "0 12px",
              }}
            >
              By completing a day in your diary, you have captured a highly
              indicative and super insightful set of data from that day.
            </h3>
            <VideoComponent
              path="https://cdn.sanity.io/files/ou6d2gec/production/749899cbd61d871d3ee4e03e0505e20b16ac63f0.mp4"
              state={2}
              num={1}
            />
            <p
              style={{
                color: "#004a22",
                padding: "12px 6px 0 6px",
                fontSize: "0.8em",
              }}
            >
              Over time, and when combined other such days, you have created
              something that you can use to learn deeply and sustainably about
              yourself.
            </p>
          </motion.div>
          <motion.div className="two">
            <h3
              style={{
                color: "#004a22",
                fontWeight: "700",
                padding: "0 12px",
              }}
            >
              Your insights page is given life by your data.
            </h3>
            <VideoComponent
              path="https://cdn.sanity.io/files/ou6d2gec/production/c39d0ff1a74c9ece3bcbbc6e7f7aff163ef11f48.mp4"
              state={2}
              num={1}
            />

            <p
              style={{
                color: "#004a22",
                padding: "12px 6px 0 6px",
                fontSize: "0.8em",
              }}
            >
              Your insights are a hub of simple graphs and charts designed to
              transform your diary data into signals towards greater joy and
              growth.
            </p>
          </motion.div>

          <motion.div className="three">
            <h3
              style={{ color: "#004a22", fontWeight: "700", padding: "0 12px" }}
            >
              If you find it hard to look at the charts, your insight story
              there to help.
            </h3>
            <VideoComponent
              path="https://cdn.sanity.io/files/ou6d2gec/production/cb9491eb7d2ca1bb268e80f4117414e120002119.mp4"
              state={2}
              num={1}
            />
            <p
              style={{
                color: "#004a22",
                padding: "12px 6px 0 6px",
                fontSize: "0.8em",
              }}
            >
              It is designed to steer you towards days with the strongest
              insight signals and prime you with the right questions to ask in
              order to make unlocking great personal insight much, much simpler.
            </p>
          </motion.div>
          <motion.div className="four">
            <h3
              style={{ color: "#004a22", fontWeight: "700", padding: "0 12px" }}
            >
              To help you become an even more awesome analyser of your own data,
              we have created a unique online adventure called 'the Art of
              Looking'.
            </h3>
            <VideoComponent
              path="https://cdn.sanity.io/files/ou6d2gec/production/fe84c44da89385d635dbcb56ab23d83099d73a30.mp4"
              state={2}
              num={1}
            />
            <p
              style={{
                color: "#004a22",
                padding: "12px 6px 0 6px",
                fontSize: "0.8em",
              }}
            >
              Follow along with Joey as it discovers different dimensions in the
              world of data that helps it see itself in a clearer light.
            </p>
          </motion.div>
        </motion.section>

        <TestFooter />
      </>
    </>
  );
}
