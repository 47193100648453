import {
  DAYS_CREATE_FAIL,
  DAYS_CREATE_REQUEST,
  DAYS_CREATE_SUCCESS,
  DAYS_LIST_FAIL,
  DAYS_LIST_REQUEST,
  DAYS_LIST_SUCCESS,
  DAYS_LIST_FAIL_ID,
  DAYS_LIST_REQUEST_ID,
  DAYS_LIST_SUCCESS_ID,
  DAYS_UPDATE_FAIL,
  DAYS_UPDATE_REQUEST,
  DAYS_UPDATE_SUCCESS,
  DAYS_SINGLE_FAIL,
  DAYS_SINGLE_REQUEST,
  DAYS_SINGLE_SUCCESS,
  DAYS_DELETE_FAIL,
  DAYS_DELETE_REQUEST,
  DAYS_DELETE_SUCCESS,
} from "../constants/daysConstants";

export const dayListReducer = (state = { days: [] }, action) => {
  switch (action.type) {
    case DAYS_LIST_REQUEST:
      return { loading: true };
    case DAYS_LIST_SUCCESS:
      return { loading: false, days: action.payload };
    case DAYS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const dayListIDReducer = (state = { days: [] }, action) => {
  switch (action.type) {
    case DAYS_LIST_REQUEST_ID:
      return { loading: true, days: [] };
    case DAYS_LIST_SUCCESS_ID:
      return { loading: false, days: action.payload };
    case DAYS_LIST_FAIL_ID:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const dayCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DAYS_CREATE_REQUEST:
      return { loading: true };
    case DAYS_CREATE_SUCCESS:
      return { loading: false, success: true };
    case DAYS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const daySingleReducer = (state = { day: null }, action) => {
  switch (action.type) {
    case DAYS_SINGLE_REQUEST:
      return { loading: true };
    case DAYS_SINGLE_SUCCESS:
      return { loading: false, day: action.payload };
    case DAYS_SINGLE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const dayUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DAYS_UPDATE_REQUEST:
      return { loading: true };
    case DAYS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case DAYS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const dayDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DAYS_DELETE_REQUEST:
      return { loading: true };
    case DAYS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DAYS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
