// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// Internal Components
import Header from "../../../layouts/Header/Header";
import TestFooter from "../../../layouts/Footer/TestFooter";

// Assets
import { client } from "../../../client";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCaretRight,
  faSquareCaretLeft,
} from "@fortawesome/free-solid-svg-icons";

import { updateQuizAction, listQuizes } from "../../../actions/quizActions";
import generateSecret from "../../../utils/generateSecret";

import "./quizdebrief.css";

export default function QuizDebriefScreen(history) {
  const [titleDiv, setTitleDiv] = useState(false);
  const [kickOff, setKickOff] = useState(true);
  const [open, setOpen] = useState(false);
  const [skeptical, setSkeptical] = useState(false);
  const [present, setPresent] = useState(false);
  const [optimistic, setOptimistic] = useState(false);

  const [pic, setPic] = useState(true);
  const [poc, setPoc] = useState(true);
  const [nic, setNic] = useState(true);
  const [noc, setNoc] = useState(true);

  const [chapterOneScore, setChapterOneScore] = useState(100);
  const [chapterTwoScore, setChapterTwoScore] = useState(100);
  const [chapterThreeScore, setChapterThreeScore] = useState(20);
  const [chapterFourScore, setChapterFourScore] = useState(0);

  const [chapterOneScoreLive, setChapterOneScoreLive] = useState();
  const [chapterTwoScoreLive, setChapterTwoScoreLive] = useState();
  const [chapterThreeScoreLive, setChapterThreeScoreLive] = useState();
  const [chapterFourScoreLive, setChapterFourScoreLive] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const quizUpdate = useSelector((state) => state.quizUpdate); //
  const { success: successUpdate } = quizUpdate;

  const quizList = useSelector((state) => state.quizList);
  const { quizes } = quizList;

  useEffect(() => {
    dispatch(listQuizes());
  }, [dispatch, userInfo, history]);

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateQuizAction(
        id,
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !chapterOneScore ||
      !chapterTwoScore ||
      !chapterThreeScore ||
      !chapterFourScore
    )
      return;

    // resetHandler();
  };

  const [hasQuiz, setHasQuiz] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    if (Array.isArray(quizes)) {
      if (quizes.length === 0) {
        setHasQuiz((hasQuiz) => false);
      } else if (quizes.length === 1) {
        setLinkId((linkId) => quizes[0]._id);

        setChapterOneScoreLive(quizes[0].chapterOneScore);

        if (chapterOneScoreLive > chapterOneScore) {
          setChapterOneScore(chapterOneScoreLive);
        }

        setChapterTwoScoreLive(quizes[0].chapterTwoScore);

        if (chapterTwoScoreLive > chapterTwoScore) {
          setChapterTwoScore(chapterTwoScoreLive);
        }

        setChapterThreeScoreLive(quizes[0].chapterThreeScore);

        if (chapterThreeScoreLive > chapterThreeScore) {
          setChapterThreeScore(chapterThreeScoreLive);
        }

        setChapterFourScoreLive(quizes[0].chapterFourScore);

        if (chapterFourScoreLive > chapterFourScore) {
          setChapterFourScore(chapterFourScoreLive);
        }
      }
    } else {
      setHasQuiz((hasQuiz) => true);
    }
  });

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      transition: {
        duration: 2,
        ease: "easeInOut",
        delay: 2,
      },
    },
  };

  const showHideStateTitle = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  };

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
    },
  };

  const showHideFlex = {
    hide: {
      display: "none",
      minHeight: "60vh",
      opacity: 0,
    },
    show: {
      display: "flex",
      minHeight: "60vh",
      opacity: 1,
    },
  };

  const showHideFlexAhora = {
    hide: {
      display: "none",
      minHeight: "60vh",
      opacity: 0,
    },
    show: {
      display: "flex",
      opacity: 1,
      minHeight: "60vh",
    },
  };

  const showHideFlexCol = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  useEffect(() => {
    document.title = "Art of Looking";
  }, []);

  const school = "1JCJVaK48RM";
  const amorFati = "2Xzh1BjCA5Q";

  const [background, setBackground] = useState("background-image");

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "24px",
        }}
      >
        <motion.div
          className="titleDiv"
          animate={titleDiv ? "show" : "hide"}
          initial="hide"
          style={{ padding: "16px 20px" }}
          variants={vw > 1024 ? showHideState : showHideStateTitle}
          onClick={() => {
            setTitleDiv((titleDiv) => false);
            setKickOff((kickOff) => true);
          }}
        >
          <h1>Owning an Outcome</h1>
          <span>
            <strong>Click to get started</strong>
          </span>
        </motion.div>

        <motion.div
          animate={kickOff ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlexAhora}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Content</h4>
            </div>

            <br />
            <p>
              When you get into your groove with NoWastedDays, you will find
              yourself doing a lof of dissecting decisions, making sense of
              various moments and evaluating outcomes.
            </p>
            <br />

            <p>
              To look at an outcome objectively and in its entirety is extremely
              difficult. To look at an outcome objectively and in its entirety
              without a reliable prompt or piece of guidance is basically
              impossible.
              <br />
              <br />
              The impact of this added difficulty is that it stands in the way
              of any of us learning as much as we possibly could from the things
              we have tried to achieve within each day. As a result, more often
              than not, we end up short on insight and high on a poorly
              calibrated sense of your role in the outcome.
            </p>
            <br />
            <br />
            <p>
              This mis-calibration is important to acknowledge and usually takes
              one of two forms:
              <br />
              <br />
              <strong>Form #1.</strong> We portion ourselves the blame for all
              the things that went 'wrong', and can only see the role of others
              and random good fortune in the things that went 'right'.
              <br />
              <br />
              <strong>Form #2.</strong> We flood ourselves in praise and
              acclaim, and really start to believe our own hype. Then, quite
              naturally, we don't have a grateful word to say about the role of
              key people or any of the many moments of sheer blind good fortune
              that afforded us an opportunity to succeed at all.
            </p>
            <br />
            <br />
            <p>
              I have worked in data for over a decade as a marketing analyst, as
              a performance development coach for a professional ice hockey
              team, and as a founder of different start-ups. In that time I have
              needed to review performances and outcomes of many different
              types. Whilst depending on the situation there are a million ways
              to do this, I want to share one ultra-robust, really accessible
              framework that you can do on any scrap of paper, spare word doc,
              excel sheet or on NoWastedDays itself using our{" "}
              <a href="/debrief">Debrief tool</a>.
            </p>
            <br />
            <br />
            <p>
              <strong>The Debrief Format</strong>
              <br />
              <br />
              Create 4 colums or chunks, and give each one of the following
              headers:
              <br />
              <br />
              1. <strong>Positive Impact</strong> +{" "}
              <strong>Inside Your Control</strong>
              <br />
              <br />
              2. <strong>Positive Impact</strong> +{" "}
              <strong>Outside of Your Control</strong>
              <br />
              <br />
              3. <strong>Negative Impact</strong> +{" "}
              <strong>Inside Your Control</strong>
              <br />
              <br />
              4. <strong>Negative Impact</strong> +{" "}
              <strong>Outside of Your Control</strong>
            </p>
            <br />
            <br />
            <p>
              <strong>Step #1:</strong> Give your debrief a name.
            </p>

            <p>
              <strong>Step #2:</strong> Write down the very specific outcome
              that you are looking to break down. A debrief can only work well
              if you are looking at one specific outcome at a time.
            </p>

            <p>
              <strong>Your Objective:</strong> Fill each column with as many
              things as you can honestly say were present.
            </p>

            <p>
              <strong>Tips:</strong> Every column will have multiple items in,
              even the positive columns if things have gone ‘badly’, and even
              the negative columns if things have gone ‘well’. Share with
              someone else involved in the same outcome, or have them do the
              same break down and compare your respective perspectives.
            </p>
          </div>
          <div id="debriefColMain" className="rightCol">
            <div id="debriefColDiv" className="rightContent">
              <motion.div className="positiveInControl">
                <img
                  src="https://cdn.sanity.io/images/ou6d2gec/production/d3e7018370074414881b0190bd3f7fa710c189eb-5000x3250.png"
                  width="100%"
                />
              </motion.div>

              <div className="positiveOutControl">
                <img
                  src="https://cdn.sanity.io/images/ou6d2gec/production/b54be18aca492d85c52f73b2285bef23fb5b2052-5000x3250.png"
                  width="100%"
                />
              </div>

              <div className="negativeInControl">
                <img
                  src="https://cdn.sanity.io/images/ou6d2gec/production/3511c26cf00b12956c708ff47cd7e39aff6a6dd1-5000x3250.png"
                  width="100%"
                />
              </div>
              <div className="negativeOutControl">
                <img
                  src="https://cdn.sanity.io/images/ou6d2gec/production/293458ecffda7decf7fa3318c8220160bde6ff4c-5000x3250.png"
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setKickOff((kickOff) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setKickOff((kickOff) => false);
                setOpen((open) => true);
                setBackground("background-image-two");

                if (chapterThreeScore > chapterThreeScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterThreeScore((chapterThreeScore) => 40);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={open ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                Positive & In Your Control: Consolidating Confidence
              </h4>
            </div>

            <br />
            <p>
              There can be few more talked about things online than confidence;
              how you get it, how you exude it, how you keep it. If you are man
              in your teens, 20's or 30's, you are only ever one Youtube advert
              or social media suggestion away from being littered with advice
              from a certain brand of charisma expert or self-certified
              confidence guru.
            </p>
            <br />
            <br />
            <p>
              They will often talk about self-esteem, self-belief, having goals,
              being ruthless and a whole other litany of borderline psychotic
              modes of thought, but there are truths about confidence that can
              empower anyone build their self-assurance in a far healthily and
              genuine fashion.
            </p>
            <br />
            <br />
            <p>
              A fundamental truth about consolidating confidence is that within
              any outcome that you have exerted some genuine control over -
              whether it was the most culturally celebrated, intellectually
              exuberant, physically magnificent, god-like accomplishment or the
              most apparently abject misstep leading to an painful, reputation
              tainting defeat - there will be something that you contributed to
              the situation that represents a positive impact towards the goal
              in question.
            </p>

            <div className="mobileImage">
              <br />
              <br />
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/d3e7018370074414881b0190bd3f7fa710c189eb-5000x3250.png"
                alt="consolidating the confidence you've earned"
              ></img>
            </div>
            <p>
              <br />
              That positive contribution represents a package of confidence that
              it is important that you capture. A fabulous way to capture this
              confidence is by writing it down in the "Positive & In Your
              Control" column.
              <br />
              <br />
              To help you get filling out the column well, find below a list of
              things you may be forgetting to capture confidence from or
              evaluate amongst the things you contributed positively to a
              situation.
              <br />
              <br />
              <strong>
                Things you may forget to consolidate confidence from:
              </strong>
              <ul>
                <br />
                <li>
                  Putting yourself into the arena; being bold and taking a
                  chance.
                </li>
                <br />
                <li>Giving time, energy and attention towards preparation.</li>
                <br />
                <li>Priming your mind for the road ahead.</li>
                <br />
                <li>Extending yourself beyond previous limits.</li>
                <br />
                <li>Perceiving a situation in which change was possible.</li>
                <br />
                <li>The 10 great decisions that preceded the 1 dodgy one</li>
                <br />
                <li>
                  Nurturing trust to the point that someone believed that you
                  could play your role in achieving a team outcome.
                </li>
                <br />
                <li>Believing in yourself</li>
                <br />
                <li>Emotional control</li>
                <br />
                <li>Application of technical skills</li>
                <br />
              </ul>
              Be careful here as overstating your impact on something is as bad
              as failing to capture what confidence you did earn. We explore
              what might fall in that former category in a coming section.
              <br />
              <br />
              <strong>
                To summarise, whether you hit all of your aims or fell short of
                every standard, their is confidence out there to be consolidated
                and you can rely on the debrief format, win or lose, as a way to
                grasp it!
              </strong>
            </p>
            <p></p>
          </div>
          <div className="rightCol">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/d3e7018370074414881b0190bd3f7fa710c189eb-5000x3250.png"
              width="100%"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setOpen((open) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setSkeptical((skeptical) => true);
                setOpen((open) => false);
                setBackground("background-image-three");

                if (chapterThreeScore > chapterThreeScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterThreeScore((chapterThreeScore) => 60);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={skeptical ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                Positive & Outside Your Control: Share Genuine Gratitude
              </h4>
            </div>
            <br />
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/b54be18aca492d85c52f73b2285bef23fb5b2052-5000x3250.png"
                alt="share genuine gratitude"
              ></img>
            </div>
            <p>
              If I am ever in a bit of a mental funk, I have a thought that
              helps me wake up, be grateful and carry on again with an upturn in
              energy.
            </p>
            <br />
            <br />
            <p>
              I stop and just reflect on the sheer infinitesimal chance that I
              am alive in the first place and how this lived experience I am
              having is so precious.
              <br />
              <br />
              If you are feeling up for it, consider for a second the curious
              courses of history that have had to intersect...
              <br />
              <br />
              ...all the love, hate, pioneering effort, balking chance,
              migration, stagnation, inflation and procrastination; each of the
              dizzying lineages of people, places, processes; the human ocean of
              postulations, perambulations and prognostications that go back
              even just a single generation, let alone a longer period of time
              and space...
              <br />
              <br />
              ...for you to even have a chance to exist in the first place.
              <br />
              <br />
              The chance is so mind bogglingly small that you can't help but be
              brought back to the present moment and with a bit of perspective
              in tow.
            </p>
            <br />
            <br />
            <p>
              This thought exercise ladders up to the broader point of this step
              which that so much is happening constantly outside of your
              awareness or control that is helping to you succeed at any given
              task. This impact is thoroughly worth articulating and sharing,
              and is a key part of the debrief format.
              <br />
              <br />
              In a more distilled and present tense fashion, there is so much
              good that is afforded to us by other people and things on a daily
              basis that it is so important to be grateful for it.
            </p>
            <br />
            <br />
            <p>
              Some of these instances you may be saying, 'Well, I had to choose
              to make the best of these moments and do something about it.
              Nothing actually happened without me doing it myself'.
              <br />
              <br />
              Fair point, but to do something, as I hope my prior ramblings
              show, you have to have an environment in which to do it; and that
              environment will never have been created exclusively by you.
              <br />
              <br />
              We all should be doting on existence, nature, favour, friends,
              family and or some other kind of good exceptional external
              circumstances over which you had less than zero control.
              <br />
              <br />
              For those of you that may find gratitude a bit difficult, I have
              included a list below that can help you build the muscle. Start
              with point number one, and when you find giving gratitude to that
              type of thing easy to do, move onto point number two.
              <br />
              <br />
              <ol>
                <br />
                <li>
                  <strong>People / Person:</strong>

                  <p>
                    Who has helped you today, or recently; even if only in a
                    very small and practical way?
                  </p>

                  <p>From whose wisdom have you made better decisions?</p>

                  <p>Whose goodness have you witness or heard about?</p>

                  <p>
                    Who is someone who you have never met but you feel an
                    affinity for that transcends time and space?
                  </p>
                </li>

                <li>
                  <strong>Place:</strong>

                  <p>
                    Which of the spaces in your own home bring you most joy and
                    safety.
                  </p>

                  <p>
                    What places have you been grateful to spend time within or
                    enjoyed visiting?
                  </p>

                  <p>
                    What organisations have helped you succeed or feel more at
                    home in the world?
                  </p>
                </li>
                <br />
                <br />
                <li>
                  <strong>Part of your Body:</strong>

                  <p>
                    Our limbs and organs work with unbelievable connectivity and
                    afford us the chance to think, feel and act. Which have
                    helped you the most recently?
                  </p>

                  <p>
                    Which have you perhaps never said thank you to, despite the
                    fact they have worked continually, and perhaps without fail,
                    since before you were born?
                  </p>
                </li>
                <br />
                <br />
                <li>
                  <strong>Plant or Product:</strong>

                  <p>
                    Which of the plants in your home, or plants in your
                    surrounding area are you grateful to see each day?{" "}
                  </p>

                  <p>
                    Which of the appliances that you use has helped you a lot
                    recently?
                  </p>
                </li>
                <br />
                <br />
                <li>
                  <strong>Property (of an object, space, time.):</strong>
                  <p>
                    What force can you say has afforded you a chance to feel
                    deeper or happier?
                  </p>
                  <p>
                    What emotional aspect of a person or place to relish the
                    most?
                  </p>
                </li>
                <br />
                <br />
                <li>
                  <strong>Pain or Perversity:</strong>

                  <p>
                    Which slice of pain or piece of individual perversity can
                    you be grateful for because a) it is a sign you are alive in
                    the world and b) because it is better than succumbing to
                    greater pain or be trapped by greater levels of perverse
                    thinking?
                  </p>
                  <p>
                    Which bitter experience from the past has, without meaning
                    to, enriched your understanding of being alive and
                    connecting with others?
                  </p>
                </li>
              </ol>
              <strong>
                To summarise, ply your 'Positive & Out of Your Control' column
                with, in order of importance, as many of the environmental
                factors that helped you as possible and you will feel a huge
                wash of gratitude and goodness!
              </strong>
            </p>
          </div>
          <div className="rightCol">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/b54be18aca492d85c52f73b2285bef23fb5b2052-5000x3250.png"
              width="100%"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setOpen((open) => true);
                setSkeptical((skeptical) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setSkeptical((skeptical) => false);
                setPresent((present) => true);
                setBackground("background-image-four");
                if (chapterThreeScore > chapterThreeScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterThreeScore((chapterThreeScore) => 80);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={present ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                Negative & In Your Control: Clear Constructive Commentary
              </h4>
            </div>
            <div className="mobileImage">
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${school}?start=0`}
              ></iframe>
            </div>
            <br />
            <p>
              This section could feel like it would simply be the inverse of the
              positive and in your control section. But, as we all know, we
              often find processing the negative harder to do than ruminating
              and replaying the positive.
            </p>
            <br />
            <br />
            <p>
              Fortunately, there is one additional insight that can hugely help
              with this bucket, and help us overcome this difficulty of
              processing the negative.
              <br />
              <br />
              <strong>
                We assume that we are not idiots and, as a result, should only
                be making choices that represent the best of our accumulated
                knowledge and demonstrate near-godly apprehension.
              </strong>
            </p>
            <br />
            <br />
            <p>
              The frailty of this assumption is a point that a great{" "}
              <a href="https://youtu.be/1JCJVaK48RM?si=cYnlX2mGW2rLEQqq">
                School of Life
              </a>{" "}
              video makes far more eruditely and persuasively than I just have.
            </p>
            <br />
            <br />
            <p>
              The video summarises all their writings and content into an eight
              point credo. Most of the 8 principles they present, which are born
              from so much wisdom, millions of words written and decades of
              expertise, centre on embracing imperfection, accepting your own
              unique yet archetypal idiocy and the pervasively tragic nature of
              being alive. They present these themes as a powerful remedy to the
              time we waste resisting these actually unavoidable elements of
              some large, large part of your life.
            </p>
            <br />
            <br />
            <p>
              In fact, point #4 is even entitled 'Accept Your Idiocy' and is
              explained thusly:
              <br />
              <br />
              <strong>
                "Don't runaway from the thought that you may be an idiot as if
                this was a rare and dreadful prospect and insight. Accept the
                certainty with good grace in full daylight. You are an idiot.
                There is not other alternative for a human being.[...] Embracing
                our idiocy should render us confident before challenges because
                messing up is to be expected."
              </strong>
              <br />
              <br />
              <div className="mobileImage">
                <img
                  src="https://cdn.sanity.io/images/ou6d2gec/production/3511c26cf00b12956c708ff47cd7e39aff6a6dd1-5000x3250.png"
                  alt="constructive, compassionate commentary"
                ></img>
              </div>
              <br />
              The 'Negative and In Your Control' bucket is here to help with the
              process of healthily dismantling the earlier assumption and
              replacing its underpinning angst and struggle with a wave of
              empathetic yet unemotional constructive commentary.
              <br />
              <br />
              Fill it up as much as you can with things that you could have
              either done better, or more holistically, or in less time.
              <br />
              <br />
              Celebrate a heaving bucket of such remarks as the kind of
              self-insight and awareness that is beautifully symbolic of both
              your engagement with yourself and the nature of being alive.
            </p>
          </div>
          <div className="rightCol">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/3511c26cf00b12956c708ff47cd7e39aff6a6dd1-5000x3250.png"
              width="100%"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setSkeptical((skeptical) => true);
                setPresent((present) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setOptimistic((optimistic) => true);
                setPresent((present) => false);
                setBackground("background-image-five");
                if (chapterThreeScore > chapterThreeScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterThreeScore((chapterThreeScore) => 80);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={optimistic ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                Negative & Outside Your Control: Forgive Yourself & Others
              </h4>
            </div>
            <br />
            <p>
              As we discussed in the "positive and out of your control" bucket,
              life is full of forces and features over which you exercise no
              control whilst trying to journey towards a particular destination.
            </p>
            <br />
            <br />
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/293458ecffda7decf7fa3318c8220160bde6ff4c-5000x3250.png"
                alt="constructive, compassionate commentary"
              ></img>
            </div>
            <br />
            <p>
              To map, not only the helpful factors as we discussed earlier, but
              any present hindering factors as well is very important. It often
              requires more thought and toleration of discomfort than playing
              back the positive parts of a journey, and often feels more
              skippable than endeavouring to consolidate what you personally
              could have done better. However, I feel that to map the 'negative
              and out of your control' is like pushing a snowball down a hill; a
              small act which grows in size without you even trying.
              <br />
              <br />
              <strong>
                Why write down the 'Negative & Outside of Your Control' factors?
              </strong>
              <br />
              <br />
              <strong>1. First, It allows you to detach from them.</strong>
              <br />
              As I stated earlier, it is so easy to mis-calibrate how things
              played out as it relates to your own contribution. You, obviously,
              should not attribute any of yourself to the things you could not
              control, whether negative or positive, but in the messy, often
              emotional aftermath of a outcome we can so often do just the
              opposite.
              <br />
              <br />
              Writing the 'Negative Impact & Out of Your Control' down is a
              simple yet powerful way of acknowledging that it was outside of
              your control and the fact that it is now there on the spreadsheet
              or on the A4 in a very separate bucket is a stark visual
              acknowledgment of this externality.
              <br />
              <br />
              <strong>
                2. This detachment allows you to forgive it more easily.
              </strong>
              <br />
              When we attach our 'selves' to a outcome, it can be far harder to
              forgive negative external circumstances because they have come at
              the cost of us - our very self - and it seems crazy to be prepared
              to pay that toll.
              <br />
              <br />
              But, as we discussed in the mindset section, attachment and the
              self are notions not only associated with suffering, but are two
              of its major underpinnings. By writing down and detaching from
              whatever the negative forces in a situation may have been, we can
              see it outside of ourselves, free of a cost to us, and then we can
              forgive both ourselves and it more readily.
              <br />
              <br />
              <strong>
                3. This forgiveness then empowers you to love the way things
                played out.
              </strong>
              <br />
              Once you have detached from something, and have chosen to forgive
              it, you are then in a position to love it because there is every
              chance that it made you grow. This thought has a kind of Stoic
              origin, most notably in the form of Freidrich Nietzsche. He said
              as follows:
              <br />
              <br />
              <strong>
                "My formula for greatness in a human being is{" "}
                <strong>amor fati:</strong> that one wants nothing to be
                different, not forward, not backward, not in all eternity. Not
                merely bear what is necessary, still less conceal it...
                <strong>but love it."</strong>
              </strong>
            </p>
            <br />
            <br />
            <div className="mobileImage">
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${amorFati}?start=0`}
              ></iframe>
            </div>
            <br />
            <p>
              Most people do the opposite of this - keeping negative chance
              events outside of their consciousness or, worse still, actively
              resenting them. This leaves them bitter and victimised. In
              contrast, doing your best to embrace the hand that fate has dealt
              you will see you moving forward more optimistically and calmly.
              <br />
              <br />
              <strong>
                Overall, to map the negative and outside of your control is to
                significantly detach from said circumstances; to detach is to
                forgive and to forgive is to love them; to love them is to
                transcend them.
              </strong>
            </p>
          </div>
          <div className="rightCol">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/293458ecffda7decf7fa3318c8220160bde6ff4c-5000x3250.png"
              width="100%"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setPresent((present) => true);
                setOptimistic((optimistic) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setChapterThreeScore((chapterThreeScore) => 100);

                setTimeout(() => {
                  if (chapterThreeScore > chapterThreeScoreLive) {
                    updateHandler();
                  }
                  navigate(`/insight/quiz/${linkId}/representative`);
                }, 1000);
              }}
            />
          </div>
        </motion.div>
      </main>
      <TestFooter />
    </>
  );
}
