import React, { useState, useEffect } from "react";

function Urgency({ task, title, subTaskIndex, handleUrgencyChange }) {
  const [urgencyState, setUrgencyState] = useState({
    "#e30000": { name: "", level: 4 },
    "#e34d4d": { name: "", level: 3 },
    "#46cf4d": { name: "", level: 2 },
    green: { name: "", level: 1 },
  });

  const [altState, setAltState] = useState(null);
  const [altStateActive, setAltStateActive] = useState();

  const adjustState = (urgentScore) => {
    if (!urgentScore) return null;

    const filteredEntry = Object.entries(urgencyState).find(
      ([, value]) => value.level == urgentScore
    );

    return filteredEntry ? filteredEntry[0] : null;
  };

  useEffect(() => {
    const adjustedKey = adjustState(task.urgency);
    setAltState(adjustedKey);
  }, [task.urgency]);

  useEffect(() => {
    if (altState) {
      setAltStateActive("active");
    } else {
      setAltStateActive("disabled");
    }
  }, [altState]);

  return (
    <div className={`urgency__grid--${altStateActive}`}>
      {altState
        ? // If `altState` exists, map only that key
          [altState].map((label) => (
            <div
              className="urgency__input"
              key={label}
              type="text"
              style={{ backgroundColor: label }}
              data-value={urgencyState[label].level}
              data-name={title}
              data-display-name="urgency"
              onClick={(e) => handleUrgencyChange(e, subTaskIndex)}
            />
          ))
        : // Otherwise, map all keys
          Object.keys(urgencyState).map((label) => (
            <div
              className="urgency__input"
              key={label}
              type="text"
              style={{ backgroundColor: label }}
              data-value={urgencyState[label].level}
              data-name={title}
              data-display-name="urgency"
              onClick={(e) => handleUrgencyChange(e, subTaskIndex)}
            />
          ))}
    </div>
  );
}

export default Urgency;
