// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// Internal Components
import { dateFormatter } from "../../utils/UtilityFunctions";
import axios from "axios";
import { createDayAction } from "../../actions/dayActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";
import { Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

import TileLoading from "../../components/Loading/TileLoading";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faHandPointRight,
  faSignature,
  faDna,
  faCalendarDay,
  faArrowUp91,
  faQuoteLeft,
  faLandmarkDome,
  faCompassDrafting,
  faQuestion,
  faPenToSquare,
  faEraser,
  faGlasses,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import Header from "../../layouts/Header/Header";
import FullDock from "../../features/dock/FullDock";
import {
  FullDockMobileOne,
  FullDockMobileTwo,
} from "../../features/dock/FullDockMobile";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import TestFooter from "../../layouts/Footer/TestFooter";
import "./yearscreen.css";
import { isToday, set } from "date-fns";

export default function TutorialCreateYearScreen({ history, props }) {
  // Form State - Today
  const [yourName, setYourName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [myValues_1, setMyValues_1] = useState("");
  const [myValues_1_Text, setMyValues_1_Text] = useState("");
  const [myValues_2, setMyValues_2] = useState("");
  const [myValues_2_Text, setMyValues_2_Text] = useState("");
  const [myValues_3, setMyValues_3] = useState("");
  const [myValues_3_Text, setMyValues_3_Text] = useState("");
  const [myValues_4, setMyValues_4] = useState("");
  const [myValues_4_Text, setMyValues_4_Text] = useState("");
  const [myValues_5, setMyValues_5] = useState("");
  const [myValues_5_Text, setMyValues_5_Text] = useState("");
  const [myVision_1, setMyVision_1] = useState("");
  const [myVision_2, setMyVision_2] = useState("");
  const [myVision_3, setMyVision_3] = useState("");
  const [myVision_4, setMyVision_4] = useState("");
  const [myVision_5, setMyVision_5] = useState("");
  const [whyNWD, setWhyNWD] = useState("");
  const [rememberToday, setRememberToday] = useState("");
  const [myIkigai, setMyIkigai] = useState("");
  const [navigationalQuote, setNavigationalQuote] = useState("");

  // Loading State
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonState, setButtonState] = useState(false);

  //Animation State
  const [isIntro, setIsIntro] = useState(true);
  const [show, setShow] = useState(false);
  const [isPrimes, setIsPrimes] = useState(false);
  const [isMoments, setIsMoments] = useState(false);
  const [isEvenings, setIsEvenings] = useState(false);
  const [beforeScore, setBeforeScore] = useState(false);
  const [finalScore, setFinalScore] = useState(false);
  const [thankYouState, setThankYouState] = useState(false);
  const [selfLessState, setSelfLessState] = useState(false);
  const [mindBodyState, setMindBodyState] = useState(false);
  const [rememberTodayState, setRememberTodayState] = useState(false);
  const [leaveBehindState, setLeaveBehindState] = useState(false);

  const [visionActive, setVisionActive] = useState(false);
  const [valuesActive, setValuesActive] = useState(false);
  const [nameActive, setNameActive] = useState(false);
  const [dobActive, setDOBActive] = useState(false);
  const [ikiActive, setIkiActive] = useState(false);
  const [quoteActive, setQuoteActive] = useState(false);
  const [uniqueActive, setUniqueActive] = useState(false);
  const [memoriesActive, setMemoriesActive] = useState(false);

  const showHideState = {
    show: {
      display: "flex",
    },
    hide: {
      display: "none",
    },
  };

  const introState = {
    hidden: {
      opacity: 0,
      scale: 0.1,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(255, 255, 255,1)",
      fontWeight: 600,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const activeState = {
    hidden: {
      backgroundColor: "rgba(245, 245, 245,1)",
      opacity: 0,
      border: "3px solid rgba(0, 150, 30,0)",
      borderRadius: "5px",
      color: "rgba(0, 74, 35,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(255, 255, 255,1)",
      fontWeight: 600,
      borderRadius: "5px",
      border: "0px solid rgba(0, 150, 30,0.1)",
      backgroundColor: "rgba(0, 74, 35,0.5)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      color: "rgba(0, 74, 35,1)",
      opacity: 1,
      border: "0px solid rgba(0, 150, 30,0.1)",
      borderRadius: "5px",
      backgroundColor: "rgba(245, 245, 245,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const fontActiveState = {
    hidden: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(226, 223, 207,1)",
      fontWeight: "600",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      color: "rgba(226, 223, 207,0.5)",
      fontWeight: "400",
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const colorState = {
    hidden: {
      color: "rgba(0, 74, 35,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      color: "rgba(255, 255, 255,1)",
      fontWeight: 600,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      color: "rgba(0, 74, 35,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  // Data Calls & Event Handlers
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 3000);
  };

  const [instructionState, setInstructionState] = useState("");
  const [supportingState, setSupportingState] = useState("");
  const [supportingStateTwo, setSupportingStateTwo] = useState("");
  const [clickCounter, setClickCounter] = useState(0);
  const [successState, setSuccessState] = useState(" .active");

  const clickPlus = () => {
    setClickCounter((clickCounter) => clickCounter + 1);
  };

  const clickMinus = () => {
    setClickCounter((clickCounter) => clickCounter - 1);
  };

  const displayText = () => {
    if (clickCounter === 0) {
      setInstructionState(
        (instructionState) =>
          "Your Year Map helps you keep your most helpful thinking together so, where possible, it can improve future decision making."
      );
      setShow((show) => true);
      setBirthDate((birthDate) => "2000-01-01");
    } else if (clickCounter === 1) {
      setInstructionState(
        (instructionState) => "Here is a quick summary of its key elements:"
      );
      setSupportingState((supportingState) => "");
    } else if (clickCounter === 2) {
      setInstructionState((instructionState) => "Your Name ");
      setSupportingState(
        (supportingState) =>
          "Your name helps keep your unique point of view and purpose front of mind."
      );
      setUniqueActive((uniqueActive) => false);
      setNameActive((nameActive) => true);
      setDOBActive((dobActive) => false);
      setYourName((yourName) => "John Doer");
    } else if (clickCounter === 3) {
      setInstructionState((instructionState) => "Your Date of Birth");
      setSupportingState(
        (supportingState) =>
          "Your date of birth allows you remember that your life has both a start and end. Identifying with both points unlocks a lot of wisdom."
      );
      setUniqueActive((uniqueActive) => false);
      setNameActive((nameActive) => false);
      setDOBActive((dobActive) => true);
      setBirthDate((birthDate) => "1992-03-09");
      setBirthDateDisplay((birthDateDisplay) => true);
    } else if (clickCounter === 4) {
      setInstructionState((instructionState) => "Your Vision for the Year");
      setSupportingState(
        (supportingState) =>
          "Clearly describing your vision(s) is an invaluable step towards making it much easier to achieve."
      );
      setVisionActive((visionActive) => true);
      setNameActive((nameActive) => false);
      setDOBActive((dobActive) => false);
      setTimeout(() => {
        setMyVision_1((myVision_1) => "Run a Marathon");
      }, 250);
      setTimeout(() => {
        setMyVision_2((myVision_2) => "Read the Complete Works of Shakespeare");
      }, 500);
      setTimeout(() => {
        setMyVision_3((myVision_3) => "Get promoted");
      }, 750);
      setTimeout(() => {
        setMyVision_4((myVision_4) => "Seek enlightenment");
      }, 1000);
      setTimeout(() => {
        setMyVision_5((myVision_5) => "Move abroad");
      }, 1250);
    } else if (clickCounter === 5) {
      setInstructionState((instructionState) => "Your Personal Values");
      setSupportingState(
        (supportingState) =>
          "We all have a value system that we move through the world with but rarely write it down."
      );
      setSupportingStateTwo(
        (supportingStateTwo) =>
          "Once written, we can better use it as a filter to improve the quality and integrity of our daily choices."
      );
      setVisionActive((visionActive) => false);
      setValuesActive((valuesActive) => true);
      setTimeout(() => {
        setMyValues_1((myValues_1) => "Effort");
        setMyValues_1_Text(
          (myValues_1_Text) =>
            "Honest, smart and focused application of time, energy and attention"
        );
      }, 250);
      setTimeout(() => {
        setMyValues_2((myValues_2) => "Attitude");
        setMyValues_2_Text(
          (myValues_2_Text) =>
            "A joyful and mindful perspective in the context of daily courage"
        );
      }, 500);
      setTimeout(() => {
        setMyValues_3((myValues_3) => "Character");
        setMyValues_3_Text(
          (myValues_3_Text) => "Resolute in adversity, composed in victory"
        );
      }, 750);
      setTimeout(() => {
        setMyValues_4((myValues_4) => "Visionary");
        setMyValues_4_Text(
          (myValues_4_Text) =>
            "Make the difference that is written on your bones"
        );
      }, 1000);
      setTimeout(() => {
        setMyValues_5((myValues_5) => "Love");
        setMyValues_5_Text(
          (myValues_5_Text) =>
            "Unconditionally Support Other People Whenever Possible"
        );
      }, 1250);
    } else if (clickCounter === 6) {
      setInstructionState((instructionState) => "Your Ikigai");
      setSupportingState(
        (supportingState) =>
          "A japanese concept often translated as your reason for being."
      );
      setSupportingStateTwo(
        (supportingStateTwo) =>
          "Having and renewing your ikigai is strongly correlated with greater happiness and longevity."
      );
      setValuesActive((valuesActive) => false);
      setIkiActive((ikiActive) => true);
      setMyIkigai(
        (myIkigai) =>
          "to make the experience of life commensurate with the reality of being alive."
      );
    } else if (clickCounter === 7) {
      setInstructionState(
        (instructionState) => "Your latest most motivating quote"
      );
      setSupportingState(
        (supportingState) =>
          "Quotes can be fantastic tools for providing short to medium term focus and enthusiasm."
      );
      setSupportingStateTwo((supportingStateTwo) => "");
      setQuoteActive((quoteActive) => true);
      setIkiActive((ikiActive) => false);
      setNavigationalQuote(
        (navigationalQuote) =>
          "A man that is young in years may be old in hours, if he have lost no time. Francis Bacon"
      );
    } else if (clickCounter === 8) {
      setInstructionState((instructionState) => "Unique Day Number");
      setSupportingState(
        (supportingState) =>
          "Every day in your life is completely unique, yet modern life makes it seem like we live the same days over and over again."
      );
      setSupportingStateTwo(
        (supportingStateTwo) =>
          "Seeing the unique number that today represents in your journey helps you treat it more like the unique and special thing it really is."
      );
      setQuoteActive((quoteActive) => false);
      setUniqueActive((uniqueActive) => true);
      setContinuation((continuation) => days1);
    } else if (clickCounter === 9) {
      setInstructionState((instructionState) => "Your Past Memories");
      setSupportingState(
        (supportingState) =>
          "Every time you load it up, your year map will show you a random memory from your diary."
      );
      setSupportingStateTwo(
        (supportingStateTwo) =>
          "This allows you to easily be inspired by days from the past."
      );
      setNameActive((nameActive) => false);
      setDOBActive((dobActive) => false);
      setMemoriesActive((memoriesActive) => true);
      setUniqueActive((uniqueActive) => false);
      setRememberToday(
        (rememberToday) =>
          "working in West Land talking about the Elizabeth line, smashing through coding, thinking about new brand with beauty"
      );
    } else if (clickCounter === 10) {
      setInstructionState((instructionState) => "Have a play");
      setSupportingState(
        (supportingState) =>
          "Do you know what you would write in some of these spaces?"
      );
      setSupportingStateTwo(
        (supportingStateTwo) =>
          "Try changing the date of birth and watch the unique day number shift."
      );
      setMemoriesActive((memoriesActive) => false);
      setButtonState((buttonState) => true);
    }
  };

  const vw = Math.max(document.documentElement.clientWidth);

  const [viewWidth, setViewWidth] = useState(vw);

  const scoreAnimator = () => {
    let fiveScore = Math.floor(Math.random() * 50) / 10;
    let tenScore = Math.floor(Math.random() * 100) / 10;
    let datesArr = [
      "2023-01-02",
      "2023-01-03",
      "2023-01-04",
      "2023-01-05",
      "2023-01-06",
      "2023-01-07",
      "2023-01-08",
      "2023-01-09",
      "2023-01-10",
      "2023-01-11",
      "2023-01-12",
      "2023-01-02",
      "2023-01-03",
      "2023-01-04",
      "2023-01-05",
      "2023-01-06",
      "2023-01-07",
      "2023-01-08",
      "2023-01-09",
      "2023-01-10",
      "2023-01-11",
      "2023-01-12",
    ];
    let thankYouArr = [
      "my friends and family",
      "my home for keeping me warm and safe",
      "my beauty for bringing love",
      "my feet for getting me around everywhere",
      "that flower on my way to work",
      "xyz xyz",
      "abc abc",
      "def def",
      "ghi ghi",
      "ghi",
      "jkl jkl",
      "jkl jkl",
      "mno mno",
      "pqr pqr",
      "suv suv",
    ];
    let selfLessArr = [
      "focusing on one thing at a time",
      "taking each moment for play and exploration",
      "simply being curious about everything",
      "taking the day one moment at a time",
      "xyz xyz",
      "abc abc",
      "def def",
      "ghi ghi",
      "ghi",
      "jkl jkl",
      "jkl jkl",
      "mno mno",
      "pqr pqr",
      "suv suv",
    ];
    let mindBodyArr = [
      "I do not have a toothache",
      "it views the world with hope and love",
      "it is free from pain most of the time",
      "it is here with me today",
      "xyz xyz",
      "abc abc",
      "def def",
      "ghi ghi",
      "ghi",
      "jkl jkl",
      "jkl jkl",
      "mno mno",
      "pqr pqr",
      "suv suv",
    ];
    let momentsArr = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "aa",
      "ab",
      "ac",
    ];
    let memoriesArr = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "aa",
      "ab",
      "ac",
    ];
    let leaveBehindArr = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "aa",
      "ab",
      "ac",
    ];

    let randomArraySelector = (arr) => {
      return Math.floor(Math.random() * arr.length);
    };
  };

  let birthday = birthDate;

  let day1 = new Date(birthday);

  let day2 = new Date();

  let diff = Math.abs(day2 - day1);

  let daysCalc = diff / (1000 * 3600 * 24);

  let days1 = Math.floor(daysCalc);

  const [continuation, setContinuation] = useState();
  const [birthDateDisplay, setBirthDateDisplay] = useState(false);

  const dateString = birthDate;
  const date = new Date(dateString);

  const formattedDate = dateFormatter(dateString);

  useEffect(() => {
    displayText();
    setContinuation((continuation) => days1);
  });

  return (
    <>
      <>
        <Helmet>
          <title>Learn | Year</title>
        </Helmet>
      </>

      <main
        onClick={clickPlus}
        className="tutorialpage--container"
        style={{ width: `${vw - 48}`, height: "100vh" }}
      >
        <motion.div className="instruction">
          <div>
            <h2>{instructionState}</h2>
            <span>{supportingState}</span>
            <span>{supportingStateTwo}</span>
          </div>
          <div>
            <a href={"/today"}>
              <FontAwesomeIcon id="crossIcon" icon={faX} />
            </a>
            <motion.a
              variants={showHideState}
              animate={buttonState ? "hide" : "show"}
            >
              <FontAwesomeIcon id="nextIcon" icon={faHandPointRight} />
            </motion.a>
            <motion.div
              variants={showHideState}
              animate={buttonState ? "show" : "hide"}
              onClick={() => {
                setInstructionState(
                  (instructionState) =>
                    "To help these thoughts be with you at the right time, I created the..."
                );
                setSupportingState((supportingState) => "");
                setSupportingStateTwo((supportingStateTwo) => "");
                setMemoriesActive((memoriesActive) => false);

                setShow((show) => false);
                setTimeout(() => {
                  navigate("/tutorial/week");
                }, 4000);
              }}
            >
              <FontAwesomeIcon id="nextIcon" icon={faHandPointRight} />
            </motion.div>
          </div>
        </motion.div>

        <motion.section
          className="yearContainer"
          style={{ margin: 0 }}
          variants={introState}
          initial="hidden"
          animate={show ? "open" : "closed"}
        >
          <>
            <div id="yeartesttutorial">
              <>
                <motion.div
                  className="yeartile yn"
                  animate={nameActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={nameActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>Name</strong>
                    </motion.label>
                    <FontAwesomeIcon className="icon" icon={faSignature} />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>
                  <motion.textarea
                    animate={nameActive ? "open" : "closed"}
                    initial="hidden"
                    variants={colorState}
                    wrap="soft"
                    autoComplete="off"
                    value={yourName}
                    placeholder="My name is...."
                    onChange={(e) => setYourName(e.target.value)}
                  />
                </motion.div>

                <motion.div
                  className="yeartile dob"
                  animate={dobActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={dobActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>D.O.B.</strong>
                    </motion.label>
                    <FontAwesomeIcon className="icon" icon={faCalendarDay} />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>
                  {!birthDateDisplay ? (
                    <input
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "none",
                        outline: "none",
                        border: "none",
                        padding: "6px",
                        borderRadius: "0 0 5px 5px",
                      }}
                      className="dobInput"
                      type="date"
                      value={birthDate}
                      placeholder=""
                      onChange={(e) => setBirthDate(e.target.value)}
                    />
                  ) : (
                    <motion.span
                      animate={dobActive ? "open" : "closed"}
                      initial="hidden"
                      variants={colorState}
                    >
                      {formattedDate}
                    </motion.span>
                  )}
                </motion.div>

                <motion.div
                  id="yeartilevalues"
                  className="yeartile values"
                  animate={valuesActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={valuesActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>Values</strong>
                    </motion.label>
                    <FontAwesomeIcon
                      className="icon"
                      icon={faCompassDrafting}
                    />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>
                  <div className="valuesRow">
                    <div className="valuesDiv">
                      <motion.input
                        type="text"
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_1}
                        placeholder="Trait #1"
                        onChange={(e) => setMyValues_1(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_1_Text}
                        placeholder="Trait Description"
                        onChange={(e) => setMyValues_1_Text(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.input
                        type="text"
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_2}
                        placeholder="Trait #2"
                        onChange={(e) => setMyValues_2(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_2_Text}
                        placeholder="Trait Description"
                        onChange={(e) => setMyValues_2_Text(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.input
                        type="text"
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_3}
                        placeholder="Trait #3"
                        onChange={(e) => setMyValues_3(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_3_Text}
                        placeholder="Trait Description"
                        onChange={(e) => setMyValues_3_Text(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.input
                        type="text"
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_4}
                        placeholder="Trait #4"
                        onChange={(e) => setMyValues_4(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_4_Text}
                        placeholder="Trait Description"
                        onChange={(e) => setMyValues_4_Text(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.input
                        type="text"
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_5}
                        placeholder="Trait #5."
                        onChange={(e) => setMyValues_5(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myValues_5_Text}
                        placeholder="Trait Description"
                        onChange={(e) => setMyValues_5_Text(e.target.value)}
                        animate={valuesActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  id="yeartilevision"
                  className="yeartile vision"
                  animate={visionActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={visionActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>Vision</strong>
                    </motion.label>
                    <FontAwesomeIcon className="icon" icon={faGlasses} />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>
                  <div className="valuesRow">
                    <div className="valuesDiv">
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myVision_1}
                        placeholder="#1 Vision for 2023"
                        onChange={(e) => setMyVision_1(e.target.value)}
                        animate={visionActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myVision_2}
                        placeholder="#2 Vision for 2023"
                        onChange={(e) => setMyVision_2(e.target.value)}
                        animate={visionActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myVision_3}
                        placeholder="#3 Vision for 2023"
                        onChange={(e) => setMyVision_3(e.target.value)}
                        animate={visionActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myVision_4}
                        placeholder="#4 Vision for 2023"
                        onChange={(e) => setMyVision_4(e.target.value)}
                        animate={visionActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                    <div className="valuesDiv">
                      <motion.textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myVision_5}
                        placeholder="#5 Vision for 2023"
                        onChange={(e) => setMyVision_5(e.target.value)}
                        animate={visionActive ? "open" : "closed"}
                        initial="hidden"
                        variants={colorState}
                      />
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  className="yeartile ikigai"
                  animate={ikiActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={ikiActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>Ikigai</strong>
                    </motion.label>
                    <FontAwesomeIcon className="icon" icon={faDna} />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>
                  <motion.textarea
                    id="iki"
                    className="formInput year"
                    wrap="soft"
                    autoComplete="off"
                    value={myIkigai}
                    placeholder="My Reason for Being is..."
                    onChange={(e) => setMyIkigai(e.target.value)}
                    animate={ikiActive ? "open" : "closed"}
                    initial="hidden"
                    variants={colorState}
                  />
                </motion.div>

                <motion.div
                  className="yeartile continuation"
                  animate={uniqueActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={uniqueActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>Continuation</strong>
                    </motion.label>
                    <FontAwesomeIcon className="icon" icon={faArrowUp91} />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>
                  {/* <motion.span 
      className="score label" 
      animate= {uniqueActive ? "open": "closed"}
      initial="hidden"
      variants={colorState}>
       Today is unique. It is day number {continuation} in my life so far.
      </motion.span> */}

                  <motion.textarea
                    id="cont"
                    className="formInput year"
                    wrap="soft"
                    autoComplete="off"
                    value={`Today is unique. It is day number ${continuation} in my life so far.`}
                    placeholder=""
                    animate={uniqueActive ? "open" : "closed"}
                    initial="hidden"
                    variants={colorState}
                  />
                </motion.div>

                <motion.div
                  className="yeartile navquote"
                  animate={quoteActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={quoteActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>Quote</strong>
                    </motion.label>
                    <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>
                  <motion.textarea
                    id="navQuote"
                    className="formInput year"
                    wrap="soft"
                    autoComplete="off"
                    value={navigationalQuote}
                    placeholder="My Navigational Quote is..."
                    onChange={(e) => setNavigationalQuote(e.target.value)}
                    animate={quoteActive ? "open" : "closed"}
                    initial="hidden"
                    variants={colorState}
                  />
                </motion.div>

                <motion.div
                  className="yeartile memory"
                  animate={memoriesActive ? "open" : "closed"}
                  initial="hidden"
                  variants={activeState}
                >
                  <div className="tutorial access">
                    <motion.label
                      className="score label"
                      animate={memoriesActive ? "open" : "closed"}
                      initial="hidden"
                      variants={fontActiveState}
                    >
                      <strong>Memories</strong>
                    </motion.label>
                    <FontAwesomeIcon className="icon" icon={faLandmarkDome} />
                    <div className="question_mark">
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#004a22" }}
                      />
                    </div>
                  </div>

                  <motion.textarea
                    id="memory"
                    className="formInput year"
                    wrap="soft"
                    autoComplete="off"
                    value={rememberToday}
                    placeholder=""
                    animate={memoriesActive ? "open" : "closed"}
                    initial="hidden"
                    variants={colorState}
                  />
                </motion.div>
              </>
            </div>
          </>
        </motion.section>
      </main>
      <TestFooter />
    </>
  );
}
