// React
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// External Packages
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

// Internal Components
import CheckoutForm from "./../../../features/payments/CheckoutForm";
import { dateFormatter } from "./../../../utils/UtilityFunctions";

function Payment(props) {
  // Props
  const { stripePromise } = props;
  const dispatch = useDispatch();

  // State
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [clientSecret, setClientSecret] = useState("");
  const [priceId, setPriceId] = useState();
  const [customerId, setCustomerId] = useState();
  const [tier, setTier] = useState();
  const [email, setEmail] = useState();
  const [firstPayment, setFirstPayment] = useState();
  const [nextPayment, setNextPayment] = useState();
  const [fetchComplete, setFetchComplete] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [todaysDate, setTodaysDate] = useState(null);
  const [nextMonthDate, setNextMonthDate] = useState(null);

  let id = userInfo && userInfo._id;

  useEffect(() => {
    const fetching = async () => {
      // const key = generateSecret().toString();

      try {
        const { data } = await axios.get(
          `https://nwd-backend.herokuapp.com/api/users/${id}`
        );

        setEmail(data.email);
        setPriceId(data.subscription.sessionId);
        setCustomerId(data.subscription.customerId);
        setTier(data.subscription.plan);
        setFetchComplete(true);
      } catch (error) {
        console.log(error);
      }
    };

    fetching();
  }, [id, customerId, priceId]);

  useEffect(() => {
    const fetchData = async (cus, price) => {
      try {
        const customerId = cus;
        const priceId = price;

        const response = await fetch(
          "https://nwd-backend.herokuapp.com/create-subscription",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ customerId, priceId }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to create subscription");
        }

        const result = await response.json();
        setClientSecret(result.clientSecret);
        // Fetch clientSecret from backend
      } catch (error) {
        console.error("Error:", error.message);
        // Handle error if needed
      }
    };

    if (customerId && priceId.length > 5) {
      fetchData(customerId, priceId);
    }
  }, [customerId, priceId]);

  const appearance = {
    theme: "flat",
  };

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    // Function to get today's date
    const getTodaysDate = () => {
      const today = new Date();
      setTodaysDate(dateFormatter(today.toISOString().split("T")[0]));
    };

    // Function to get a month after today's date
    const getNextMonthDate = () => {
      const today = new Date();
      today.setMonth(today.getMonth() + 1);

      setNextMonthDate(dateFormatter(today.toISOString().split("T")[0]));
    };

    // Call the functions when the component mounts
    getTodaysDate();
    getNextMonthDate();
  }, []);

  return (
    <>
      <main className="payment__main">
        <section className="payment__main--explainer">
          <div className="explainer__area">
            <h1>Subscription Details</h1>
            {tier === "CORE_MONTHLY" && <strong>Tier: Core (Monthly) </strong>}
            {tier === "CORE_ANNUAL" && <strong>Tier: Core (Annual) </strong>}
            {tier === "CLASSIC_MONTHLY" && (
              <strong>Tier: Classic (Monthly) </strong>
            )}
            {tier === "CLASSIC_ANNUAL" && (
              <strong>Tier: Classic (Annual) </strong>
            )}
            {tier === "CLASSIC_COACH_MONTHLY" && (
              <strong>Tier: Classic & Coach (Monthly) </strong>
            )}
            {tier === "CLASSIC_COACH_ANNUAL" && (
              <strong>Tier: Classic & Coach (Annual) </strong>
            )}

            <hr></hr>
            <strong>Features:</strong>
            {(tier === "CORE_MONTHLY" || tier === "CORE_ANNUAL") && (
              <ul>
                <li>Your Daily Diary</li>
                <li>Your Insights Story</li>
                <li>Week Planner</li>
                <li>Interactive Experiences</li>
                <li>Free Access to 'Art of Looking' Course</li>
                <li>Ads Enabled</li>
              </ul>
            )}
            {(tier === "CLASSIC_MONTHLY" || tier === "CLASSIC_ANNUAL") && (
              <ul>
                <li>All Core NoWastedDays Features</li>
                <li>Ads Disabled</li>
              </ul>
            )}
            {(tier === "CLASSIC_COACH_MONTHLY" ||
              tier === "CLASSIC_COACH_ANNUAL") && (
              <ul>
                <li>All Core & Classic NoWastedDays Features</li>
                <li>24/7 Coach Chat Support</li>
              </ul>
            )}
            <strong>Details:</strong>
            <ul>
              {tier === "CORE_MONTHLY" && <li>Price: £0 per month</li>}
              {tier === "CORE_ANNUAL" && <li>Price: £0 per year</li>}
              {tier === "CLASSIC_MONTHLY" && <li>Price: £5.99 per month</li>}
              {tier === "CLASSIC_ANNUAL" && <li>Price: £50 per year</li>}
              {tier === "CLASSIC_COACH_MONTHLY" && (
                <li>Price: £7.99 per month</li>
              )}
              {tier === "CLASSIC_COACH_ANNUAL" && <li>Price: £75 per year</li>}

              <li>First Payment: {todaysDate}</li>
              <li>Next Payment: {nextMonthDate}</li>
              <li>
                You can cancel anytime by visiting your profile page and sending
                a cancel subscription request.
              </li>
            </ul>
          </div>
        </section>
        {userInfo &&
          (tier === "CORE_MONTHLY" || tier === "CORE_ANNUAL" ? (
            <section className="payment__main--payment">
              <div className="payment__area">
                <h1>Subscription Updated!</h1>
                <a href="/today">Start a New Day</a>
                <a href="/week/create">Create a New Week Plan</a>
                <a href="/insight/quiz">Explore the Art of Looking</a>
                <a href="/values">Think About Your Values</a>
              </div>
            </section>
          ) : (
            <section className="payment__main--payment">
              <div className="payment__area">
                <h1>Subscription Payment</h1>
                {clientSecret && stripePromise && email && (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm email={email} />
                  </Elements>
                )}
              </div>
            </section>
          ))}
      </main>
    </>
  );
}

export default Payment;
