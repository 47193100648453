// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Internal Components
import TestFooter from "../../layouts/Footer/TestFooter";
import { YearIcon, WeekIcon, InsightsIcon } from "../../components/Icons/Icon";

// External Packages
import * as d3 from "d3";

//Time Graphs
import FeelingScore from "../../components/Charts/FeelingScore/FeelingScore.js";
import TasksCompleted from "../../components/Charts/TasksCompleted/TasksCompleted.js";
import WaveChart from "../../components/Charts/Wave/WaveChart.js";
import TasksCompletionRate from "../../components/Charts/TaskCompletionRate/TaskCompletionRate";

// Week Graphs
import FeelingWD from "../../components/Charts/FeelingScore/Weekday/FeelingScoreWD.js";
import TasksCompletedWD from "../../components/Charts/TasksCompleted/WeekDay/TasksCompletedWD.js";
import TcrWD from "../../components/Charts/TaskCompletionRate/Weekday/TcrWD.js";
import MomentsWD from "../../components/Charts/TasksCompleted/WeekDay/MomentsWD.js";

// Crossover
import FeelingDoing from "../../components/Charts/Crossover/FeelingDoing.js";
import MomentByMoment from "../../components/Charts/Crossover/MomentByMoment";

// Insight Story
import InsightStory from "../../components/InsightStory/InsightStory.js";

//Loading
import TileLoading from "../../components/Loading/TileLoading";

//Title
import { Helmet } from "react-helmet";

//Header
import Header from "../../layouts/Header/Header";

//StyleSheet
import "./insightsscreen.css";

export default function InsightsScreen({ history, props }) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  // State
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonState, setButtonState] = useState(1);
  const [optionsState, setOptionsState] = useState(1);
  const [optionsSelected, setOptionsSelected] = useState("time");
  const [graphSelected, setGraphSelected] = useState("time_feeling_score");
  const [nodeSelected, setNodeSelected] = useState();
  const [device, setDevice] = useState("");
  const [graphWidth, setGraphWidth] = useState();
  const [storyActive, setStoryActive] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [trialDays, setTrialDays] = useState();

  const parseDate = d3.timeParse("%Y-%m-%d");

  //  useEffect(() => {
  //     if (
  //       !userInfo ||
  //       !userInfo.subscription ||
  //       !userInfo.subscription.userStart
  //     ) {
  //       console.log("No User Data");
  //     } else {
  //       const trialStartDate = userInfo.subscription.userStart;
  //       const currentTier = userInfo.subscription.plan;

  //       if (currentTier === "trial") {
  //         const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
  //         const trialStartDateMs = new Date(trialStartDate).getTime();
  //         const trialStartDateStart = new Date(trialStartDate);
  //         let thirtyDaysAhead = new Date(trialStartDateStart);
  //         thirtyDaysAhead.setDate(trialStartDateStart.getDate() + 30);
  //         const differenceInMilliseconds = Math.abs(thirtyDaysAhead - Date.now());
  //         const differenceInDays = Math.ceil(
  //           differenceInMilliseconds / (1000 * 60 * 60 * 24)
  //         );

  //         const currentDateMs = new Date().getTime();

  //         setTrialDays(differenceInDays);
  //       } else if (currentTier !== "trial") {
  //         setTrialDays(0);
  //       }
  //     }
  //   }, []);

  //    const userFunction = () => {
  //       if (
  //         !userInfo ||
  //         !userInfo.subscription ||
  //         !userInfo.subscription.userStart
  //       ) {
  //         console.log("No User Data");
  //       } else if (
  //         userInfo.subscription.plan === "trial" ||
  //         userInfo.subscription.plan === "classic_annual" ||
  //         userInfo.subscription.plan === "classic_monthly" ||
  //         userInfo.subscription.plan === "core_annual" ||
  //         userInfo.subscription.plan === "core_monthly"
  //       ) {
  //         setUserStatus("no_coach");
  //       } else {
  //         setUserStatus("coach");
  //       }
  //     };

  //     useEffect(() => {
  //       userFunction();
  //     }, [userInfo]);

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let orientation = window.orientation;

    if (width < 431 || height < 431) {
      setDevice("mobile");
      let diff = height / width - 1;
      let widthAdd = width * diff;

      if (height > width && orientation === 0) {
        setGraphWidth(width + widthAdd - 48);
      }
    } else if (width < 1025) {
      setDevice("tablet");

      let diff = height / width - 1;
      let widthAdd = width * diff;
      if (height > width) {
        setGraphWidth(width + widthAdd - 48);
      }
    } else {
      setDevice("desktop");
    }
  }, [device]);

  const [help, setHelp] = useState(
    "This is your feeling score mapped over time."
  );

  const updateOptionsSelected = (newValue) => {
    setOptionsSelected(newValue);
  };

  const updateGraphSelected = (newValue) => {
    setGraphSelected(newValue);
  };

  const updateButtonSelected = (newValue) => {
    setButtonState(newValue);
  };

  const updateNodeSelected = (newValue) => {
    setNodeSelected(newValue);
  };

  const handleSelectedButtonClick = (buttonId) => {
    setButtonState(buttonId);
  };

  const handleSelectedOptionClick = (optionId) => {
    setOptionsState(optionId);
  };
  // console.log(days);

  return (
    <>
      <Helmet>
        <title>Your Insights</title>
      </Helmet>
      {/* <HideDock showTutorial={showTutorial} /> */}
      <Header variant="light" />
      {pageLoading && !days ? (
        <TileLoading />
      ) : days.length < 10 ? (
        <div
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="tooltip__wrapper">
            <div className="tooltip__container">
              <h5 className="sickTooltip">
                When you log 10 days in your journal, you will unlock your
                insights.
              </h5>
            </div>
            <div className="tooltip--icon">
              <InsightsIcon />
            </div>
          </div>
        </div>
      ) : (
        <main className={`viewinsight ${device}`}>
          <section className={`insight__section ${device}`}>
            <div className={`insight__selectors ${device}`}>
              <div className={`insight__help ${device}`}>
                <span style={{ fontWeight: "600", fontFamily: "futura" }}>
                  {help}
                </span>
              </div>
              <div className={`button__div ${device}`}>
                <div
                  className={`insight__div ${device}`}
                  data-tooltip="All Time"
                >
                  <button
                    className={`insight__buttons button--square ${device}`}
                    style={
                      buttonState === 1
                        ? {
                            backgroundColor: "rgba(103, 36, 131,1)",
                            border: "2px solid #004a22",
                          }
                        : { backgroundColor: "#004a22", color: "#e2dfcf" }
                    }
                    onClick={() => {
                      setOptionsSelected("time");
                      setGraphSelected("time_feeling_score");
                      handleSelectedButtonClick(1);
                      handleSelectedOptionClick(1);
                    }}
                  >
                    <YearIcon />
                  </button>
                </div>
                <div
                  className={`insight__div ${device}`}
                  data-tooltip="Week Day"
                >
                  <button
                    className={`insight__buttons button--square ${device}`}
                    style={
                      buttonState === 2
                        ? {
                            backgroundColor: "rgba(103, 36, 131,1)",
                            border: "2px solid #004a22",
                          }
                        : { backgroundColor: "#004a22", color: "#e2dfcf" }
                    }
                    onClick={() => {
                      setOptionsSelected("week");
                      setGraphSelected("week_feeling_score");
                      handleSelectedButtonClick(2);
                      handleSelectedOptionClick(1);
                    }}
                  >
                    <WeekIcon />
                  </button>
                </div>
                <div
                  className={`insight__div ${device}`}
                  data-tooltip="Crossover"
                >
                  <button
                    className={`insight__buttons button--square ${device}`}
                    style={
                      buttonState === 3
                        ? {
                            backgroundColor: "rgba(103, 36, 131,1)",
                            border: "2px solid #004a22",
                          }
                        : { backgroundColor: "#004a22", color: "#e2dfcf" }
                    }
                    onClick={() => {
                      setOptionsSelected("cross");
                      setGraphSelected("cross_feeling_doing");
                      handleSelectedButtonClick(3);
                      handleSelectedOptionClick(1);
                      setHelp(
                        "This scatter plot contrasts how you felt with how much you did."
                      );
                    }}
                  >
                    <InsightsIcon />
                  </button>
                </div>
              </div>
            </div>
            {optionsSelected === "time" ? (
              <div className={`insight__options ${device}`}>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 1
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    setGraphSelected("time_feeling_score");
                    setHelp(
                      "This is your feeling score visualised from the start of your journalling until now."
                    );
                    handleSelectedOptionClick(1);
                  }}
                >
                  How did you feel?
                </button>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 2
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    setGraphSelected("time_moments_completed");
                    setHelp(
                      "This is how much you accomplished each day from the start of your journalling until now."
                    );
                    handleSelectedOptionClick(2);
                  }}
                >
                  How much did you do?
                </button>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 3
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    setGraphSelected("time_efficiency_graph");
                    handleSelectedOptionClick(3);
                    setHelp(
                      "This is how much you did of what you said you would do visualised over time."
                    );
                  }}
                >
                  Did you do everything you said you would?
                </button>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 4
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    setGraphSelected("time_wave_graph");
                    handleSelectedOptionClick(4);
                    setHelp("This is all your measures mapped over time.");
                  }}
                >
                  What does it look like if you combine all those ideas?
                </button>
              </div>
            ) : (
              <></>
            )}
            {optionsSelected === "week" ? (
              <div className={`insight__options ${device}`}>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 1
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    handleSelectedOptionClick(1);
                    setGraphSelected("week_feeling_score");
                    setHelp(
                      "This is how you felt on average on different days of the week."
                    );
                  }}
                >
                  How did you feel on different weekdays?
                </button>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 2
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    handleSelectedOptionClick(2);
                    setGraphSelected("week_moments_completed");
                    setHelp(
                      "This is how much you did on average on different days of the week."
                    );
                  }}
                >
                  How much did you do on different weekdays?
                </button>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 3
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    handleSelectedOptionClick(3);
                    setGraphSelected("week_day_efficiency");
                    setHelp(
                      "This is the % of things you did of what you said you would do on different days of the week."
                    );
                  }}
                >
                  Did you do everything you said you would on different days?
                </button>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 4
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    handleSelectedOptionClick(5);
                    setGraphSelected("week_days_frequency");
                    setHelp("Which days do you tend to add journal entries?");
                  }}
                >
                  Which days do you tend to add journal entries?
                </button>
              </div>
            ) : (
              <></>
            )}
            {optionsSelected === "cross" ? (
              <div className={`insight__options ${device}`}>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 1
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    setGraphSelected("cross_feeling_doing");
                    handleSelectedOptionClick(1);
                    setHelp(
                      "This scatter plot contrasts how you felt with how much you did."
                    );
                    handleSelectedOptionClick(1);
                  }}
                >
                  How did you feel? & How much did you do?
                </button>
                <button
                  className={`insight__buttons ${device}`}
                  style={
                    optionsState === 2
                      ? {
                          backgroundColor: "rgba(103, 36, 131,1)",
                          border: "2px solid #004a22",
                          color: "#e2dfcf",
                        }
                      : {
                          backgroundColor: "#004a22",
                          color: "#e2dfcf",
                          border: "none",
                        }
                  }
                  onClick={() => {
                    setGraphSelected("cross_moment_moment");
                    handleSelectedOptionClick(2);
                    setHelp(
                      "From top to bottom, the moments in your diary are ordered from 1 to 5. Here is the % you complete of each of them."
                    );
                    handleSelectedOptionClick(2);
                  }}
                >
                  How much of each moment you do on average.
                </button>
              </div>
            ) : (
              <></>
            )}
            {optionsSelected === "time" ? (
              <div className={`insight__graphs ${device}`}>
                {graphSelected === "time_feeling_score" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <FeelingScore
                      data={days}
                      nodeSelected={nodeSelected}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
                {graphSelected === "time_moments_completed" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <TasksCompleted
                      data={days}
                      nodeSelected={nodeSelected}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
                {graphSelected === "time_efficiency_graph" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <TasksCompletionRate
                      data={days}
                      nodeSelected={nodeSelected}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
                {graphSelected === "time_wave_graph" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <WaveChart
                      data={days}
                      nodeSelected={nodeSelected}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            {optionsSelected === "week" && (
              <div className={`insight__graphs ${device}`}>
                {graphSelected === "week_feeling_score" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <FeelingWD
                      data={days}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
                {graphSelected === "week_moments_completed" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <TasksCompletedWD
                      data={days}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
                {graphSelected === "week_day_efficiency" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <TcrWD
                      data={days}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
                {graphSelected === "week_days_frequency" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <MomentsWD
                      data={days}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
              </div>
            )}
            {optionsSelected === "cross" && (
              <div className={`insight__graphs ${device}`}>
                {graphSelected === "cross_feeling_doing" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <FeelingDoing
                      data={days}
                      nodeSelected={nodeSelected}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
                {graphSelected === "cross_moment_moment" && (
                  <div className={`insight__graphs__block ${device}`}>
                    <MomentByMoment
                      data={days}
                      nodeSelected={nodeSelected}
                      device={device}
                      graphWidth={graphWidth}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={`insights__signals ${device}`}>
              <span
                style={{
                  fontFamily: "futura",
                  fontSize: "0.9em",
                  fontWeight: "600",
                }}
              >
                The <div className="little_square"></div> indicate strong
                signals in your data. Click them to visit the corresponding day
                in your diary.
              </span>
            </div>
            <div className={`insight__story ${device}`}>
              <InsightStory
                data={days && days}
                updateOptionsSelected={updateOptionsSelected}
                updateGraphSelected={updateGraphSelected}
                updateButtonSelected={updateButtonSelected}
                updateNodeSelected={updateNodeSelected}
              />
            </div>
          </section>
        </main>
      )}
      <TestFooter />
    </>
  );
}
