import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as d3 from "d3";
import { ZeroRemove } from "../../../../utils/UtilityFunctions";
import "../tc.css";
import { text } from "d3";

const MomentsWD = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const d3Chart9 = useRef();
  const parseDate = d3.timeParse("%Y-%m-%d");
  let device = props.device;

  const getDataTransform = () => {
    const tasksCompletedData =
      Array.isArray(props.data) &&
      props.data.map((each) => {
        return {
          date: parseDate(each.logDate.slice(0, 10)),
          es: each.endScore,
        };
      });

    const tcDataFinal = tasksCompletedData.sort((a, b) => a.date - b.date);

    // Function to get the day of the week from a date string
    const getDayOfWeek = (dateString) => {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const date = new Date(dateString);
      return daysOfWeek[date.getDay()];
    };

    const transformArray = (inputArray) => {
      const transformedArray = inputArray.reduce((result, item) => {
        const dayOfWeek = getDayOfWeek(item.date);

        if (!result[dayOfWeek]) {
          result[dayOfWeek] = {
            weekDay: dayOfWeek,
            count: 0,
          };
        }

        result[dayOfWeek].count += 1;
        return result;
      }, {});

      // Convert transformed object to an array
      const outputArray = Object.values(transformedArray);

      // Define the order of the days
      const daysOrder = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      // Sort the array based on the days order
      outputArray.sort(
        (a, b) => daysOrder.indexOf(a.weekDay) - daysOrder.indexOf(b.weekDay)
      );

      return outputArray;
    };
    let output = transformArray(tcDataFinal);

    return output;
  };

  useEffect(() => {
    let tcData = getDataTransform();

    const margin = { top: 24, right: 36, bottom: 48, left: 80 };
    const width =
      parseInt(d3.select(`#d3demo9${device}`).style("width")) -
      margin.left -
      margin.right;
    const height =
      parseInt(d3.select(`#d3demo9${device}`).style("height")) -
      margin.top -
      margin.bottom;

    // Set up chart
    const svg = d3
      .select(d3Chart9.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const maxValue = d3.max(tcData, function (d) {
      return d.count;
    });

    const minValue = d3.min(tcData, function (d) {
      return d.count;
    });

    // Create the x and y scales
    var xScale = d3
      .scaleBand()
      .domain([
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ])
      .range([0, width])
      .padding(0.1);

    var yScale = d3
      .scaleLinear()
      .domain([0, maxValue + 10])
      .range([height, 0]);

    const Tooltip5 = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("border", "2px solid #004a22")
      .style("border-radius", "2.5px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover1 = function (d) {
      Tooltip5.style("opacity", 1);
      Tooltip5.style("display", "flex");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)")
        .style("cursor", "pointer")
        .style("stroke-width", "2");
    };

    const mousemove1 = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo9${device}`));

      Tooltip5.text(`${d.count}`)
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "#004a22");
    };

    var mouseleave1 = function (d) {
      Tooltip5.style("opacity", 0);
      Tooltip5.style("display", "none");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)")
        .style("cursor", "auto")
        .style("stroke-width", "1");
    };

    // Create the x-axis
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .style("font-family", "Futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 200);

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 12.5) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Weekdays");

    // Create the y-axis
    svg
      .append("g")
      .call(d3.axisLeft(yScale))
      .style("font-family", "futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 600);

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Total");

    // Create the bars
    svg
      .selectAll(".bar")
      .data(tcData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", function (d) {
        return xScale(d.weekDay);
      })
      .attr("width", xScale.bandwidth())
      .attr("y", function (d) {
        return yScale(d.count);
      })
      .attr("height", function (d) {
        return height - yScale(d.count);
      })
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (d.count === maxValue || d.count === minValue) {
          return "rgba(103, 36, 131,1)"; // Change color for values within range
        } else {
          return "rgba(0,74,34,1)"; // Default color for other values
        }
      })
      .style("stroke", "#e2dfcf")
      .style("stroke-width", "1")
      .style("opacity", 1)
      .style("z-index", "1")
      .style("stroke", "#e2dfcf")
      .style("stroke-width", "1")
      .style("opacity", 1)
      .style("z-index", "1")
      .on("mouseover", mouseover1)
      .on("mousemove", mousemove1)
      .on("mouseleave", mouseleave1);
  }, [props.nodeSelected]);

  return (
    <div
      id={`d3demo9${device}`}
      style={props.graphWidth && { width: `${props.graphWidth}px` }}
    >
      <svg ref={d3Chart9}></svg>
    </div>
  );
};

export default MomentsWD;
