// React
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// External Packages
import * as d3 from "d3";
import CryptoJS from "crypto-js";
import generateSecret from "../../../utils/generateSecret";

// Styling
import "./feelingscorechart.css";

export default function FeelingScore(props) {
  const navigate = useNavigate();
  const d3Chart = useRef();
  const parseDate = d3.timeParse("%Y-%m-%d");
  const key = generateSecret().toString();
  let device = props.device;

  useEffect(() => {
    let feelingData = Array.isArray(props.data) && props.data;

    const startScoreData = feelingData.map((each) => {
      let node = false;
      let mem = each.rememberToday;
      let leave = each.leaveBehind;

      if (props.nodeSelected === each.logDate.slice(0, 10)) {
        node = true;
      }
      return {
        id: each._id,
        date: parseDate(each.logDate.slice(0, 10)),
        es: each.endScore,
        node: node,
        memory: mem,
        leave: leave,
      };
    });

    const ssDataFinal = startScoreData.sort((a, b) => a.date - b.date);

    let ssDataFinalFinal = () => {
      if (ssDataFinal.length > 5) {
        let ssDataLength = ssDataFinal.length - 1;

        if (
          ssDataFinal[ssDataLength].es === undefined ||
          ssDataFinal[ssDataLength].es === null ||
          isNaN(ssDataFinal[ssDataLength].es)
        ) {
          ssDataFinal.pop();
        }
      }

      return ssDataFinal;
    };

    const avgArray = [];
    ssDataFinalFinal() &&
      ssDataFinalFinal().map((each, i) => avgArray.push(each.es));

    const ZeroRemove = (arr) => {
      let lastItem = arr.length - 1;

      if (
        arr[lastItem] === 0 ||
        isNaN(arr[lastItem]) ||
        arr[lastItem] === undefined
      ) {
        return arr.pop();
      } else {
        return arr;
      }
    };

    ZeroRemove(avgArray);

    const avg = avgArray && Math.round(d3.mean(avgArray) * 10) / 10;

    const margin = { top: 24, right: 24, bottom: 48, left: 72 };
    const width =
      device &&
      parseInt(d3.select(`#d3demo${device}`).style("width")) -
        margin.left -
        margin.right;
    const height =
      device &&
      parseInt(d3.select(`#d3demo${device}`).style("height")) -
        margin.top -
        margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const x = d3
      .scaleTime()
      .domain(
        d3.extent(ssDataFinalFinal(), function (d) {
          return d.date;
        })
      )
      .range([0, width]);

    const tickFormat =
      ssDataFinalFinal().length < 50
        ? d3.timeFormat("%b %y")
        : d3.timeFormat("%b %y");

    const xAxis2 = d3.axisBottom(x).tickFormat(d3.timeFormat(tickFormat));

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis2.ticks(d3.timeMonth))
      .style("font-family", "futura")
      .style("font-weight", 200)
      .style("font-size", "8px")
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 12.5) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Date");

    const max = d3.max(ssDataFinalFinal(), function (d) {
      return d.es;
    });
    const min = d3.min(ssDataFinalFinal(), function (d) {
      return d.es;
    });

    const upperLimit = max - max / 20;
    const lowerLimit = min + max / 20;

    const yScale = d3.scaleLinear().domain([0, 5]).range([height, 0]);

    const customTickLabels = ["Less Joy", "More Joy"];
    const customTicks = [0, 5];

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(customTicks)
      .tickFormat((d, i) => customTickLabels[i]);

    svg
      .append("g")
      .call(yAxis)
      .style("font-family", "futura")
      .style("font-size", "8px")
      .style("font-weight", 200)
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Feeling");

    var Tooltip = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("max-width", "250px")
      .style("border", "2px solid #004a22")
      .style("border-radius", "2.5px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover = function (d) {
      Tooltip.style("opacity", 1);
      Tooltip.style("display", "flex");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "2")
        .style("cursor", "pointer")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    var handleNavigate = (id) => {
      // navigate(`/today/${id}`);

      window.open(`/today/${id}`, "_blank");
    };

    const mousemove = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo${device}`));

      Tooltip.html(
        `<div style={{ display: 'flex', flex-direction: 'column'}}>
								<strong>Remember From Today:</strong>
								<p>${d.memory}</p>
								<hr/>
								<strong>Leave Behind Today:</strong>
								<p>${d.leave}</p>
								</div>`
      )
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "#004a22");
    };

    var mouseleave = function (d) {
      Tooltip.style("opacity", 0);
      Tooltip.style("display", "none");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "1")
        .style("cursor", "auto")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    // Draw line
    svg
      .append("path")
      .datum(ssDataFinalFinal())
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "0.1")
      .style("z-index", "-1")
      .attr("stroke-width", 6)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(d.es);
          })
          .curve(d3.curveBasis)
      );

    svg
      .append("path")
      .datum(ssDataFinalFinal())
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "1")
      .style("z-index", "-1")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", 4)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(avg);
          })
      );

    svg
      .append("g")
      .selectAll("square")
      .data(ssDataFinalFinal())
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return x(d.date) - 2.5;
      })
      .attr("y", function (d) {
        return yScale(d.es) - 2.5;
      })
      .attr("width", 10)
      .attr("height", 10)
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners (ry is for vertical rounded corners, not required for squares)
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (d.es > upperLimit || d.es < lowerLimit) {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(103, 36, 131,1)";
          }
        } else {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(0,74,34,1)";
          }
        }
      }) // Set the fill color
      .style("stroke", function (d) {
        // Check if the value falls within the specified range
        if (d.node === true) {
          return "#f5f5f5";
        } else {
          return "#e2dfcf";
        }
      }) // Set the stroke color
      .style("stroke-width", "1")
      .style("z-index", 10)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .on("click", function (d) {
        Tooltip.style("opacity", 0);
        handleNavigate(d.srcElement.__data__.id);
      });

    // {`d3demo${props.device}`}
  }, [props.nodeSelected]);

  return (
    <div
      id={`d3demo${device}`}
      style={props.graphWidth && { width: `${props.graphWidth}px` }}
    >
      <svg ref={d3Chart}></svg>
    </div>
  );
}
