// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Button, Row, Col, Container, Form } from "react-bootstrap";

// External Packages
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCaretLeft,
  faSquareCaretRight,
} from "@fortawesome/free-solid-svg-icons";

// Internal Components
import TestFooter from "../../../layouts/Footer/TestFooter";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import Header from "../../../layouts/Header/Header";
import generateSecret from "../../../utils/generateSecret";

// User Actions
import { updateQuizAction, listQuizes } from "../../../actions/quizActions";
import { updateProfile } from "../../../actions/dayActions";

// Styling
import "./quiztasks.css";

export default function QuizTasksScreen(history) {
  const [titleDiv, setTitleDiv] = useState(false);
  const [whatYou, setWhatYou] = useState(true);
  const [myLearnings, setMyLearnings] = useState(false);
  const [myLearnings1, setMyLearnings1] = useState(false);
  const [myLearnings2, setMyLearnings2] = useState(false);
  const [myLearnings3, setMyLearnings3] = useState(false);
  const [myLearnings4, setMyLearnings4] = useState(false);

  const [input, setInput] = useState(true);
  const [output, setOutput] = useState(false);

  const [chapterOneScore, setChapterOneScore] = useState(41.5);
  const [chapterTwoScore, setChapterTwoScore] = useState(0);
  const [chapterThreeScore, setChapterThreeScore] = useState(0);
  const [chapterFourScore, setChapterFourScore] = useState(0);

  const [chapterOneScoreLive, setChapterOneScoreLive] = useState();
  const [chapterTwoScoreLive, setChapterTwoScoreLive] = useState();
  const [chapterThreeScoreLive, setChapterThreeScoreLive] = useState();
  const [chapterFourScoreLive, setChapterFourScoreLive] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const quizUpdate = useSelector((state) => state.quizUpdate); //
  const { success: successUpdate } = quizUpdate;

  const quizList = useSelector((state) => state.quizList);
  const { quizes } = quizList;

  useEffect(() => {
    dispatch(listQuizes());
  }, [dispatch, userInfo, history]);

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateQuizAction(
        id,
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !chapterOneScore ||
      !chapterTwoScore ||
      !chapterThreeScore ||
      !chapterFourScore
    )
      return;

    // resetHandler();
  };

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
    },
  };

  const showHideFlex = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideFlexCol = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const michealCaine = "sqOgyNfHl1U";

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  useEffect(() => {
    document.title = "Art of Looking";
  }, []);

  const [hasQuiz, setHasQuiz] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    if (Array.isArray(quizes)) {
      if (quizes.length === 0) {
        setHasQuiz((hasQuiz) => false);
      } else if (quizes.length === 1) {
        setLinkId((linkId) => quizes[0]._id);

        setChapterOneScoreLive(quizes[0].chapterOneScore);

        if (chapterOneScoreLive > chapterOneScore) {
          setChapterOneScore(chapterOneScoreLive);
        }

        setChapterTwoScoreLive(quizes[0].chapterTwoScore);

        if (chapterTwoScoreLive > chapterTwoScore) {
          setChapterTwoScore(chapterTwoScoreLive);
        }

        setChapterThreeScoreLive(quizes[0].chapterThreeScore);

        if (chapterThreeScoreLive > chapterThreeScore) {
          setChapterThreeScore(chapterThreeScoreLive);
        }

        setChapterFourScoreLive(quizes[0].chapterFourScore);

        if (chapterFourScoreLive > chapterFourScore) {
          setChapterFourScore(chapterFourScoreLive);
        }
      }
    } else {
      setHasQuiz((hasQuiz) => true);
    }
  });

  // const [content, setContent] = useState();

  // async function getPosts() {
  //   const posts = await client.fetch(`*[_type == 'post']{
  //     _id,
  //     title,
  //     mainImage {
  //       asset -> {
  //         _id,
  //         url
  //       },
  //     },
  //   }`);
  //   return posts;
  // }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let picsObj = [];

  //     try {
  //       const response = await getPosts();
  //       if (response) {
  //         for (let i = 0; i < response.length; i++) {
  //           if (response[i].title.startsWith("AOFL_")) {
  //             picsObj.push({
  //               id: response[i].mainImage.asset._id,
  //               title: response[i].title,
  //               url: response[i].mainImage.asset.url,
  //             });
  //           }
  //         }
  //       }
  //       setContent(picsObj);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   console.log(content);
  // });

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "24px",
        }}
      >
        <motion.div
          className="titleDiv"
          animate={titleDiv ? "show" : "hide"}
          variants={showHideState}
          onClick={() => {
            setTitleDiv((titleDiv) => false);
            setWhatYou((whatYou) => true);
          }}
        >
          <h1>Moment Scores</h1>
          <p>
            <strong>Click here to get started</strong>
          </p>
        </motion.div>

        <motion.div
          animate={whatYou ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">What you do each day.</h4>
            </div>
            <br />
            <p>Now we are motoring along! Up next, your !</p>
            <p>
              The middle page in your journal is for simply and smartly planning
              your day. Each of the 5 blocks you use can then be scored out of
              10. Logging and scoring your moments this way can create a
              staggering amount of interesting data.
            </p>
            <p>
              We can look at how much you did, how efficiently you did it, how
              much you tried to do, the weekday that you do the most and so much
              more!
            </p>
            <hr />
            <p>
              All of this being true, as with the feeling score, I want to now
              share the 4 biggest difference makers when it comes to interacting
              with your data generated by your plans and productivity.
            </p>
            <br></br>
            <p>
              1.
              <strong>"Wait? I am just a hot mess?"</strong>
            </p>

            <p>
              2.
              <strong>"Hmm... That doesn't sound like me?"</strong>
            </p>

            <p>
              3.
              <strong>"Wait? Surely that must be bad?"</strong>
            </p>

            <p>
              4.
              <strong>The Right Balance of Running and Resting</strong>
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/6648e3835bbd89a6e01031a3a1d6de7aac1e701a-5000x3250.png"
              style={{ border: "2px solid rgba(0, 74, 34, 0.4)" }}
              alt="Moments Overview"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setWhatYou((whatYou) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setWhatYou((whatYou) => false);
                setMyLearnings((myLearnings) => false);
                setMyLearnings1((myLearnings1) => true);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings ? "show" : "hide"}
          variants={showHideGrid}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">3 Key Starting Points</h4>
            </div>
            <br />
            <p>
              In relation to the moments, there are 4 particular learnings that
              stand out in my mind as likely having the greatest positive impact
              on your initial interactions with the data.
            </p>
            <br />
            <br />
            <p>1.</p>
            <strong className="emphasis">"Wait? I am just a hot mess?"</strong>
            <br />
            <br />
            <p>2.</p>
            <strong className="emphasis">
              "Hmm... That doesn't sound like me?"
            </strong>
            <br />
            <br />
            <p>3.</p>
            <strong className="emphasis">
              "Wait? Surely that must be bad?"
            </strong>
            <br />
            <br />
            <p>4.</p>
            <strong className="emphasis">Balance of Running and Resting</strong>
          </div>
          <div className="rightCol">
            <div className="rightContent">
              <p>A real cool visual. Fancy making one?</p>
              <a href={"https://www.nowasteddays.org/contact"} target="_blank">
                Send me an idea.
              </a>
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => false);
                setWhatYou((whatYou) => true);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => false);
                setMyLearnings1((myLearnings1) => true);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings1 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Lukewarm mess alert!</h4>
            </div>
            <br />
            <p>
              In the feeling score section, we kicked off by talking about
              peripeteia and anagorisis. Boy! I have had my fair share of those
              over my time using NoWastedDays, and there may be no better way to
              help you all cope with the uncomfortable revelations in your own
              data than to share some of the most revealing things my data has
              tried to signal to me about myself.
            </p>
            <br />
            <br />
            <p>
              Look at my data from an old google-sheets based version of
              NowastedDays. I think it is fair to say that the data is trying to
              tell me something loud and clear! Immediately signals start
              leaping off the screen:
            </p>
            <br />
            <br />
            <div className="mobileImage">
              <img
                className="taskImage1"
                src="https://cdn.sanity.io/images/ou6d2gec/production/1115690dda18b50812620a7bcf5543cfe00f9488-2232x868.png"
                width="75%"
              />
              <img
                className="taskImage2"
                src="https://cdn.sanity.io/images/ou6d2gec/production/90c8d40e474c6510352646e67c7ff1e5aa3b12c1-2224x872.png"
                width="75%"
              />
              <br />
              <br />
            </div>

            <p>
              <strong>Spot a Signal:</strong> "Man, your tasks charts show a lot
              of variance and not much consistency."
            </p>

            <p>
              <strong>Ask Better Questions:</strong> "Ooh! I wonder what is
              causing you to...
              <br></br>
              <br></br>
              <ul>
                <li>
                  ...not have many days where you do everything you said you
                  would?"
                </li>
                <br></br>
                <li>
                  ...rarely follow a day where you did do everything you said
                  you would with another such day?"
                </li>
                <br></br>
                <li>
                  ...write things down that perhaps you are not truly engaged
                  and invested in doing?"
                </li>
              </ul>
            </p>
            <br></br>
            <strong>
              Remember, these signals, just as your own will be, are objective
              nudges, without emotion and attachment, that are trying to teach
              you something about yourself, and are not a value judgement.
            </strong>
            <br />
            <br />
            <p>
              The signal itself cannot provide a solution. The 'solution' comes
              from following it with a question, sufficient scrutiny and
              exploration and then an observation that will inspire a new
              action. This new action will then create different kinds of
              signals in your data. And, the cycle will renew itself.
            </p>
            <p>
              Other sections of this course talk in more detail regarding the
              mindset and mental tools that can help you cope with this cycle of
              self-revelation, however, this is a very timely moment to empower
              you to see your distant or recent past with lots of empathy by
              sharing more from my own.
            </p>
            <hr></hr>
            <p>
              We are all different versions of the human animals response to
              being alive. This response will reflect the various difficulties
              that being alive presents; learning, connecting, growing,
              thinking, perceiving etc.
            </p>
            <p>
              Following my own response, a pretty accurate description of me
              would be as follows...
            </p>
            <p>
              I am a person whose suffering has tended to manifest in terms of
              people pleasing and self-sabotage by making things too difficult,
              attempting more than I could possibly do and or not seeing things
              through to the finish.
            </p>
            <p>
              You can see some of this in my data. I can't string two productive
              days together. Whilst the story isn't always that simple, it is a
              accurate observation.
            </p>
            <p>
              In several ways, both directly and indirectly, being the kind of
              person who has the kind of suffering and creates the kind of data
              I did does tend to make you more...
            </p>
            <p>
              <strong>Curious:</strong>
            </p>

            <p>
              Being subconsciously committed to not finish things you started,
              if you are really good at it, often means starting another really
              quite divergent thing. Despite not having the skills or
              understanding to do either thoroughly, in my mid-twenties, I tried
              to start a sustainable fashion brand and an ice hockey analytics
              company - at the same time, as one person, with no genuine hard
              skills in either space. I succeeded a little at both, but could
              never lay a foundation for sustained success. This caused me to
              burn myself out, spend all of my savings and take on business
              loans with nothing to show for it except some hard lessons and
              fond memories.
            </p>
            <br />
            <br />
            <strong>Creative</strong>
            <br />
            <p>
              If you have explored many divergent things, you can see joins that
              others struggle to or you will have a larger plain of reference
              from which to make imaginative suggestions. In contrast, people
              who specialise on just one thing will inevitably lack this ability
              to channel the vast underlying connectedness of things because
              their frame of reference is too narrow.
            </p>
            <br />
            <br />
            <strong>Empathetic</strong>
            <br />
            <p>
              Playing in different spaces usually means being prepared to be
              vulnerable and having seen or heard a greater diversity of human
              stories; both of which are likely to engender more empathy in you.
            </p>
            <br />
            <br />
            <p>
              This all being true, the manifestations of my suffering I have
              just shared have principally been acting as blockers to a better
              relationship with my own mind and, as a result, how I can best
              relate to the world and allow my gifts and purpose to shine
              through. I am doing an increasingly effective job of transcending
              the worst parts of my suffering by accepting them, understanding
              them and transforming my relationship with them. I would never
              have reached this clarity quite so calmly had it not been for the
              NoWastedDays format; the signals it spawns, the questions it then
              prompted, the explorations it then inspired and the observations
              they unlocked which I then translated into action.
            </p>
            <br />
            <br />
            <p>
              In summary, as one my favourite sports coaches says, 'Doers make
              mistakes'. Expect your data to look like a hot mess and remember
              this mess is just a series of signals that you can use to improve.
              Also, don't forget that no one comes out of childhood and into
              adulthood unscathed so have a base expectation that you will have
              inhibiting behaviours that it is both a joy and challenge to
              transcend over time.
            </p>
          </div>
          <div className="rightCol" style={{ backgroundColor: "#004a22" }}>
            <div id="taskImageDiv" className="rightContent">
              <img
                className="taskImage1"
                src="https://cdn.sanity.io/images/ou6d2gec/production/1115690dda18b50812620a7bcf5543cfe00f9488-2232x868.png"
                width="75%"
              />
              <img
                className="taskImage2"
                src="https://cdn.sanity.io/images/ou6d2gec/production/90c8d40e474c6510352646e67c7ff1e5aa3b12c1-2224x872.png"
                width="75%"
              />
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings1((myLearnings1) => false);
                setWhatYou((myLearnings) => true);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings1((myLearnings1) => false);
                setMyLearnings2((myLearnings2) => true);

                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 49.8);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings2 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h3 className="quizHeaderWhite">"But you said you would!"</h3>
            </div>
            <br />
            <p>Another section, another tasty takeaway.</p>
            <p>
              From my 800 days and rising inputted in my NoWastedDays, a really
              key takeaway and experience I can share with utter confidence is
              that{" "}
              <strong>
                the action(s) that sit behind any of the metrics or measures in
                your data
              </strong>
              <strong> are never wholly bad or wholly good.</strong>
            </p>
            <br></br>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/ad7e3bff4ce994fffb00a3ef2fb8113116fa510a-5000x3250.png"
                width="90%"
              ></img>
            </div>
            <strong>Why is that?</strong>
            <p>
              In life, you can only consider using the terms 'bad' or 'good' if
              it is completely clear what single trait, outcome or objective you
              are optimising for (and even then I'd probably still resist doing
              so). This is something which is obvious to us in some realms, but
              often escapes us when referring to our own lives and minds.
            </p>
            <p>
              For example, if you want to climb to the top of a mountain, a good
              step is one that takes you closer to the top or closer to a route
              that will then take you do the top. A bad step is one that takes
              you further away from the top, or further from a safe route to the
              top. When you have one single objective, through this very narrow
              lens, you don't lose much by perceiving things as good and bad.
            </p>
            <p>
              However, when you are dealing with a far larger lens such as the
              myriad things that contribute to your joy, growth and well being,
              my experience has shown me that it is spectacularly unhelpful to
              perceive the vast swathe of life as 'bad'.
            </p>
            <p>This is because something is 'good' thanks to either...</p>
            <p>
              a) how it is helping provide, in the present moment or confidently
              nurture for the future, a relationship with joy and growth that
              you know deeply resonates with your authentic self, doesn't have
              unhealthy side effects and is, therefore, of known positive value
              or, far more simply put, <strong>is actually good.</strong>,
            </p>
            <br />
            <br />
            <p>
              or b) how it has the <strong>potential for good</strong> by
              offering you a chance to transform your currently negative
              relationship with it. This could be a long standing habit, or
              something you have yet to explore; ostensibly any thought or
              action that, with some introspection and a degree of effort, will
              be transcended to the point that it falls in the category above.
              (Unfortunately, there is not easy word for these 'potential for
              goods' at the moment. Let me know if you have any ideas!)
            </p>

            <p>
              It's quite a lot like when athletes are asked, as a veiled
              reference to struggles in their life, 'Looking back, do you think
              you would you have changed anything?'. Far more often than not,
              they say something to the effect of "No, because without that
              difficult time in my life, I might never have made it here today".
            </p>
            <p>
              No one comes through their early life and early adult life
              unscathed i.e. free from trauma, incident or anywhere near
              perfectly calibrated for peak levels of existential conscience
              that result in a near divine experience of every present moment.
              As a result, we all have a long list of a 'potential for goods' in
              our life, and it is the joy of a lifetime to find a way to
              transcend them. Every incremental (even infinitesimal) improvement
              in condition is a huge and captivating victory.
            </p>
            <p>
              Overall, remember in the largest, most prevailing sense, when
              dealing with joy and growth, we are not dealing in the realms of
              good and bad; only good and the potential for good thanks to
              self-awareness turning trauma into transformation.
            </p>
            <br />
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/ad7e3bff4ce994fffb00a3ef2fb8113116fa510a-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings2((myLearnings2) => false);
                setMyLearnings1((myLearnings1) => true);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings2((myLearnings2) => false);
                setMyLearnings3((myLearnings3) => true);

                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 58.1);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings3 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h3 className="quizHeaderWhite">
                What is getting in the way of me doing what I said I would do?
              </h3>
            </div>
            <br />
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/304e962155b2c805949e76d5457c3361726ab33d-5000x3250.png"
                width="90%"
              ></img>
            </div>

            <p>
              As we were just starting to explore in the previous step, the
              action that is impacting any metric is never wholly bad or good
              because that narrow paradigm is not sufficiently applicable to the
              nurturing of greater joy and growth over time within the human
              experience.
            </p>
            <p>
              Similarly to this wholly not bad or good point, whatever is
              getting in the way of me doing what I said I would do could fall
              anywhere on the following scale from...
            </p>

            <strong>
              something I should 100% avoid doing and or craving because I have
              near complete confidence it is inversely correlated with my joy
              and growth, both now and in the future.
            </strong>
            <br />
            <br />
            <p>or...</p>
            <br />

            <strong>
              something that I should, in fact, optimise lots of my life towards
              because, without me realising it before now, it is something I
              experience huge joy and or growth from every time I do it.
            </strong>
            <hr></hr>
            <p>
              To repeat, what is getting in the way of you doing what you said
              you would do may be something that feels like a classic habit
              faux-pas (think getting distracted by instagram, or losing time
              due to poor management of your day). But, it could also be the
              actual joys of your lifetime getting in the way; joys that you are
              either suppressing, to use Jim Carrey's line due to 'fear
              disguised as practicality', or are completely oblivious to.
            </p>

            <p>
              I have had many such days, and it does hit you over the head when
              you see it on the screen. You stare at a day where in the morning
              you mapped out this temporal mind-field of a plan featuring a
              million things, juggling work and play in some cartesian web of
              personal 'mastery'. But all you did in the end is have a lay-in, a
              read on the sofa with a coffee, get an ice cream with your
              girlfriend, sit and watch the sunset from that nice seat on the
              canal, and then wander home, taking the odd moment to dance
              together in the fading light. You see a big feeling score attached
              to such a 'disastrous' day in terms of those pre-planned moments
              and it gets your attention.
            </p>
            <p>The signals start popping up off the page:</p>

            <p>
              <strong>Spot a signal:</strong> "I have a low productivity but
              high feeling"
            </p>

            <p>
              <strong>Ask Better Questions:</strong> "I wonder if I..."
              <br></br>
              <br></br>
              <ul>
                <li>"...am optimising for the right things?"</li>
                <br></br>
                <li>
                  "...making enough time for joys like serendipity and quality
                  time with those I love."
                </li>
                <br></br>
                <li>
                  "...am stuck always optimising for the complex or complicated
                  versus the simple and serene?"
                </li>
              </ul>
            </p>

            <hr className="dividingLine" />
            <br />
            <p>
              On the flip side, here are a few things that, when delving into my
              diary, I found were getting in my way in the more conventional
              sense.
            </p>
            <p>
              <strong>Not blocking time:</strong> I would say I wanted to do
              something, but not block out a specific time window to focus on
              and complete it.
            </p>
            <p>
              <strong>Not making a moment easier to action or evaluate:</strong>{" "}
              (Often true of exercise.) Writing in 'Back and Biceps workout'
              means think of what exercises to do, in what order, how many sets,
              reps, rest, where are you are going to do it. But instead linking
              to a pre-made plan or a YouTube workout really reduces decision
              fatigue and helps increase all my moment scores.
            </p>
            <p>
              <strong>Not asking for help:</strong> Your moments don't all
              expressly relate to things you have to do yourself. Putting things
              in motion and calling on the help of experts and friends is
              essential for a) achieving the things you'd like to do, b) getting
              more joy via sharing the journey with others and c) learning more
              yourself.
            </p>
            <p>
              <strong>Not blocking websites on my laptop:</strong> I can
              definitely be guilty of falling into a concentration catastrophe
              by loading up YouTube, whatsapp, BBC news, etc when my brain is
              working on a hard task. Using the google chrome extension 'Focus'
              really helps with that.
            </p>
            <p>
              <strong>Looking at social media first thing:</strong> I have found
              this recently. I finally put an iPhone 5C I had been using to
              rest, and replaced it with a refurbished iPhone 13. One of the
              huge virtues of an old phone is that is much harder to get
              distracted, even more so when the tiny screen is cracked. But on
              this brand new device, everything is big, pretty and crystal
              clear, and suddenly I have it by my side constantly and am
              checking instagram as soon as I wake up, and sometimes this can
              throw my capacity to concentrate across the entire day.
            </p>
            <p>
              <strong>
                Not saying no to things that came up but weren't on the list:{" "}
              </strong>
              I have certainly been a bit of a people pleaser in my past, and
              sometimes this would derail my day simply because I couldn't say
              no to asks or invitations.
            </p>
            <p>
              <strong>Not engaging with my plan more:</strong> I like to keep
              the browser tab open or my today page linked in my bookmarks so I
              can easily come back to it, and now I can view it on my phone as
              well. But when it was just a spreadsheet in its very early days,
              this was harder to do.
            </p>
            <p>
              <strong>Not writing moments specifically enough:</strong> How are
              you meant to complete a task to the full with no parameters that
              define success?
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/304e962155b2c805949e76d5457c3361726ab33d-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings3((myLearnings3) => false);
                setMyLearnings2((myLearnings2) => true);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings3((myLearnings3) => false);
                setMyLearnings4((myLearnings4) => true);

                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 66.4);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings4 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                Finding your balance between running and resting
              </h4>
            </div>
            <br />
            <p>
              If the light teasing of my friends is anything to go by, were
              NoWastedDays to ever become a more widely known platform, the
              prevailing opinion would be that, on the basis of the name, that
              it is about cramming more and more activities into everyday in the
              kind of Type-A frantic, no rest, 'sleep when you are dead' way
              that is often lauded in our culture.
            </p>

            <p>
              NoWastedDays really refers to the notion that on any given day we
              don't want the information we need to learn more about ourselves -
              that will in time help us become more self-aware and self-assured
              - to be wasted. We are generating this information constantly, and
              the <strong>Diary</strong> format is a prime way to consolidate a
              lot of what would otherwise go missing.
            </p>

            <p>
              To this point, a huge learning from my personal experience of
              feverish over-activity and unsustainable action was that of the 5
              moments I had a day, a larger percentage than I initially thought
              needed to be explicity dedicated to rest and recovery. Without
              this very intentional recovery element, any heighten productivity
              would be offset by a troubling crash in energy, motivation and
              self-compassion.
            </p>
            <p>
              How much rest and how much recovery is needed will certainly vary
              person to person, but I have found the following inspiration to
              provide a really good baseline; the penultimate couplet in the
              Rudyard Kipling poem, 'If'.
            </p>
            <p>
              <strong>If you can fill the unforgiving minute,</strong>
              <br />
              <strong>With fourty seconds’ worth of distance run...</strong>
              <br />
              <strong>Yours is the earth and everything thats in it,</strong>
              <br />
              <strong>and which is more, you'll be a man, my son.</strong>
              <br />
              <br />
              40 seconds is 66% of a minute so, if you apply this to your
              NoWastedDays, a good baseline is having 3 things dedicated to
              growth and 'achievement' and 2 solely dedicated to recovery and
              restoration.
            </p>
            <div className="mobileImage">
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${michealCaine}?start=0`}
              ></iframe>
            </div>
          </div>
          <div className="rightCol">
            <div className="rightContent">
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${michealCaine}?start=0`}
              ></iframe>
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings3((myLearnings3) => true);
                setMyLearnings4((myLearnings4) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 66.4);
                  navigate(`/insight/quiz/${linkId}/wave`);
                }, 1000);
              }}
            />
          </div>
        </motion.div>
      </main>
      <TestFooter />
    </>
  );
}
