// React
import React, { useState, useMemo } from "react";

// Internal Components
import AutoResizeTextArea from "../../components/AutoResizeTextarea/AutosizeTextarea";
import SliderTooltip from "../../components/Tooltips/Tooltips";

function PlanningTile({
  today,
  tooltipPosition,
  continuation,
  startSlider,
  background,
  handleChange,
}) {
  // State
  const [cornerState, setCornerState] = useState({
    momentOneScore: "cornerStateOff",
    momentTwoScore: "cornerStateOff",
    momentThreeScore: "cornerStateOff",
    momentFourScore: "cornerStateOff",
    momentFiveScore: "cornerStateOff",
  });

  // Hooks

  return (
    <div className={"tile left"}>
      <div className="dateRow--header">
        <label className="score label">
          {isNaN(continuation)
            ? `My Plan for Day`
            : `My Plan for Day #${continuation}`}
        </label>
      </div>

      <div className="moment__submission">
        <div
          className={
            today.momentOneScore === "10" || today.momentOneScore === undefined
              ? `score__row--moment complete`
              : "score__row--moment"
          }
        >
          <AutoResizeTextArea
            className={"formInput moment"}
            wrap="soft"
            value={today.momentOne && today.momentOne}
            name="momentOne"
            autoComplete="off"
            placeholder={
              "Slow mindful first hour of the day; tea, stretch, no phone"
            }
            onChange={handleChange}
          />
          <div className="textarea-tooltip">
            <div className="textarea-tooltip-content">
              {today.momentOneScore ? today.momentOneScore : 0}
            </div>
          </div>
        </div>
        <input
          className={today.momentOneScore ? "weekSlider" : "weekSlider off"}
          type="range"
          min="0"
          max="10"
          step="0.1"
          defaultValue="0"
          value={today.momentOneScore && today.momentOneScore}
          name="momentOneScore"
          style={{
            background: today.momentOneScore
              ? startSlider(today.momentOneScore, 10)
              : background.momentOneScore && background.momentOneScore,
          }}
          onChange={handleChange}
        />
        <SliderTooltip
          position={
            tooltipPosition &&
            tooltipPosition.momentOneScore &&
            tooltipPosition.momentOneScore.position &&
            tooltipPosition.momentOneScore.position
          }
          score={
            tooltipPosition &&
            tooltipPosition.momentOneScore &&
            tooltipPosition.momentOneScore.position &&
            today.momentOneScore
              ? today.momentOneScore
              : ""
          }
        />
      </div>

      <div className="moment__submission">
        <div
          className={
            today.momentTwoScore === "10" || today.momentTwoScore === undefined
              ? `score__row--moment complete`
              : "score__row--moment"
          }
        >
          <AutoResizeTextArea
            className={"formInput moment"}
            wrap="soft"
            value={today.momentTwo && today.momentTwo}
            name="momentTwo"
            autoComplete="off"
            placeholder={"Focus in on important task"}
            onChange={handleChange}
          />
          <div className="textarea-tooltip">
            <div className="textarea-tooltip-content">
              {today.momentTwoScore ? today.momentTwoScore : 0}
            </div>
          </div>
        </div>
        <input
          className={today.momentTwoScore ? "weekSlider" : "weekSlider off"}
          type="range"
          min="0"
          max="10"
          step="0.1"
          defaultValue="0"
          value={today.momentTwoScore && today.momentTwoScore}
          name="momentTwoScore"
          style={{
            background: today.momentTwoScore
              ? startSlider(today.momentTwoScore, 10)
              : background.momentTwoScore && background.momentTwoScore,
          }}
          onChange={handleChange}
        />
        <SliderTooltip
          position={
            tooltipPosition &&
            tooltipPosition.momentTwoScore &&
            tooltipPosition.momentTwoScore.position &&
            tooltipPosition.momentTwoScore.position
          }
          score={
            tooltipPosition &&
            tooltipPosition.momentTwoScore &&
            tooltipPosition.momentTwoScore.position &&
            today.momentTwoScore
              ? today.momentTwoScore
              : ""
          }
        />
      </div>

      <div className="moment__submission">
        <div
          className={
            today.momentThreeScore === "10" ||
            today.momentThreeScore === undefined
              ? `score__row--moment complete`
              : "score__row--moment"
          }
        >
          <AutoResizeTextArea
            className={"formInput moment"}
            wrap="soft"
            value={today.momentThree && today.momentThree}
            name="momentThree"
            autoComplete="off"
            placeholder={
              "Read new book for 45 minutes. Meditate for 15 minutes"
            }
            onChange={handleChange}
          />
          <div className="textarea-tooltip">
            <div className="textarea-tooltip-content">
              {today.momentThreeScore ? today.momentThreeScore : 0}
            </div>
          </div>
        </div>
        <input
          className={today.momentThreeScore ? "weekSlider" : "weekSlider off"}
          type="range"
          min="0"
          max="10"
          step="0.1"
          defaultValue="0"
          value={today.momentThreeScore && today.momentThreeScore}
          name="momentThreeScore"
          style={{
            background: today.momentThreeScore
              ? startSlider(today.momentThreeScore, 10)
              : background.momentThreeScore && background.momentThreeScore,
          }}
          onChange={handleChange}
        />
        <SliderTooltip
          position={
            tooltipPosition &&
            tooltipPosition.momentThreeScore &&
            tooltipPosition.momentThreeScore.position &&
            tooltipPosition.momentThreeScore.position
          }
          score={
            tooltipPosition &&
            tooltipPosition.momentThreeScore &&
            tooltipPosition.momentThreeScore.position &&
            today.momentThreeScore
              ? today.momentThreeScore
              : ""
          }
        />
      </div>

      <div className="moment__submission">
        <div
          className={
            today.momentFourScore === "10" ||
            today.momentFourScore === undefined
              ? `score__row--moment complete`
              : "score__row--moment"
          }
        >
          <AutoResizeTextArea
            className={"formInput moment"}
            wrap="soft"
            value={today.momentFour && today.momentFour}
            name="momentFour"
            autoComplete="off"
            placeholder={"Workout: Biceps"}
            onChange={handleChange}
          />
          <div className="textarea-tooltip">
            <div className="textarea-tooltip-content">
              {today.momentFourScore ? today.momentFourScore : 0}
            </div>
          </div>
        </div>
        <input
          className={today.momentFourScore ? "weekSlider" : "weekSlider off"}
          type="range"
          min="0"
          max="10"
          step="0.1"
          defaultValue="0"
          value={today.momentFourScore && today.momentFourScore}
          name="momentFourScore"
          style={{
            background: today.momentThreeScore
              ? startSlider(today.momentFourScore, 10)
              : background.momentFourScore && background.momentFourScore,
          }}
          onChange={handleChange}
        />
        <SliderTooltip
          position={
            tooltipPosition &&
            tooltipPosition.momentFourScore &&
            tooltipPosition.momentFourScore.position &&
            tooltipPosition.momentFourScore.position
          }
          score={
            tooltipPosition &&
            tooltipPosition.momentFourScore &&
            tooltipPosition.momentFourScore.position &&
            today.momentFourScore
              ? today.momentFourScore
              : ""
          }
        />
      </div>

      <div className="moment__submission">
        <div
          className={
            today.momentFiveScore === "10" ||
            today.momentFiveScore === undefined
              ? `score__row--moment complete`
              : "score__row--moment"
          }
        >
          <AutoResizeTextArea
            className={"formInput moment"}
            wrap="soft"
            value={today.momentFive && today.momentFive}
            name="momentFive"
            autoComplete="off"
            placeholder={"Wind down before bed; phone away"}
            onChange={handleChange}
          />
          <div className="textarea-tooltip">
            <div className="textarea-tooltip-content">
              {today.momentFiveScore ? today.momentFiveScore : 0}
            </div>
          </div>
        </div>
        <input
          className={today.momentFiveScore ? "weekSlider" : "weekSlider off"}
          type="range"
          min="0"
          max="10"
          step="0.1"
          defaultValue="0"
          value={today.momentFiveScore && today.momentFiveScore}
          name="momentFiveScore"
          style={{
            background: today.momentFiveScore
              ? startSlider(today.momentFiveScore, 10)
              : background.momentFiveScore && background.momentFiveScore,
          }}
          onChange={handleChange}
        />
        <SliderTooltip
          position={
            tooltipPosition &&
            tooltipPosition.momentFiveScore &&
            tooltipPosition.momentFiveScore.position &&
            tooltipPosition.momentFiveScore.position
          }
          score={
            tooltipPosition &&
            tooltipPosition.momentFiveScore &&
            tooltipPosition.momentFiveScore.position &&
            today.momentFiveScore
              ? today.momentFiveScore
              : ""
          }
        />
      </div>
    </div>
  );
}

export default PlanningTile;
