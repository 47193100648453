// React
import React from "react";

// External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCalendarCheck,
  faEllipsisVertical,
  faTrash,
  faSquareMinus,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSquarePlus,
  faCalendar,
  fa,
} from "@fortawesome/free-regular-svg-icons";

function Sort({ title, sort, sortHandler, handleSort }) {
  return (
    <div className="objective__sort--wrapper">
      <div className="sort--tool">
        <div
          className={sort && sort["dead"].active ? "dead on" : "dead"}
          data-name="dead"
          data-sort={sort && sort["dead"].type}
          onClick={async (e) => {
            await sortHandler(e, title);
            handleSort(e, title);
          }}
        >
          <span
            data-name="dead"
            data-sort={sort && sort["dead"].type}
            onClick={async (e) => {
              await sortHandler(e, title);
              handleSort(e, title);
            }}
          >
            <FontAwesomeIcon
              icon={faCalendar}
              data-name="dead"
              data-sort={sort && sort["dead"].type}
              onClick={async (e) => {
                await sortHandler(e, title);
                handleSort(e, title);
              }}
            />
          </span>
          {sort &&
            sort["dead"].active &&
            (sort && sort["dead"].type === "asc" ? (
              <FontAwesomeIcon
                icon={faArrowUp}
                data-name="dead"
                data-sort={sort && sort["dead"].type}
                onClick={async (e) => {
                  await sortHandler(e, title);
                  handleSort(e, title);
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faArrowDown}
                data-name="dead"
                data-sort={sort && sort["dead"].type}
                onClick={async (e) => {
                  await sortHandler(e, title);
                  handleSort(e, title);
                }}
              />
            ))}
        </div>
        <div
          className={sort && sort["urge"].active ? "urge on" : "urge"}
          data-name="urge"
          data-sort={sort && sort["urge"].type}
          onClick={async (e) => {
            await sortHandler(e, title);
            handleSort(e, title);
          }}
        >
          <span
            data-name="urge"
            data-sort={sort && sort["urge"].type}
            onClick={async (e) => {
              await sortHandler(e, title);
              handleSort(e, title);
            }}
          >
            <FontAwesomeIcon
              icon={faSquarePlus}
              data-name="urge"
              data-sort={sort && sort["urge"].type}
              onClick={async (e) => {
                await sortHandler(e, title);
                handleSort(e, title);
              }}
            />
          </span>
          {sort &&
            sort["urge"].active &&
            (sort && sort["urge"].type === "asc" ? (
              <FontAwesomeIcon
                icon={faArrowUp}
                data-name="urge"
                data-sort={sort && sort["urge"].type}
                onClick={async (e) => {
                  await sortHandler(e, title);
                  handleSort(e, title);
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faArrowDown}
                data-name="urge"
                data-sort={sort && sort["urge"].type}
                onClick={async (e) => {
                  await sortHandler(e, title);
                  handleSort(e, title);
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Sort;
