//React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";

//Internal Components
import {
  ValuesIcon,
  IkigaiIcon,
  VisionIcon,
  QuoteIconV2,
  QuestionIcon,
  SaveIcon,
} from "../../components/Icons/Icon";

// Styling
import "./dock.css";

export default function YearDock({ handleSubmit, load }) {
  const navigate = useNavigate();

  const [currentWindow, setCurrentWindow] = useState(window.location.href);
  const [saveTooltip, setSaveTooltip] = useState("");

  const handleTooltipChange = () => {
    setSaveTooltip("Saving...");
    setTimeout(() => {
      setSaveTooltip("Saved");
    }, 1200);
  };

  return (
    <div className="yeardock--container">
      {/* <div className={`hidedock node`} data-tooltip="Click for Tutorial">
        <QuestionIcon />
      </div>

      <div className="hidedock separator">
        <FontAwesomeIcon
          id="separatorIcon"
          className="separator"
          icon={faGripLinesVertical}
        />
      </div>

      <div
        className={`hidedock node`}
        data-tooltip="Values Process Coming Soon."
        style={{ opacity: "0.25" }}
      >
        <ValuesIcon />
      </div>

      <div
        className={`hidedockwide node`}
        data-tooltip="Vision Process Coming Soon."
        style={{ opacity: "0.25" }}
      >
        <VisionIcon />
      </div>

      <div
        className={`hidedock node`}
        data-tooltip="Ikigai Process Coming Soon."
        style={{ opacity: "0.25" }}
      >
        <IkigaiIcon />
      </div>

      <div
        className={`hidedock node`}
        data-tooltip="Quote Finder Coming Soon."
        style={{ opacity: "0.25" }}
      >
        <QuoteIconV2 />
      </div>

      <div className="hidedock separator">
        <FontAwesomeIcon
          id="separatorIcon"
          className="separator"
          icon={faGripLinesVertical}
        />
      </div> */}

      <div
        className={`yeardock node_save ${load}`}
        data-tooltip={load === "success" ? saveTooltip : "Click to Save"}
        onClick={(e) => {
          handleSubmit(e);
          handleTooltipChange();
        }}
      >
        <SaveIcon load={load} />
      </div>
    </div>
  );
}
