//React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//External Packages

//Internal Components
import {
  ValuesIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  HideOverlayIcon,
  OneIconMob,
  TwoIconMob,
  ThreeIconMob,
  FourIconMob,
  FiveIconMob,
  SixIconMob,
  SevenIconMob,
  EightIconMob,
  NineIconMob,
  TenIconMob,
  VitIcon,
} from "../../components/Icons/Icon";

//Styling
import "./dock.css";

export function FullDockMobileOne({ meyear, handleMouseState, yearid }) {
  const navigate = useNavigate();

  const [valuesShowState, setValuesShowState] = useState("mini node inactive");
  const [visionShowState, setVisionShowState] = useState("mini node inactive");
  const [currentWindow, setCurrentWindow] = useState(window.location.href);

  const handleNav = (meyear) => {
    if (meyear) {
      navigate("/year/create");
    } else {
      navigate(`/year/${yearid}`);
    }
  };

  return (
    <div
      className={"mobiledock--container center"}
      style={{ justifyContent: "space-evenly" }}
    >
      <div
        className={`mobiledock node`}
        onClick={() => {
          meyear && meyear.name && meyear.birthday
            ? handleMouseState("me")
            : handleNav(meyear.name);
        }}
        style={
          meyear && meyear.name && meyear.birthday ? null : { opacity: "0.5" }
        }
      >
        <VitIcon />
      </div>

      <div className="mobileDockNodeWrapper">
        <div
          className={`mobiledock node`}
          onClick={() => {
            meyear && meyear.values
              ? handleMouseState("values")
              : handleNav(meyear.values.one);
          }}
          style={meyear && meyear.values ? null : { opacity: "0.5" }}
        >
          <ValuesIcon />
        </div>
      </div>

      <div className="mobileDockNodeWrapper">
        <div
          className={`mobiledockwide node`}
          onClick={() => {
            meyear && meyear.vision
              ? handleMouseState("vision")
              : handleNav(meyear.vision.one);
          }}
          style={meyear && meyear.vision ? null : { opacity: "0.5" }}
        >
          <VisionIcon />
        </div>
      </div>

      <div
        className={`mobiledock node`}
        onClick={() => {
          meyear && meyear.vision
            ? handleMouseState("ikigai")
            : handleNav(meyear.ikigai);
        }}
        style={meyear && meyear.ikigai ? null : { opacity: "0.5" }}
      >
        <IkigaiIcon />
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip="My Quote"
        onClick={() => {
          meyear && meyear.quote
            ? handleMouseState("quote")
            : handleNav(meyear.quote);
        }}
        style={meyear && meyear.quote ? null : { opacity: "0.5" }}
      >
        <QuoteIconV2 />
      </div>
    </div>
  );
}

export function FullDockMobileTwo({
  handleColumnChange,
  objectives,
  handleSubmit,
}) {
  const [valuesShowState, setValuesShowState] = useState("mini node inactive");
  const [visionShowState, setVisionShowState] = useState("mini node inactive");
  const [currentWindow, setCurrentWindow] = useState(window.location.href);

  return (
    <div className={"mobiledock--container"}>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-one"]?.title
            ? objectives["obj-one"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-one"
        onClick={(e) => {
          handleColumnChange(e);
        }}
      >
        {objectives["obj-one"]?.title ? (
          <OneIconMob opacity={objectives["obj-one"]?.score} />
        ) : (
          <OneIconMob opacity={objectives["obj-one"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-two"]?.title
            ? objectives["obj-two"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-two"
        onClick={(e) => handleColumnChange(e)}
      >
        {objectives["obj-two"]?.title ? (
          <TwoIconMob opacity={objectives["obj-two"]?.score} />
        ) : (
          <TwoIconMob opacity={objectives["obj-two"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-three"]?.title
            ? objectives["obj-three"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-three"
      >
        {objectives["obj-three"]?.title ? (
          <ThreeIconMob opacity={objectives["obj-three"]?.score} />
        ) : (
          <ThreeIconMob opacity={objectives["obj-three"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-four"]?.title
            ? objectives["obj-four"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-four"
      >
        {objectives["obj-four"]?.title ? (
          <FourIconMob opacity={objectives["obj-four"]?.score} />
        ) : (
          <FourIconMob opacity={objectives["obj-four"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-five"]?.title
            ? objectives["obj-five"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-five"
      >
        {objectives["obj-five"]?.title ? (
          <FiveIconMob opacity={objectives["obj-five"]?.score} />
        ) : (
          <FiveIconMob opacity={objectives["obj-five"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-six"]?.title
            ? objectives["obj-six"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-six"
      >
        {objectives["obj-six"]?.title ? (
          <SixIconMob opacity={objectives["obj-six"]?.score} />
        ) : (
          <SixIconMob opacity={objectives["obj-six"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-seven"]?.title
            ? objectives["obj-seven"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-seven"
      >
        {objectives["obj-seven"]?.title ? (
          <SevenIconMob opacity={objectives["obj-seven"]?.score} />
        ) : (
          <SevenIconMob opacity={objectives["obj-seven"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-eight"]?.title
            ? objectives["obj-eight"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-eight"
      >
        {objectives["obj-eight"]?.title ? (
          <EightIconMob opacity={objectives["obj-eight"]?.score} />
        ) : (
          <EightIconMob opacity={objectives["obj-eight"]?.score} op="0.5" />
        )}
      </div>
      <div
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-nine"]?.title
            ? objectives["obj-nine"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-nine"
      >
        {objectives["obj-nine"]?.title ? (
          <NineIconMob opacity={objectives["obj-nine"]?.score} />
        ) : (
          <NineIconMob opacity={objectives["obj-nine"]?.score} op="0.5" />
        )}
      </div>
      <div
        id="finalnode"
        className={`mobiledock node`}
        data-tooltip={
          objectives["obj-ten"]?.title
            ? objectives["obj-ten"].title.slice(0, 20) + "..."
            : "Add an objective into your week planner to access it here."
        }
        data-name="obj-ten"
      >
        {objectives["obj-ten"]?.title ? (
          <TenIconMob opacity={objectives["obj-ten"]?.score} />
        ) : (
          <TenIconMob opacity={objectives["obj-ten"]?.score} op="0.5" />
        )}
      </div>
    </div>
  );
}
