import React, { useEffect, useState } from "react";
import Board from "../../components/Objective/Board/Board";
import {
  reorder,
  reorderQuoteMap,
  moveBetween,
} from "../../components/Objective/Reorder";

function PracticeScreen() {
  const [objectives, setObjectives] = useState({
    "obj-one": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-two": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-three": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-four": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-five": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-six": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-seven": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-eight": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-nine": {
      title: "",
      active: 0,
      subTasks: [],
    },
    "obj-ten": {
      title: "",
      active: 0,
      subTasks: [],
    },
  });
  const [ordered, setOrdered] = useState(objectives && Object.keys(objectives));
  const [calloutActive, setCalloutActive] = useState(false);

  const changeObjectiveTitle = (e, order) => {
    let newTitle = e.target.value;
    let name = e.target.name;

    setObjectives((prev) => {
      let newState = { ...prev };

      let reorderedColumns = order.reduce((acc, key) => {
        if (newState[key]) {
          acc[key] = newState[key];
        }
        return acc;
      }, {});

      reorderedColumns[name].title = newTitle;

      return reorderedColumns;
    });
  };

  const addSubTask = (event, id) => {
    const descriptionValue = event.target.value;
    const name = event.target.name;
    const random = Math.floor(Math.random() * 100);

    setObjectives((prevState) => {
      // Make a copy of the previous state
      const updatedObjectives = { ...prevState };

      // Check if the objective exists in the state
      if (updatedObjectives[name]) {
        // Update the subTasks array of the specific objective
        updatedObjectives[name] = {
          ...updatedObjectives[name],
          subTasks: [
            ...updatedObjectives[name].subTasks,
            {
              id: `${name}-${id}-${random}`,
              description: descriptionValue,
              steps: [],
              notes: "",
              completed: 0,
            },
          ],
        };
      }

      return updatedObjectives;
    });

    // Clear the input value
    event.target.value = "";
  };

  const toggleCompletedSubTasks = (index, columnId) => {
    // Assuming objectives and setObjectives are defined in your component
    // Make sure objectives is a state variable and setObjectives is a setState function

    // Make a copy of objectives[columnId]
    const columnObjectives = { ...objectives[columnId] };

    // Ensure columnObjectives has a subTasks array
    if (columnObjectives && Array.isArray(columnObjectives.subTasks)) {
      // Toggle the completed value of the specified subTask
      columnObjectives.subTasks[index].completed =
        columnObjectives.subTasks[index].completed === 0 ? 1 : 0;

      // Update the state with the new copy
      setObjectives((prevObjectives) => ({
        ...prevObjectives,
        [columnId]: columnObjectives,
      }));
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const reorderObjectives = (newOrder) => {
    const reordered = newOrder.reduce((acc, key) => {
      if (objectives[key]) {
        acc[key] = objectives[key];
      }
      return acc;
    }, {});
    setObjectives(reordered);
  };

  const onDragEnd = (result) => {
    console.log(result);
    console.log(result.destination);

    if (result.combine) {
      // After drag and drop, check if result is type COLUMN, then setOrdered
      if (result.type === "COLUMN") {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);

        return;
      }

      const column = objectives[result.source.droppableId];
      const withQuoteRemoved = [...column];

      withQuoteRemoved.splice(result.source.index, 1);

      const orderedColumns = {
        ...objectives,
        [result.source.droppableId]: withQuoteRemoved,
      };

      setObjectives(orderedColumns);
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const reorderedorder = reorder(ordered, source.index, destination.index);

      setOrdered(reorderedorder);
      return;
    }

    const subTaskMap = (objectives) => {
      return Object.keys(objectives).reduce((acc, key) => {
        acc[key] = objectives[key].subTasks;
        return acc;
      }, {});
    };

    const data = reorderQuoteMap({
      quoteMap: subTaskMap(objectives),
      source,
      destination,
    });

    setObjectives((prevObjectives) => {
      const updatedObjectives = { ...prevObjectives };

      Object.keys(data.quoteMap).forEach((key) => {
        updatedObjectives[key] = {
          ...updatedObjectives[key], // Keep title and active
          subTasks: data.quoteMap[key], // Update subTasks
        };
      });

      return updatedObjectives;
    });
  };

  const updateDescription = (event, columnId, index) => {
    let newDescription = event.target.value;

    setObjectives((prevState) => {
      const updatedObjectives = { ...prevState };
      const task = updatedObjectives[columnId];
      if (task && task.subTasks[index]) {
        task.subTasks[index].description = newDescription;
      }
      return updatedObjectives;
    });
  };

  const onDragEndStep = (result, columnId, subTaskIndex) => {
    const { destination, source, draggableId, type } = result;

    console.log(result);
    console.log(destination);
    console.log(subTaskIndex);
    console.log(columnId);

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setObjectives((prevObjectives) => {
      // Copy the previous state
      const newObjectives = { ...prevObjectives };

      // Get the subTasks array that needs to be updated
      const subTasksToUpdate = [
        ...newObjectives[columnId].subTasks[subTaskIndex].steps,
      ];

      // Remove the dragged item from its previous position
      const [removed] = subTasksToUpdate.splice(source.index, 1);

      // Insert the dragged item at its new position
      subTasksToUpdate.splice(destination.index, 0, removed);

      // Update the subTasks array in the newObjectives
      newObjectives[columnId].subTasks[subTaskIndex].steps = subTasksToUpdate;

      return newObjectives;
    });
  };

  const [isOverlayVisible, setOverlayVisible] = useState(false);

  // Function to handle state change from the child
  const handleOverlayChange = (newState) => {
    setOverlayVisible(newState);
  };

  const deleteObjective = (id) => {
    setObjectives((prevState) => ({
      ...prevState,
      [id]: {
        title: "",
        active: 0,
        subTasks: [],
      },
    }));
  };

  const toggleStep = (subTaskIndex, stepIndex, columnId) => {
    console.log(
      "subTaskIndex" + subTaskIndex,
      "stepIndex" + stepIndex,
      "columnId" + columnId
    );

    // Make a copy of the entire objectives object
    const updatedObjectives = { ...objectives };

    // Ensure columnId exists and has a subTasks array
    if (
      updatedObjectives[columnId] &&
      Array.isArray(updatedObjectives[columnId].subTasks)
    ) {
      const updatedSubTasks = [...updatedObjectives[columnId].subTasks];
      const updatedSteps = [...updatedSubTasks[subTaskIndex].steps];

      // Toggle the completed value of the specified subTask
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        completed: updatedSteps[stepIndex].completed === 0 ? 1 : 0,
      };

      // Update the subTasks array with the new copy
      updatedSubTasks[subTaskIndex] = {
        ...updatedSubTasks[subTaskIndex],
        steps: updatedSteps,
      };

      // Update the objectives object with the new subTasks array
      updatedObjectives[columnId] = {
        ...updatedObjectives[columnId],
        subTasks: updatedSubTasks,
      };

      // Update the state with the new objectives copy
      setObjectives(updatedObjectives);
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const addStep = (event, columnId, subTaskIndex) => {
    const descriptionValue = event.target.value;
    const name = event.target.name;

    setObjectives((prevState) => {
      const updatedObjectives = { ...prevState };
      const objective = updatedObjectives[columnId];
      if (objective) {
        const subTask = objective.subTasks[subTaskIndex];
        if (subTask) {
          subTask.steps.push({
            action: descriptionValue,
            completed: 0,
          });
        }
      }
      return updatedObjectives;
    });
    event.target.value = "";
  };

  const addStepTwo = (event, columnId, subTaskId, length) => {
    const descriptionValue = event.target.value;
    const random = Math.floor(Math.random() * 100);
    const id = length;

    setObjectives((prevState) => {
      // Make a copy of the previous state
      const updatedObjectives = { ...prevState };

      // Check if the objective exists
      if (updatedObjectives[columnId]) {
        // Find the specific subTask by subTaskId
        const updatedSubTasks = updatedObjectives[columnId].subTasks.map(
          (subTask) => {
            if (subTask.id === subTaskId) {
              // If it matches, add the new step
              return {
                ...subTask,
                steps: [
                  ...subTask.steps,
                  {
                    id: `${subTask.id}-${id}`,
                    action: descriptionValue,
                    completed: 0,
                  },
                ],
              };
            }
            return subTask; // Return unchanged
          }
        );

        // Update the subTasks of the specific objective
        updatedObjectives[columnId] = {
          ...updatedObjectives[columnId],
          subTasks: updatedSubTasks,
        };
      }

      return updatedObjectives;
    });
    event.target.value = "";
  };

  const updateStep = (columnId, subTaskIndex, stepIndex, event) => {
    const { value } = event.target;
    setObjectives((prevObjectives) => {
      const newObjectives = { ...prevObjectives };
      newObjectives[columnId].subTasks[subTaskIndex].steps[stepIndex].action =
        value;
      return newObjectives;
    });
  };

  const deleteStep = (columnId, subTaskIndex, stepIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives[columnId].subTasks[subTaskIndex].steps.splice(
      stepIndex,
      1
    );
    setObjectives(updatedObjectives);
  };

  const updateNotes = (columnId, index, event) => {
    let newNotes = event.target.value;

    const updatedObjectives = { ...objectives };
    updatedObjectives[columnId].subTasks[index] = {
      ...updatedObjectives[columnId].subTasks[index],
      notes: newNotes,
    };
    setObjectives(updatedObjectives);
  };

  const deleteSubtask = (columnId, subtaskIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives[columnId].subTasks.splice(subtaskIndex, 1);
    setObjectives(updatedObjectives);
  };

  useEffect(() => {
    console.log("overlay", isOverlayVisible);
  }, [isOverlayVisible]);

  const handleCalloutDisplay = () => {
    setCalloutActive((calloutActive) => !calloutActive);
  };

  return (
    <>
      <main className="wekContainer">
        {objectives && (
          <Board
            initial={objectives}
            ordered={ordered}
            overlay={isOverlayVisible}
            active={calloutActive}
            onDragEnd={onDragEnd}
            onDragEndSteps={onDragEndStep}
            addSubTask={addSubTask}
            addStep={addStepTwo}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            toggleDisplay={handleCalloutDisplay}
            toggleOverlay={handleOverlayChange}
            updateTitle={changeObjectiveTitle}
            updateOrder={reorderObjectives}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateNotes={updateNotes}
            deleteObjective={deleteObjective}
            deleteStep={deleteStep}
            deleteSubtask={deleteSubtask}
            withScrollableColumns
          />
        )}
      </main>
    </>
  );
}

export default PracticeScreen;
