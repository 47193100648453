// React
import React, { useEffect, useState } from "react";

// External Packages
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEllipsisVertical,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

// Internal Components
import {
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
} from "../../../components/Icons/Icon";
import WeekLoading from "../../../components/Loading/WeekLoading";

import QuoteList from "../Styles/List";
import Sort from "./Sort";
import "../objective.css";

const Column = ({
  index,
  title,
  order,
  objective,
  overlay,
  isScrollable,
  isCombineEnabled,
  useClone,
  updateTitle,
  handleScoreChange,
  handleUrgencyChange,
  handleSort,
  addSubTask,
  addStep,
  toggleSubTask,
  toggleStep,
  toggleOverlay,
  deleteObjective,
  deleteStep,
  deleteSubtask,
  updateDescription,
  updateStep,
  updateNotes,
  onDragEndSteps,
  viewType,
}) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [sort, setSort] = useState({
    urge: {
      active: false,
      type: "asc",
    },
    dead: {
      active: false,
      type: "asc",
    },
    cust: {
      active: false,
    },
  });

  useEffect(() => {
    const loadingTimeout = () => {
      var randNum = Math.random(1) * 3000 + Math.random(1) * 3000;
      setTimeout(() => {
        if (title === "" || title !== "") {
          setPageLoading(false);
        }
      }, randNum);
    };

    loadingTimeout();
  }, [objective, index]);

  const sortHandler = (e) => {
    const targetData = e.currentTarget.dataset;
    let sortStyle = targetData.name;

    setSort((prevSort) => {
      let shallow = { ...prevSort };
      if (sort[sortStyle].active) {
        shallow[sortStyle].type =
          shallow[sortStyle].type === "asc" ? "desc" : "asc";
      } else {
        shallow[sortStyle].active = !shallow[sortStyle].active;
      }

      Object.keys(shallow).forEach((key) => {
        if (key !== sortStyle) {
          shallow[key].active = false;
        }
      });
      return shallow;
    });
  };

  let numIconObj = {
    "obj-one": <OneIcon />,
    "obj-two": <TwoIcon />,
    "obj-three": <ThreeIcon />,
    "obj-four": <FourIcon />,
    "obj-five": <FiveIcon />,
    "obj-six": <SixIcon />,
    "obj-seven": <SevenIcon />,
    "obj-eight": <EightIcon />,
    "obj-nine": <NineIcon />,
    "obj-ten": <TenIcon />,
  };

  return (
    <>
      {pageLoading ? (
        <WeekLoading num={title && numIconObj[title]} />
      ) : (
        <Draggable draggableId={title && title} index={index}>
          {(provided, snapshot) => (
            <div
              className={
                objective?.title === ""
                  ? `objective__col hideColumn`
                  : `objective__col${viewType ? viewType : ""}`
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                className="objective__col--name"
                isdragging={snapshot.isdragging}
              >
                <div className="col__name--icon">
                  {title && numIconObj[title]}
                </div>
                <div className="col__name--trash">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => setWarning(true)}
                  />
                </div>
                <input
                  aria-label={`${objective?.title} quote list`}
                  type="text"
                  className="col__name--input"
                  value={objective?.title}
                  placeholder="Objective Name"
                  name={`${title}`}
                  onChange={(event) => updateTitle(event, order)}
                  autoComplete="off"
                ></input>
              </div>
              <QuoteList
                listId={title}
                listType="QUOTE"
                style={{
                  backgroundColor: snapshot.isDragging
                    ? "rgba(0,74,34,0.1)"
                    : null,
                }}
                overlay={overlay}
                handleScoreChange={handleScoreChange}
                title={title}
                index={index}
                tasks={objective?.subTasks}
                internalScroll={isScrollable}
                isCombineEnabled={Boolean(isCombineEnabled)}
                handleUrgencyChange={handleUrgencyChange}
                useClone={Boolean(useClone)}
                onDragEndSteps={onDragEndSteps}
                addStep={addStep}
                toggleSubTask={toggleSubTask}
                toggleStep={toggleStep}
                toggleOverlay={toggleOverlay}
                updateDescription={updateDescription}
                updateStep={updateStep}
                updateNotes={updateNotes}
                deleteStep={deleteStep}
                deleteObjective={deleteObjective}
                deleteSubtask={deleteSubtask}
              />
              <Sort
                sort={sort}
                sortHandler={sortHandler}
                handleSort={handleSort}
                title={title}
              />
              <div className="objective__col--name">
                <FontAwesomeIcon
                  className="objective__col--icon"
                  icon={faPlus}
                />
                <input
                  type="text"
                  className="col__name--input"
                  placeholder="Add new action"
                  name={`${title}`}
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && event.target.value !== "") {
                      event.preventDefault();
                      addSubTask(event, objective?.subTasks.length);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </Draggable>
      )}
      <div
        className={warning ? "warning__callout--active" : "warning__callout"}
      >
        <strong>Are you sure you want to delete this objective?</strong>
        <div className="warning__button--row">
          <div
            className="callout__button"
            onClick={() => {
              deleteObjective(title);
              setWarning(false);
            }}
          >
            Yes
          </div>
          <div className="callout__button" onClick={() => setWarning(false)}>
            No
          </div>
        </div>
      </div>
    </>
  );
};

export default Column;
