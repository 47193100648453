// React
import React from "react";
import PropTypes from "prop-types";

function PageContainer({ children, style, ...props }) {
  return (
    <main {...props} style={style}>
      {children}
    </main>
  );
}

export default PageContainer;
