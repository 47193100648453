// React
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Internal Components
import Header from "../../../layouts/Header/Header";

// External Packages

function Completion(props) {
  const [messageBody, setMessageBody] = useState("");
  const { stripePromise } = props;

  // State
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      const url = new URL(window.location);
      const clientSecret = url.searchParams.get("payment_intent_client_secret");
      const { error, paymentIntent } =
        await stripe.retrievePaymentIntent(clientSecret);

      setMessageBody(
        error ? (
          `> ${error.message}`
        ) : (
          <>
            &gt; Payment {paymentIntent.status}:{" "}
            <a
              href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {paymentIntent.id}
            </a>
          </>
        )
      );
    });
  }, [stripePromise]);

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main className="payment__main">
        <section className="payment__main--explainer">
          <div className="explainer__area">
            <h1>Thank you for subscribing!</h1>
            <p>
              Not only do I hope this gives you the experience of NoWastedDays
              that works best for you, it also helps the project reach more
              people that it may be able to help.
            </p>
          </div>
        </section>
        <section className="payment__main--payment">
          <div className="payment__area">
            <h1>What would you like to do next?</h1>
            <a href="/today">Start a New Day</a>
            <a href="/week/create">Create a New Week Plan</a>
            <a href="/insight/quiz">Explore the Art of Looking</a>
            <a href="/values">Think About Your Values</a>
          </div>
        </section>
      </main>
    </>
  );
}

export default Completion;
