export const WEEKS_CREATE_REQUEST = "WEEKS_CREATE_REQUEST";
export const WEEKS_CREATE_SUCCESS = "WEEKS_CREATE_SUCCESS";
export const WEEKS_CREATE_FAIL = "WEEKS_CREATE_FAIL";

export const WEEKS_LIST_REQUEST = "WEEKS_LIST_REQUEST";
export const WEEKS_LIST_SUCCESS = "WEEKS_LIST_SUCCESS";
export const WEEKS_LIST_FAIL = "WEEKS_LIST_FAIL";

export const WEEKS_UPDATE_REQUEST = "WEEKS_UPDATE_REQUEST";
export const WEEKS_UPDATE_SUCCESS = "WEEKS_UPDATE_SUCCESS";
export const WEEKS_UPDATE_FAIL = "WEEKS_UPDATE_FAIL";

export const WEEKS_SINGLE_REQUEST = "WEEKS_SINGLE_REQUEST";
export const WEEKS_SINGLE_SUCCESS = "WEEKS_SINGLE_SUCCESS";
export const WEEKS_SINGLE_FAIL = "WEEKS_SINGLE_FAIL";
