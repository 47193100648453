//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

//Actions
import { createDayAction } from "../../actions/dayActions";
import { updateWeekAction } from "../../actions/weekActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";

//Internal Components
import TileLoading from "../../components/Loading/TileLoading";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import FullDock from "../../features/dock/FullDock";
import WeekDock from "../../features/dock/WeekDock";
import Matrix from "../../components/Matrix/Matrix";
import TestFooter from "../../layouts/Footer/TestFooter";
import DisabledDock from "../../features/dock/DisabledDock";
import Header from "../../layouts/Header/Header";
import {
  FullDockMobileOne,
  FullDockMobileTwo,
} from "../../features/dock/FullDockMobile";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import generateSecret from "../../utils/generateSecret";
import { dateFormatter } from "../../utils/UtilityFunctions";
// import Objective from "../../components/Day/Objective";
import {
  CreateDayIcon,
  CreateDayIconV2,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  YearIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
  VitIcon,
  WeekIcon,
} from "../../components/Icons/Icon";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faGlasses,
  faDna,
  faLandmarkDome,
  faQuoteLeft,
  faEarthAmericas,
  faFloppyDisk,
  faPenToSquare,
  faEraser,
  faCircleCheck,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import CryptoJS from "crypto-js";

//Styling
import "./selfsurvey.css";

function SelfSurvey() {
  // Data Calls & Event Handlers
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [response, setResponse] = useState({
    demographics: {
      first_name: "",
      last_name: "",
      age: 0,
      gender: "",
      brought_up_city: "",
      brought_up_country: "",
    },
    response: {
      now: [0, 0],
      last_year: [0, 0],
      next_year_hope: [0, 0],
      near_year_pred: [0, 0],
    },
    define: {
      self_awareness: "",
      self_assurance: "",
    },
  });

  const nowCollect = (xVal, yVal) => {
    setResponse((prevResponse) => {
      let surveyResponse = [xVal, yVal];

      return {
        ...prevResponse.response,
        now: surveyResponse,
      };
    });
  };

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main className="survey__main">
        <section className="survey__intro">
          <p>Welcome to the Great British Self-Survey.</p>
          <p>
            We are really keen to understand how Britons understand themselves
            in order to then share what we learn so people can understand
            themselves more and do more positive things with that
            self-knowledge.
          </p>
        </section>
        <section className="survey__matrix">
          <Matrix collectNow={nowCollect} />
        </section>
        <section className="survey__defintions"></section>
        <section className="survey__demos"></section>
      </main>
    </>
  );
}

export default SelfSurvey;
