// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//External Packages

// Internal Components
import Register from "../../components/Register/Register";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import TestFooter from "../../layouts/Footer/TestFooter";
import Header from "../../layouts/Header/Header";

// User Actions

// Images

// Styling
import "./registerscreen.css";

export default function ViewRegisterScreen() {
  const navigate = useNavigate();
  const [paycPicker, setPaycPicker] = useState("");

  return (
    <>
      <Header variant="light" />

      <section id="registerScreen">
        <Register />
      </section>

      <TestFooter />
    </>
  );
}
