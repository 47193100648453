//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//Actions
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";
import { logout } from "../../actions/userActions";

//Internal Components
import HeaderSearch from "./HeaderSearch";
import DropdownLoading from "../../components/Loading/DropdownLoading";
import {
  ProfileIcon,
  YearIcon,
  WeekIcon,
  DiaryIcon,
  InsightsIcon,
  LogoutIcon,
  LoginIcon,
  RegisterIcon,
} from "../../components/Icons/Icon";
import IconBlock from "./IconBlock";

// Utilities
import { dateFormatter } from "../../utils/UtilityFunctions";

//External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

//Styling
import "./header.css";

function Header({ variant }) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  // State
  const [homepage, setHomepage] = useState(
    window.location.href === "/" ? true : false
  );
  const [currentWindow, setCurrentWindow] = useState(window.location.href);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [daysMap, setDaysMap] = useState();
  const [viewWidth, setViewWidth] = useState(
    Math.max(document.documentElement.clientWidth)
  );
  const [insightTextState, setInsightTextState] = useState("Insights");
  const [headerSpan, setHeaderSpan] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [menuState, setMenuState] = useState("menu");
  const [navMenu, setNavMenu] = useState("navMenu");
  const [navDock, setNavDock] = useState("navDock");
  const [trialDays, setTrialDays] = useState();

  // Redux State Management
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList?.years?.at(-1)?._id);

  const weekList = useSelector((state) => state.weekList?.weeks?.at(-1)?._id);

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listDays());
    dispatch(listYears());
    dispatch(listWeeks());
  }, [dispatch, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  const yearMap = yearList ? `/year/${yearList}` : `/year/create`;
  const weeksMap = weekList ? `/week/${weekList}` : `/week/create`;
  const dayUrl =
    days?.length > 0
      ? days[days.length - 1].endScore === undefined
        ? `/today/${days[days.length - 1].id}`
        : `/today`
      : `/today`;

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000;
    setTimeout(() => {
      if (days) {
        setPageLoading(false);
      }
    }, randNum);
  };

  useEffect(() => {
    if (
      !userInfo ||
      !userInfo.subscription ||
      !userInfo.subscription.userStart
    ) {
      console.log("No User Data");
    } else {
      const trialStartDate = userInfo.subscription.userStart;
      const currentTier = userInfo.subscription.plan;

      if (currentTier === "trial") {
        const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const trialStartDateMs = new Date(trialStartDate).getTime();
        const trialStartDateStart = new Date(trialStartDate);
        let thirtyDaysAhead = new Date(trialStartDateStart);
        thirtyDaysAhead.setDate(trialStartDateStart.getDate() + 30);
        const differenceInMilliseconds = Math.abs(thirtyDaysAhead - Date.now());
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        const currentDateMs = new Date().getTime();

        setTrialDays(differenceInDays);
      } else if (currentTier !== "trial") {
        setTrialDays(0);
      }
    }
  }, []);

  useEffect(() => {
    loadingTimeout();
  }, []);

  const userFunction = () => {
    if (
      !userInfo ||
      !userInfo.subscription ||
      !userInfo.subscription.userStart
    ) {
      console.log("No User Data");
    } else if (
      userInfo.subscription.plan === "trial" ||
      userInfo.subscription.plan === "classic_annual" ||
      userInfo.subscription.plan === "classic_monthly" ||
      userInfo.subscription.plan === "core_annual" ||
      userInfo.subscription.plan === "core_monthly"
    ) {
      setUserStatus("no_coach");
    } else {
      setUserStatus("coach");
    }
  };

  useEffect(() => {
    userFunction();
  }, [userInfo]);

  const svgIcons = [
    { Component: YearIcon, title: "Map", url: yearMap },
    { Component: WeekIcon, title: "Planner", url: weeksMap },
    { Component: DiaryIcon, title: "Journal", url: dayUrl },
    { Component: InsightsIcon, title: "Insights", url: "/insights" },
    { Component: ProfileIcon, title: "My Account", url: "/my-account/menu" },
    { Component: LogoutIcon, title: "Logout", url: "" },
    { Component: RegisterIcon, title: "Register", url: "/register" },
    { Component: LoginIcon, title: "Login", url: "/login" },
  ];

  const openMenu = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavMenu((navMenu) => "navMenu active");
      setNavDock((navDock) => "navDock");
    } else {
      setMenuState((menuState) => "menu");
      setNavMenu((navMenu) => "navMenu");
      setNavDock((navDock) => "navDock");
    }
  };

  const openDock = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavDock((navDock) => "navDock active");
      setNavMenu((navMenu) => "navMenu");
    } else {
      setMenuState((menuState) => "menu");
      setNavDock((navDock) => "navDock");
      setNavMenu((navMenu) => "navMenu");
    }
  };

  const [nav, setNav] = useState(false);

  const handleNavChange = () => {
    setNav(!nav);
  };

  return (
    <>
      <nav className={variant === "dark" ? `nav central dark` : `nav central`}>
        {/* Thin Row with flat arrow */}
        <div
          className={nav ? "nav--thin--active" : "nav--thin"}
          onClick={handleNavChange}
        >
          {nav ? (
            <FontAwesomeIcon icon={faChevronDown} className="nav--chevron" />
          ) : (
            <FontAwesomeIcon icon={faChevronUp} className="nav--chevron" />
          )}
        </div>
        {/* Fatter Row, center justified, */}
        {/* <div className="box left">
           {Array.isArray(days) === true ? (
            <a href={dayUrl}>
              <ValuesIcon />
            </a>
          ) : (
            <a href={"/today"} target="_blank" rel="noopener noreferrer">
              <ValuesIcon />
            </a>
          )} 
        </div>
        <div className="box centre">
         {!pageLoading && <HeaderSearch data={days} variant={variant} />} 
        </div>*/}
        <div
          className="nav--wide"
          style={nav ? { display: "flex" } : { display: "none" }}
        >
          {currentWindow.endsWith("/") && !userInfo ? (
            <IconBlock
              block={svgIcons.slice(-2)}
              variant={variant}
              trialDays={trialDays}
              userStatus={userStatus}
            />
          ) : (
            <IconBlock
              block={svgIcons.slice(0, -3)}
              variant={variant}
              trialDays={trialDays}
              userStatus={userStatus}
            />
          )}
        </div>
      </nav>
    </>
  );
}

export default Header;
