//React & Redux
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//Internal Compoments
import TestFooter from "../../layouts/Footer/TestFooter";
import BlogPostComponent from "../../components/Blog/BlogPostComponent";
import Header from "../../layouts/Header/Header";

// External Components
// Sanity Client
import { client } from "../../client";

//CSS
import "./mission.css";

function AboutScreen() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [pageContent, setPageContent] = useState();
  const [isContentReady, setIsContentReady] = useState(false);
  const [latestContent, setLatestContent] = useState();
  const [nextContent, setNextContent] = useState();

  let slug = "about-nowasteddays";

  async function getPosts() {
    const posts = await client.fetch("*[_type == 'post']");
    return posts;
  }

  useEffect(() => {
    const fetchData = async () => {
      let blogPosts = [];
      try {
        const response = await getPosts();

        if (response) {
          for (let i = 0; i < response.length; i++) {
            if (
              response[i].title.startsWith("LP_") ||
              response[i].title.startsWith("TUTORIAL_") ||
              response[i].title.startsWith("AOFL_") ||
              response[i].title.startsWith("PLAN_") ||
              response[i].title.startsWith("PRIME_") ||
              response[i].title.startsWith("PROCESS_") ||
              response[i].title.startsWith("PAGE_") ||
              response[i].title.startsWith("LOGO_")
            ) {
              console.log("not right");
            } else {
              blogPosts.push(response[i]);
            }
          }
        }
        setPageContent(blogPosts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}

      <main className="landingpage__main">
        <div className="testimony--shift about">
          {pageContent &&
            pageContent
              .filter((page, i) => page.slug.current === slug)
              .map((p, i) => (
                <div key={i}>
                  <BlogPostComponent body={p.body} />
                </div>
              ))}
        </div>

        <TestFooter />
      </main>
    </>
  );
}

export default AboutScreen;
