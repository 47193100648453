// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// External Packages
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { Card, Button, Row, Col, Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faEraser,
  faSquareCaretRight,
  faSquareCaretLeft,
} from "@fortawesome/free-solid-svg-icons";

// Internal Components
import Header from "../../../layouts/Header/Header";
import generateSecret from "../../../utils/generateSecret";
import TestFooter from "../../../layouts/Footer/TestFooter";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import TileLoading from "../../../components/Loading/TileLoading";

// User Actions
import { updateQuizAction, listQuizes } from "../../../actions/quizActions";
import { updateProfile } from "../../../actions/dayActions";

// Images
import { client } from "../../../client";

// Styling
import "./quizmindset.css";

export default function QuizMindsetScreen(history) {
  const [titleDiv, setTitleDiv] = useState(true);
  const [kickOff, setKickOff] = useState(false);
  const [open, setOpen] = useState(false);
  const [skeptical, setSkeptical] = useState(false);
  const [present, setPresent] = useState(false);
  const [optimistic, setOptimistic] = useState(false);

  const [video1, setVideo1] = useState(true);
  const [video2, setVideo2] = useState(false);
  const [video3, setVideo3] = useState(false);

  const [chapterOneScore, setChapterOneScore] = useState(100);
  const [chapterTwoScore, setChapterTwoScore] = useState(25);
  const [chapterThreeScore, setChapterThreeScore] = useState(0);
  const [chapterFourScore, setChapterFourScore] = useState(0);

  const [chapterOneScoreLive, setChapterOneScoreLive] = useState();
  const [chapterTwoScoreLive, setChapterTwoScoreLive] = useState();
  const [chapterThreeScoreLive, setChapterThreeScoreLive] = useState();
  const [chapterFourScoreLive, setChapterFourScoreLive] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const quizUpdate = useSelector((state) => state.quizUpdate); //
  const { success: successUpdate } = quizUpdate;

  const quizList = useSelector((state) => state.quizList);
  const { quizes } = quizList;

  useEffect(() => {
    dispatch(listQuizes());
  }, [dispatch, userInfo, history]);

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateQuizAction(
        id,
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !chapterOneScore ||
      !chapterTwoScore ||
      !chapterThreeScore ||
      !chapterFourScore
    )
      return;

    // resetHandler();
  };

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
    },
  };

  const showHideFlex = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideFlexCol = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const johnCleese = "Gg-6LtfB5JA";
  const johnBerger = "0pDE4VX_9Kk";
  const johnnyWilkinson = "O80qs9OEadw";
  const elaineStritch = "pL_pIS_QnPc";
  const steveJobs = "UF8uR6Z6KLc";
  const peterUstinov = "ooCIpYOxaoo";

  useEffect(() => {
    document.title = "Art of Looking";
  }, []);

  const [hasQuiz, setHasQuiz] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    if (Array.isArray(quizes)) {
      if (quizes.length === 0) {
        setHasQuiz((hasQuiz) => false);
      } else if (quizes.length === 1) {
        setLinkId((linkId) => quizes[0]._id);

        setChapterOneScoreLive(quizes[0].chapterOneScore);

        if (chapterOneScoreLive > chapterOneScore) {
          setChapterOneScore(chapterOneScoreLive);
        }

        setChapterTwoScoreLive(quizes[0].chapterTwoScore);

        if (chapterTwoScoreLive > chapterTwoScore) {
          setChapterTwoScore(chapterTwoScoreLive);
        }

        setChapterThreeScoreLive(quizes[0].chapterThreeScore);

        if (chapterThreeScoreLive > chapterThreeScore) {
          setChapterThreeScore(chapterThreeScoreLive);
        }

        setChapterFourScoreLive(quizes[0].chapterFourScore);

        if (chapterFourScoreLive > chapterFourScore) {
          setChapterFourScore(chapterFourScoreLive);
        }
      }
    } else {
      setHasQuiz((hasQuiz) => true);
    }
  });

  const [pageLoading, setPageLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  };

  useEffect(() => {
    loadingTimeout();
  });

  const [content, setContent] = useState();

  async function getPosts() {
    const posts = await client.fetch(`*[_type == 'post']{
      _id,
      title,
      mainImage {
        asset -> {
          _id,
          url
        },
      },
    }`);
    return posts;
  }

  useEffect(() => {
    const fetchData = async () => {
      let picsObj = [];

      try {
        const response = await getPosts();
        if (response) {
          for (let i = 0; i < response.length; i++) {
            if (response[i].title.startsWith("AOFL_")) {
              picsObj.push({
                id: response[i].mainImage.asset._id,
                title: response[i].title,
                url: response[i].mainImage.asset.url,
              });
            }
          }
        }
        setContent(picsObj);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(content);
  });

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "24px",
        }}
      >
        {/* <motion.div
          className="titleDiv"
          animate={titleDiv ? "show" : "hide"}
          variants={showHideState}
          style={{ padding: "16px 20px" }}
        >
          <h1>Making a Mindset</h1>
          <span>
            <strong>Click to get started</strong>
          </span>
        </motion.div> */}

        {pageLoading && imageLoading ? (
          <TileLoading />
        ) : (
          <motion.div
            id="titleDivMain"
            style={{ overflow: "hidden" }}
            animate={titleDiv ? "show" : "hide"}
            variants={showHideState}
            onClick={() => {
              setTitleDiv((titleDiv) => false);
              setKickOff((kickOff) => true);
            }}
          >
            <motion.img
              className="title__image"
              src="https://cdn.sanity.io/images/ou6d2gec/production/5db8c4c42946c9ff557049accd4d83942d520c04-5000x3250.png"
              onLoad={handleImageLoad}
              width="100%"
              alt="Art of Looking Cover"
              loading="lazy"
            />
            <span className="clickAnywhere">Click to get Started</span>
          </motion.div>
        )}

        <motion.div
          animate={kickOff ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Mindset Mastery</h4>
            </div>
            <br />
            <p>
              Getting value from a graph is only a very small part about being
              able to spot and scrutinise the visualisation itself in any
              statistical manner. We can all spot a peak or a trough, and all
              spy a subtler shift in a line or trend.
            </p>
            <p>
              The far larger portion of potential value is derived by knowing
              how to reliably create the perfect mind state from which to study
              your insights - one that will prime you for better, happier
              explorations time after time.
            </p>
            <span>
              There are 4 practical pillars of this primed mental state:
              <br />
              <br />
              <span>1. </span>
              <strong style={{ fontSize: "1.5em" }}>The Open Mode</strong>
              <br />
              <br />
              <span>2. </span>
              <strong style={{ fontSize: "1.5em" }}>Healthy Skepticism</strong>
              <br />
              <br />
              <span>3. </span>
              <strong style={{ fontSize: "1.5em" }}>The Present Moment</strong>
              <br />
              <br />
              <span>and a...</span>
              <br />
              <br />
              <span>4. </span>
              <strong style={{ fontSize: "1.5em" }}>
                Strong Undercurrent of Optimism
              </strong>
            </span>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/6ba8d13cda2b0bc1bca4b1cb7a2477a32c29cd8c-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setKickOff((kickOff) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setKickOff((kickOff) => false);
                setOpen((open) => true);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={open ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Entering the Open Mode:</h4>
            </div>
            <br />
            <p>
              Whilst it is not something we normally think of as being a part of
              working with data, enabling creativity and free thinking when
              studying your insights is essential. It is the particular skill
              and state that will prompt the most interesting questions and
              delve back into your memories to spot the joins between certain
              moments that can create certain new opportunities.
            </p>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/db00c8462de1ce23fb58d5553e2bd2cc34dde0c4-5000x3250.png"
                alt="Entering the Open Mode"
              />
            </div>
            <p>
              John Cleese once gave a tremendous talk about creativity and how
              for him, after extensive research and collaboration, it was
              something that was available to us all and was best fostered in
              what he and the other researchers (most notably Donald McKinnon)
              called <strong>the Open Mode</strong>. I would 100% recommend
              watching it, but for ease, I have dissected the key actions and
              thoughts below.
            </p>
            <p>
              Since watching it, I think about its principles daily as I set
              about trying to think, whether at work in or in my general life.
            </p>
            <hr className="dividingLine" />
            <p>
              <strong>Objective:</strong> To successfully get our minds into the
              'Open Mode' when we are looking at our insights.
            </p>
            <p>
              <strong>Why:</strong> To help us spot signals and convert them
              into further growth and joy systematically and safely.
            </p>
            <strong>How: John's Rules for the Open Mode</strong>
            <br />
            <br />
            <span>
              1.<strong>You Need Time and Space </strong>
            </span>
            <br />
            <br />
            <ul>
              <li>
                <strong>Create your 'Oasis'</strong> i.e. the physical and
                mental space that you need from people, and other distractions.
              </li>
              <br />
              <li>
                Obviously, the nature and shape of this oasis will be different
                for everyone. However, to know what it is for you is essential
                as distractions, task switching and insufficient time will all
                steal from your minds ability to settle in and do genuinely deep
                and creative thinking in regards to your own data.
              </li>
              <br />
              <li>
                <strong>
                  Define the slot of time you are going to think within.
                </strong>
              </li>
              <br />
              <ul className="subList">
                <li>This slot needs a pre-defined start</li>
                <li>and the slot needs a pre-defined end</li>
                <li>Half a day is too long in most cases</li>
                <li>30 minutes is too short in most cases</li>
                <li>100 - 180 minutes is often an optimal window</li>
              </ul>
              <br />
              <li>
                Be prepared for your mind to take a moment to warm up in the
                first 30 minutes. Your brain will likely resist a litle as you
                try and put it to work, however, resist the temptation to
                prioritise something that is either less urgent or straight up
                less important.
              </li>
              <br />
              <li>
                Be prepared for it to feel like perhaps more like play, and less
                like rational, linear optimisation.
              </li>
            </ul>
            <br />
            <span>
              2.<strong>Time (to put the sub-conscious to work)</strong>
            </span>
            <br />
            <br />
            <ul>
              <li>
                If you don't reach a satisfactory conclusion within your time
                and space oasis, and you are not at the point where a decision
                needs to be made, <strong>then don't make one. </strong>
                Your sub-conscious will then work gamely on a solution in the
                time you have now afforded it.
                <br />
                <br />
                This frees you from feeling like you have to to force it in the
                moment. This will not always be easy as you have to learn to
                tolerate the curious mental space created when a question
                doesn't have an answer. However, we are optimising for maximum
                creativity and the best solution at the right time; not the best
                possible solution in too short a window of time.
                <br />
                <br />
                This space is not a void; it is rather a crucible for creative
                combustion; keep it open for as long as you can before having an
                answer is utterly imperative.
              </li>
            </ul>
            <br />
            <span>
              3.<strong>Confidence</strong>
            </span>
            <br />
            <br />
            <ul>
              <li>
                When you have a solution you love, or simply the best you could
                have created before having to make a decision, then switch to
                the confident closed mode to back your decision with strong,
                assertive energy
              </li>
            </ul>
            <br />
            <span>
              But, remember, be very careful not to get stuck there by unduly
              prioritising other tasks, making snap decisions to reduce your
              discomfort, starving yourself of space and time and or believing
              that you can force everything in a rational, linear and compulsive
              fashion.
            </span>
          </div>
          <div className="rightCol">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/db00c8462de1ce23fb58d5553e2bd2cc34dde0c4-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setKickOff((kickOff) => true);
                setOpen((open) => false);
              }}
            />

            <a
              className="niceLink"
              href="https://youtu.be/Pb5oIIPO62g"
              target="_blank"
            >
              Link to Full 35 Minute Video
            </a>

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setSkeptical((skeptical) => true);
                setOpen((open) => false);

                if (chapterTwoScore > chapterTwoScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterTwoScore((chapterTwoScore) => 50);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={skeptical ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h3 className="quizHeaderWhite">A Chunk of Healthy Scepticism</h3>
            </div>
            <br />
            <p>
              Healthy skepticism is the backbone of progress. The scientist who
              doesn't just settle for an apparently correct solution and rather
              asks skeptically, 'how could this be wrong?' is the one far more
              likely to get to the truth of a subject than the one who is only
              looking to simply confirm his/her pre-existing belief.
            </p>
            <p>
              Furthermore, in general life, when we cease to be skeptical, we
              tend to accept whatever we are told as fact when even minor
              scrutiny would lead to a better outcome for all parties. On the
              other side of the equation, when we are exclusively, belligerently
              skeptical, we strafe into cynicism, and find ourselves reluctant
              to believe in anything, undertake any action or believe in anyone,
              even those who look like they are putting good action behind a
              good intention.
            </p>
            <p>
              But there is more we can add here, again, with the help of a video
              - in this case from John Berger.
            </p>
            <p>
              John Berger was a famous art history scholar, writer and tv
              presenter in the UK. His series of programmes called{" "}
              <strong>'Ways of Seeing'</strong> in the 1970s were beautifully
              pitched to use art as a means of exploring how we have come to
              look at so many things in everyday life.
            </p>
            <p>
              He concludes the first episode by saying the following regarding
              skepticism:
            </p>
            <span>
              <strong>
                "I'm going to try and relate the experience of art directly to
                other experiences, and to use the means of reproduction as
                though they offered a language, as if pictures were like words,
                not holy relics.
              </strong>
              <br />
              <br />
              <strong>
                But remember I am controlling and using for my own purposes the
                means of reproduction needed for these programmes. The images
                may be like words, but there is no dialogue yet.
              </strong>
              <br />
              <br />

              <strong>
                ...with this programme, as with all programmes, you receive
                images and meanings which are arranged. I hope you will consider
                what I arrange, but be sceptical of it."
              </strong>
            </span>

            <div className="mobileImage">
              <br />
              <br />
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${johnBerger}?start=1681`}
              ></iframe>
            </div>
            <br />
            <br />
            <span>
              Not only do I think this is one of the most presient, wise and
              courageous things you can say when making a TV programme, the
              sentiment it captures is true of the data. section.
              <br />
              <br />
              It is arranged and presented according to its potential for good,
              but also it has inevitable limitations.
              <br />
              <br />
              <strong>Example limitation:</strong> It is not your every waking
              thought, movement, feeling or foible.
              <br />
              <br />
              <strong>Example limitation:</strong> Your data is just the
              expression of a few self-reported intentions, actions and
              feelings.
              <br />
              <br />
              <strong>Example limitation:</strong> What you are looking at is
              just a mixture of lines, dots, marks and numbers on a page.
            </span>
            <br />
            <br />
            <p>
              Yet, for all their limitations, when looked at in the open mode,
              with due skepticism and an understanding of the context, these
              limited arrangements can help you see deep inside yourself and
              change your very existence for the better.
            </p>
            <p>
              To summarise, treat every signal you find in the open mode with
              skepticism. This diligent scrutiny will help you get to the
              deepest lying truth and be less shaken by the things you saw and
              felt on first glance.
            </p>
          </div>
          <div className="rightCol">
            <div className="rightContent">
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${johnBerger}?start=1681`}
              ></iframe>
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setOpen((open) => true);
                setSkeptical((skeptical) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setPresent((present) => true);
                setSkeptical((skeptical) => false);

                if (chapterTwoScore > chapterTwoScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterTwoScore((chapterTwoScore) => 75);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={present ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h3 className="quizHeaderWhite">The Present Moment</h3>
            </div>

            <br />
            <span>
              Presence and mindfulness are much talked about and engaged with
              nowadays, particular as remedies for stress and anxiety.
              <br />
              <br />
              Further and further engagement with mindfulness practices on a
              individual level have the power to not only allow you to extricate
              yourself from stress and anxiety, but distance you from notions of
              self, and the attachment to the past and the future which is the
              source of so much of your suffering.
              <br />
              <br />
              In this present, meditative state, people often describe
              themselves as feeling more and more connected with all facets of
              the world. They no longer feel like an isolated ego with a past
              and a future, but rather as a pulsating node in one giant
              organism; completely alike and united with the rest of existence.
            </span>
            <br />
            <br />
            <span>
              Don't worry. I am not about to say you need to reach this kind of
              zen state before you can study your data effectively, however,
              this idea of detaching from self and existing only in the present
              moment is something we can use with real effect.
            </span>
            <br />
            <br />
            <span>
              As we explored earlier, looking at your habits played back in data
              can be a quite a triggering experience for people. It is so easy
              to lurch to thoughts such as, 'Man, I regret doing that' or 'What
              does that say about me?' or look at even a great day and be like,
              'thank goodness for that; that is more like it.' All of these
              thoughts should be looked at with certain level of caution.
            </span>
            <br />
            <br />
            <span>
              Why that it is the case is something that Johnny Wilkinson, the
              former England Rugby Player, speaks about brilliantly.
            </span>
            <br />
            <br />
            <span>
              He says,
              <strong>
                you never live a consequence in the now. If you stay in the now,
                there is no consequence.
              </strong>
            </span>
            <div className="mobileImage">
              <br />
              <br />
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${johnnyWilkinson}?start=3068`}
              ></iframe>
            </div>
            <br />
            <br />
            <span>
              Most people I have relayed that quote to wriggle and writhe a
              little bit, and go "what? obviously there are consequences.
              Everything I do has a consequence, everything has a cause and
              effect".
            </span>
            <br />
            <br />
            <span>
              Within physical laws, this is undeniable - action and reaction etc
              - but we are dealing in the realm of the mental and emotional.
              <br />
              <br />
              In short, Wilkinson's remark is saying that the suffering or
              triggers we experience are exclusively products of an attachment
              with the past, or projecting into the future and that if you are,
              instead, exclusively alive entirely and solely in the present
              moment, you will avoid that suffering.
              <br />
              <br />
              <hr className="dividingLine" />
              <br />
              There is nothing wrong with having a vision for the future but
              don't mistake a commitment to grow with projecting your self into
              the future, panicking over whether the projections you make will
              come to pass, and seeing your only chance of standing upright as a
              assured person if that vision plays out.
              <br />
              <br />
              To summarise, as Johnny Wilkinson said, there are no consequences
              in the present moment. The present moment is your refuge from
              suffering and where the acceptance, observation and orientation
              that is essential for new actions and growth can be fostered.
            </span>
          </div>
          <div className="rightCol">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/6ba8d13cda2b0bc1bca4b1cb7a2477a32c29cd8c-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setSkeptical((skeptical) => true);
                setPresent((present) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setOptimistic((optimistic) => true);
                setPresent((present) => false);

                if (chapterTwoScore > chapterTwoScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterTwoScore((chapterTwoScore) => 100);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={optimistic ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h3 className="quizHeaderWhite">Optimism.</h3>
            </div>

            <br />
            <span>
              In a perpetually alarming and negative news cycle, pessimism about
              the human condition - a solely bleak outlook on the future and a
              dwindling association with hope - becomes an all too common mental
              default; one that is 'proved to be correct' day in and day out as
              another murder is followed by another fraud, another conflict
              superseded by another tragedy.
              <br />
              <br />
              This tends to leave optimism relegated to the presumed shallow and
              naive.
              <br />
              <br />
              However, whilst there is no denying that the kind of absent
              optimism that just assumes things will get better without people
              actually acting to make the world a better place is a very
              self-defeating state, the kind of hopeful view that provides the
              final pillar in this mindset section is not only more perceptive
              than pessimism, but is going to translate to better performance
              too.
              <br />
              <br />
              I have three lovely clips that I refer to when I reflect on
              optimism that you can explore by clicking on the buttons below.
              <br />
              <br />
              #1: Elaine Stritch describes the balance of optimism and presence
              well, in her classic raw edged style.
              <br />
              <br />
              <strong>
                "I pray that I may live expectantly. To live expectantly.
                "What's going to happen on Sunday?", and on Sunday, "What's
                going to happen on Monday? In the meantime, stay where you
                f**kin are - and enjoy it; the best way you know how."
              </strong>
              <br />
              <br />
              In more infamous terms, Steve Jobs describes a similar state of
              mind in his much viewed Stanford Commencement Speech:
              <br />
              <br />
              <strong>
                "You have to trust that the dots will somehow connect in your
                future[...]Because believing the dots will connect down the
                road, will give you confidence to follow your heart even when it
                leads off the well-worn path".
              </strong>
              <br />
              <br />
              Finally, a third quote: the actor, raconteur and humanitarian
              Peter Ustinov said this on the Parkinson programme which I think
              is bang on as well:
              <br />
              <br />
              <strong>
                "I think pessimism is completely out of date[...]a romantic
                indulgence. I have always thought that an optimist was a person
                who know exactly how sad a place the world could be, and a
                pessimist was someone who finds out anew every morning."
              </strong>
              <br />
              <br />
              These three thoughts beautifully demonstrate the difference
              between mindless hope without action or awareness of reality, and
              genuine optimism - an honest, empowering perspective that inspires
              appropriate action.
              <br />
              <br />
              You know it will be hard, you know the world can be ruthless, but
              that isn't reason to despair - it is more reason to be confident
              that the optimistic effort and attitude you bring will be a) its
              own reward, b) worth it in the end, regardless if things go
              exactly to plan or not, and c) an antidote, rather than a salve,
              to life's suffering.
            </span>
          </div>
          <div className="rightCol">
            <div className="rightContent">
              <motion.div
                className="video One"
                animate={video1 ? "show" : "hide"}
                variants={showHideState}
              >
                <iframe
                  className="videoPlayerQuiz"
                  title="Youtube player"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/${elaineStritch}?start=26&end=52`}
                ></iframe>
              </motion.div>
              <motion.div
                className="video Two"
                animate={video2 ? "show" : "hide"}
                variants={showHideState}
              >
                <iframe
                  className="videoPlayerQuiz"
                  title="Youtube player"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/${steveJobs}?start=302&end=333`}
                ></iframe>
              </motion.div>
              <motion.div
                className="video Three"
                animate={video3 ? "show" : "hide"}
                variants={showHideState}
              >
                <iframe
                  className="videoPlayerQuiz"
                  title="Youtube player"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/${peterUstinov}?start=0`}
                ></iframe>
              </motion.div>
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setPresent((present) => true);
                setOptimistic((optimistic) => false);
              }}
            />

            {vw > 500 ? (
              <div className="nameButtonRow">
                <motion.button
                  className="toggleButton"
                  onClick={() => {
                    setVideo1((video1) => true);
                    setVideo2((video2) => false);
                    setVideo3((video3) => false);
                  }}
                >
                  Elaine
                </motion.button>
                <motion.button
                  className="toggleButton"
                  onClick={() => {
                    setVideo1((video1) => false);
                    setVideo2((video2) => true);
                    setVideo3((video3) => false);
                  }}
                >
                  Steve
                </motion.button>
                <motion.button
                  className="toggleButton"
                  onClick={() => {
                    setVideo1((video1) => false);
                    setVideo2((video2) => false);
                    setVideo3((video3) => true);
                  }}
                >
                  Peter
                </motion.button>
              </div>
            ) : (
              <></>
            )}

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setChapterTwoScore((chapterTwoScore) => 100);

                setTimeout(() => {
                  if (chapterTwoScore > chapterTwoScoreLive) {
                    updateHandler();
                  }
                  navigate(`/insight/quiz/${linkId}/debrief`);
                }, 1000);
              }}
            />
          </div>
        </motion.div>
      </main>
      <TestFooter />
    </>
  );
}
