//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

//Actions
import { createDayAction } from "../../actions/dayActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";

//Internal Components
import TestFooter from "../../layouts/Footer/TestFooter";
import TileLoading from "../../components/Loading/TileLoading";
import { ErrorMessage } from "../../components/Error/ErrorMessage";

//External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPointRight,
  faX,
  faPlusCircle,
  faFloppyDisk,
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faLandmarkDome,
  faDna,
  faGlasses,
  faQuoteLeft,
  faPersonCircleQuestion,
  faGripLinesVertical,
  faBookOpen,
  faEyeSlash,
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faBolt,
  faCalendarPlus,
  faPlusSquare,
  faQuestion,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import Header from "../../layouts/Header/Header";
import FullDock from "../../features/dock/FullDock";
import {
  FullDockMobileOne,
  FullDockMobileTwo,
} from "../../features/dock/FullDockMobile";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import { hardData } from "../Insight/HardData";
import axios from "axios";

//Styling
import "./dayscreens.css";
import { isToday } from "date-fns";

export default function TutorialCreateDayScreenFinal({ history, props }) {
  // Form State - Today
  const [startScore, setStartScore] = useState();
  const [logDate, setLogDate] = useState();
  const [thankYou, setThankYou] = useState(" ");
  const [selfLess, setSelfLess] = useState(" ");
  const [mindBody, setMindBody] = useState(" ");
  const [momentOne, setMomentOne] = useState();
  const [momentOneScore, setMomentOneScore] = useState();
  const [momentTwo, setMomentTwo] = useState();
  const [momentTwoScore, setMomentTwoScore] = useState();
  const [momentThree, setMomentThree] = useState();
  const [momentThreeScore, setMomentThreeScore] = useState();
  const [momentFour, setMomentFour] = useState();
  const [momentFourScore, setMomentFourScore] = useState();
  const [momentFive, setMomentFive] = useState();
  const [momentFiveScore, setMomentFiveScore] = useState();
  const [rememberToday, setRememberToday] = useState(" ");
  const [leaveBehind, setLeaveBehind] = useState(" ");
  const [endScore, setEndScore] = useState();

  // Loading State
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonState, setButtonState] = useState(false);

  //Animation State
  const [isIntro, setIsIntro] = useState(true);
  const [show, setShow] = useState(false);
  const [isPrimes, setIsPrimes] = useState(true);
  const [isMoments, setIsMoments] = useState(true);
  const [isEvenings, setIsEvenings] = useState(true);
  const [beforeScore, setBeforeScore] = useState(false);
  const [finalScore, setFinalScore] = useState(false);
  const [thankYouState, setThankYouState] = useState(false);
  const [selfLessState, setSelfLessState] = useState(false);
  const [mindBodyState, setMindBodyState] = useState(false);
  const [rememberTodayState, setRememberTodayState] = useState(false);
  const [leaveBehindState, setLeaveBehindState] = useState(false);
  const [momentActive, setMomentActive] = useState(false);

  const showHideState = {
    show: {
      display: "flex",
    },
    hide: {
      display: "none",
    },
  };

  const introState = {
    hidden: {
      opacity: 0,
      scale: 0.1,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const activeState = {
    hidden: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      padding: "0px",
      borderRadius: "5px",
      opacity: 0,
      color: "rgba(0, 74, 35,1)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      color: "rgba(0, 74, 35,1)",
      padding: "4px",
      borderRadius: "5px",
      backgroundColor: "rgba(0, 150, 30,0.5)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      scale: 1,
      padding: "0px",
      borderRadius: "5px",
      color: "rgba(0, 74, 35,1)",
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  // Data Calls & Event Handlers
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 3000);
  };

  const [instructionState, setInstructionState] = useState("");
  const [supportingState, setSupportingState] = useState("");
  const [clickCounter, setClickCounter] = useState(0);
  const [successState, setSuccessState] = useState(" .active");

  const clickPlus = () => {
    setClickCounter((clickCounter) => clickCounter + 1);
  };

  const clickMinus = () => {
    setClickCounter((clickCounter) => clickCounter - 1);
  };

  const [valuesShowState, setValuesShowState] = useState("mini node inactive");
  const [visionShowState, setVisionShowState] = useState("mini node inactive");

  const valuesShow = () => {
    if (valuesShowState === "mini node inactive") {
      setValuesShowState((valuesShowState) => "mini node");
      setVisionShowState((visionShowState) => "mini node inactive");
    } else {
      setValuesShowState((valuesShowState) => "mini node inactive");
    }
  };

  const visionShow = () => {
    if (visionShowState === "mini node inactive") {
      setVisionShowState((visionShowState) => "mini node");
      setValuesShowState((valuesShowState) => "mini node inactive");
    } else {
      setVisionShowState((visionShowState) => "mini node inactive");
    }
  };

  const displayText = () => {
    if (clickCounter === 0) {
      setInstructionState(
        (instructionState) =>
          "Through the diary page dock, you can access both the year map and week planner."
      );
      setSupportingState((supportingState) => "Hover over to give it a go");
      setShow((show) => true);
    } else if (clickCounter === 1) {
      setInstructionState(
        (instructionState) =>
          "The shading of the week nodes lets you know how much of a objective you have completed"
      );
      setSupportingState(
        (supportingState) => "The darker the shading the more you have done!"
      );
    } else if (clickCounter === 2) {
      setInstructionState(
        (instructionState) =>
          "That is everything you need to know to get started!"
      );
      if (userInfo) {
        setSupportingState(
          (supportingState) =>
            "Click the button to the right to sign up or log your very first day."
        );
      } else {
        setSupportingState(
          (supportingState) =>
            "Click the button to the right to sign up for your own NWD diary"
        );
      }

      setButtonState((buttonState) => true);
    }
  };

  const vw = Math.max(document.documentElement.clientWidth);

  const [viewWidth, setViewWidth] = useState(vw);

  const scoreAnimator = () => {
    let fiveScore = Math.floor(Math.random() * 50) / 10;
    let tenScoreOne = Math.floor(Math.random() * 100) / 10;
    let tenScoreTwo = Math.floor(Math.random() * 100) / 10;
    let tenScoreThree = Math.floor(Math.random() * 100) / 10;
    let tenScoreFour = Math.floor(Math.random() * 100) / 10;
    let tenScoreFive = Math.floor(Math.random() * 100) / 10;
    let datesArr = [
      "2023-01-02",
      "2023-01-03",
      "2023-01-04",
      "2023-01-05",
      "2023-01-06",
      "2023-01-07",
      "2023-01-08",
      "2023-01-09",
      "2023-01-10",
      "2023-01-11",
      "2023-01-12",
      "2023-01-02",
      "2023-01-03",
      "2023-01-04",
      "2023-01-05",
      "2023-01-06",
      "2023-01-07",
      "2023-01-08",
      "2023-01-09",
      "2023-01-10",
      "2023-01-11",
      "2023-01-12",
    ];
    let thankYouArr = [
      "my friends and family",
      "my home for keeping me warm and safe",
      "my beauty for bringing love",
      "my feet for getting me around everywhere",
      "that flower on my way to work",
      "Rhodri - for being a beautiful music freak",
      "David Hieatt for the inspiration yesterday",
      "Clever writers of Comedy",
      "William Morris",
      "that SpreadLoveMovement dude for the good thoughts",
      "my home: for safely hosting my life",
      "the dude cleaning out the pond",
      "Walter; for getting me in and out of work everyday",
      "this moment now; alone, awake early, cup of tea brewing; lovely",
      "Mike Posner - for his reflections, music and positivity",
    ];
    let selfLessArr = [
      "focusing on one thing at a time",
      "taking each moment for play and exploration",
      "simply being curious about everything",
      "taking the day one moment at a time",
      "breathing in the good shit, and letting go of the bad shit",
      "Meditating and aligning with the present",
      "seeing all things as opportunities for exploration",
      "by talking it into existence",
      "planning precisely and settling down to work with headspace",
      "switching off when it matters",
      "focusing on what matters with a spirit of problems as opportunities",
      "being up early; giving myself to what matters",
      "remembering that ego is the enemy",
      "just doing; thought can get in the way sometimes",
    ];
    let mindBodyArr = [
      "I do not have a toothache",
      "it views the world with hope and love",
      "it is free from pain most of the time",
      "it is here with me today",
    ];
    let momentsArr = [
      "Read for 45 minutes",
      "Meditate - 20 mins",
      "Switch off and go for a run",
      "Be present with my beauty",
      "Be present at art class",
      "Go for a 20 minute walk with a coffee",
      "Visit the Tate and take a deep breath",
      "Work before work: 90 mins of Coding",
      "If stress, take 5 breaths to reset",
      "Workout: Back & Biceps (250 Reps)",
      "Enjoy a skate round the park",
      "Be present at yoga class",
      "Speak to Mum and ask her how she is feeling",
      "Be present with the boys",
      "Be present at date night",
      "Prepare for Football practice",
      "Listen to Stravinsky for 60 minutes",
      "Be kind and work hard on my shift",
      "Have fun at football",
      "Take 15 minutes to look after the house plants",
      "No single use plastic today",
      "Be present making dinner",
      "Enjoy my online course",
      "Notice if I get angry",
      "Be present round Elenas",
      "Plan for the weekend",
      "Organise date night",
      "Be kind",
      "Start a new book",
    ];
    let memoriesArr = [
      "Coding progress; relaxing at work; fun chat after football",
      "Read all the books courtesy of COVID, Grid book, swag book, william morris book",
      "Good day; coffee and sandwich in MokaPot House; coded a lot; good exercise; good reading",
      "Lots of good exercise; fixing Maras jacket pocket; listening to Stephen Sondheim documentaries",
      "working in West Land talking about the Elizabeth line, smashing through coding, thinking about Shala brand with beauty",
      "Nice connection with Louise; going to Abel Selaocoe with Matt, Mara and Em; that pizza",
      "seeing the broken window at Westland, great midday chat with Toby, good afternoon chat with Will, evening out in China town with Petal <3",
      "Putting bricks down left right and centre; sitting in the back garden with the sushi",
      "Tasting in Monicas garden (her dog, and spraying the bottle everywhere), fun at football (feeling good in possession)",
      "Up early at Maccers - good work; good chat to Paul about Shala; nice to get paid by Refill; spirtual teaching on the label front; nice to watch race with beauty",
      "Joy of a lifetime stuff - hugging Nana, talking to Caspar, hearing the kids laugh, watching them express themselves, spending time together",
      "Going to Hucks, and going to Beaten by a Whisker; Making some good progress, generally enjoying exploring by myself",
      "Great work session pre-work. Solid work day. Good podcast listening with Chris Kamara, Joe Wicks and the notonthehighstreet woman. Good progress from beauty.",
      "good play on codepen in the morning; solid workday; nice chat with Skipp dev guy in evening; looking at leftist stickers ",
      "Nice to really move Shala along; listened to some strong John Hegarty, Rory and Jacob Collier talks.",
      "Cycling in the rain; having some interesting ideas; tackling the day well; that chat with Jamie and Louise; having a good last hour; lasagne and potato salad; reading Status Anxiety and the book of quotes",
    ];
    let leaveBehindArr = [
      "being trepidatious about anything. You can do it, or survive the reality of not doing it",
      "Rattling my brain through phone; never worth it",
      "any performance anxiety; just break it down and enjoy it one moment at a time. What are you rushing for?",
      "Worry about time passing by / not getting up early; you can never cheat the about of rest you need.",
      "Worrying about anything; just focus on things you can control and control them until they are controlled",
      "Phone vortex detracting from meditation time.",
      "Worrying like a people pleaser.",
      "being short on patience generally.",
      "any anxiety",
      "Fatigue; just breathe deeply to reset",
      "having any anxiety about anything; see it as an opportunity for growth and learning.",
      "being envious or resentful of other peoples achievements.",
      "not priming the day earlier for clear thought throughout.",
      "worrying about what anyone else thinks",
    ];

    let randomArraySelector = (arr) => {
      let num;

      num = Math.floor(Math.random() * arr.length);

      return num;
    };

    if (clickCounter === 7) {
    } else if (clickCounter === 8) {
    }
  };

  const [cursorState, setCursorState] = useState();
  const [cursorDisplayState, setCursorDisplayState] = useState();

  const cursorDisplayHide = () => {
    setCursorDisplayState((cursorDisplayState) => !cursorDisplayState);
  };

  const cursorChangeName = () => {
    setCursorState((cursorState) => "John Doer");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeDOB = () => {
    setCursorState((cursorState) => "01/01/2000");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeValuesOne = () => {
    setCursorState(
      (cursorState) =>
        "Effort: Honest, Smart, Focused Application of Time, Energy and Attention"
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeValuesTwo = () => {
    setCursorState(
      (cursorState) =>
        "Attitude: A joyful and mindful perspective in the context of daily courage."
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeValuesThree = () => {
    setCursorState(
      (cursorState) => "Character: Resolute in adversity, composed in victory."
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeValuesFour = () => {
    setCursorState(
      (cursorState) =>
        "Visionary: Make the difference that is written on your bones."
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeValuesFive = () => {
    setCursorState(
      (cursorState) =>
        "Love: Unconditionally Support Other People Whenever Possible."
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeVisionFive = () => {
    setCursorState((cursorState) => "Move Abroad");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeVisionOne = () => {
    setCursorState((cursorState) => "Run a Marathon");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeVisionTwo = () => {
    setCursorState((cursorState) => "Read the Complete Works of Shakespeare");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeVisionThree = () => {
    setCursorState((cursorState) => "Get Promoted");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeVisionFour = () => {
    setCursorState((cursorState) => "Reach Enlightenment");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeIkigai = () => {
    setCursorState(
      (cursorState) => "To protect and nurture the rights of people everywhere"
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeQuote = () => {
    setCursorState(
      (cursorState) =>
        "A man that is young in years may be old in hours, if he have lost no time. - Francis Bacon"
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeMemory = () => {
    setCursorState((cursorState) => "Memory");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjOne = () => {
    setCursorState((cursorState) => "Objective #1");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjTwo = () => {
    setCursorState((cursorState) => "Objective #2");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjThree = () => {
    setCursorState((cursorState) => "Objective #3");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjFour = () => {
    setCursorState((cursorState) => "Objective #4");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjFive = () => {
    setCursorState((cursorState) => "Objective #5");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjSix = () => {
    setCursorState((cursorState) => "Objective #6");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjSeven = () => {
    setCursorState((cursorState) => "Objective #7");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjEight = () => {
    setCursorState((cursorState) => "Objective #8");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjNine = () => {
    setCursorState((cursorState) => "Objective #9");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeObjTen = () => {
    setCursorState((cursorState) => "Objective #10");
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorDisplayToggle = () => {
    setCursorDisplayState((cursorDisplayState) => !cursorDisplayState);
  };

  const valOne = 0.1;

  const valTwo = 0.5;

  const valThree = 1;

  const valFour = 0.8;

  const valFive = 0.6;

  const valSix = 0.4;

  const valSeven = 1;

  const valEight = 0.2;

  const valNine = 0.1;

  const valTen = 0.5;

  useEffect(() => {
    displayText();
  });

  return (
    <>
      <Helmet>
        <title>Learn | Today</title>
      </Helmet>
      <MouseTooltip
        visible={cursorDisplayState}
        offsetX={15}
        offsetY={10}
        zIndex={1000}
      >
        <h1 className="sickTooltip">{cursorState}</h1>
      </MouseTooltip>
      <main
        onClick={clickPlus}
        className="tutorialpage--container"
        style={{ width: `${vw - 48}`, height: "100vh" }}
      >
        <motion.div className="instruction" style={{ marginTop: "24px" }}>
          <div>
            <h2>{instructionState}</h2>
            <span>{supportingState}</span>
          </div>
          <div>
            <a href={"/"}>
              <FontAwesomeIcon id="crossIcon" icon={faX} />
            </a>
            <motion.a
              variants={showHideState}
              animate={buttonState ? "hide" : "show"}
            >
              <FontAwesomeIcon id="nextIcon" icon={faHandPointRight} />
            </motion.a>
            <motion.a
              href={userInfo ? "/today" : "/register"}
              variants={showHideState}
              animate={buttonState ? "show" : "hide"}
            >
              <FontAwesomeIcon
                id="nextIcon"
                icon={userInfo ? faCalendarPlus : faUserPlus}
              />
            </motion.a>
          </div>
        </motion.div>

        <motion.section
          className="createday container"
          variants={introState}
          initial="hidden"
          animate={show ? "open" : "closed"}
        >
          <>
            <motion.div
              variants={introState}
              initial="hidden"
              animate={isPrimes ? "open" : "closed"}
            >
              <div className="tile left" style={{ minWidth: "388px" }}>
                <div style={{ width: "100%" }}>
                  <div className="dateRow">
                    <label htmlFor="loggedDate">Diary Entry Date:</label>
                    <input
                      id="loggedDate"
                      type="date"
                      style={{ fontFamily: "monospace" }}
                      value={logDate}
                      onChange={(e) => setLogDate(e.target.value)}
                    />

                    <div className="mobileSaveDiv">
                      <div className="dock node save">
                        <FontAwesomeIcon
                          id="saveIcon"
                          className="icon"
                          icon={faFloppyDisk}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <hr className="horizontalLine" /> */}

                  <div className="scoring__row">
                    <div className="score__row">
                      <label
                        htmlFor="beforeScore"
                        style={
                          startScore ? { opacity: "1" } : { opacity: "0.5" }
                        }
                      >
                        Before filling in my NWD today, I feel...
                      </label>{" "}
                      <span>{startScore}</span>
                    </div>
                    <motion.input
                      id="beforeScore"
                      className="weekSlider"
                      type="range"
                      min="0"
                      max="5"
                      step="0.1"
                      value={hardData[0].startScore}
                      onChange={(e) => setStartScore(e.target.value)}
                      variants={activeState}
                      animate={beforeScore ? "open" : "closed"}
                    />
                  </div>
                </div>

                <div className="prime submission">
                  {thankYou ? (
                    <label htmlFor="thankYou" className="score label">
                      Today, I would like to say thank you to
                    </label>
                  ) : (
                    <></>
                  )}
                  <motion.textarea
                    id="thankYou"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={thankYou}
                    placeholder="Today, I would like to say thank you to..."
                    onChange={(e) => setThankYou(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={thankYouState ? "open" : "closed"}
                  />
                </div>
                <div className="prime submission">
                  {selfLess ? (
                    <label htmlFor="selfLess" className="score label">
                      I will prime myself for self-less exploration today by{" "}
                    </label>
                  ) : (
                    <></>
                  )}
                  <motion.textarea
                    id="selfLess"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={selfLess}
                    placeholder="I will prime myself for self-less exploration today by..."
                    onChange={(e) => setSelfLess(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={selfLessState ? "open" : "closed"}
                  />
                </div>

                <div className="prime submission">
                  {mindBody ? (
                    <label htmlFor="mindBody" className="score label">
                      I am pleased to live in my mind and body today because{" "}
                    </label>
                  ) : (
                    <></>
                  )}
                  <motion.textarea
                    id="mindBody"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={mindBody}
                    placeholder="I am pleased to live in my mind and body today because..."
                    onChange={(e) => setMindBody(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={mindBodyState ? "open" : "closed"}
                  />
                </div>
              </div>
            </motion.div>

            <motion.div
              variants={introState}
              initial="hidden"
              animate={isMoments ? "open" : "closed"}
            >
              <div className="tile centre" style={{ minWidth: "388px" }}>
                <div className="moment__submission">
                  <div className="score__row">
                    <div className="score__column">
                      {momentOneScore ? (
                        <>
                          <span>{momentOneScore}</span>
                          <span>------</span>
                          <span>10</span>
                        </>
                      ) : (
                        <>
                          <span style={{ opacity: "0.5" }}>0.0</span>
                          <span
                            style={{ opacity: "0.5", fontFamily: "futura" }}
                          >
                            ------
                          </span>
                          <span style={{ opacity: "0.5" }}>10</span>
                        </>
                      )}
                    </div>
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentOne}
                      autoComplete="off"
                      placeholder="Today's first moment is..."
                      onChange={(e) => setMomentOne(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={momentOneScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentOneScore}
                    onChange={(e) => setMomentOneScore(e.target.value)}
                    variants={activeState}
                    animate={momentActive ? "open" : "closed"}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row">
                    <div className="score__column">
                      {momentTwoScore ? (
                        <>
                          <span>{momentTwoScore}</span>
                          <span>------</span>
                          <span>10</span>
                        </>
                      ) : (
                        <>
                          <span style={{ opacity: "0.5" }}>0.0</span>
                          <span
                            style={{ opacity: "0.5", fontFamily: "futura" }}
                          >
                            ------
                          </span>
                          <span style={{ opacity: "0.5" }}>10</span>
                        </>
                      )}
                    </div>
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentTwo}
                      autoComplete="off"
                      placeholder="Today's second moment is..."
                      onChange={(e) => setMomentTwo(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={momentTwoScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentTwoScore}
                    onChange={(e) => setMomentTwoScore(e.target.value)}
                    variants={activeState}
                    animate={momentActive ? "open" : "closed"}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row">
                    <div className="score__column">
                      {momentThreeScore ? (
                        <>
                          <span>{momentThreeScore}</span>
                          <span>------</span>
                          <span>10</span>
                        </>
                      ) : (
                        <>
                          <span style={{ opacity: "0.5" }}>0.0</span>
                          <span
                            style={{ opacity: "0.5", fontFamily: "futura" }}
                          >
                            ------
                          </span>
                          <span style={{ opacity: "0.5" }}>10</span>
                        </>
                      )}
                    </div>
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentThree}
                      autoComplete="off"
                      placeholder="Today's third moment is..."
                      onChange={(e) => setMomentThree(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={
                      momentThreeScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentThreeScore}
                    onChange={(e) => setMomentThreeScore(e.target.value)}
                    variants={activeState}
                    animate={momentActive ? "open" : "closed"}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row">
                    <div className="score__column">
                      {momentFourScore ? (
                        <>
                          <span>{momentFourScore}</span>
                          <span>------</span>
                          <span>10</span>
                        </>
                      ) : (
                        <>
                          <span style={{ opacity: "0.5" }}>0.0</span>
                          <span
                            style={{ opacity: "0.5", fontFamily: "futura" }}
                          >
                            ------
                          </span>
                          <span style={{ opacity: "0.5" }}>10</span>
                        </>
                      )}
                    </div>
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentFour}
                      autoComplete="off"
                      placeholder="Today's fourth moment is..."
                      onChange={(e) => setMomentFour(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={
                      momentFourScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFourScore}
                    onChange={(e) => setMomentFourScore(e.target.value)}
                    variants={activeState}
                    animate={momentActive ? "open" : "closed"}
                  />
                </div>

                <div className="moment__submission">
                  <div className="score__row">
                    <div className="score__column">
                      {momentFiveScore ? (
                        <>
                          <span>{momentFiveScore}</span>
                          <span>------</span>
                          <span>10</span>
                        </>
                      ) : (
                        <>
                          <span style={{ opacity: "0.5" }}>0.0</span>
                          <span
                            style={{ opacity: "0.5", fontFamily: "futura" }}
                          >
                            ------
                          </span>
                          <span style={{ opacity: "0.5" }}>10</span>
                        </>
                      )}
                    </div>
                    <textarea
                      className="formInput moment"
                      wrap="soft"
                      value={momentFive}
                      autoComplete="off"
                      placeholder="Today's fifth moment is..."
                      onChange={(e) => setMomentFive(e.target.value)}
                    />
                  </div>
                  <motion.input
                    className={
                      momentFiveScore ? "weekSlider" : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFiveScore}
                    onChange={(e) => setMomentFiveScore(e.target.value)}
                    variants={activeState}
                    animate={momentActive ? "open" : "closed"}
                  />
                </div>
              </div>
            </motion.div>

            <motion.div
              variants={introState}
              initial="hidden"
              animate={isEvenings ? "open" : "closed"}
            >
              <div className="tile right" style={{ minWidth: "388px" }}>
                <div className="review submission">
                  {/* <h6 className="score label">The memories from today that will capture it forever <input className="formInput" type="text" placeholder="..."></input></h6> */}
                  {rememberToday ? (
                    <label htmlFor="rememberToday" className="score label">
                      The memories from today that will capture it forever
                      are...
                    </label>
                  ) : (
                    <></>
                  )}
                  <motion.textarea
                    id="rememberToday"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={rememberToday}
                    placeholder="The memories from today that will capture it forever are..."
                    onChange={(e) => setRememberToday(e.target.value)}
                    rows={4}
                    variants={activeState}
                    initial="hidden"
                    animate={rememberTodayState ? "open" : "closed"}
                  />
                </div>
                <div className="review submission">
                  {leaveBehind ? (
                    <label htmlFor="leaveBehind" className="score label">
                      The thoughts and feelings from today I would like to leave
                      behind are...
                    </label>
                  ) : (
                    <></>
                  )}
                  <motion.textarea
                    id="leaveBehind"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={leaveBehind}
                    placeholder="The thoughts and feelings from today I would like to leave behind are..."
                    onChange={(e) => setLeaveBehind(e.target.value)}
                    rows={4}
                    variants={activeState}
                    initial="hidden"
                    animate={leaveBehindState ? "open" : "closed"}
                  />
                </div>
                <div className="scoring__row" style={{ borderBottom: "none" }}>
                  <div className="score__row">
                    <label
                      htmlFor="endScore"
                      style={endScore ? { opacity: "1" } : { opacity: "0.5" }}
                    >
                      After the day described above, I feel...{" "}
                    </label>{" "}
                    <span>{endScore}</span>
                  </div>
                  <motion.input
                    id="endScore"
                    className={endScore ? "weekSlider" : "weekSlider off"}
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                    value={endScore}
                    onChange={(e) => setEndScore(e.target.value)}
                    variants={activeState}
                    initial="hidden"
                    animate={finalScore ? "open" : "closed"}
                  />
                </div>
              </div>
            </motion.div>
          </>
        </motion.section>
      </main>
      <footer className="dock footer">
        <div className="dock container">
          <div className="dock node" data-tooltip="Start a New Day">
            <a href="/today">
              <FontAwesomeIcon
                id="openIcon"
                className="icon"
                icon={faCalendarPlus}
              />
            </a>
          </div>

          <div className="dock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className="dock node"
            data-tooltip="My Name"
            onClick={cursorChangeName}
          >
            <FontAwesomeIcon
              id="nameIcon"
              className="icon"
              icon={faSignature}
            />
          </div>

          <div
            className="dock node"
            data-tooltip="My Date of Birth"
            onClick={cursorChangeDOB}
          >
            <FontAwesomeIcon
              id="dobIcon"
              className="icon"
              icon={faCalendarDays}
            />
          </div>

          <div className="dockNodeWrapper">
            <div className="nodeRow">
              <div
                className={valuesShowState}
                data-tooltip="Value #1"
                onClick={cursorChangeValuesOne}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa1}
                />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Value #2"
                onClick={cursorChangeValuesTwo}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa2}
                />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Value #3"
                onClick={cursorChangeValuesThree}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa3}
                />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Value #4"
                onClick={cursorChangeValuesFour}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa4}
                />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Value #5"
                onClick={cursorChangeValuesFive}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa5}
                />
              </div>
            </div>
            <div
              className="dock node save"
              data-tooltip="My Values"
              onClick={valuesShow}
            >
              <FontAwesomeIcon
                id="saveIcon"
                className="icon"
                icon={faCompassDrafting}
              />
            </div>
          </div>

          <div className="dockNodeWrapper">
            <div className="nodeRow">
              <div
                className={visionShowState}
                data-tooltip="Vision #1"
                onClick={cursorChangeVisionOne}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa1}
                />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Vision #2"
                onClick={cursorChangeVisionTwo}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa2}
                />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Vision #2"
                onClick={cursorChangeVisionThree}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa3}
                />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Vision #2"
                onClick={cursorChangeVisionFour}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa4}
                />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Vision #2"
                onClick={cursorChangeVisionFive}
              >
                <FontAwesomeIcon
                  id="miniValuesIcon"
                  className="icon"
                  icon={fa5}
                />
              </div>
            </div>

            <div
              className="dock node save"
              data-tooltip="My Vision"
              onClick={visionShow}
            >
              <FontAwesomeIcon
                id="saveIcon"
                className="icon"
                icon={faGlasses}
              />
            </div>
          </div>

          <div
            className="dock node"
            data-tooltip="My Ikigai"
            onClick={cursorChangeIkigai}
          >
            <FontAwesomeIcon id="ikigaiIcon" className="icon" icon={faDna} />
          </div>
          <div
            className="dock node"
            data-tooltip="My Quote"
            onClick={cursorChangeQuote}
          >
            <FontAwesomeIcon
              id="quoteIcon"
              className="icon"
              icon={faQuoteLeft}
            />
          </div>
          <div
            className="dock node"
            data-tooltip="My Memories"
            onClick={cursorChangeMemory}
          >
            <FontAwesomeIcon
              id="memoryIcon"
              className="icon"
              icon={faLandmarkDome}
            />
          </div>
          <div
            className="dock node"
            data-tooltip="Hide Overlay"
            onClick={cursorDisplayToggle}
          >
            <FontAwesomeIcon id="openIcon" className="icon" icon={faEyeSlash} />
          </div>

          <div className="dock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valOne + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #1"
            onClick={cursorChangeObjOne}
          >
            <FontAwesomeIcon id="oneIcon" className="icon" icon={fa1} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valTwo + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #2"
            onClick={cursorChangeObjTwo}
          >
            <FontAwesomeIcon id="twoIcon" className="icon" icon={fa2} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valThree + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #3"
            onClick={cursorChangeObjThree}
          >
            <FontAwesomeIcon id="threeIcon" className="icon" icon={fa3} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valFour + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #4"
            onClick={cursorChangeObjFour}
          >
            <FontAwesomeIcon id="fourIcon" className="icon" icon={fa4} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valFive + 0.025}`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #5"
            onClick={cursorChangeObjFive}
          >
            <FontAwesomeIcon id="fiveIcon" className="icon" icon={fa5} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valSix + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #6"
            onClick={cursorChangeObjSix}
          >
            <FontAwesomeIcon id="sixIcon" className="icon" icon={fa6} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valSeven + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #7"
            onClick={cursorChangeObjSeven}
          >
            <FontAwesomeIcon id="sevenIcon" className="icon" icon={fa7} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valEight + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #8"
            onClick={cursorChangeObjEight}
          >
            <FontAwesomeIcon id="eightIcon" className="icon" icon={fa8} />
          </div>
          <div
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valNine + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #9"
            onClick={cursorChangeObjNine}
          >
            <FontAwesomeIcon id="nineIcon" className="icon" icon={fa9} />
          </div>
          <div
            id="finalnode"
            className="dock node"
            style={{
              backgroundColor: `rgba(0, 74, 35, ${valTen + 0.025})`,
              border: "1px solid rgba(0, 74, 35,0.05)",
            }}
            data-tooltip="Objective #10"
            onClick={cursorChangeObjTen}
          >
            <FontAwesomeIcon
              id="tenIcon"
              className="icon"
              icon={fa1}
              style={{ marginRight: "3px" }}
            />
            <FontAwesomeIcon id="tenIcon" className="icon" icon={fa0} />
          </div>
          <div className="dock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>
          <div className="dock node save" data-tooltip="Click to Save">
            <FontAwesomeIcon
              id="saveIcon"
              className="icon"
              icon={faFloppyDisk}
            />
          </div>
        </div>
      </footer>
      <TestFooter />
    </>
  );
}
