// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Internal Components
import {
  CreateDayIconV2,
  SaveIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

function DayDock({ load, handleSubmit, dayList, today, previousDay }) {
  const navigate = useNavigate();

  const [saveTooltip, setSaveTooltip] = useState("");

  const handleTooltipChange = () => {
    setSaveTooltip("Saving...");
    setTimeout(() => {
      setSaveTooltip("Saved");
    }, 1200);
  };

  const [createDayState, setCreateDayState] = useState({
    active: true,
    text: "",
  });

  const [previousDayState, setPreviousDayState] = useState({
    active: true,
    text: "",
  });

  const createDayHandler = () => {
    if (today && !today.endScore) {
      setCreateDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = false;
        shallow.text = "Add your final score to move onto a new day.";
        return shallow;
      });
    } else {
      setCreateDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = true;
        shallow.text = "Ready to start a new day?";
        return shallow;
      });
    }
  };

  useEffect(() => {
    createDayHandler();
  }, [today]);

  const previousDayHandler = () => {
    if (!dayList) {
      setPreviousDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = false;
        shallow.text = "You are yet to log multiple days.";
        return shallow;
      });
    } else {
      setPreviousDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = true;
        shallow.text = "Look back at the previous day.";
        return shallow;
      });
    }
  };

  useEffect(() => {
    previousDayHandler();
  }, [dayList]);

  return (
    <div className="hidedock--container">
      <div className={`hidedock node`} data-tooltip={createDayState.text}>
        {createDayState.active ? (
          <a href={"/today"}>
            <CreateDayIconV2 />
          </a>
        ) : (
          <CreateDayIconV2 opacity="0.5" />
        )}
      </div>
      <div className={`hidedock node`}>
        {previousDayState.active ? (
          <a href={`/today${previousDay()}`}>
            <PreviousDay />
          </a>
        ) : (
          <PreviousDay opacity="0.5" />
        )}
      </div>
      <div
        className={`hidedock node_save ${load}`}
        data-tooltip={load === "success" ? saveTooltip : "Click to Save"}
        onClick={(e) => {
          handleSubmit(e);
          handleTooltipChange();
        }}
      >
        <SaveIcon load={load} />
      </div>
    </div>
  );
}

export default DayDock;
