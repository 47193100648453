import React, { useState, useEffect } from "react";

const useResponsiveState = () => {
  const getViewType = (width) => {
    if (width < 768) return "mobile";
    if (width < 1024) return "tablet";
    return "desktop";
  };

  const getInitialState = (viewType) => {
    switch (viewType) {
      case "mobile":
        return {
          /* mobile-specific initial state */
        };
      case "tablet":
        return {
          /* tablet-specific initial state */
        };
      default:
        return {
          /* desktop-specific initial state */
        };
    }
  };

  const [viewType, setViewType] = useState(() => {
    if (typeof window !== "undefined") {
      return getViewType(window.innerWidth);
    }
    return "desktop";
  });

  const [data, setData] = useState(() => getInitialState(viewType));

  useEffect(() => {
    const handleResize = () => {
      setViewType(getViewType(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { viewType, data };
};

export default useResponsiveState;
