// React
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// D3
import * as d3 from "d3";

// Styling
import "./feelingdoing.css";

const MomentByMoment = (props) => {
  const navigate = useNavigate();
  const d3Chart8 = useRef();
  const parseDate = d3.timeParse("%Y-%m-%d");
  let device = props.device;

  const getDataTransform = () => {
    props.data &&
      props.data.forEach((day) => {
        let sum =
          (day.momentOneScore !== undefined && parseFloat(day.momentOneScore)) +
          (day.momentTwoScore !== undefined && parseFloat(day.momentTwoScore)) +
          (day.momentThreeScore !== undefined &&
            parseFloat(day.momentThreeScore)) +
          (day.momentFourScore !== undefined &&
            parseFloat(day.momentFourScore)) +
          (day.momentFiveScore !== undefined &&
            parseFloat(day.momentFiveScore));

        day.total = sum;

        let count =
          (day.momentOneScore / day.momentOneScore === 1 ||
          day.momentOneScore == 0
            ? 1
            : 0) +
          (day.momentTwoScore / day.momentTwoScore == 1 ||
          day.momentTwoScore == 0
            ? 1
            : 0) +
          (day.momentThreeScore / day.momentThreeScore == 1 ||
          day.momentThreeScore == 0
            ? 1
            : 0) +
          (day.momentFourScore / day.momentFourScore === 1 ||
          day.momentFourScore == 0
            ? 1
            : 0) +
          (day.momentFiveScore / day.momentFiveScore === 1 ||
          day.momentFiveScore == 0
            ? 1
            : 0);
        day.tasksAttempted = count;

        let tcr = day.total / 10 / day.tasksAttempted;
        day.taskCompletionRate = tcr;
      });

    const tasksCompletedData =
      Array.isArray(props.data) &&
      props.data.map((each) => {
        let node = false;

        if (props.nodeSelected === each.logDate.slice(0, 10)) {
          node = true;
        }

        return {
          id: each._id,
          date: parseDate(each.logDate.slice(0, 10)),
          es: each.endScore,
          total: each.total,
          node: node,
        };
      });

    const tcDataFinal = tasksCompletedData.sort((a, b) => a.date - b.date);

    if (tcDataFinal.length > 5) {
      let tcDataLength = tcDataFinal.length - 1;

      if (tcDataFinal[tcDataLength].es === undefined) {
        tcDataFinal.pop();
      }
    }

    let meanMomentOne =
      Array.isArray(props.data) &&
      Math.round(
        d3.mean(props.data, function (d) {
          return d.momentOneScore;
        }) * 10
      ) /
        10 /
        10;
    let meanMomentTwo =
      Array.isArray(props.data) &&
      Math.round(
        d3.mean(props.data, function (d) {
          return d.momentTwoScore;
        }) * 10
      ) /
        10 /
        10;
    let meanMomentThree =
      Array.isArray(props.data) &&
      Math.round(
        d3.mean(props.data, function (d) {
          return d.momentThreeScore;
        }) * 10
      ) /
        10 /
        10;
    let meanMomentFour =
      Array.isArray(props.data) &&
      Math.round(
        d3.mean(props.data, function (d) {
          return d.momentFourScore;
        }) * 10
      ) /
        10 /
        10;
    let meanMomentFive =
      Array.isArray(props.data) &&
      Math.round(
        d3.mean(props.data, function (d) {
          return d.momentFiveScore;
        }) * 10
      ) /
        10 /
        10;

    return {
      "Moment One": meanMomentOne,
      "Moment Two": meanMomentTwo,
      "Moment Three": meanMomentThree,
      "Moment Four": meanMomentFour,
      "Moment Five": meanMomentFive,
    };
  };

  useEffect(() => {
    let tcData = getDataTransform();

    const margin = { top: 24, right: 36, bottom: 48, left: 80 };
    const width =
      parseInt(d3.select(`#d3demo8${device}`).style("width")) -
      margin.left -
      margin.right;
    const height =
      parseInt(d3.select(`#d3demo8${device}`).style("height")) -
      margin.top -
      margin.bottom;

    // Set up chart
    const svg = d3
      .select(d3Chart8.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Extract values from the data object
    var values = Object.values(tcData);

    // Find the maximum value using d3.max
    var maxValue = d3.max(values);

    // Create the x and y scales
    var xScale = d3
      .scaleBand()
      .domain(Object.keys(tcData))
      .range([0, width])
      .padding(0.1);

    var yScale = d3.scaleLinear().domain([0, 1]).range([height, 0]);

    const Tooltip4 = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("border", "2px solid #004a22")
      .style("border-radius", "2.5px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover1 = function (d) {
      Tooltip4.style("opacity", 1);

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)")
        .style("cursor", "pointer")
        .style("stroke-width", "2");
    };

    const mousemove1 = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo8${device}`));

      Tooltip4.text(`${(d[1] * 100).toFixed(1)}%`)
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "#004a22");
    };

    var mouseleave1 = function (d) {
      Tooltip4.style("opacity", 0);

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)")
        .style("cursor", "auto")
        .style("stroke-width", "1");
    };

    // Create the x-axis
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .style("font-family", "Futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 200);

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 12.5) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Moment Number");

    // Create the y-axis
    svg
      .append("g")
      .call(d3.axisLeft(yScale).tickFormat(d3.format(".0%")))
      .style("font-family", "futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 600);

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Efficiency");

    // Create the bars
    svg
      .selectAll(".bar")
      .data(Object.entries(tcData))
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", function (d) {
        return xScale(d[0]);
      })
      .attr("width", xScale.bandwidth())
      .attr("y", function (d) {
        return yScale(d[1]);
      })
      .attr("height", function (d) {
        return height - yScale(d[1]);
      })
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners (ry is for
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (d[1] === maxValue) {
          return "rgba(103, 36, 131,1)"; // Change color for values within range
        } else {
          return "rgba(0,74,34,1)"; // Default color for other values
        }
      })
      .style("stroke", "#e2dfcf")
      .style("stroke-width", "1")
      .style("opacity", 1)
      .style("z-index", "1")
      .on("mouseover", mouseover1)
      .on("mousemove", mousemove1)
      .on("mouseleave", mouseleave1);
  }, [props.nodeSelected]);

  return (
    <div
      id={`d3demo8${device}`}
      style={props.graphWidth && { width: `${props.graphWidth}px` }}
    >
      <svg ref={d3Chart8}></svg>
    </div>
  );
};

export default MomentByMoment;
