// React
import React, { useState, useEffect, useRef } from "react";

// External Components
import styled from "@xstyled/styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faSquareCaretDown,
  faSquareCaretUp,
  faSquareXmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./Dropable";
import isUrl from "../../../../utils/UtilityFunctions";

// Internal Components
import { borderRadius, grid } from "../Constants";
import Callout from "./Callout";
import Input from "./Input";

const getBackgroundColor = (isdragging, isGroupedOver, authorColors) => {
  if (isdragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return "#004a22";
  }

  return "#f5f5f5";
};

const imageSize = 40;

const CloneBadge = styled.div`
  background: #79f2c0;
  bottom: ${grid / 2}px;
  border: 2px solid #57d9a3;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);
  height: ${imageSize}px;
  width: ${imageSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.a`
  user-select: none;
  text-decoration: none;
  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #004a22;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  /* flexbox */
  display: flex;
`;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

function QuoteItem({
  task,
  isdragging,
  isGroupedOver,
  provided,
  style,
  isClone,
  subTaskIndex,
  title,
  overlay,
  active,
  onDragEndSteps,
  addStep,
  handleScoreChange,
  handleUrgencyChange,
  toggleSubTask,
  toggleStep,
  toggleOverlay,
  updateDescription,
  updateStep,
  updateNotes,
  deleteStep,
  deleteSubtask,
  deleteObjective,
}) {
  const [stepIndex, setStepIndex] = useState();
  const [warning, setWarning] = useState(false);

  const textareaRef = useRef(null);

  // Function to auto resize the textarea
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      let scrollHeight = textareaRef.current.scrollHeight;
      let clientHeight = textareaRef.current.clientHeight;
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.minHeight = "12px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  // UseEffect to call autoResizeTextarea when the task description changes
  useEffect(() => {
    autoResizeTextarea();
  }, [task.description]);

  const [calloutActive, setCalloutActive] = useState(false);

  const [displayState, setDisplayState] = useState({
    callout: false,
    deadline: task.deadline ? true : false,
    urgency: task.urgency ? true : false,
  });

  const handleDisplayChange = (e, optionalKey) => {
    let eventData = e.currentTarget.dataset;
    let displayName = e.target.name ? e.target.name : eventData.displayName;

    if (displayName === "remove") {
      if (optionalKey) {
        setDisplayState((prevState) => ({
          ...prevState,
          [optionalKey]: false,
        }));
      } else {
        setDisplayState((prevState) => ({
          ...prevState,
          [displayName]: false,
        }));
      }
    } else {
      setDisplayState((prevState) => ({
        ...prevState,
        [displayName]: !prevState[displayName],
      }));
    }
  };

  return (
    <>
      <Container
        isdragging={isdragging}
        isGroupedOver={isGroupedOver}
        isClone={isClone}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getStyle(provided, style)}
        data-is-dragging={isdragging}
        data-testid={task.id}
        data-index={subTaskIndex}
        // aria-label={`${ta.author.name} quote ${quote.content}`}
      >
        {isClone ? <CloneBadge>Clone</CloneBadge> : null}

        <Input
          task={task}
          subTaskIndex={subTaskIndex}
          title={title}
          display={displayState}
          toggleSubTask={toggleSubTask}
          toggleOverlay={toggleOverlay}
          updateDescription={updateDescription}
          handleCalloutDisplay={handleDisplayChange}
          handleUrgencyChange={handleUrgencyChange}
          handleScoreChange={handleScoreChange}
        />
      </Container>
      <Callout
        id={task.id}
        active={active}
        steps={task.steps}
        overlay={overlay}
        calloutActive={displayState.callout}
        stepsLength={task.steps.length}
        subTaskIndex={subTaskIndex}
        description={task.description}
        notes={task.notes}
        columnId={title}
        onDragEndSteps={onDragEndSteps}
        addStep={addStep}
        toggleStep={toggleStep}
        toggleOverlay={toggleOverlay}
        updateDescription={updateDescription}
        updateStep={updateStep}
        updateNotes={updateNotes}
        deleteStep={deleteStep}
        deleteSubtask={deleteSubtask}
        deleteObjective={deleteObjective}
        handleCalloutDisplay={handleDisplayChange}
      />
    </>
  );
}

export default React.memo(QuoteItem);
