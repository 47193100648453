// React
import React, { useState } from "react";

// Internal Components
import {
  ValuesIcon,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  VitIcon,
  PreviousDay,
} from "../../components/Icons/Icon";
import YearTile from "./YearTile";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

// Styling

function YearMap({ year, handleChange }) {
  const [mapState, setMapState] = useState([
    {
      tile_name: "name",
      icon: <VitIcon />,
    },
    {
      tile_name: "birthday",
      icon: <DobIcon />,
    },
    {
      tile_name: "values",
      icon: <ValuesIcon />,
    },
    {
      tile_name: "vision",
      icon: <VisionIcon />,
    },
    {
      tile_name: "ikigai",
      icon: <IkigaiIcon />,
    },
    {
      tile_name: "continuation",
      icon: <PreviousDay />,
    },
    {
      tile_name: "quote",
      icon: <QuoteIconV2 />,
    },
    {
      tile_name: "memory",
      icon: <MemoryIcon />,
    },
  ]);

  // console.log("mapState", mapState);
  // console.log("yearMap", year);

  return (
    <>
      <div id="yeartest">
        {mapState?.map((tile, index) => (
          <YearTile
            key={index}
            tile_name={tile.tile_name}
            value={year[tile.tile_name]}
            handleChange={handleChange}
          >
            {tile.icon}
          </YearTile>
        ))}
      </div>
    </>
  );
}

export default YearMap;
