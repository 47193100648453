export const DAYS_CREATE_REQUEST = "DAYS_CREATE_REQUEST";
export const DAYS_CREATE_SUCCESS = "DAYS_CREATE_SUCCESS";
export const DAYS_CREATE_FAIL = "DAYS_CREATE_FAIL";

export const DAYS_LIST_REQUEST = "DAYS_LIST_REQUEST";
export const DAYS_LIST_SUCCESS = "DAYS_LIST_SUCCESS";
export const DAYS_LIST_FAIL = "DAYS_LIST_FAIL";

export const DAYS_LIST_REQUEST_ID = "DAYS_LIST_REQUEST_ID";
export const DAYS_LIST_SUCCESS_ID = "DAYS_LIST_SUCCESS_ID";
export const DAYS_LIST_FAIL_ID = "DAYS_LIST_FAIL_ID";

export const DAYS_UPDATE_REQUEST = "DAYS_UPDATE_REQUEST";
export const DAYS_UPDATE_SUCCESS = "DAYS_UPDATE_SUCCESS";
export const DAYS_UPDATE_FAIL = "DAYS_UPDATE_FAIL";

export const DAYS_SINGLE_REQUEST = "DAYS_SINGLE_REQUEST";
export const DAYS_SINGLE_SUCCESS = "DAYS_SINGLE_SUCCESS";
export const DAYS_SINGLE_FAIL = "DAYS_SINGLE_FAIL";

export const DAYS_DELETE_REQUEST = "DAYS_DELETE_REQUEST";
export const DAYS_DELETE_SUCCESS = "DAYS_DELETE_SUCCESS";
export const DAYS_DELETE_FAIL = "DAYS_DELETE_FAIL";
