// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

// User Actions
import { updateProfile } from "../../actions/userActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";

// Internal Components
import Header from "../../layouts/Header/Header";
import YearDock from "../../features/dock/YearDock";
import TileLoading from "../../components/Loading/TileLoading";
import TestFooter from "../../layouts/Footer/TestFooter";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import FAQ from "../../components/FAQ/FAQ";

// External Packages
import emailjs from "@emailjs/browser";
import { Card, Button, Row, Col, Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faEraser } from "@fortawesome/free-solid-svg-icons";
import * as d3 from "d3";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

// Stylesheet
import "./profile_menu.css";

export default function ProfileFAQScreen({ history }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  const [pageLoading, setPageLoading] = useState(true);

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  };

  useEffect(() => {
    loadingTimeout();
  });

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main className="profile__menu--screen">
        <FAQ />
      </main>
      <TestFooter />
    </>
  );
}
