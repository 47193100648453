import { createStore, combineReducers, applyMiddleware } from "redux";
// import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userRegisterReducer,
  userUpdateReducer,
  userUpdateSubscriptionReducer,
  userListReducer,
} from "./reducers/userReducers";
import {
  dayCreateReducer,
  dayListReducer,
  dayListIDReducer,
  daySingleReducer,
  dayUpdateReducer,
  dayDeleteReducer,
} from "./reducers/daysReducers";
import {
  quizCreateReducer,
  quizListReducer,
  quizUpdateReducer,
} from "./reducers/quizReducers";
import {
  yearCreateReducer,
  yearUpdateReducer,
  yearListReducer,
  valuesUpdateReducer,
  yearSingleReducer,
} from "./reducers/yearsReducers";
import {
  weeksCreateReducer,
  weekListReducer,
  weekUpdateReducer,
  weekSingleReducer,
} from "./reducers/weekReducers";
import {
  debriefCreateReducer,
  debriefListReducer,
  debriefUpdateReducer,
  debriefDeleteReducer,
} from "./reducers/debriefsReducers";

const reducer = combineReducers({
  yearList: yearListReducer,
  userList: userListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userUpdateSubscription: userUpdateSubscriptionReducer,
  dayList: dayListReducer,
  dayListID: dayListIDReducer,
  dayCreate: dayCreateReducer,
  daySingle: daySingleReducer,
  dayDelete: dayDeleteReducer,
  quizCreate: quizCreateReducer,
  quizList: quizListReducer,
  quizUpdate: quizUpdateReducer,
  dayUpdate: dayUpdateReducer,
  yearSingle: yearSingleReducer,
  yearCreate: yearCreateReducer,
  yearUpdate: yearUpdateReducer,
  valuesUpdate: valuesUpdateReducer,
  weekCreate: weeksCreateReducer,
  weekList: weekListReducer,
  weekUpdate: weekUpdateReducer,
  weekSingle: weekSingleReducer,
  debriefList: debriefListReducer,
  debriefCreate: debriefCreateReducer,
  debriefUpdate: debriefUpdateReducer,
  debriefDelete: debriefDeleteReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// const store = configureStore({
//   reducer,
//   middleware: composeWithDevTools(applyMiddleware(...middleware)),
//   preLoadedState: initialState,
// });

export default store;
