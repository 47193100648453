// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

// Import Actions
import { listYears, updateYearActionValues } from "../../actions/yearActions";

// Internal Components
import Header from "../../layouts/Header/Header";
import { ValuesIcon } from "../../components/Icons/Icon";

// External Packages
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faCircleMinus,
  faBullseye,
} from "@fortawesome/free-solid-svg-icons";

// Styling
import "../../screens/Values/values.css";

function Intro(history) {
  const [myValues_1, setMyValues_1] = useState("");
  const [myValues_1_Text, setMyValues_1_Text] = useState("");
  const [myValues_2, setMyValues_2] = useState("");
  const [myValues_2_Text, setMyValues_2_Text] = useState("");
  const [myValues_3, setMyValues_3] = useState("");
  const [myValues_3_Text, setMyValues_3_Text] = useState("");
  const [myValues_4, setMyValues_4] = useState("");
  const [myValues_4_Text, setMyValues_4_Text] = useState("");
  const [myValues_5, setMyValues_5] = useState("");
  const [myValues_5_Text, setMyValues_5_Text] = useState("");
  const [iconState, setIconState] = useState(faBullseye);
  const [yourName, setYourName] = useState();
  const [birthDate, setBirthDate] = useState();
  const [myVision_1, setMyVision_1] = useState();
  const [myVision_2, setMyVision_2] = useState();
  const [myVision_3, setMyVision_3] = useState();
  const [myVision_4, setMyVision_4] = useState();
  const [myVision_5, setMyVision_5] = useState();
  const [whyNWD, setWhyNWD] = useState();
  const [myIkigai, setMyIkigai] = useState();
  const [navigationalQuote, setNavigationalQuote] = useState();

  const [inputValues_1, setInputValues_1] = useState(false);
  const [inputValues_2, setInputValues_2] = useState(false);
  const [inputValues_3, setInputValues_3] = useState(false);
  const [inputValues_4, setInputValues_4] = useState(false);
  const [inputValues_5, setInputValues_5] = useState(false);
  const [inputValues_1_Text, setInputValues_1_Text] = useState(false);
  const [inputValues_2_Text, setInputValues_2_Text] = useState(false);
  const [inputValues_3_Text, setInputValues_3_Text] = useState(false);
  const [inputValues_4_Text, setInputValues_4_Text] = useState(false);
  const [inputValues_5_Text, setInputValues_5_Text] = useState(false);

  const [ispageState, setIsPageState] = useState(true);
  const [isclearState, setIsClearState] = useState(true);
  const [isScroll, setIsScrollState] = useState(false);
  const [isScroll2, setIsScrollState2] = useState(false);
  const [isTitle, setIsTitle] = useState(true);
  const [isTitle2, setIsTitle2] = useState(true);
  const [isIntro, setIsIntro] = useState(true);
  const [isExplanation, setIsExplanation] = useState(false);
  const [isFoundation, setIsFoundation] = useState(false);
  const [isConsistency, setIsConsistency] = useState(false);
  const [isExperience, setIsExperience] = useState(false);
  const [isSpecificity, setIsSpecificity] = useState(false);
  const [isDisplayContainer, setIsDisplayContainer] = useState(false);

  const [isFourthInput, setIsFourthInput] = useState(false);
  const [isFourthButton, setIsFourthButton] = useState(true);
  const [isFifthInput, setIsFifthInput] = useState(false);
  const [isFifthButton, setIsFifthButton] = useState(true);

  const [showTraits, setShowTraits] = useState(false);
  const [traitsButton, setTraitsButton] = useState(true);

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo, history]);

  const [yearMap, setYearMap] = useState("/year/create");

  const yearMapChange = () => {
    if (userInfo) {
      const id =
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map((year) => year._id);

      if (years && years.length >= 1) {
        setYearMap((yearMap) => `/year/${id}`);
      } else {
        setYearMap((yearMap) => `/year/create`);
      }
    } else {
      setYearMap("/register");
    }
  };

  useEffect(() => {
    yearMapChange();
  });

  const scrollState = {
    hidden: {
      display: "none",
    },
    scrollFlash: {
      display: "block",
      color: "whitesmoke",
      transition: {
        duration: 0.5,
        ease: "linear",
        repeat: 20,
      },
    },
  };

  const titleState = {
    titleOff: {
      display: "none",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        repeat: 5,
      },
    },
    titleOn: {
      display: "block",
      color: "#004a22",
      marginBottom: "12px",
      fontSize: "3em",
    },
  };

  const introStateMob = {
    hidden: {
      opacity: "0",
      width: "0%",
      display: "none",
      scale: 0.1,
    },
    open: {
      opacity: "1",
      width: "95%",
      display: "flex",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: "0",
      width: "0%",
      display: "none",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const explanationStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const foundationStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      width: "95%",
      scale: 1,
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0.1,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const consistencyStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const experienceStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const specificityStateMob = {
    hidden: {
      display: "none",
      width: "50%",
    },
    open: {
      display: "block",
      width: "95%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const finalDisplayStateMob = {
    hidden: {
      display: "none",
      width: "50%",
    },
    open: {
      display: "block",
      width: "95%",
      transition: {
        duration: 2,
        ease: "easeInOut",
        delay: 0.25,
      },
    },
    closed: {
      display: "none",
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
        delay: 0.25,
      },
    },
  };

  const introState = {
    hidden: {
      display: "none",
      scale: 0.1,
    },
    open: {
      display: "block",
      scale: 1,
      width: "auto",
      transition: {
        duration: 2.5,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const explanationState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "65%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const foundationState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const consistencyState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const experienceState = {
    hidden: {
      display: "none",
      scale: 0,
      x: "200%",
    },
    open: {
      display: "flex",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const specificityState = {
    hidden: {
      display: "none",
      width: "90%",
    },
    open: {
      display: "flex",
      width: "90%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      width: "90%",
      display: "none",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const finalDisplayState = {
    hidden: {
      display: "none",
      width: "90%",
    },
    open: {
      display: "flex",
      width: "90%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      width: "90%",
      display: "none",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const valueState = {
    hide: {
      display: "none",
    },
    show: {
      display: "block",
      whiteSpace: "normal",
      textAlign: "start",
      paddingRight: "6px",
    },
  };

  const valuesState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const testState = {
    hidden: {
      display: "none",
      width: "95%",
    },
    open: {
      display: "flex",
      width: "95%",
    },
    closed: {
      display: "none",
      width: "95%",
    },
  };

  const [widthState, setWidthState] = useState(20);

  const widthSetter = () => {
    if (myValues_4 && !myValues_5) {
      setWidthState(25);
    } else if (myValues_4 && myValues_5) {
      setWidthState(20);
    }
  };

  useEffect(() => {
    widthSetter();
  });

  return (
    <>
      {vw > 800 ? (
        <main style={{ height: "100vh", width: "100%" }}>
          {userInfo ? <Header variant="light" /> : <Header variant="light" />}
          <motion.div className="introStageMain">
            <motion.div
              className="introContainer"
              variants={introState}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial="hidden"
              animate={isIntro ? "open" : "closed"}
              onClick={() => {
                setIsIntro((isIntro) => false);
                setIsExplanation((isExplanation) => true);
              }}
            >
              <h1>Values.</h1>
              <p>A little process to help you think about what they are.</p>
              <hr></hr>
              <span>
                <strong>Click to get started.</strong>
              </span>
            </motion.div>

            <motion.div
              className="explanationContainer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial="hidden"
              animate={isExplanation ? "open" : "closed"}
              variants={explanationState}
              onClick={() => {
                setIsExplanation((isExplanation) => false);
                setIsFoundation((isFoundation) => !isFoundation);
                setIsTitle((isTitle) => false);
                setIsScrollState((isScroll) => true);
                setTimeout(() => {
                  setIsScrollState((isScroll) => false);
                }, 4000);
              }}
            >
              <motion.h1
                initial="hidden"
                animate={isScroll ? "scrollFlash" : "hidden"}
                variants={scrollState}
              >
                Scroll Down to the First Stage
              </motion.h1>
              <motion.div>
                <div className="explanationTitle">
                  <motion.h1
                    id="titleOn"
                    initial="titleOn"
                    animate={isTitle ? "titleOn" : "titleOff"}
                    variants={titleState}
                  >
                    How the process works
                  </motion.h1>
                </div>
                <motion.div>
                  <p>
                    I designed this process about 10 years ago whilst at
                    university. During my degree, I setup an ice hockey team and
                    whilst doing so instinctively gave it a set of team values.
                    The process of then embodying and being guided by these
                    values was hugely interesting and transformative for both me
                    and the team.
                  </p>
                  <p>
                    As a result of this, one day, I sat down and tried to think
                    about how anyone could think about producing a great set of
                    values, either for themselves as an individual or ones to to
                    be expressed within a team. Perhaps unsurprisingly, what I
                    created works a bit like a good ice hockey drill!
                  </p>
                  <p>
                    Over the next few steps, you are going to be invited to set
                    a foundation - a series of traits that are important to you
                    as an individual. From there, you will go through a series
                    of small progressions that will help you look at your
                    foundation from different perspectives.
                  </p>
                  <p>
                    At the end, you will have a set of clear values that you can
                    use as a filter through which to pass all kinds of decisions
                    in your day-to-day life.
                  </p>
                  <hr></hr>
                  <h4></h4>
                </motion.div>
                <span>
                  <strong>
                    Ready? Click on the box to create your foundation
                  </strong>
                </span>
              </motion.div>
            </motion.div>

            <motion.div
              className="foundationContainer"
              initial="hidden"
              animate={isFoundation ? "open" : "closed"}
              variants={foundationState}
            >
              <motion.h1
                initial="titleOn"
                animate={isTitle2 ? "titleOn" : "titleOff"}
                variants={titleState}
              >
                Laying the Foundation
              </motion.h1>
              <label htmlFor="action" className="instructionLabel">
                Instruction:
              </label>
              <h6 id="action" className="valuesInstruction">
                Write down 3 - 5{" "}
                <span
                  className="hoverHelp"
                  data-tooltip="A trait is a quality or attribute that a person represents through their actions that distinguishes them as an individual."
                >
                  traits
                </span>{" "}
                that you would like to embody simply because they are
                particularly important to you.
              </h6>
              <p className="staticHelp">
                A trait is a quality or attribute in a person. To embody a trait
                means to exhibit it through your actions strongly and naturally.
              </p>
              <motion.div style={{ marginTop: "12px" }} className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_1}
                  placeholder="My first value is..."
                  onChange={(e) => setMyValues_1(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_2}
                  placeholder="My second value is..."
                  onChange={(e) => setMyValues_2(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_3}
                  placeholder="My third value is..."
                  onChange={(e) => setMyValues_3(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFourthButton ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                />
                <input
                  style={{ fontStyle: "italic" }}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                  className="vpTextInput"
                  type="text"
                  placeholder="Add a fourth value?"
                  readOnly
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFourthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFourthButton((isFourthButton) => true);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_4}
                  placeholder="My fourth value is..."
                  onChange={(e) => {
                    setMyValues_4(e.target.value);
                    setWidthState((widthState) => 25);
                  }}
                />
              </motion.div>

              <motion.div
                animate={isFifthButton ? "hide" : "show"}
                variants={valuesState}
                className="fourFiveDiv"
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
                <input
                  className="vpTextInput"
                  style={{ fontStyle: "italic" }}
                  type="text"
                  placeholder="Add a fifth value?"
                  readOnly
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFifthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => false);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_5}
                  placeholder="My fifth value is..."
                  onChange={(e) => {
                    setMyValues_5(e.target.value);
                    setWidthState((widthState) => 20);
                  }}
                />
              </motion.div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  className="examplesButton"
                  data-tooltip="Honest, Respectful, Loving, Characterful, Resilient, Visionary, Brave, Friendly"
                >
                  Hover for Examples of Traits
                </Button>
                <Button
                  className="valuesButton"
                  onClick={() => {
                    setIsFoundation((isFoundation) => !isFoundation);
                    setIsConsistency((isConsistency) => !isConsistency);
                    setIsScrollState2((isScroll2) => true);
                    setIsTitle2((isTitle2) => false);
                    setTimeout(() => {
                      setIsScrollState2((isScroll2) => false);
                    }, 6000);
                  }}
                >
                  Ready? Let's move to the first progression!
                </Button>
              </div>
            </motion.div>

            <motion.div
              className="consistencyContainer"
              whileHover={{ scale: 1.05 }}
              initial="hidden"
              animate={isConsistency ? "open" : "closed"}
              variants={consistencyState}
            >
              <h1>Consistency is Key</h1>
              <label htmlFor="action" className="instructionLabel">
                Instruction:
              </label>
              <h6 id="action" className="valuesInstruction">
                For these traits that you have just written down to speak
                powerfully and positively to other people as well as have the
                greatest impact on your own wellbeing, you have to authentically
                embody them in every decision you make; every detail, every
                word, every moment.<br></br>
                <br></br>Does this knowledge change what you have written down?
                (Feel free to edit and tweak if so)
              </h6>
              <p className="staticHelp"></p>
              <motion.div style={{ marginTop: "12px" }} className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_1}
                  placeholder="My first value is..."
                  onChange={(e) => setMyValues_1(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_2}
                  placeholder="My second value is..."
                  onChange={(e) => setMyValues_2(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_3}
                  placeholder="My third value is..."
                  onChange={(e) => setMyValues_3(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFourthButton ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFourthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFourthButton((isFourthButton) => true);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_4}
                  placeholder="My fourth value is..."
                  onChange={(e) => setMyValues_4(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFifthButton ? "hide" : "show"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFifthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => false);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_5}
                  placeholder="My fifth value is..."
                  onChange={(e) => setMyValues_5(e.target.value)}
                />
              </motion.div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  className="examplesButton"
                  data-tooltip="A good set of values are part authentic and part aspirational. Don't worry if you think you'd struggle to embody all of them all of the time as you are now. It will be critical going forward but needn't hold you back today."
                >
                  Hover for Help
                </Button>
                <Button
                  className="valuesButton"
                  onClick={() => {
                    setIsConsistency((isConsistency) => !isConsistency);
                    setIsExperience((isExperience) => !isExperience);
                  }}
                >
                  Ready? Click to move on to the next stage.
                </Button>
              </div>
            </motion.div>

            <motion.div
              className="experienceContainer"
              whileHover={{ scale: 1.05 }}
              animate={isExperience ? "open" : "closed"}
              initial="hidden"
              variants={experienceState}
            >
              <h1>The Experience of Others</h1>
              <label htmlFor="action" className="instructionLabel">
                Instruction:
              </label>
              <h6 id="action" className="valuesInstruction">
                The traits you are considering not only have to optimise your
                performance but they also, when authentically and consistently
                embodied, need to contribute to a special experience and
                environment for others.<br></br>
                <br></br>How could you shape your value set to ensure this is
                accounted for?<br></br>(Have a tweak, have a play. Sit with the
                question for a minute)
              </h6>
              <p className="staticHelp"></p>
              <motion.div style={{ marginTop: "12px" }} className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_1}
                  placeholder="My first value is..."
                  onChange={(e) => setMyValues_1(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_2}
                  placeholder="My second value is..."
                  onChange={(e) => setMyValues_2(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_3}
                  placeholder="My third value is..."
                  onChange={(e) => setMyValues_3(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFourthButton ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFourthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFourthButton((isFourthButton) => true);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />

                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_4}
                  placeholder="My fourth value is..."
                  onChange={(e) => setMyValues_4(e.target.value)}
                />
              </motion.div>

              <motion.div
                animate={isFifthButton ? "hide" : "show"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFifthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => false);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_5}
                  placeholder="My fifth value is..."
                  onChange={(e) => setMyValues_5(e.target.value)}
                />
              </motion.div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  className="examplesButton"
                  data-tooltip="A useful question here is, 'if I was to embody these values every day with the same group of people, would they feel safe and inspired by my actions?"
                >
                  Hover for Help
                </Button>
                <Button
                  className="valuesButton"
                  onClick={() => {
                    setIsExperience((isExperience) => !isExperience);
                    setIsSpecificity((isSpecificity) => !isSpecificity);
                  }}
                >
                  Ready to move on? Click here
                </Button>
              </div>
            </motion.div>

            <motion.div
              className="specificityContainer"
              whileHover={{ scale: 1.05 }}
              animate={isSpecificity ? "open" : "closed"}
              variants={specificityState}
            >
              <h1>Adding Essential Clarity</h1>
              <label htmlFor="action" className="instructionLabel">
                Instruction:
              </label>
              <h6 id="action" className="valuesInstruction">
                When used by themselves, words can have many different
                interpretations and potential meanings. Yet you have pick the
                traits below because they mean something very specifically to
                your experience of life. <br></br>
                <br></br>Add a short phrase next to each trait that would help
                you explain it to someone else if they asked about your values.
              </h6>
              <p className="staticHelp"></p>

              <motion.div className="valuesBlock" style={{ marginTop: "12px" }}>
                <div
                  className="valuesIntBox desktop"
                  style={{ width: `${widthState}%` }}
                >
                  <input
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed #004a22",
                    }}
                    className="vpTextInput"
                    type="text"
                    value={myValues_1}
                    placeholder=""
                    onChange={(e) => setMyValues_1(e.target.value)}
                  />
                  <textarea
                    className="vpDescriptionInput"
                    style={{ height: "25vh", width: "100%" }}
                    type="text"
                    value={myValues_1_Text}
                    placeholder="Value #1 Description"
                    onChange={(e) => setMyValues_1_Text(e.target.value)}
                  />
                </div>

                <div
                  className="valuesIntBox desktop"
                  style={{ width: `${widthState}%` }}
                >
                  <input
                    type="text"
                    className="vpTextInput"
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed #004a22",
                    }}
                    value={myValues_2}
                    placeholder=""
                    onChange={(e) => setMyValues_2(e.target.value)}
                  />
                  <textarea
                    className="vpDescriptionInput"
                    type="text"
                    style={{ height: "25vh", width: "100%" }}
                    value={myValues_2_Text}
                    placeholder="Value #2 Description"
                    onChange={(e) => setMyValues_2_Text(e.target.value)}
                  />
                </div>

                <div
                  className="valuesIntBox desktop"
                  style={{ width: `${widthState}%` }}
                >
                  <input
                    type="text"
                    className="vpTextInput"
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed #004a22",
                    }}
                    value={myValues_3}
                    placeholder=""
                    onChange={(e) => setMyValues_3(e.target.value)}
                  />
                  <textarea
                    className="vpDescriptionInput"
                    type="text"
                    style={{ height: "25vh", width: "100%" }}
                    value={myValues_3_Text}
                    placeholder="Value #3 Description"
                    onChange={(e) => setMyValues_3_Text(e.target.value)}
                  />
                </div>

                {widthState == 25 ? (
                  <div
                    className="valuesIntBox desktop"
                    style={{ width: `${widthState}%` }}
                  >
                    <input
                      type="text"
                      className="vpTextInput"
                      style={{
                        width: "100%",
                        borderBottom: "1px dashed #004a22",
                      }}
                      value={myValues_4}
                      placeholder="Add a fourth value?"
                      onChange={(e) => setMyValues_4(e.target.value)}
                    />
                    <textarea
                      className="vpDescriptionInput"
                      type="text"
                      style={{ height: "25vh", width: "100%" }}
                      value={myValues_4_Text}
                      placeholder="Value #4 Description"
                      onChange={(e) => setMyValues_4_Text(e.target.value)}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {widthState == 20 ? (
                  <>
                    <div
                      className="valuesIntBox desktop"
                      style={{ width: `${widthState}%` }}
                    >
                      <input
                        type="text"
                        className="vpTextInput"
                        style={{
                          width: "100%",
                          borderBottom: "1px dashed #004a22",
                        }}
                        value={myValues_4}
                        placeholder="Add a fourth value?"
                        onChange={(e) => setMyValues_4(e.target.value)}
                      />
                      <textarea
                        className="vpDescriptionInput"
                        type="text"
                        style={{ height: "25vh", width: "100%" }}
                        value={myValues_4_Text}
                        placeholder="Value #4 Description"
                        onChange={(e) => setMyValues_4_Text(e.target.value)}
                      />
                    </div>
                    <div
                      className="valuesIntBox desktop"
                      style={{ width: `${widthState}%` }}
                    >
                      <input
                        type="text"
                        className="vpTextInput"
                        style={{
                          width: "100%",
                          borderBottom: "1px dashed #004a22",
                        }}
                        value={myValues_5}
                        placeholder="Add a fifth value?"
                        onChange={(e) => setMyValues_5(e.target.value)}
                      />
                      <textarea
                        className="vpDescriptionInput"
                        type="text"
                        style={{ height: "25vh", width: "100%" }}
                        value={myValues_5_Text}
                        placeholder="Value #5 Description"
                        onChange={(e) => setMyValues_5_Text(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </motion.div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ margin: "0 6px 0 0" }}
                    className="examplesButton"
                    data-tooltip="For example, one of my values is 'Characterful: Resolute in adversity, composed in victory'. This extra piece of context is critical in shaping my appreciation of what it means to have character, and what I would hope to communicate to others through my actions."
                  >
                    Hover for Help
                  </Button>
                  <span>|</span>
                  {/* <span style={{margin: '0 6px 0 6px'}}>Add New Values: </span>
                <select name="widthPicker" value={widthState} onChange={(e) => setWidthState(e.target.value)} >
                  <option value="33">3</option>
                  <option value="25">4</option>
                  <option value="20">5</option>
                </select> */}
                </div>
                <Button
                  className="valuesButton"
                  onClick={() => {
                    setIsSpecificity((isSpecificity) => false);
                    setIsDisplayContainer((isDisplayContainer) => true);
                  }}
                >
                  Let's take a little look.
                </Button>
              </div>
            </motion.div>

            <motion.div
              className="finalDisplayContainer"
              animate={isDisplayContainer ? "open" : "closed"}
              initial="hidden"
              variants={finalDisplayState}
            >
              <ValuesIcon />
              <h1>Your Values</h1>
              <p style={{ margin: "0 0 24px 0" }}>
                How do these feel? Continue to play with the traits and
                explanations. Then, when you are happy, add them into your
                'Year' view
              </p>

              <motion.div className="valuesBlock">
                <motion.div
                  className="valuesIntBox desktop"
                  style={{ width: `${widthState}%`, whiteSpace: "normal" }}
                >
                  <motion.h2
                    className="valueName"
                    animate={inputValues_1 ? "hide" : "show"}
                    variants={valueState}
                  >
                    {myValues_1}:{" "}
                  </motion.h2>

                  <motion.h6
                    style={{ fontFamily: "Courier, monospace" }}
                    className="valueExplanation"
                    animate={inputValues_1_Text ? "hide" : "show"}
                    variants={valueState}
                  >
                    {myValues_1_Text}
                  </motion.h6>
                </motion.div>

                <motion.div
                  className="valuesIntBox desktop"
                  style={{ width: `${widthState}%`, whiteSpace: "normal" }}
                >
                  <motion.h2
                    className="valueName"
                    animate={inputValues_2 ? "hide" : "show"}
                    variants={valueState}
                  >
                    {myValues_2}:{" "}
                  </motion.h2>

                  <motion.h6
                    style={{ fontFamily: "Courier, monospace" }}
                    className="valueExplanation"
                    animate={inputValues_2_Text ? "hide" : "show"}
                    variants={valueState}
                  >
                    {myValues_2_Text}
                  </motion.h6>
                </motion.div>

                <motion.div
                  className="valuesIntBox desktop"
                  style={{ width: `${widthState}%`, whiteSpace: "normal" }}
                >
                  <motion.h2
                    className="valueName"
                    animate={inputValues_3 ? "hide" : "show"}
                    variants={valueState}
                  >
                    {myValues_3}:{" "}
                  </motion.h2>

                  <motion.h6
                    style={{ fontFamily: "Courier, monospace" }}
                    className="valueExplanation"
                    animate={inputValues_3_Text ? "hide" : "show"}
                    variants={valueState}
                  >
                    {myValues_3_Text}
                  </motion.h6>
                </motion.div>

                {myValues_4 ? (
                  <motion.div
                    className="valuesIntBox desktop"
                    style={{ width: `${widthState}%`, whiteSpace: "normal" }}
                  >
                    <motion.h2
                      className="valueName"
                      animate={inputValues_4 ? "hide" : "show"}
                      variants={valueState}
                    >
                      {myValues_4}:{" "}
                    </motion.h2>

                    <motion.h6
                      style={{ fontFamily: "Courier, monospace" }}
                      className="valueExplanation"
                      animate={inputValues_4_Text ? "hide" : "show"}
                      variants={valueState}
                    >
                      {myValues_4_Text}
                    </motion.h6>
                  </motion.div>
                ) : null}

                {myValues_5 ? (
                  <motion.div
                    className="valuesIntBox desktop"
                    style={{ width: `${widthState}%`, whiteSpace: "normal" }}
                  >
                    <motion.h2
                      className="valueName"
                      animate={inputValues_5 ? "hide" : "show"}
                      variants={valueState}
                    >
                      {myValues_5}:{" "}
                    </motion.h2>

                    <motion.h6
                      style={{ fontFamily: "Courier, monospace" }}
                      className="valueExplanation"
                      animate={inputValues_5_Text ? "hide" : "show"}
                      variants={valueState}
                    >
                      {myValues_5_Text}
                    </motion.h6>
                  </motion.div>
                ) : null}
              </motion.div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  className="valuesButton"
                  onClick={() => {
                    setIsSpecificity((isSpecificity) => true);
                    setIsDisplayContainer((isDisplayContainer) => false);
                  }}
                >
                  Want to tweak a bit more? Click here to go back.
                </Button>

                <a href={yearMap}>
                  <Button
                    className="valuesButton"
                    onClick={() => {
                      localStorage.setItem("Values_1", myValues_1);
                      localStorage.setItem("Values_1_Text", myValues_1_Text);
                      localStorage.setItem("Values_2", myValues_2);
                      localStorage.setItem("Values_2_Text", myValues_2_Text);
                      localStorage.setItem("Values_3", myValues_3);
                      localStorage.setItem("Values_3_Text", myValues_3_Text);
                      localStorage.setItem("Values_4", myValues_4);
                      localStorage.setItem("Values_4_Text", myValues_4_Text);
                      localStorage.setItem("Values_5", myValues_5);
                      localStorage.setItem("Values_5_Text", myValues_5_Text);
                      setTimeout(() => {
                        navigate(`${yearMap}`);
                      }, 1000);
                    }}
                  >
                    Add your values to your year map.
                  </Button>
                </a>
              </div>
            </motion.div>
          </motion.div>
        </main>
      ) : (
        // ===========================================================================================
        <main style={{ height: "auto", width: "100%" }}>
          <Header variant="light" />
          <motion.div className="introStageMain">
            <motion.div
              className="introContainer"
              variants={introStateMob}
              initial="hidden"
              animate={isIntro ? "open" : "closed"}
              onClick={() => {
                setIsIntro((isIntro) => false);
                setIsExplanation((isExplanation) => true);
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <h1>Your Values.</h1>
                <div style={{ height: "24px", width: "24px" }}>
                  <ValuesIcon />
                </div>
              </div>

              <p style={{ width: "65%" }}>
                A little process to help you define about what they are.
              </p>
              <p style={{ fontWeight: "bold" }}>Tap to get started.</p>
            </motion.div>

            <motion.div
              className="explanationContainer"
              variants={explanationStateMob}
              initial="hidden"
              animate={isExplanation ? "open" : "closed"}
            >
              <motion.h1
                initial="hidden"
                animate={isScroll ? "scrollFlash" : "hidden"}
                variants={scrollState}
              >
                Scroll Down to the First Stage
              </motion.h1>
              <motion.div>
                <div className="explanationTitle">
                  <motion.h1
                    initial="titleOn"
                    animate={isTitle ? "titleOn" : "titleOff"}
                    variants={titleState}
                  >
                    The Process
                  </motion.h1>
                </div>
                <motion.div>
                  <motion.div>
                    <p>
                      I designed this process about 10 years ago. After setting
                      up an ice hockey team at university, and having
                      instinctively given it a set of team values, I felt the
                      immense power and positivity that can come from having
                      clear guiding principles.
                    </p>
                    <p>
                      I sat down one day and tried to think about how anyone
                      could systematically get the same feeling and value for
                      themselves, either as an individual or within a team.
                      Perhaps unsurprisingly, what I created works a bit like a
                      good ice hockey drill!
                    </p>
                    <p>
                      Over the next few steps, you are going to be invited to
                      set a foundation - a series of traits that are important
                      to you as an individual. From there, you will go through a
                      series of small progressions that will help you look at
                      your foundation from different perspectives.
                    </p>
                    <p>
                      At the end, you will have a set of clear values that you
                      can use as a filter through which to pass all kinds of
                      decisions in your day-to-day life.
                    </p>
                  </motion.div>
                </motion.div>
                <button
                  className="valuesButton"
                  onClick={() => {
                    setIsExplanation((isExplanation) => false);
                    setIsFoundation((isFoundation) => true);
                    setIsTitle((isTitle) => false);
                    setIsScrollState((isScroll) => true);
                    setTimeout(() => {
                      setIsScrollState((isScroll) => false);
                    }, 4000);
                  }}
                >
                  Click here to begin
                </button>
              </motion.div>
            </motion.div>

            <motion.div
              className="foundationContainer"
              initial="hidden"
              animate={isFoundation ? "open" : "closed"}
              variants={testState}
            >
              <motion.h1
                initial="hidden"
                animate={isScroll2 ? "scrollFlash" : "hidden"}
                variants={scrollState}
              >
                Scroll Up to Second Stage
              </motion.h1>

              <motion.h1
                initial="titleOn"
                animate={isTitle2 ? "titleOn" : "titleOff"}
                variants={titleState}
              >
                Laying the Foundation
              </motion.h1>
              <p>
                Write down 3 - 5 traits that you would like to embody as a
                person simply because they are particularly important to you.
              </p>
              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_1}
                  placeholder="My first value is..."
                  onChange={(e) => setMyValues_1(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_2}
                  placeholder="My second value is..."
                  onChange={(e) => setMyValues_2(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_3}
                  placeholder="My third value is..."
                  onChange={(e) => setMyValues_3(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFourthButton ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                />
                <input
                  style={{ fontStyle: "italic" }}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                  className="vpTextInput"
                  type="text"
                  placeholder="Add a fourth value?"
                  readOnly
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFourthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFourthButton((isFourthButton) => true);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_4}
                  placeholder="My fourth value is..."
                  onChange={(e) => setMyValues_4(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFifthButton ? "hide" : "show"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
                <input
                  style={{ fontStyle: "italic" }}
                  className="vpTextInput"
                  type="text"
                  placeholder="Add a fifth value?"
                  readOnly
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFifthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => false);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_5}
                  placeholder="My fifth value is..."
                  onChange={(e) => setMyValues_5(e.target.value)}
                />
              </motion.div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <motion.button
                  className="valuesButton"
                  animate={traitsButton ? "show" : "hide"}
                  variants={valuesState}
                  onClick={() => {
                    setShowTraits((showTraits) => true);
                    setTraitsButton((traitsButton) => false);
                  }}
                >
                  Examples of Traits
                </motion.button>
                <motion.div
                  animate={showTraits ? "show" : "hide"}
                  variants={valuesState}
                  onClick={() => {
                    setShowTraits((showTraits) => false);
                    setTraitsButton((traitsButton) => true);
                  }}
                >
                  <p>
                    Honest, Respectful, Loving, Characterful, Resilient,
                    Visionary, Brave, Friendly
                  </p>
                </motion.div>
                <Button
                  className="valuesButton"
                  style={{ width: "45%" }}
                  onClick={() => {
                    setIsFoundation((isFoundation) => false);
                    setIsConsistency((isConsistency) => true);
                  }}
                >
                  Ready? Let's move to the first progression!
                </Button>
              </div>
            </motion.div>

            <motion.div
              className="consistencyContainer"
              initial="hidden"
              animate={isConsistency ? "open" : "closed"}
              variants={testState}
            >
              <h1>Consistency is Key.</h1>
              <p>
                For these traits that you have just written down to speak
                powerfully and positively to other people, you have to
                authentically embody them in every decision you make; every
                detail, every word, every moment.
              </p>
              <p>
                Does this knowledge change what you have written down? (Feel
                free to edit and tweak if so)
              </p>
              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_1}
                  placeholder="My first value is..."
                  onChange={(e) => setMyValues_1(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_2}
                  placeholder="My second value is..."
                  onChange={(e) => setMyValues_2(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_3}
                  placeholder="My third value is"
                  onChange={(e) => setMyValues_3(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFourthButton ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFourthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFourthButton((isFourthButton) => true);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_4}
                  placeholder="My fourth value is..."
                  onChange={(e) => setMyValues_4(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFifthButton ? "hide" : "show"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFifthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => false);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_5}
                  placeholder="My fifth value is..."
                  onChange={(e) => setMyValues_5(e.target.value)}
                />
              </motion.div>

              <Button
                className="valuesButton"
                onClick={() => {
                  setIsConsistency((isConsistency) => false);
                  setIsExperience((isExperience) => true);
                }}
              >
                Next Stage
              </Button>
            </motion.div>

            <motion.div
              className="experienceContainer"
              animate={isExperience ? "open" : "closed"}
              initial="hidden"
              variants={testState}
            >
              <h1>The Experience of Others</h1>
              <p>
                The traits you are considering not only have to optimise your
                performance and communicate clearly to others but they also,
                when authentically and consistently embodied, need to contribute
                to a special experience and environment for others.
              </p>
              <p>
                Does that change what you have written down? (Have a tweak, have
                a play. Sit with the question for a minute)
              </p>
              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_1}
                  placeholder="My first value is..."
                  onChange={(e) => setMyValues_1(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_2}
                  placeholder="My second value is..."
                  onChange={(e) => setMyValues_2(e.target.value)}
                />
              </motion.div>

              <motion.div className="fourFiveDiv">
                <FontAwesomeIcon className="bullseyeIcon" icon={faBullseye} />
                <input
                  className="vpTextInput"
                  type="text"
                  value={myValues_3}
                  placeholder="My third value is..."
                  onChange={(e) => setMyValues_3(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFourthButton ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFifthButton((isFifthButton) => !isFifthButton);
                    setIsFourthButton((isFourthButton) => false);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFourthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFourthInput((isFourthInput) => !isFourthInput);
                    setIsFourthButton((isFourthButton) => true);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_4}
                  placeholder="My fourth value is..."
                  onChange={(e) => setMyValues_4(e.target.value)}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                animate={isFifthButton ? "hide" : "show"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="addIcon"
                  icon={faCirclePlus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => true);
                  }}
                />
              </motion.div>

              <motion.div
                className="fourFiveDiv"
                initial="hide"
                animate={isFifthInput ? "show" : "hide"}
                variants={valuesState}
              >
                <FontAwesomeIcon
                  className="minusIcon"
                  icon={faCircleMinus}
                  onClick={() => {
                    setIsFifthInput((isFifthInput) => !isFifthInput);
                    setIsFifthButton((isFifthButton) => false);
                  }}
                />
                <motion.input
                  className="vpTextInput"
                  type="text"
                  value={myValues_5}
                  placeholder="My fifth value is"
                  onChange={(e) => setMyValues_5(e.target.value)}
                />
              </motion.div>
              <Button
                className="valuesButton"
                onClick={() => {
                  setIsExperience((isExperience) => !isExperience);
                  setIsSpecificity((isSpecificity) => !isSpecificity);
                }}
              >
                Next Stage
              </Button>
            </motion.div>

            <motion.div
              className="specificityContainer"
              animate={isSpecificity ? "open" : "closed"}
              initial="hidden"
              variants={testState}
            >
              <h1>Adding Essential Clarity</h1>
              <p>
                Single words are inherently ambiguous, yet you have pick these
                traits because they mean something specifically to your
                experience of life.
              </p>
              <p>
                Add a short phrase next to each trait that would help you
                explain it to someone else.
              </p>

              <motion.div className="fourFiveDiv">
                <div className="valuesIntBox mobile">
                  <div
                    className="txtInput mobile"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      style={{
                        width: "100%",
                        borderBottom: "1px dashed #004a22",
                      }}
                      className="vpTextInput"
                      type="text"
                      value={myValues_1}
                      placeholder="My first value is..."
                      onChange={(e) => setMyValues_1(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <textarea
                      id="value1"
                      className="valueExplainer"
                      type="text"
                      value={myValues_1_Text}
                      placeholder="Value #1 Description"
                      onChange={(e) => setMyValues_1_Text(e.target.value)}
                    />
                  </div>
                </div>
              </motion.div>

              <motion.div className="fourFiveDiv">
                <div className="valuesIntBox mobile">
                  <div
                    className="txtInput mobile"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      style={{
                        width: "100%",
                        borderBottom: "1px dashed #004a22",
                      }}
                      className="vpTextInput"
                      type="text"
                      value={myValues_2}
                      placeholder="My second value is..."
                      onChange={(e) => setMyValues_2(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <textarea
                      id="value1"
                      className="valueExplainer"
                      type="text"
                      value={myValues_2_Text}
                      placeholder="Value #2 Description"
                      onChange={(e) => setMyValues_2_Text(e.target.value)}
                    />
                  </div>
                </div>
              </motion.div>

              <motion.div className="fourFiveDiv">
                <div className="valuesIntBox mobile">
                  <div
                    className="txtInput mobile"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      style={{
                        width: "100%",
                        borderBottom: "1px dashed #004a22",
                      }}
                      className="vpTextInput"
                      type="text"
                      value={myValues_3}
                      placeholder="My third value is..."
                      onChange={(e) => setMyValues_3(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <textarea
                      id="value1"
                      className="valueExplainer"
                      type="text"
                      value={myValues_3_Text}
                      placeholder="Value #3 Description"
                      onChange={(e) => setMyValues_3_Text(e.target.value)}
                    />
                  </div>
                </div>
              </motion.div>

              {widthState == 25 ? (
                <motion.div className="fourFiveDiv">
                  <div className="valuesIntBox mobile">
                    <div
                      className="txtInput mobile"
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <input
                        style={{
                          width: "100%",
                          borderBottom: "1px dashed #004a22",
                        }}
                        className="vpTextInput"
                        type="text"
                        value={myValues_4}
                        placeholder="My fourth value is..."
                        onChange={(e) => setMyValues_4(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <textarea
                        id="value1"
                        className="valueExplainer"
                        type="text"
                        value={myValues_4_Text}
                        placeholder="Value #4 Description"
                        onChange={(e) => setMyValues_4_Text(e.target.value)}
                      />
                    </div>
                  </div>
                </motion.div>
              ) : (
                <></>
              )}

              {widthState == 20 ? (
                <>
                  <motion.div className="fourFiveDiv">
                    <div className="valuesIntBox mobile">
                      <div
                        className="txtInput mobile"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            borderBottom: "1px dashed #004a22",
                          }}
                          className="vpTextInput"
                          type="text"
                          value={myValues_4}
                          placeholder="My fourth value is..."
                          onChange={(e) => setMyValues_4(e.target.value)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <textarea
                          id="value1"
                          className="valueExplainer"
                          type="text"
                          value={myValues_4_Text}
                          placeholder="Value #4 Description"
                          onChange={(e) => setMyValues_4_Text(e.target.value)}
                        />
                      </div>
                    </div>
                  </motion.div>
                  <motion.div className="fourFiveDiv">
                    <div className="valuesIntBox mobile">
                      <div
                        className="txtInput mobile"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            borderBottom: "1px dashed #004a22",
                          }}
                          className="vpTextInput"
                          type="text"
                          value={myValues_5}
                          placeholder="My fifth value is..."
                          onChange={(e) => setMyValues_5(e.target.value)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <textarea
                          id="value1"
                          className="valueExplainer"
                          type="text"
                          value={myValues_5_Text}
                          placeholder="Value #5 Description"
                          onChange={(e) => setMyValues_5_Text(e.target.value)}
                        />
                      </div>
                    </div>
                  </motion.div>
                </>
              ) : (
                <></>
              )}

              <Button
                className="valuesButton"
                onClick={() => {
                  setIsSpecificity((isSpecificity) => !isSpecificity);
                  setIsDisplayContainer(
                    (isDisplayContainer) => !isDisplayContainer
                  );
                }}
              >
                Let's take a little look.
              </Button>
            </motion.div>

            <motion.div
              className="finalDisplayContainer"
              animate={isDisplayContainer ? "open" : "closed"}
              initial="hidden"
              variants={finalDisplayStateMob}
            >
              <h1>Your Values</h1>
              <p>
                How do these feel? Continue to play with the traits and
                explanations. Then, when you are happy, add them into your
                'Year' view
              </p>

              <motion.div className="fourFiveDiv">
                <div className="valuesIntBox mobile">
                  <div
                    className="txtInput mobile"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      style={{
                        width: "100%",
                        borderBottom: "1px dashed #004a22",
                      }}
                      className="vpTextInput"
                      type="text"
                      value={myValues_1}
                      placeholder="My first value is..."
                      onChange={(e) => setMyValues_1(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <textarea
                      id="value1"
                      className="valueExplainer"
                      type="text"
                      value={myValues_1_Text}
                      placeholder="Value #1 Description"
                      onChange={(e) => setMyValues_1_Text(e.target.value)}
                    />
                  </div>
                </div>
              </motion.div>

              <motion.div className="fourFiveDiv">
                <div className="valuesIntBox mobile">
                  <div
                    className="txtInput mobile"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      style={{
                        width: "100%",
                        borderBottom: "1px dashed #004a22",
                      }}
                      className="vpTextInput"
                      type="text"
                      value={myValues_2}
                      placeholder="My second value is..."
                      onChange={(e) => setMyValues_2(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <textarea
                      id="value1"
                      className="valueExplainer"
                      type="text"
                      value={myValues_2_Text}
                      placeholder="Value #2 Description"
                      onChange={(e) => setMyValues_2_Text(e.target.value)}
                    />
                  </div>
                </div>
              </motion.div>

              <motion.div className="fourFiveDiv">
                <div className="valuesIntBox mobile">
                  <div
                    className="txtInput mobile"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      style={{
                        width: "100%",
                        borderBottom: "1px dashed #004a22",
                      }}
                      className="vpTextInput"
                      type="text"
                      value={myValues_3}
                      placeholder="My third value is..."
                      onChange={(e) => setMyValues_3(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <textarea
                      id="value1"
                      className="valueExplainer"
                      type="text"
                      value={myValues_3_Text}
                      placeholder="Value #3 Description"
                      onChange={(e) => setMyValues_3_Text(e.target.value)}
                    />
                  </div>
                </div>
              </motion.div>

              {widthState == 25 ? (
                <motion.div className="fourFiveDiv">
                  <div className="valuesIntBox mobile">
                    <div
                      className="txtInput mobile"
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <input
                        style={{
                          width: "100%",
                          borderBottom: "1px dashed #004a22",
                        }}
                        className="vpTextInput"
                        type="text"
                        value={myValues_4}
                        placeholder="My fourth value is..."
                        onChange={(e) => setMyValues_4(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <textarea
                        id="value1"
                        className="valueExplainer"
                        type="text"
                        value={myValues_4_Text}
                        placeholder="Value #4 Description"
                        onChange={(e) => setMyValues_4_Text(e.target.value)}
                      />
                    </div>
                  </div>
                </motion.div>
              ) : (
                <></>
              )}

              {widthState == 20 ? (
                <>
                  <motion.div className="fourFiveDiv">
                    <div className="valuesIntBox mobile">
                      <div
                        className="txtInput mobile"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            borderBottom: "1px dashed #004a22",
                          }}
                          className="vpTextInput"
                          type="text"
                          value={myValues_4}
                          placeholder="My fourth value is..."
                          onChange={(e) => setMyValues_4(e.target.value)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <textarea
                          id="value1"
                          className="valueExplainer"
                          type="text"
                          value={myValues_4_Text}
                          placeholder="Value #4 Description"
                          onChange={(e) => setMyValues_4_Text(e.target.value)}
                        />
                      </div>
                    </div>
                  </motion.div>
                  <motion.div className="fourFiveDiv">
                    <div className="valuesIntBox mobile">
                      <div
                        className="txtInput mobile"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            borderBottom: "1px dashed #004a22",
                          }}
                          className="vpTextInput"
                          type="text"
                          value={myValues_5}
                          placeholder="My fifth value is..."
                          onChange={(e) => setMyValues_5(e.target.value)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <textarea
                          id="value1"
                          className="valueExplainer"
                          type="text"
                          value={myValues_5_Text}
                          placeholder="Value #5 Description"
                          onChange={(e) => setMyValues_5_Text(e.target.value)}
                        />
                      </div>
                    </div>
                  </motion.div>
                </>
              ) : (
                <></>
              )}
              <div>
                <Button
                  className="valuesButton"
                  onClick={() => {
                    setIsSpecificity((isSpecificity) => true);
                    setIsDisplayContainer((isDisplayContainer) => false);
                  }}
                >
                  Make a few changes?
                </Button>
                <a href={yearMap}>
                  <Button
                    className="valuesButton"
                    onClick={() => {
                      localStorage.setItem("Values_1", myValues_1);
                      localStorage.setItem("Values_1_Text", myValues_1_Text);
                      localStorage.setItem("Values_2", myValues_2);
                      localStorage.setItem("Values_2_Text", myValues_2_Text);
                      localStorage.setItem("Values_3", myValues_3);
                      localStorage.setItem("Values_3_Text", myValues_3_Text);
                      localStorage.setItem("Values_4", myValues_4);
                      localStorage.setItem("Values_4_Text", myValues_4_Text);
                      localStorage.setItem("Values_5", myValues_5);
                      localStorage.setItem("Values_5_Text", myValues_5_Text);
                      setTimeout(() => {
                        navigate(`${yearMap}`);
                      }, 1000);
                    }}
                  >
                    Add your values to your year map.
                  </Button>
                </a>
              </div>
            </motion.div>
          </motion.div>
        </main>
      )}
    </>
  );
}

export default Intro;
