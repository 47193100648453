//React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";

//Internal Components
import {
  CreateDayIconV2,
  ValuesIcon,
  VitIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  QuestionIcon,
  SaveIcon,
} from "../../components/Icons/Icon";

// Styling
import "./dock.css";

export default function WeekDock({
  viewType,
  dockLoading,
  meyear,
  load,
  yearid,
  handleSubmit,
  handleMouseState,
}) {
  // Hooks
  const navigate = useNavigate();

  // State
  const [currentWindow, setCurrentWindow] = useState(window.location.href);
  const [viewWidth, setViewWidth] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );
  const [showDock, setShowDock] = useState(false);
  const [colorState, setColorState] = useState({
    one: "cls-1-invert",
    two: "cls-2-invert",
  });
  const [dockState, setDockState] = useState("disableddock");

  useEffect(() => {
    if (dockLoading) {
      setDockState("disableddock");
    } else {
      if (viewType === "mobile") {
        setDockState("mobiledock");
      } else {
        setDockState("jordandock");
      }
    }
  }, [dockLoading, viewType]);

  const showDockFunc = () => {
    setShowDock((showDock) => !showDock);
  };

  const [saveTooltip, setSaveTooltip] = useState("");

  const handleTooltipChange = () => {
    setSaveTooltip("Saving...");
    setTimeout(() => {
      setSaveTooltip("Saved");
    }, 1200);
  };

  const handleNav = (meyear) => {
    if (meyear) {
      navigate("/year/create");
    } else {
      navigate(`/year/${yearid}`);
    }
  };

  return (
    <div
      className={
        viewType === "mobile" ? "mobiledock--container" : `dock--container`
      }
    >
      {viewType !== "mobile" && (
        <>
          <div className={`${dockState} node`} data-tooltip="Start a New Week">
            <CreateDayIconV2 />
          </div>
          <div className="dock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>
        </>
      )}

      <div
        className={`${dockState} node`}
        data-tooltip={
          meyear && meyear.name && meyear.birthday
            ? "Me"
            : "Add your name and date of birth to your year map to access it in the dock."
        }
        onClick={() => {
          meyear && meyear.name && meyear.birthday
            ? handleMouseState("me")
            : handleNav(meyear.name);
        }}
        style={
          meyear && meyear.name && meyear.birthday ? null : { opacity: "0.5" }
        }
      >
        <VitIcon />
      </div>
      <div
        className={`${dockState} node`}
        data-tooltip={
          meyear && meyear.values
            ? "My Values"
            : "Add your values in your year map to access them in the dock."
        }
        onClick={() => {
          meyear && meyear.values
            ? handleMouseState("values")
            : handleNav(meyear.values.one);
        }}
        style={meyear && meyear.values ? null : { opacity: "0.5" }}
      >
        <ValuesIcon />
      </div>
      <div
        className={`${dockState} node`}
        data-tooltip={
          meyear && meyear.vision
            ? "My Vision"
            : "Add your vision to your year map to access it through the dock."
        }
        onClick={() => {
          meyear && meyear.vision
            ? handleMouseState("vision")
            : handleNav(meyear.vision.one);
        }}
        style={meyear && meyear.vision ? null : { opacity: "0.5" }}
      >
        <VisionIcon />
      </div>
      <div
        className={`${dockState} node`}
        data-tooltip={
          meyear && meyear.ikigai
            ? "My Ikigai"
            : "Add your ikigai to your year map to access it in the dock"
        }
        onClick={() => {
          meyear && meyear.vision
            ? handleMouseState("ikigai")
            : handleNav(meyear.ikigai);
        }}
        style={meyear && meyear.ikigai ? null : { opacity: "0.5" }}
      >
        <IkigaiIcon />
      </div>
      <div
        className={`${dockState} node`}
        data-tooltip={
          meyear && meyear.quote
            ? "My Favourite Quote"
            : "Add your favourite quote to your year map to access it in the dock."
        }
        onClick={() => {
          meyear && meyear.quote
            ? handleMouseState("quote")
            : handleNav(meyear.quote);
        }}
        style={meyear && meyear.quote ? null : { opacity: "0.5" }}
      >
        <QuoteIconV2 />
      </div>
      {viewType !== "mobile" && (
        <>
          <div
            className={`${dockState} node`}
            data-tooltip="Click for Tutorial"
          >
            <QuestionIcon />
          </div>

          <div className="dock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>
        </>
      )}
      <div
        id="start"
        className={`${dockState} node ${load}`}
        data-tooltip={load === "success" ? saveTooltip : "Click to Save"}
        onClick={() => {
          handleSubmit();
          handleTooltipChange();
        }}
      >
        <SaveIcon load={load} />
      </div>
    </div>
  );
}
