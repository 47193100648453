// React
import React, { useState } from "react";

// Internal Components
import MouseTooltip from "./MouseTooltip";
import {
  IkigaiIcon,
  QuoteIconV2,
  ValuesIcon,
  VitIcon,
} from "../../components/Icons/Icon";

// Utils
import { dateFormatter } from "../../utils/UtilityFunctions";
import { continuationCalculator } from "../../utils/UtilityFunctions";

// Styling
import "./tooltip.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";

function TooltipWrapper({
  mouseState,
  viewType,
  meyear,
  cursorDisplayState,
  closeTooltip,
}) {
  const renderMouseBlock = (state) => {
    if (state === "") {
      return;
    }

    if (state === "me") {
      // return concatenated string
      let formattedDate = dateFormatter(meyear.obj.birthday);
      let continuation = continuationCalculator(meyear.obj.birthday);
      return (
        <div className="tooltip__wrapper">
          <div className="tooltip__container">
            <h1 className="sickTooltip">{`I am ${meyear.obj.name}. I was born on ${formattedDate} and today is day ${continuation} of my life.`}</h1>
          </div>
          <div className="tooltip--icon">
            <VitIcon />
          </div>
          {viewType === "mobile" && (
            <div className="tooltip--icon--close" onClick={closeTooltip}>
              <FontAwesomeIcon
                icon={faCircleMinus}
                size="2x"
                style={{
                  color: "#004a22",
                  border: "2px solid #e2dfcf",
                  borderRadius: "50%",
                }}
              />
            </div>
          )}
        </div>
      );
    }

    if (state === "values") {
      // return grid with different elements in

      return (
        <div className="tooltip__wrapper">
          <div className="tooltip--grid">
            {Object.keys(meyear.obj.values).map((obj, index) => {
              return (
                <div
                  key={index}
                  className="tooltip--column"
                  style={
                    viewType === "desktop"
                      ? {
                          width: `${100 / meyear.values_arr.length}%`,
                          paddingRight: "6px",
                        }
                      : null
                  }
                >
                  <div className="tooltip--row--top">
                    <h1 className="sickTooltip">
                      {meyear.obj.values[obj].title}
                    </h1>
                  </div>
                  <div className="tooltip--row--bottom">
                    <h2 className="sickTooltip">
                      {meyear.obj.values[obj].description}
                    </h2>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="tooltip--icon">
            <ValuesIcon />
          </div>
          {viewType === "mobile" && (
            <div className="tooltip--icon--close" onClick={closeTooltip}>
              <FontAwesomeIcon
                icon={faCircleMinus}
                size="2x"
                style={{
                  color: "#004a22",
                  border: "2px solid #e2dfcf",
                  borderRadius: "50%",
                }}
              />
            </div>
          )}
        </div>
      );
    }

    if (state === "vision") {
      // return columns
      return (
        <div className="tooltip__wrapper">
          <div className="tooltip--grid">
            {Object.keys(meyear.obj.vision).map((obj, index) => (
              <div key={index} className="tooltip--column">
                <div className="tooltip--row--bottom">
                  <h2 className="sickTooltip">
                    {meyear.obj.vision[obj].description}
                  </h2>
                </div>
              </div>
            ))}
          </div>
          <div className="tooltip--icon">
            <ValuesIcon />
          </div>
          {viewType === "mobile" && (
            <div className="tooltip--icon--close" onClick={closeTooltip}>
              <FontAwesomeIcon
                icon={faCircleMinus}
                size="2x"
                style={{
                  color: "#004a22",
                  border: "2px solid #e2dfcf",
                  borderRadius: "50%",
                }}
              />
            </div>
          )}
        </div>
      );
    }

    if (state === "ikigai" || state === "quote") {
      return (
        <div className="tooltip__wrapper">
          <div className="tooltip__container">
            <h1 className="sickTooltip">{`${meyear.obj[state]}`}</h1>
          </div>
          <div className="tooltip--icon">
            {state === "ikigai" ? <IkigaiIcon /> : <QuoteIconV2 />}
          </div>
          {viewType === "mobile" && (
            <div className="tooltip--icon--close" onClick={closeTooltip}>
              <FontAwesomeIcon
                icon={faCircleMinus}
                size="2x"
                style={{
                  color: "#004a22",
                  border: "2px solid #e2dfcf",
                  borderRadius: "50%",
                }}
              />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <section style={{ width: "100%", height: "100%" }}>
      <MouseTooltip
        viewType={viewType}
        visible={cursorDisplayState}
        offsetX={15}
        offsetY={10}
        zIndex={1000}
        fontState={96}
      >
        {renderMouseBlock(mouseState)}
      </MouseTooltip>
    </section>
  );
}

export default TooltipWrapper;
