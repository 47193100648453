import React, { useEffect, useRef, useCallback, useState } from "react";

const AutoResizeTextArea = ({
  value,
  onChange,
  id,
  className,
  wrap,
  autoComplete,
  placeholder,
  name,
}) => {
  const textAreaRef = useRef();

  const [scrollHeight, setScrollHeight] = useState(0);

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      const newScrollHeight = textAreaRef.current.scrollHeight;

      textAreaRef.current.style.height = `${newScrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  // useEffect(() => {
  //   if (textAreaRef.current) {
  //     textAreaRef.current.style.height = "auto";
  //     textAreaRef.current.style.height = `${scrollHeight}px`;
  //   }
  // }, [scrollHeight]);

  return (
    <textarea
      id={id}
      ref={textAreaRef}
      value={value}
      name={name}
      onChange={onChange}
      style={{
        overflow: "hidden",
        resize: "none",
      }}
      className={className}
      placeholder={placeholder}
      wrap={wrap}
      autoComplete={autoComplete}
    />
  );
};

export default AutoResizeTextArea;
