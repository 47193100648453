// React
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// D3
import * as d3 from "d3";

// Internal Components
import generateSecret from "../../../utils/generateSecret";
import { ZeroRemove } from "./../../../utils/UtilityFunctions";

// Styling
import "./tcr.css";

const TasksCompletionRate = (props) => {
  const navigate = useNavigate();
  const d3Chart3 = useRef();

  const parseDate = d3.timeParse("%Y-%m-%d");

  const key = generateSecret().toString();
  let device = props.device;

  useEffect(() => {
    let taskTcrData = props.data;
    //Data Transformations
    taskTcrData &&
      taskTcrData.forEach((day) => {
        let sum =
          (day.momentOneScore !== undefined && parseFloat(day.momentOneScore)) +
          (day.momentTwoScore !== undefined && parseFloat(day.momentTwoScore)) +
          (day.momentThreeScore !== undefined &&
            parseFloat(day.momentThreeScore)) +
          (day.momentFourScore !== undefined &&
            parseFloat(day.momentFourScore)) +
          (day.momentFiveScore !== undefined &&
            parseFloat(day.momentFiveScore));
        // console.log(sum);

        day.total = sum;

        let count =
          (day.momentOneScore / day.momentOneScore === 1 ||
          day.momentOneScore == 0
            ? 1
            : 0) +
          (day.momentTwoScore / day.momentTwoScore == 1 ||
          day.momentTwoScore == 0
            ? 1
            : 0) +
          (day.momentThreeScore / day.momentThreeScore == 1 ||
          day.momentThreeScore == 0
            ? 1
            : 0) +
          (day.momentFourScore / day.momentFourScore === 1 ||
          day.momentFourScore == 0
            ? 1
            : 0) +
          (day.momentFiveScore / day.momentFiveScore === 1 ||
          day.momentFiveScore == 0
            ? 1
            : 0);
        day.tasksAttempted = count;

        let tcr = day.total / 10 / day.tasksAttempted;
        day.taskCompletionRate = tcr;
      });

    //Getting Dates
    const taskCompletionRateData =
      Array.isArray(taskTcrData) &&
      taskTcrData.map((each) => {
        let node = false;
        let memOne = each.momentOne;
        let memTwo = each.momentTwo;
        let memThree = each.momentThree;
        let memFour = each.momentFour;
        let memFive = each.momentFive;

        if (props.nodeSelected === each.logDate.slice(0, 10)) {
          node = true;
        }

        return {
          id: each._id,
          date: parseDate(each.logDate.slice(0, 10)),
          es: each.endScore,
          tcr: each.taskCompletionRate,
          node: node,
          m1: memOne,
          m1Score: each.momentOneScore,
          m2: memTwo,
          m2Score: each.momentTwoScore,
          m3: memThree,
          m3Score: each.momentThreeScore,
          m4: memFour,
          m4Score: each.momentFourScore,
          m5: memFive,
          m5Score: each.momentFiveScore,
        };
      });

    const tcrDataFinal =
      taskTcrData && taskCompletionRateData.sort((a, b) => a.date - b.date);

    let tcrDataFinalFinal = () => {
      if (tcrDataFinal) {
        if (tcrDataFinal.length > 5) {
          let tcrDataLength = tcrDataFinal.length - 1;

          if (tcrDataFinal[tcrDataLength].es === undefined) {
            tcrDataFinal.pop();
          }
        }
      }

      return tcrDataFinal;
    };

    const avgArray = [];

    tcrDataFinalFinal() &&
      tcrDataFinalFinal().map((each) => avgArray.push(each.tcr));

    ZeroRemove(avgArray);

    const avg = Number(d3.mean(avgArray));

    //Getting
    const margin = { top: 24, right: 24, bottom: 48, left: 36 };
    const width =
      parseInt(d3.select(`#d3demo4${device}`).style("width")) -
      margin.left -
      margin.right;
    const height =
      parseInt(d3.select(`#d3demo4${device}`).style("height")) -
      margin.top -
      margin.bottom;

    // Set up chart

    const svg = d3
      .select(d3Chart3.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // x axis scale
    const x = d3
      .scaleTime()
      .domain(
        d3.extent(tcrDataFinalFinal(), function (d) {
          return d.date;
        })
      )
      .range([0, width]);

    const tickFormat =
      tcrDataFinalFinal().length < 50
        ? d3.timeFormat("%b %y")
        : d3.timeFormat("%b %y");

    const xAxis2 = d3.axisBottom(x).tickFormat(d3.timeFormat(tickFormat));

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis2.ticks(d3.timeMonth))
      .style("font-family", "futura")
      .style("font-weight", 200)
      .style("font-size", "8px")
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 6) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Date");

    // Get the max value of counts
    const max = d3.max(tcrDataFinalFinal(), function (d) {
      return d.tcr;
    });

    const min = d3.min(tcrDataFinalFinal(), function (d) {
      return d.tcr;
    });

    const upperLimit = max - max / 20;
    const lowerLimit = min + max / 20;

    var handleNavigate = (id) => {
      window.open(`/today/${id}`, "_blank");
    };

    // y axis scale
    const yScale = d3.scaleLinear().domain([0, max]).range([height, 0]);

    const customTicks = [min, max];
    const customTickLabels = ["Nope", "Yeah"];

    // Create y-axis
    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(customTicks)
      .tickFormat((d, i) => customTickLabels[i]);

    svg
      .append("g")
      .call(yAxis)
      .style("font-family", "futura")
      .style("font-size", "8px")
      .style("font-weight", 200)
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Efficiency");

    // Draw line
    svg
      .append("path")
      .datum(tcrDataFinalFinal())
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "0.1")
      .attr("stroke-width", 6)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(d.tcr);
          })
          .curve(d3.curveBasis)
      );

    svg
      .append("path")
      .datum(tcrDataFinalFinal())
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "1")
      .style("z-index", "-1")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", 4)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(avg);
          })
      );

    const Tooltip1 = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("border", "2px solid #004a22")
      .style("border-radius", "2.5px")
      .style("max-width", "250px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover = function (d) {
      Tooltip1.style("opacity", 1);
      Tooltip1.style("display", "flex");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "2")
        .style("cursor", "pointer")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    const mousemove = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo4${device}`));

      Tooltip1.html(
        `<div style={{ display: 'flex', flex-direction: 'column'}}>
             <p>${d.m1}</p> 
             <p>${d.m2}</p> 
             <p>${d.m3}</p> 
             <p>${d.m4}</p> 
             <p>${d.m5}</p> 
								</div>`
      )
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "rgba(0,74,34,1)");
    };

    var mouseleave = function (d) {
      Tooltip1.style("opacity", 0);
      Tooltip1.style("display", "none");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "1")
        .style("cursor", "auto")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    svg
      .append("g")
      .selectAll("square") // Change "dot" to "square"
      .data(tcrDataFinalFinal())
      .enter()
      .append("rect") // Change "circle" to "rect"
      .attr("x", function (d) {
        return x(d.date) - 2.5;
      })
      .attr("y", function (d) {
        return yScale(d.tcr) - 2.5;
      })
      .attr("width", 10) // Set the width of the square
      .attr("height", 10) // Set the height of the square
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners (ry is for vertical rounded corners, not required for squares)
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (d.tcr > upperLimit || d.tcr < lowerLimit) {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(103, 36, 131,1)";
          }
        } else {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(0,74,34,1)";
          }
        }
      }) // Set the fill color
      .style("stroke", function (d) {
        if (d.node === true) {
          return "#f5f5f5";
        } else {
          return "#e2dfcf";
        }
      }) // Set the stroke color
      .style("stroke-width", "1")
      .style("z-index", 10)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .on("click", function (d) {
        Tooltip1.style("display", "none");
        handleNavigate(d.srcElement.__data__.id);
      });
  }, [props.nodeSelected]);

  return (
    <div
      id={`d3demo4${device}`}
      style={props.graphWidth && { width: `${props.graphWidth}px` }}
    >
      <svg ref={d3Chart3}></svg>
    </div>
  );
};

export default TasksCompletionRate;
