// React
import React, { useRef, useState, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form, ToggleButton } from "react-bootstrap";

// Internal Components
import TestFooter from "../../layouts/Footer/TestFooter";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import Header from "../../layouts/Header/Header";

import PrintableContent from "../../components/ValuesProcess/PrintableContent";
import generateSecret from "../../utils/generateSecret";
import {
  CreateDayIcon,
  CreateDayIconV2,
  SevenIcon,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  SaveIcon,
  ProfileIcon,
} from "../../components/Icons/Icon";

// External Packages
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faCirclePlus,
  faCircleMinus,
  faGlasses,
  faX,
  faPenToSquare,
  faEraser,
  faQuestion,
  faCompassDrafting,
  faSignature,
  faCalendarDay,
  faDna,
  faQuoteLeft,
  faLandmarkDome,
  faArrowUp91,
} from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";

// User Actions
import {
  listDebriefs,
  updateDebriefAction,
  deleteDebriefAction,
} from "../../actions/debriefActions";

// Styling
import "../../screens/Values/values.css";

function DebriefUpdateScreen(history) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const [isScroll, setIsScrollState] = useState(false);
  const [isTitle, setIsTitle] = useState(true);
  const [isIntro, setIsIntro] = useState(true);
  const [isExplanation, setIsExplanation] = useState(false);
  const [isDebrief, setIsDebrief] = useState(true);
  const [isMenu, setIsMenu] = useState(false);

  const [showTraits, setShowTraits] = useState(false);
  const [traitsButton, setTraitsButton] = useState(true);

  const [pic, setPic] = useState();
  const [poc, setPoc] = useState();
  const [nic, setNic] = useState();
  const [noc, setNoc] = useState();
  const [debriefName, setDebriefName] = useState();
  const [debriefOutcome, setDebriefOutcome] = useState();
  const [linkId, setLinkId] = useState();

  const [posInHover, setPosInHover] = useState(false);
  const handleHoverIn_PosIn = () => {
    setPosInHover((posInHover) => true);
  };
  const handleHoverOut_PosIn = () => {
    setPosInHover((posInHover) => false);
  };

  const [posOutHover, setPosOutHover] = useState(false);
  const handleHoverIn_PosOut = () => {
    setPosOutHover((posOutHover) => true);
  };
  const handleHoverOut_PosOut = () => {
    setPosOutHover((posOutHover) => false);
  };

  const [negInHover, setNegInHover] = useState(false);
  const handleHoverIn_NegIn = () => {
    setNegInHover((negInHover) => true);
  };
  const handleHoverOut_NegIn = () => {
    setNegInHover((negInHover) => false);
  };

  const [negOutHover, setNegOutHover] = useState(false);
  const handleHoverIn_NegOut = () => {
    setNegOutHover((negOutHover) => true);
  };
  const handleHoverOut_NegOut = () => {
    setNegOutHover((negOutHover) => false);
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const debriefUpdate = useSelector((state) => state.debriefUpdate);
  const { success: successUpdate } = debriefUpdate;

  const debriefList = useSelector((state) => state.debriefList);
  const { debriefs } = debriefList;

  const debriefDelete = useSelector((state) => state.debriefDelete);
  const { loading: loadingDelete, error: errorDelete } = debriefDelete;

  useEffect(() => {
    dispatch(listDebriefs());
  }, [dispatch, userInfo, history]);

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateDebriefAction(id, debriefName, debriefOutcome, pic, poc, nic, noc)
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (!debriefName || !debriefOutcome || !pic || !poc || !nic || !noc) return;

    // resetHandler();
  };

  const deleteHandler = () => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteDebriefAction(id));
    }
    navigate("/debrief");
  };

  useEffect(() => {
    const fetching = async () => {
      const key = generateSecret().toString();

      try {
        const { data } = await axios.get(
          `https://nwd-backend.herokuapp.com/api/debriefs/debrief/${id}`
        );
        setDebriefName(data.debriefName);
        setDebriefOutcome(data.debriefOutcome);
        setPic(data.pic);
        setPoc(data.poc);
        setNic(data.nic);
        setNoc(data.noc);
      } catch (error) {
        console.log(error);
      }
    };
    fetching();
  }, [id]);

  const scrollState = {
    hidden: {
      display: "none",
    },
    scrollFlash: {
      display: "block",
      color: "whitesmoke",
      transition: {
        duration: 0.5,
        ease: "linear",
        repeat: 20,
      },
    },
  };

  const titleState = {
    titleOff: {
      display: "none",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        repeat: 5,
      },
    },
    titleOn: {
      display: "block",
      color: "#004a22",
      marginBottom: "12px",
      fontSize: "3em",
    },
  };

  const introStateMob = {
    hidden: {
      opacity: "0",
      width: "0%",
      display: "none",
      scale: 0.1,
    },
    open: {
      opacity: "1",
      width: "95%",
      display: "flex",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: "0",
      width: "0%",
      display: "none",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const explanationStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const foundationStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      width: "95%",
      scale: 1,
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0.1,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const consistencyStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const experienceStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const specificityStateMob = {
    hidden: {
      display: "none",
      width: "50%",
    },
    open: {
      display: "block",
      width: "95%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const finalDisplayStateMob = {
    hidden: {
      display: "none",
      width: "50%",
    },
    open: {
      display: "block",
      width: "95%",
      transition: {
        duration: 2,
        ease: "easeInOut",
        delay: 0.25,
      },
    },
    closed: {
      display: "none",
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
        delay: 0.25,
      },
    },
  };

  const introState = {
    hidden: {
      display: "none",
      scale: 0.1,
    },
    open: {
      display: "block",
      scale: 1,
      width: "auto",
      transition: {
        duration: 2.5,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const explanationState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "65%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const foundationState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const debriefState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      width: "90%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const consistencyState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const experienceState = {
    hidden: {
      display: "none",
      scale: 0,
      x: "200%",
    },
    open: {
      display: "flex",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const specificityState = {
    hidden: {
      display: "none",
      width: "90%",
    },
    open: {
      display: "flex",
      width: "90%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      width: "90%",
      display: "none",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const finalDisplayState = {
    hidden: {
      display: "none",
      width: "90%",
    },
    open: {
      display: "flex",
      width: "90%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      width: "90%",
      display: "none",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const valueState = {
    hide: {
      display: "none",
    },
    show: {
      display: "block",
      whiteSpace: "normal",
      textAlign: "start",
      paddingRight: "6px",
    },
  };

  const valuesState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const testState = {
    hidden: {
      display: "none",
      width: "95%",
    },
    open: {
      display: "flex",
      width: "95%",
    },
    closed: {
      display: "none",
      width: "95%",
    },
  };

  return (
    <>
      {vw > 500 ? (
        <main style={{ height: "100vh", width: "100%" }}>
          {userInfo ? <Header variant="light" /> : <Header variant="light" />}

          <motion.div className="introStageMain">
            <motion.section
              className="debriefContainer"
              initial="hidden"
              animate={isDebrief ? "open" : "closed"}
              variants={debriefState}
            >
              <div className="debriefRow_title">
                <div>
                  <label>Name of Debrief:</label>
                  <input
                    className="debriefInput_small"
                    onChange={(e) => setDebriefName(e.target.value)}
                    value={debriefName}
                    placeholder="Title your debrief"
                  ></input>
                </div>
                <div>
                  <label>
                    I want to understand the following more clearly and easily:
                  </label>
                  <input
                    className="debriefInput_small"
                    onChange={(e) => setDebriefOutcome(e.target.value)}
                    value={debriefOutcome}
                    placeholder="Be precise. Which exact outcome or event are you trying to process."
                  ></input>
                </div>
              </div>
              <div className="debriefRow_Cols">
                {posInHover === false && vw > 500 ? (
                  <motion.div
                    className="InControl column"
                    onMouseEnter={handleHoverIn_PosIn}
                  >
                    <h2>Positive</h2>
                    <h3>Inside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="InControl column active"
                    onMouseLeave={handleHoverOut_PosIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPic(e.target.value)}
                    >
                      {pic}
                    </textarea>
                  </motion.div>
                )}

                {posOutHover === false && vw > 500 ? (
                  <motion.div
                    className="OutControl column"
                    onMouseEnter={handleHoverIn_PosOut}
                  >
                    <h2>Positive</h2>
                    <h3>Outside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="column active"
                    onMouseLeave={handleHoverOut_PosOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPoc(e.target.value)}
                    >
                      {poc}
                    </textarea>
                  </motion.div>
                )}

                <div class="vertical-line"></div>

                {negInHover === false && vw > 500 ? (
                  <motion.div
                    className="InControl column"
                    onMouseEnter={handleHoverIn_NegIn}
                  >
                    <h2>Negative</h2>
                    <h3>Inside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="InControl column active"
                    onMouseLeave={handleHoverOut_NegIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNic(e.target.value)}
                    >
                      {nic}
                    </textarea>
                  </motion.div>
                )}

                {negOutHover === false && vw > 500 ? (
                  <motion.div
                    className="negInControl column"
                    onMouseEnter={handleHoverIn_NegOut}
                  >
                    <h2>Negative</h2>
                    <h3>Outside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="negOutControl column active"
                    onMouseLeave={handleHoverOut_NegOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNoc(e.target.value)}
                    >
                      {noc}
                    </textarea>
                  </motion.div>
                )}
              </div>
              <div className="debriefRow_buttons">
                <PDFDownloadLink
                  className="valuesButton"
                  document={
                    <PrintableContent
                      posIn={pic}
                      posOut={poc}
                      negIn={nic}
                      negOut={noc}
                      name={debriefName}
                      aim={debriefOutcome}
                    />
                  }
                  fileName={`${debriefName}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download PDF"
                  }
                </PDFDownloadLink>

                <div className="showHideButtons">
                  <button className="valuesButton" onClick={updateHandler}>
                    Save
                  </button>
                  <button className="valuesButton" onClick={deleteHandler}>
                    Delete
                  </button>
                </div>
              </div>
            </motion.section>
          </motion.div>
        </main>
      ) : (
        // ===========================================================================================
        <main style={{ height: "auto", width: "100%" }}>
          {userInfo ? <Header variant="light" /> : <Header variant="light" />}
          <motion.div className="introStageMain">
            <motion.section
              className="debriefContainer"
              initial="hidden"
              animate={isDebrief ? "open" : "closed"}
              variants={debriefState}
            >
              <motion.div className="posInControl column">
                <div className="tutorial access">
                  <label className="score_label">
                    <strong>Name of Debrief:</strong>
                  </label>
                  <div className="icon_div">
                    <div className="newIcon">
                      <NameIconV3 />
                    </div>
                  </div>
                </div>
                <textarea
                  className="debriefInput_small"
                  wrap="soft"
                  onChange={(e) => setDebriefName(e.target.value)}
                  value={debriefName}
                  placeholder="Title your debrief"
                ></textarea>
              </motion.div>

              <motion.div className="posInControl column">
                <div className="tutorial access">
                  <label className="score_label">
                    <strong>
                      I want to understand the following more clearly and
                      easily:
                    </strong>
                  </label>
                  <div className="icon_div">
                    <div className="newIcon">
                      <QuestionIcon />
                    </div>
                  </div>
                </div>
                <textarea
                  className="debriefInput_small"
                  onChange={(e) => setDebriefOutcome(e.target.value)}
                  value={debriefOutcome}
                  wrap="soft"
                  placeholder="Be precise. Which exact outcome or event are you trying to understand."
                ></textarea>
              </motion.div>

              <hr className="debriefLine"></hr>
              <div className="debriefRow_buttons">
                <PDFDownloadLink
                  className="valuesButton"
                  document={
                    <PrintableContent
                      posIn={pic}
                      posOut={poc}
                      negIn={nic}
                      negOut={noc}
                      name={debriefName}
                      aim={debriefOutcome}
                    />
                  }
                  fileName={`${debriefName}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download PDF"
                  }
                </PDFDownloadLink>
                <div className="showHideButtons">
                  <button className="valuesButton" onClick={updateHandler}>
                    Save
                  </button>
                  <button className="valuesButton" onClick={deleteHandler}>
                    Delete
                  </button>
                </div>
                {/* <div className="showHideButtons">
  <button className="valuesButton" onClick={()=>{handleHoverIn_PosOut(); handleHoverIn_PosIn(); handleHoverIn_NegIn(); handleHoverIn_NegOut();}}>Show</button>
  <button className="valuesButton" onClick={()=>{handleHoverOut_PosOut(); handleHoverOut_PosIn(); handleHoverOut_NegIn(); handleHoverOut_NegOut();}}>Hide</button>
</div> */}
              </div>
              <div className="debriefRow_Cols">
                {posInHover === false && vw > 500 ? (
                  <motion.div
                    className="posInControl column"
                    onMouseEnter={handleHoverIn_PosIn}
                  >
                    <h2>Positive & Inside Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="posInControl column active"
                    onMouseLeave={handleHoverOut_PosIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPic(e.target.value)}
                      value={pic}
                    ></textarea>
                  </motion.div>
                )}

                {posOutHover === false && vw > 500 ? (
                  <motion.div
                    className="posOutControl column"
                    onMouseEnter={handleHoverIn_PosOut}
                  >
                    <h2>Positive & Outside Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="posOutControl column active"
                    onMouseLeave={handleHoverOut_PosOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPoc(e.target.value)}
                      value={poc}
                    ></textarea>
                  </motion.div>
                )}

                {negInHover === false && vw > 500 ? (
                  <motion.div
                    className="negInControl column"
                    onMouseEnter={handleHoverIn_NegIn}
                  >
                    <h2>Negative & Inside of Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="negInControl column active"
                    onMouseLeave={handleHoverOut_NegIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNic(e.target.value)}
                      value={nic}
                    ></textarea>
                  </motion.div>
                )}

                {negOutHover === false && vw > 500 ? (
                  <motion.div
                    className="negInControl column"
                    onMouseEnter={handleHoverIn_NegOut}
                  >
                    <h2>Negative & Outside of Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="negOutControl column active"
                    onMouseLeave={handleHoverOut_NegOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNoc(e.target.value)}
                      value={noc}
                    ></textarea>
                  </motion.div>
                )}
              </div>
            </motion.section>
          </motion.div>
        </main>
      )}
      <TestFooter />
    </>
  );
}

export default DebriefUpdateScreen;
