// React
import React, { useState } from "react";

// Internal Components
import PlanningTile from "./PlanningTile";
import ProcessingTile from "./ProcessingTile";
import PrimingTile from "./PrimingTile";
import { FullDockMobileOne, FullDockMobileTwo } from "../dock/FullDockMobile";
import FullDock from "../dock/FullDock";
import DayDock from "../dock/DayDock";

function TileContainer({
  load,
  today,
  objectives,
  tooltipPosition,
  continuation,
  background,
  startSlider,
  handleChange,
  handleMouseState,
  handleColumnChange,
  handleSubmit,
  meyear,
  yearList,
  dayList,
  yearid,
  previousDay,
  ...props
}) {
  return (
    <form>
      <div className="tile__container">
        <PrimingTile
          today={today}
          startSlider={startSlider}
          tooltipPosition={tooltipPosition}
          background={background}
          handleChange={handleChange}
        />
        <FullDockMobileOne
          load={load}
          meyear={meyear.obj}
          handleMouseState={handleMouseState}
          yearid={yearid}
        />

        <PlanningTile
          today={today}
          tooltipPosition={tooltipPosition}
          startSlider={startSlider}
          continuation={continuation}
          background={background}
          handleChange={handleChange}
        />
        <FullDockMobileTwo
          objectives={objectives}
          handleColumnChange={handleColumnChange}
        />
        <ProcessingTile
          today={today}
          tooltipPosition={tooltipPosition}
          startSlider={startSlider}
          background={background}
          handleChange={handleChange}
        />
      </div>
      <FullDock
        load={load}
        objectives={objectives}
        meyear={meyear}
        yearList={yearList}
        today={today}
        previousDay={previousDay}
        handleMouseState={handleMouseState}
        handleColumnChange={handleColumnChange}
        handleSubmit={handleSubmit}
      />
      <DayDock
        load={load}
        previousDay={previousDay}
        yearList={yearList}
        dayList={dayList}
        today={today}
        handleSubmit={handleSubmit}
      />
    </form>
  );
}

export default TileContainer;
