// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { listDays, deleteDayAction } from "../../actions/dayActions";

// Layout
import PageContainer from "../../layouts/PageContainer";
import Header from "../../layouts/Header/Header";
import TestFooter from "../../layouts/Footer/TestFooter";
import TileLoading from "../../components/Loading/TileLoading";

// Utils
import { dateFormatter } from "../../utils/UtilityFunctions";

// Styling
import "./profile_menu.css";

export const ProfileLogScreen = () => {
  // Call DayList but principally the ._id as that will be needed for the dispatch and the navigate

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dayList = useSelector((state) => state.dayList);
  const { days } = dayList;

  const [pageLoading, setPageLoading] = useState(true);

  // Dispatch Handler
  const deleteHandler = async (id) => {
    try {
      await dispatch(deleteDayAction(id));
      dispatch(listDays()); // Re-fetch the days to update the UI
    } catch (error) {
      alert(`Failed to delete day: ${error.message}`);
    }
  };

  const handleNav = (id) => {
    navigate(`/today/${id}`);
  };

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;

    setTimeout(() => {
      setPageLoading(false);
    }, randNum);
  };

  useEffect(() => {
    if (days) {
      loadingTimeout();
    }
  }, [days]);

  let tableBlock = () => {
    if (days) {
      return (
        <table className="journal--log--table">
          {days &&
            days.map((row, index) => {
              let date = row.logDate.slice(0, 10);

              return (
                <tr key={index} className="journal--log--table--row">
                  <td>{dateFormatter(date)}</td>

                  <td
                    onClick={() => {
                      handleNav(row.id);
                    }}
                  >
                    View Day
                  </td>
                  <td
                    onClick={() => {
                      deleteHandler(row.id);
                    }}
                  >
                    Delete Day
                  </td>
                </tr>
              );
            })}
          <tr style={{ color: "#004a22" }}>abc</tr>
        </table>
      );
    } else {
      return (
        <div className="tooltip__container">
          <h5 className="sickTooltip">
            When you log days in your journal, they will appear here.
          </h5>
        </div>
      );
    }
  };

  return (
    <>
      <Header variant="light" />
      <PageContainer className="profile__menu--screen">
        {pageLoading ? <TileLoading /> : <>{tableBlock()}</>}
      </PageContainer>
      <TestFooter />
    </>
  );
};
