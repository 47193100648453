// React
import React, { useEffect, useState } from "react";

//// Graphs
import {
  openingFrame,
  bestDayFeeling,
  worstDayFeeling,
  feelingVariance,
  feelingRecent,
  crossoverHigh,
  crossoverLow,
  bestDayProductive,
  bestDayEfficient,
  endFrame,
} from "./InsightsLog";

// External Packages
import * as d3 from "d3";

// Styling
import "./insightstory.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretSquareLeft,
  faCaretSquareRight,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function InsightStory(
  props,
  {
    updateOptionsSelected,
    updateGraphSelected,
    updateButtonSelected,
    updateNodeSelected,
  }
) {
  // Story State
  const [insights, setInsights] = useState([]);
  const [graphOptions, setGraphOptions] = useState([]);
  const [frame, setFrame] = useState(0);
  const [diaryDay, setDiaryDay] = useState();
  const [displayDiary, setDisplayDiary] = useState();
  const [displayMemory, setDisplayMemory] = useState("");

  // Parse Date D3 Function
  const parseDate = d3.timeParse("%Y-%m-%d");

  // Functions for Graph Passing State to Parent
  const handleClickOptions = (state) => {
    props.updateOptionsSelected(state);
  };

  const handleClickGraphs = (state) => {
    props.updateGraphSelected(state);
  };

  const handleClickButtons = (state) => {
    props.updateButtonSelected(state);
  };

  const handleClickNodes = (state) => {
    props.updateNodeSelected(state);
    setDiaryDay(state);
  };

  // Insights Compiler

  const insightsCompiler = () => {
    const objectArray = [
      openingFrame(),
      bestDayFeeling(props.data),
      worstDayFeeling(props.data),
      feelingVariance(props.data),
      feelingRecent(props.data),
      crossoverHigh(props.data),
      crossoverLow(props.data),
      bestDayProductive(props.data),
      bestDayEfficient(props.data),
      // bestDayWave(),
      endFrame(),
    ];

    let sortedInsights = objectArray.sort(
      (a, b) => b.comparator - a.comparator
    );

    setInsights((insights) => sortedInsights);

    let graphOptionsArr = [];
    if (sortedInsights.length > 0) {
      sortedInsights.length > 0 &&
        sortedInsights.map((insight, index) => {
          let button = 1;

          if (insight.insightCode === "time") {
            button = 1;
          } else if (insight.insightCode === "week") {
            button = 2;
          } else if (insight.insightCode === "cross") {
            button = 3;
          }

          graphOptionsArr.push({
            logDate: insight.logDate,
            button: button,
            option: insight.insightCode,
            graph: insight.insightType,
          });
        });
    }

    let gOLength = graphOptionsArr.length > 0 && graphOptionsArr.length - 1;

    let graphOptionsData =
      graphOptionsArr.length > 0 && graphOptionsArr.slice(1, gOLength);

    setGraphOptions((graphOptions) => graphOptionsData);
  };

  useEffect(() => {
    insightsCompiler();
  }, [props.data]);

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  return (
    <>
      {insights &&
        insights.map((story, index) => (
          <div
            key={index}
            onClick={() => {
              setFrame(frame + 1);
              handleClickOptions(graphOptions[index].option);
              handleClickGraphs(graphOptions[index].graph);
              handleClickButtons(graphOptions[index].button);
              handleClickNodes(graphOptions[index].logDate);
            }}
            style={
              frame === index
                ? {
                    display: "flex",
                  }
                : { display: "none" }
            }
            className={
              frame === 0 || frame === insights.length - 1
                ? "story__frame"
                : "story__frame story__frame_live"
            }
          >
            <div>
              {frame === 0 || frame === insights.length - 1 ? (
                <h3 className="headline">{story.insightHeadline}</h3>
              ) : (
                <h5>{`Chapter #${index}: ${story.insightHeadline}`}</h5>
              )}
              <hr></hr>
              <p className="subline">{story.insightStrapline}</p>
            </div>
            {/* {vw < 767 ? (
              <div
                style={
                  frame === 0 || frame === insights.length - 1
                    ? { display: "none" }
                    : {
                        display: "block",
                        backgroundColor: "#e2dfcf",
                        padding: "6px",
                        borderRadius: "5px",
                      }
                }
              >
                <p style={{ fontWeight: "bold", color: "#004a22" }}>
                  Here is a snapshot of that day:
                </p>
                <p style={{ color: "#004a22" }}>{displayMemory}</p>
              </div>
            ) : (
              <></>
            )} */}
            <div>
              <span
                style={
                  frame === 0 || frame === insights.length - 1
                    ? { display: "none" }
                    : {
                        display: "block",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                        paddingTop: "6px",
                      }
                }
              >
                Coaching Questions
              </span>
              <hr></hr>
              {story.questionOne && <p>{story.questionOne}</p>}
              {story.questionTwo && <p>{story.questionTwo}</p>}
              {story.questionThree && <p>{story.questionThree}</p>}
            </div>
            {story.details && vw < 1025 && (
              <>
                <div
                  style={
                    frame === 0 || frame === insights.length - 1
                      ? { display: "none" }
                      : {
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                  }
                >
                  <a href={`/today/${story.id}`}>
                    <button className="story__frame--button">
                      Visit this day
                    </button>
                  </a>
                </div>
                <div
                  style={
                    frame === 0 || frame === insights.length - 1
                      ? { display: "none" }
                      : {
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }
                  }
                >
                  <FontAwesomeIcon
                    icon={faCaretSquareLeft}
                    size="3x"
                    style={{ color: "#e2dfcf" }}
                  />
                  <button className="story__frame--button">
                    Rotate to see graph
                    <FontAwesomeIcon
                      icon={faRotateLeft}
                      style={{ color: "#004a22", marginLeft: "6px" }}
                    />
                  </button>
                  <FontAwesomeIcon
                    icon={faCaretSquareRight}
                    size="3x"
                    style={{ color: "#e2dfcf" }}
                    onClick={() => {
                      setFrame(frame + 1);
                      handleClickOptions(graphOptions[index].option);
                      handleClickGraphs(graphOptions[index].graph);
                      handleClickButtons(graphOptions[index].button);
                      handleClickNodes(graphOptions[index].logDate);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ))}
    </>
  );
}
