// React
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Graph
import * as d3 from "d3";

// Styling
import "./wavechart.css";

export default function WaveChart(props) {
  const [data, setData] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const d3Chart = useRef();
  const parseDate = d3.timeParse("%Y-%m-%d");
  let device = props.device;

  useEffect(() => {
    props.data &&
      props.data.forEach((day) => {
        let sum =
          (((day.momentOneScore !== undefined &&
            parseFloat(day.momentOneScore)) +
            (day.momentTwoScore !== undefined &&
              parseFloat(day.momentTwoScore)) +
            (day.momentThreeScore !== undefined &&
              parseFloat(day.momentThreeScore)) +
            (day.momentFourScore !== undefined &&
              parseFloat(day.momentFourScore)) +
            (day.momentFiveScore !== undefined &&
              parseFloat(day.momentFiveScore))) /
            20) *
          3;
        day.total = sum;

        // console.log(sum);

        day.total = sum;

        let count =
          (day.momentOneScore / day.momentOneScore === 1 ||
          day.momentOneScore == 0
            ? 1
            : 0) +
          (day.momentTwoScore / day.momentTwoScore == 1 ||
          day.momentTwoScore == 0
            ? 1
            : 0) +
          (day.momentThreeScore / day.momentThreeScore == 1 ||
          day.momentThreeScore == 0
            ? 1
            : 0) +
          (day.momentFourScore / day.momentFourScore === 1 ||
          day.momentFourScore == 0
            ? 1
            : 0) +
          (day.momentFiveScore / day.momentFiveScore === 1 ||
          day.momentFiveScore == 0
            ? 1
            : 0);
        day.tasksAttempted = count;

        let tcr = (day.total / day.tasksAttempted) * 15;
        day.taskCompletionRate = tcr;

        let feelingWave = day.endScore * 3;
        day.feelingWaveScore = feelingWave;

        let wave =
          parseFloat(day.total / 3) +
          parseFloat(day.taskCompletionRate / 3) +
          parseFloat(day.endScore);
        day.waveScore = wave;
      });

    const startScoreData =
      Array.isArray(props.data) &&
      props.data.map((each) => {
        let node = false;

        if (props.nodeSelected === each.logDate.slice(0, 10)) {
          node = true;
        }

        return {
          id: each.id,
          date: parseDate(each.logDate.slice(0, 10)),
          fWS: each.feelingWaveScore,
          tcr: each.taskCompletionRate,
          tc: each.total,
          wave: each.waveScore,
          node: node,
        };
      });

    const ssDataFinal = startScoreData.sort((a, b) => a.date - b.date);

    let ssDataFinalFinal = () => {
      if (ssDataFinal.length > 5) {
        let ssDataLength = ssDataFinal.length - 1;

        if (
          ssDataFinal[ssDataLength].wave == undefined ||
          ssDataFinal[ssDataLength].wave == NaN
        ) {
          ssDataFinal.pop();
        }
      }

      return ssDataFinal;
    };

    const avgArray = [];
    ssDataFinalFinal() &&
      ssDataFinalFinal().map((each, i) => avgArray.push(parseFloat(each.wave)));

    const ZeroRemove = (arr) => {
      let lastItem = arr.length - 1;

      if (
        arr[lastItem] === 0 ||
        isNaN(arr[lastItem]) ||
        arr[lastItem] === undefined
      ) {
        return arr.pop();
      } else {
        return arr;
      }
    };

    ZeroRemove(avgArray);

    console.log(ssDataFinalFinal());

    const avg = avgArray && Math.round(d3.mean(avgArray) * 10) / 10;

    const margin = { top: 24, right: 24, bottom: 48, left: 54 };
    const width =
      parseInt(d3.select(`#d3demo6${device}`).style("width")) -
      margin.left -
      margin.right;
    const height =
      parseInt(d3.select(`#d3demo6${device}`).style("height")) -
      margin.top -
      margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // x axis scale
    const x = d3
      .scaleTime()
      .domain(
        d3.extent(ssDataFinalFinal(), function (d) {
          return d.date;
        })
      )
      .range([0, width]);

    const tickFormat =
      ssDataFinalFinal().length < 50
        ? d3.timeFormat("%b %y")
        : d3.timeFormat("%b %y");

    const xAxis2 = d3.axisBottom(x).tickFormat(d3.timeFormat(tickFormat));

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis2.ticks(d3.timeMonth))
      .style("font-family", "futura")
      .style("font-weight", 200)
      .style("font-size", "8px")
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 6) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Date");

    const max1 = d3.max(ssDataFinalFinal(), function (d) {
      return d.wave;
    });
    const min1 = d3.min(ssDataFinalFinal(), function (d) {
      return d.wave;
    });

    const upperLimit = max1 - max1 / 20;
    const lowerLimit = min1 + max1 / 20;

    var handleNavigate = (id) => {
      // navigate(`/today/${id}`);

      window.open(`/today/${id}`, "_blank");
    };

    // y axis scale
    const yScale = d3.scaleLinear().domain([0, 15]).range([height, 0]);

    const customTickLabels = ["Like this", "Like that"];
    const customTicks = [0, 15];

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(customTicks)
      .tickFormat((d, i) => customTickLabels[i]);

    svg
      .append("g")
      .call(yAxis)
      .style("font-family", "futura")
      .style("font-weight", 200)
      .style("font-size", "8px")
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Wave");

    svg
      .append("path")
      .datum(ssDataFinalFinal())
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "0.1")
      .style("z-index", "-1")
      .attr("stroke-width", 6)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(d.wave);
          })
          .curve(d3.curveBasis)
      );

    var Tooltip = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("border", "2px solid #004a22")
      .style("border-radius", "2.5px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover = function (d) {
      Tooltip.style("opacity", 1);

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "2")
        .style("cursor", "pointer")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    const mousemove = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo6${device}`));
      Tooltip.text(`Click to see this day in your diary.`)
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "#004a22");
    };

    var mouseleave = function (d) {
      Tooltip.style("opacity", 0);

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "1")
        .style("cursor", "auto")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    svg
      .append("path")
      .datum(ssDataFinalFinal())
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "1")
      .style("z-index", "-1")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", 4)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return yScale(avg);
          })
      );

    svg
      .append("g")
      .selectAll("square") // Change "dot" to "square"
      .data(ssDataFinalFinal())
      .enter()
      .append("rect") // Change "circle" to "rect"
      .attr("x", function (d) {
        return x(d.date) - 2.5;
      })
      .attr("y", function (d) {
        return yScale(d.wave) - 2.5;
      })
      .attr("width", 10) // Set the width of the square
      .attr("height", 10) // Set the height of the square
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners (ry is for vertical rounded corners, not required for squares)
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (d.wave > upperLimit || d.wave < lowerLimit) {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(103, 36, 131,1)";
          }
        } else {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(0,74,34,1)";
          }
        }
      }) // Set the fill color
      .style("stroke", function (d) {
        // Check if the value falls within the specified range
        if (d.node === true) {
          return "#f5f5f5";
        } else {
          return "#e2dfcf";
        }
      }) // Set the stroke color
      .style("stroke-width", "1")
      .style("z-index", 10)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .on("click", function (d) {
        Tooltip.style("opacity", 0);
        handleNavigate(d.id);
      });
  }, [props.nodeSelected]);

  return (
    <>
      <div
        id={`d3demo6${device}`}
        style={props.graphWidth && { width: `${props.graphWidth}px` }}
      >
        <svg ref={d3Chart}></svg>
      </div>
    </>
  );
}
