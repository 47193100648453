// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { register } from "../../actions/userActions";

// Internal Components
import { ErrorMessage } from "../../components/Error/ErrorMessage";

export default function RegisterTest({ history }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subscription, setSubscription] = useState({
    sessionId: "123",
    plan: "trial",
    customerId: "123",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const vw = Math.max(document.documentElement.clientWidth);

  const [viewWidth, setViewWidth] = useState(vw);

  const createCustomer = async () => {
    try {
      const response = await fetch(
        "https://nwd-backend.herokuapp.com/create-customer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            name: name,
          }),
        }
      );

      if (response.ok) {
        const customerData = await response.json();

        // Now you can access the customer ID
        const customerId = customerData.id;

        if (customerId && customerId !== "123") {
          setSubscription((prevSubscription) => ({
            ...prevSubscription,
            customerId: customerId,
          }));

          dispatch(
            register(name, email, password, { ...subscription, customerId })
          );
        }
      }
    } catch (error) {
      console.log("Error creating customer:", error);
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate("/login");
    }
  }, [history, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      try {
        await createCustomer(); // Wait for createCustomer to complete
        const path = document.referrer;
        // Add a delay or wait for the state to update
        setTimeout(() => {
          const path = document.referrer;
          if (viewWidth > 500) {
            if (path.endsWith("/values")) {
              navigate("/year/create");
            } else {
              navigate("/register/profile");
            }
          } else {
            if (path.endsWith("/values")) {
              navigate("/year/create");
            } else {
              navigate("/register/profile");
            }
          }
        }, 1000); // Adjust the delay as necessary
      } catch (error) {
        console.error("Error creating customer:", error);
      }
    }
  };

  return (
    <>
      <main className="updateday--container">
        <section className="tile reg">
          <section className="registerContainer">
            {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
            {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
            <div className="tutorial access">
              <label style={{ fontWeight: "bold" }}>Register</label>
            </div>
            <img
              className="sticker"
              src="https://cdn.sanity.io/images/ou6d2gec/production/088a4919740e18a8e075aea3319a06cbe5dfba5d-4500x4500.png"
              width="25%"
              alt="daily planner"
              loading="lazy"
            />
            <form className="register form" onSubmit={submitHandler}>
              <div className="demographic__row--first">
                <label style={{ color: "#004a22" }} htmlFor="name">
                  Your Name
                </label>
                <input
                  id="name"
                  className="formInput register"
                  type="text"
                  value={name}
                  autocomplete="off"
                  placeholder="Enter your name here"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="demographic__row">
                <label style={{ color: "#004a22" }} htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  className="formInput register"
                  type="text"
                  value={email}
                  autocomplete="off"
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="demographic__row">
                <label style={{ color: "#004a22" }} htmlFor="password">
                  Password
                </label>
                <input
                  id="password"
                  className="formInput register"
                  type="password"
                  value={password}
                  autocomplete="off"
                  placeholder="Create your password here"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="demographic__row">
                <label style={{ color: "#004a22" }} htmlFor="confPassword">
                  Confirm Password
                </label>
                <input
                  id="confPassword"
                  className="formInput register"
                  type="password"
                  value={confirmPassword}
                  autocomplete="off"
                  placeholder="Confirm your password here"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <button className="registerButton" type="submit">
                Register
              </button>
              <span>
                By signing up, you agree to our{" "}
                <a href={"/terms-and-conditions"}>Terms of Use</a> and{" "}
                <a href={"/privacy-policy"}>Privacy Policy</a>
              </span>
              {/* <span>Already have a NWD, login <a href="/login">here...</a></span> */}
            </form>
          </section>
        </section>
      </main>
    </>
  );
}
