//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

// User Actions
import {
  updateProfile,
  updateProfileSubscription,
} from "../../../actions/userActions";

//Internal Components
import TestFooter from "../../../layouts/Footer/TestFooter";
import Header from "../../../layouts/Header/Header";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faGlasses,
  faDna,
  faLandmarkDome,
  faQuoteLeft,
  faEarthAmericas,
  faFloppyDisk,
  faPenToSquare,
  faEraser,
  faCircleCheck,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import CryptoJS from "crypto-js";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "../../../features/payments/CheckoutForm";
import Payment from "./Payment";

export default function ChoosePaymentScreen(props) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Props
  const { stripePromise } = props;

  // State
  // User
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateSubscription = useSelector(
    (state) => state.userUpdateSubscription
  );
  const { loading, error, success } = userUpdateSubscription;

  const [monthly, setMonthly] = useState("active");
  const [yearly, setYearly] = useState("");
  const [price, setPrice] = useState({
    core: "£0.00",
    classic: "£5.99",
    classic_coach: "£7.99",
  });

  const toggleRadio = () => {
    if (monthly === "active") {
      setMonthly("");
      setYearly("active");
      setPrice({ core: "£0.00", classic: "£50", classic_coach: "£75" });
    } else {
      setMonthly("active");
      setYearly("");
      setPrice({
        core: "£0.00",
        classic: "£5.99",
        classic_coach: "£7.99",
      });
    }
  };

  const [priceId, setPriceId] = useState("");
  const [plan, setPlan] = useState("");
  const [customerId, setCustomerId] = useState(
    userInfo && userInfo.subscription.customerId
  );

  const priceDetails = {
    core: {
      monthly: {
        id: "price_1OZSxtHUQmtkG68CVdXxllWM",
        lookup: "CORE_MONTHLY",
      },
      annual: {
        id: "price_1OZSz5HUQmtkG68Ct4bDJL5Z",
        lookup: "CORE_ANNUAL",
      },
    },
    classic: {
      monthly: {
        id: "price_1OZ8G4HUQmtkG68CZk1IyWga",
        lookup: "CLASSIC_MONTHLY",
      },
      annual: {
        id: "price_1OZ8IXHUQmtkG68Cb10QODbQ",
        lookup: "CLASSIC_ANNUAL",
      },
    },
    classic_coach: {
      monthly: {
        id: "price_1OZSjUHUQmtkG68CsVo5mANj",
        lookup: "CLASSIC_COACH_MONTHLY",
      },
      annual: {
        id: "price_1OZSkVHUQmtkG68C3x3KxxWw",
        lookup: "CLASSIC_COACH_ANNUAL",
      },
    },
  };

  const submitHandler = async (e, price, plan, url) => {
    e.preventDefault();

    let subscription = {
      sessionId: price,
      plan: plan,
      customerId: customerId,
    };

    dispatch(
      updateProfile({
        subscription,
      })
    );
    navigate(url);
  };

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main className="payment__screen--choose">
        <section className="tieredPricing__table">
          <div className="pricing--radio">
            <div className={`custom--radio ${monthly}`} onClick={toggleRadio}>
              Monthly
            </div>
            <div className={`custom--radio ${yearly}`} onClick={toggleRadio}>
              Annual
            </div>
          </div>
          <div className="pricing--container">
            <div className="pricing--column">
              <img
                className="pricing--image"
                src="https://cdn.sanity.io/images/ou6d2gec/production/cd837cafe11e48ec5e7041fdb7ce402505cf68d7-5000x3250.png"
                alt="price"
                width="100%"
              ></img>
              <h2 className="tier--header">All Features, Ads On</h2>
              <hr></hr>
              <div className="price--box">
                <span className="price--field">{price.core}</span>
                {monthly ? (
                  <span>
                    per<br></br>Month
                  </span>
                ) : (
                  <span>
                    per<br></br>Year
                  </span>
                )}
              </div>

              {monthly === "active" ? (
                <button
                  id="submit"
                  className="registerButton"
                  onClick={(e) =>
                    submitHandler(
                      e,
                      priceDetails.core.monthly.id,
                      priceDetails.core.monthly.lookup,
                      "/subscribe/core"
                    )
                  }
                >
                  Subscribe
                </button>
              ) : (
                <button
                  id="submit"
                  className="registerButton"
                  onClick={(e) =>
                    submitHandler(
                      e,
                      priceDetails.core.annual.id,
                      priceDetails.core.annual.lookup,
                      "/subscribe/core"
                    )
                  }
                >
                  Subscribe
                </button>
              )}
              <div id="error-message"></div>

              <div className="tier--features">
                This includes:
                <ul>
                  <li>Your Daily Diary</li>
                  <li>Your Insights Story</li>
                  <li>Week Planner</li>
                  <li>Interactive Experiences</li>
                  <li>The 'Art of Looking' Course</li>
                  <li>
                    and Ads enabled to help cover the cost of your platform
                    usage
                  </li>
                </ul>
              </div>
            </div>
            <div className="pricing--column">
              <img
                className="pricing--image"
                src="https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png"
                alt="price"
                width="100%"
              ></img>
              <h2 className="tier--header">All Features, Ads Disabled</h2>
              <hr></hr>
              <div className="price--box">
                <span className="price--field">{price.classic}</span>
                {monthly ? (
                  <span>
                    per<br></br>Month
                  </span>
                ) : (
                  <span>
                    per<br></br>Year
                  </span>
                )}
              </div>

              {monthly === "active" ? (
                <button
                  id="submit"
                  className="registerButton"
                  onClick={(e) =>
                    submitHandler(
                      e,
                      priceDetails.classic.monthly.id,
                      priceDetails.classic.monthly.lookup,
                      "/subscribe/classic"
                    )
                  }
                >
                  Subscribe
                </button>
              ) : (
                <button
                  id="submit"
                  className="registerButton"
                  onClick={(e) =>
                    submitHandler(
                      e,
                      priceDetails.classic.annual.id,
                      priceDetails.classic.annual.lookup,
                      "/subscribe/classic"
                    )
                  }
                >
                  Subscribe
                </button>
              )}
              <div id="error-message"></div>

              <div className="tier--features">
                This includes:
                <ul>
                  <li>All NoWastedDays Features</li>
                  <li>
                    Ads disabled as your subscription successfully supports your
                    use of the platform.
                  </li>
                </ul>
              </div>
            </div>
            <div className="pricing--column">
              <img
                className="pricing--image"
                src="https://cdn.sanity.io/images/ou6d2gec/production/c3bdc6191faaecfbbeed054e9df5e8396decfe0b-5000x3250.png"
                alt="price"
                width="100%"
              ></img>
              <h2 className="tier--header">
                All Features, Ads Removed, 24/7 Coach Support Activated
              </h2>
              <hr></hr>
              <div className="price--box">
                <span className="price--field">{price.classic_coach}</span>
                {monthly ? (
                  <span>
                    per<br></br>Month
                  </span>
                ) : (
                  <span>
                    per<br></br>Year
                  </span>
                )}
              </div>
              {monthly === "active" ? (
                <button
                  id="submit"
                  className="registerButton"
                  onClick={(e) =>
                    submitHandler(
                      e,
                      priceDetails.classic_coach.monthly.id,
                      priceDetails.classic_coach.monthly.lookup,
                      "/subscribe/classic_coach"
                    )
                  }
                >
                  Subscribe
                </button>
              ) : (
                <button
                  id="submit"
                  className="registerButton"
                  onClick={(e) =>
                    submitHandler(
                      e,
                      priceDetails.classic_coach.annual.id,
                      priceDetails.classic_coach.annual.lookup,
                      "/subscribe/classic_coach"
                    )
                  }
                >
                  Subscribe
                </button>
              )}
              <div id="error-message"></div>
              <div className="tier--features">
                This includes...
                <ul>
                  <li>All Core & Classic Features</li>
                  <li>
                    Activate coach chat: A direct support service to help you
                    understand your data and work together towards your mental
                    health goals.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <TestFooter />
    </>
  );
}
