//React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

//Internal Components
import {
  CreateDayIconV2,
  ValuesIcon,
  VitIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

//CSS Style
import "./dock.css";

export default function FullDock({
  today,
  previousDay,
  objectives,
  handleMouseState,
  handleColumnChange,
  handleSubmit,
  load,
  yearList,
  meyear,
}) {
  // Hooks
  const navigate = useNavigate();

  // State
  const [isVisible, setIsVisible] = useState(true);
  const [colorState, setColorState] = useState({
    one: "cls-1-invert",
    two: "cls-2-invert",
  });
  const [viewWidth, setViewWidth] = useState(
    Math.max(document.documentElement.clientWidth)
  );
  const [saveTooltip, setSaveTooltip] = useState("");

  const handleTooltipChange = () => {
    setSaveTooltip("Saving...");
    setTimeout(() => {
      setSaveTooltip("Saved");
    }, 1200);
  };
  const [createDayState, setCreateDayState] = useState({
    active: true,
    text: "",
  });

  const [previousDayState, setPreviousDayState] = useState({
    active: true,
    text: "",
  });

  const createDayHandler = () => {
    if (today && !today.endScore) {
      setCreateDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = false;
        shallow.text = "Add your final score to move onto a new day.";
        return shallow;
      });
    } else {
      setCreateDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = true;
        shallow.text = "Ready to start a new day?";
        return shallow;
      });
    }
  };

  useEffect(() => {
    createDayHandler();
  }, [today]);

  const previousDayHandler = () => {
    if (!yearList) {
      setPreviousDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = false;
        shallow.text = "You are yet to log multiple days.";
        return shallow;
      });
    } else {
      setPreviousDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = true;
        shallow.text = "Look back at the previous day.";
        return shallow;
      });
    }
  };

  useEffect(() => {
    previousDayHandler();
  }, [yearList]);

  useEffect(() => {
    const handleScroll = () => {
      if (viewWidth > 1024) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [viewWidth]);

  return (
    <section className="dock--section">
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className={"dock--container"}
      >
        <div className={`jordandock node`} data-tooltip={createDayState.text}>
          {/* Disable if endScore is empty or if moment is missing a momentScore */}
          {createDayState.active ? (
            <CreateDayIconV2 />
          ) : (
            <CreateDayIconV2 opacity="0.5" />
          )}
        </div>
        <div
          // Ensure this node is disabled if no previous day exists
          className={`jordandock node`}
          data-tooltip={previousDayState.text}
          onClick={previousDay}
        >
          {previousDayState.active ? (
            <PreviousDay />
          ) : (
            <PreviousDay opacity="0.5" />
          )}
        </div>
        {/* <div
          className={`jordandock node`}
          data-tooltip={"Quickly learn more about your journal"}
        >
          <QuestionIcon />
        </div> */}

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <div
          className={`jordandock node`}
          // Replace with passing year, initialising it as state and then calling the name key

          data-tooltip={
            meyear && meyear.name && meyear.birthday
              ? "Me"
              : "Add your name and date of birth to your year map to access it in the dock."
          }
          onClick={() => handleMouseState("me")}
        >
          <VitIcon />
        </div>

        <div
          style={{ height: "40px", width: "40px" }}
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.values
              ? "My Values"
              : "Add your values in your year map to access them in the dock."
          }
          onClick={() => handleMouseState("values")}
        >
          <ValuesIcon />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.vision
              ? "My Vision"
              : "Add your vision to your year map to access it through the dock."
          }
          onClick={() => handleMouseState("vision")}
        >
          <VisionIcon />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.ikigai
              ? "My Ikigai"
              : "Add your ikigai to your year map to access it in the dock"
          }
          onClick={() => handleMouseState("ikigai")}
        >
          <IkigaiIcon />
        </div>
        <div
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.quote
              ? "My Favourite Quote"
              : "Add your favourite quote to your year map to access it in the dock."
          }
          onClick={() => handleMouseState("quote")}
        >
          <QuoteIconV2 />
        </div>

        <div className={`jordandockwide node`} data-tooltip="Hide Overlay">
          <HideOverlayIcon />
        </div>

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-one"]?.title
              ? objectives["obj-one"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-one"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-one"].title ? (
            <OneIcon opacity={objectives["obj-one"]?.score} />
          ) : (
            <OneIcon opacity={objectives["obj-one"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-two"]?.title
              ? objectives["obj-two"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-two"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-two"].title ? (
            <TwoIcon opacity={objectives["obj-two"]?.score} />
          ) : (
            <TwoIcon opacity={objectives["obj-two"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-three"]?.title
              ? objectives["obj-three"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-three"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-three"].title ? (
            <ThreeIcon opacity={objectives["obj-three"]?.score} />
          ) : (
            <ThreeIcon opacity={objectives["obj-three"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-four"]?.title
              ? objectives["obj-four"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-four"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-four"].title ? (
            <FourIcon opacity={objectives["obj-four"]?.score} />
          ) : (
            <FourIcon opacity={objectives["obj-four"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-five"]?.title
              ? objectives["obj-five"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-five"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-five"].title ? (
            <FiveIcon opacity={objectives["obj-five"]?.score} />
          ) : (
            <FiveIcon opacity={objectives["obj-five"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-six"]?.title
              ? objectives["obj-six"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-six"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-six"].title ? (
            <SixIcon opacity={objectives["obj-six"]?.score} />
          ) : (
            <SixIcon opacity={objectives["obj-six"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-seven"]?.title
              ? objectives["obj-seven"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-seven"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-seven"].title ? (
            <SevenIcon opacity={objectives["obj-seven"]?.score} />
          ) : (
            <SevenIcon opacity={objectives["obj-seven"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-eight"]?.title
              ? objectives["obj-eight"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-eight"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-eight"].title ? (
            <EightIcon opacity={objectives["obj-eight"]?.score} />
          ) : (
            <EightIcon opacity={objectives["obj-eight"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-nine"]?.title
              ? objectives["obj-nine"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-nine"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-nine"].title ? (
            <NineIcon opacity={objectives["obj-nine"]?.score} />
          ) : (
            <NineIcon opacity={objectives["obj-nine"]?.score} op="0.5" />
          )}
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            objectives["obj-ten"]?.title
              ? objectives["obj-ten"].title.slice(0, 20) + "..."
              : "Add an objective into your week planner to access it here."
          }
          data-name="obj-ten"
          onClick={(e) => {
            handleColumnChange(e);
          }}
        >
          {objectives["obj-ten"].title ? (
            <TenIcon opacity={objectives["obj-ten"]?.score} />
          ) : (
            <TenIcon opacity={objectives["obj-ten"]?.score} op="0.5" />
          )}
        </div>

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <div
          className={`jordandock node_save ${load}`}
          data-tooltip={load === "success" ? saveTooltip : "Click to Save"}
          onClick={(e) => {
            handleSubmit(e);
            handleTooltipChange();
          }}
        >
          <SaveIcon load={load} />
        </div>
      </motion.div>
    </section>
  );
}
