export const YEARS_CREATE_REQUEST = "YEARS_CREATE_REQUEST";
export const YEARS_CREATE_SUCCESS = "YEARS_CREATE_SUCCESS";
export const YEARS_CREATE_FAIL = "YEARS_CREATE_FAIL";

export const YEARS_UPDATE_REQUEST = "YEARS_UPDATE_REQUEST";
export const YEARS_UPDATE_SUCCESS = "YEARS_UPDATE_SUCCESS";
export const YEARS_UPDATE_FAIL = "YEARS_UPDATE_FAIL";

export const YEARS_LIST_REQUEST = "YEARS_LIST_REQUEST";
export const YEARS_LIST_SUCCESS = "YEARS_LIST_SUCCESS";
export const YEARS_LIST_FAIL = "YEARS_LIST_FAIL";

export const YEARS_SINGLE_REQUEST = "YEARS_SINGLE_REQUEST";
export const YEARS_SINGLE_SUCCESS = "YEARS_SINGLE_SUCCESS";
export const YEARS_SINGLE_FAIL = "YEARS_SINGLE_FAIL";

export const VALUES_UPDATE_REQUEST = "VALUES_UPDATE_REQUEST";
export const VALUES_UPDATE_SUCCESS = "VALUES_UPDATE_SUCCESS";
export const VALUES_UPDATE_FAIL = "VALUES_UPDATE_FAIL";
